export interface MediaDeviceDto extends Partial<MediaDeviceInfo> {
  active: boolean;
  enabled: boolean;
  isMobile: boolean;
  facingMode?: string;
}

export const createConstrains = (device: MediaDeviceDto): any => {
  if (device.isMobile) {
    return {
      facingMode: { exact: device.facingMode },
    };
  } else {
    return {
      deviceId: device.deviceId,
    };
  }
};
