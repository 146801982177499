import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Alert from '../../utils/alert';
import { SessionStatus, SessionType, USER_ROLES } from '../../common/enums';
import AbstractModal from '../../components/AbstractModal';
import CustomMultiSelect, { ICustomSelectedItem } from '../../components/CustomMultiSelect';
import { CaseService } from '../../service/case.service';
import { CategoryService } from '../../service/category.service';
import { CaseResponseDto, CaseTemplateSummaryDto } from '../../service/dto/case.dto';
import { CategoryListItemDto } from '../../service/dto/category.dto';
import { SessionResponseDto } from '../../service/dto/session.dto';
import { SessionService } from '../../service/session.service';
import { getDateAndTimeDuration } from '../../utils';
import { setUserRole } from '../../utils/role';
import { IRouteComponent } from '../../routes';
import BookSessionContent from './BookSessionContent';
import ScheduleTable from './ScheduleTable';
import { schedulePageSize } from '../../common/constants';
import ReactPaginate from 'react-paginate';
import DefaultHeader from '../../components/DefaultHeader';
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {setLiveSessionCaseSelector, setLiveSessionCategorySelector} from "../../redux/actions";
import {LiveSessionCaseSelectorState} from "../../redux/types";

enum COMPONENT_STATE {
  LIST,
  CANCEL,
  JOIN,
}

const LiveSessionsList = ({ currentUser, currentInstitution }: IRouteComponent) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState(COMPONENT_STATE.LIST);
  const [sessionList, setSessionList] = useState<SessionResponseDto[]>([]);
  const [myLiveSession, setMyLiveSession] = useState<SessionResponseDto>();
  const [caseList, setCaseList] = useState<ICustomSelectedItem[]>([]);
  const [caseData, setCaseData] = useState<CaseResponseDto>();
  const [certifiedCaseList, setCertifiedCaseList] = useState<ICustomSelectedItem[]>([]);
  const [roles, setRoles] = useState<USER_ROLES[]>([USER_ROLES.Trainee]);
  const { selectedCases }: { selectedCases: string[]} = useAppSelector((state:any) => state.liveSessionCaseSelectorReducer);
  const { selectedCategories }: { selectedCategories: string[]} = useAppSelector((state:any) => state.liveSessionCategorySelectorReducer);
  const [selectedSession, setSelectedSession] = useState<SessionResponseDto>();
  const [categoryNames, setCategoryNames] = useState<ICustomSelectedItem[]>([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState<number>(0);

  useEffect(() => {
    const userRoles = setUserRole({ currentUser, currentInstitution });
    setRoles(userRoles);
    setPage(0);
    serviceGetCaseList();
    serviceCategoryFetch();
    serviceGetCertifiedCases();
  }, []);

  useEffect(() => {
    serviceGetSessionCount();
    serviceGetSessionList();
  }, [selectedCases, selectedCategories, roles]);

  useEffect(() => {
    serviceGetSessionList();
  }, [page]);

  const handleCategoryChange = (categoryIds: string[]) => {
    dispatch(setLiveSessionCategorySelector({ selectedCategories: categoryIds }));
  };

  const serviceCategoryFetch = () => {
    CategoryService.list(
      (data: CategoryListItemDto[]) => {
        setCategoryNames(
          data.map(category => {
            return { value: category.id, label: category.name };
          }),
        );
      },
      () => {},
    );
  };

  const onPageChange = (event: { selected: number }) => {
    setPage(event.selected);
  };

  // SERVICE FETCH
  const serviceGetSessionList = () => {
    SessionService.list(
      {
        cases: selectedCases,
        role: roles,
        page: page,
        pageSize: schedulePageSize,
        categories: selectedCategories,
        type: SessionType.AdHocSession,
        onlyOthers: true,
        onlyNotFinished: true,
      },
      (data: Array<SessionResponseDto>) => {
        setSessionList(data);
      },
      error => {
        setSessionList([]);
      },
    );
    SessionService.list(
      {
        cases: selectedCases,
        role: roles,
        page: page,
        pageSize: schedulePageSize,
        categories: selectedCategories,
        type: SessionType.AdHocSession,
        onlyMine: true,
        onlyNotFinished: true,
      },
      (data: Array<SessionResponseDto>) => {
        setMyLiveSession(data[0]);
      },
      error => {
        setMyLiveSession(undefined);
      },
    );
  };

  const serviceGetSessionCount = () => {
    SessionService.count(
      {
        cases: selectedCases,
        role: roles,
        categories: selectedCategories,
        type: SessionType.AdHocSession,
        onlyOthers: true,
        onlyNotFinished: true,
      },
      (data: number) => {
        setTotalPages(Math.floor((data - 1) / schedulePageSize) + 1);
      },
      error => {
        setTotalPages(0);
        setPage(0);
      },
    );
  };

  const handleCaseChange = (caseIds: string[]) => {
    dispatch(setLiveSessionCaseSelector({ selectedCases: caseIds }));
  };

  const serviceGetCaseList = () => {
    CaseService.listPublished(
      (data: Array<CaseTemplateSummaryDto>) => {
        setCaseList(
          data.map(ct => {
            return { label: ct.name, value: ct.id };
          }),
        );
      },
      () => {},
    );
  };

  const serviceGetCertifiedCases = () => {
    CaseService.listCertified(
      { search: '', rolesFilter: roles },
      (data: Array<CaseResponseDto>) => {
        const modifiedCaseList = data.map(v => {
          return { value: v.id, label: v.name };
        });
        setCertifiedCaseList(modifiedCaseList);
      },
      () => {},
    );
  };

  const serviceGetGyId = (chosenCaseId: string) => {
    CaseService.getById(
      chosenCaseId,
      (data: CaseResponseDto) => {
        setCaseData(data);
      },
      error => {},
    );
  };

  // SERVICE CREATE
  const serviceJoin = () => {
    SessionService.book(
      selectedSession!.id,
      (data: SessionResponseDto) => {
        serviceGetSessionList();
        setState(COMPONENT_STATE.LIST);
        navigate(`/session/stream/${data.id}`);
      },
      error => {
        console.log(error);
        Alert.warning(t('errorMessages.sessionNotBooked'));
      },
    );
  };

  const serviceCancel = (session: SessionResponseDto) => {
    SessionService.cancel(
      session.id,
      (data: SessionResponseDto) => {
        serviceGetSessionList();
        setState(COMPONENT_STATE.LIST);
        Alert.success(t('successMessages.sessionCanceled'));
      },
      error => {
        console.log(error);
        Alert.warning(t('errorMessages.sessionNotCanceled'));
      },
    );
  };

  // SESSION ACTIONS
  const cancelSession = (id: string, number: number) => {
    const chosenSession = sessionList[number];
    setSelectedSession(chosenSession);
    handleCancelSessionSubmit(chosenSession);
  };

  const joinSession = (id: string, number: number) => {
    const chosenSession = sessionList[number];
    serviceGetGyId(chosenSession.caseTemplateSummary.id);
    setSelectedSession(chosenSession);
    setState(COMPONENT_STATE.JOIN);
  };

  const detailsSession = (id: string) => {
    navigate('/session/details/' + id);
  };

  const resultSession = (id: string) => {
    navigate(`/session/result/${id}`);
  };

  const handleJoinSessionSubmit = () => {
    serviceJoin();
  };

  const handleCancelSessionSubmit = (session: SessionResponseDto) => {
    serviceCancel(session);
  };

  const renderTime = (item: SessionResponseDto) => {
    const { startTime, endTime, monthLabel, day } = getDateAndTimeDuration(item);
    return `${monthLabel} ${day}, ${startTime} - ${endTime}`;
  };

  let button;
  if (roles.includes(USER_ROLES.SP)) {
    if (myLiveSession) {
      button = {
        title: t('buttons.goOffline'),
        onClick: () => {
          handleCancelSessionSubmit(myLiveSession);
        },
        disabled: !certifiedCaseList || certifiedCaseList.length <= 0,
      };
    } else {
      button = {
        title: t('buttons.goLive'),
        onClick: () => {
          navigate('/session/create/adhoc');
        },
        disabled: !certifiedCaseList || certifiedCaseList.length <= 0,
      };
    }
  }
  return (
    <div className='rounded-lg bg-white my-6 shadow pb-2 mx-4 2xl:mx-6'>
      <DefaultHeader title={t('liveSessions.title')} primaryAction={button} />

      {myLiveSession && (
        <div className='mt-6 ml-6 mr-6 p-6 bg-positive-light rounded-md'>
          <span>{t('liveSessions.currentlyLive')}</span>
          <span
            className='font-bold underline cursor-pointer'
            onClick={() => {
              if (
                myLiveSession?.status === SessionStatus.Coaching ||
                myLiveSession?.status === SessionStatus.Feedback
              ) {
                navigate(`/session/stream/${myLiveSession.id}`);
              } else {
                navigate(`/session/details/${myLiveSession.id}`);
              }
            }}
          >
            {' '}
            {myLiveSession.caseTemplateSummary.name}
          </span>
        </div>
      )}

      <div className='p-6'>
        <div className='flex mt-4'>
          <div className='w-4/12 mr-9'>
            <CustomMultiSelect
              placeholderAll='All Cases'
              placeholderSelected='Cases Selected'
              list={caseList}
              handleChange={handleCaseChange}
              values={selectedCases}
            />
          </div>

          <div className='flex ml-4 mr-9 w-4/12'>
            <CustomMultiSelect
              placeholderAll='All Categories'
              placeholderSelected='Categories Selected'
              list={categoryNames}
              handleChange={handleCategoryChange}
              values={selectedCategories}
            />
          </div>
        </div>
      </div>

      <ScheduleTable
        sessionList={sessionList}
        roles={roles}
        bookSession={joinSession}
        detailsSession={detailsSession}
        resultSession={resultSession}
        cancelSession={cancelSession}
        currentUser={currentUser}
        currentInstitution={currentInstitution}
        sessionType={SessionType.AdHocSession}
      />
      {!!totalPages && (
        <ReactPaginate
          previousLabel={<i className='bi bi-chevron-left' />}
          breakLabel='...'
          nextLabel={<i className='bi bi-chevron-right' />}
          onPageChange={onPageChange}
          pageRangeDisplayed={5}
          pageCount={totalPages}
          containerClassName='pagination flex justify-center text-gray-dark'
          pageClassName='page-item'
          pageLinkClassName='page-link'
          previousClassName='page-item'
          previousLinkClassName='page-link'
          nextClassName='page-item'
          nextLinkClassName='page-link'
          activeClassName='active'
          breakClassName='page-item'
          breakLinkClassName='page-link'
          forcePage={page}
        />
      )}

      {state == COMPONENT_STATE.JOIN && (
        <AbstractModal
          label={selectedSession?.caseTemplateSummary.name}
          leftBtn={{
            label: t('buttons.cancel'),
            onClick: () => {
              setState(COMPONENT_STATE.LIST);
            },
          }}
          rightBtn={{
            label: t('buttons.joinThisSession'),
            icon: 'bi-check2-circle',
            onClick: () => {
              handleJoinSessionSubmit();
            },
          }}
          cancelButton={{
            onClick: () => {
              setState(COMPONENT_STATE.LIST);
            },
          }}
        >
          <BookSessionContent session={selectedSession!} caseData={caseData} />
        </AbstractModal>
      )}
    </div>
  );
};

export default LiveSessionsList;
