import React from 'react';
import { useTranslation } from 'react-i18next';
import { USER_ROLES } from '../../common/enums';

// UI COMPONENTS
import InputLabel from '../../components/InputLabel';
import CopyInput from '../../components/CopyInput';
import Chips from '../../components/Chips';

interface IInviteUserFormProps {
  currentInstitution: any;
}

const InviteUserForm = ({ currentInstitution }: IInviteUserFormProps) => {
  const { t } = useTranslation();

  const inviteTraineeValue = process.env.PUBLIC_URL + '/#/signup/' + currentInstitution.code + '/trainee';
  const inviteCouchValue = process.env.PUBLIC_URL + '/#/signup/' + currentInstitution.code + '/sp';

  return (
    <>
      <div className='text-base my-4 text-gray-dark'>
        {t('user.youAreInviting')} “{currentInstitution.name}”. {t('user.youCanAssign')}
      </div>
      <form>
        <div className='grid grid-col-1'>
          <InputLabel label={t('inputLabels.inviteTrainee')} />
          <CopyInput value={inviteTraineeValue} />
          <Chips institutionId={currentInstitution.id} appendRole={USER_ROLES.Trainee} />
          <div>
            <div className='text-center border-b border-solid border-black-divider w-full my-6 leading-0.1'>
              <span className='uppercase bg-background-modal text-black font-bold text-tiny'>{t('user.or')}</span>
            </div>
          </div>
          <InputLabel label={t('inputLabels.inviteCoach')} />
          <CopyInput value={inviteCouchValue} />
          <Chips institutionId={currentInstitution.id} appendRole={USER_ROLES.SP} />
        </div>
      </form>
    </>
  );
};

export default InviteUserForm;
