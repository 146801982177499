import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// SERVICE
import { AuthService } from '../../../service/auth.service';

// REDUX
import { actionSignout, signout } from '../../../redux/actions';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import LangSwitcher from "./LangSwitcher";

const Header = () => {
  const { t } = useTranslation();
  const { user: currentUser } = useAppSelector((state: any) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const handleSwitch = () => {
    setOpen(!open);
  };

  const logout = () => {
    AuthService.signout(
      () => {
        dispatch(actionSignout());
        navigate('/signin');
      },
      error => {
        console.log(error);
      },
    );
  };

  return (
    <div className='h-22 flex flex-row-reverse border-l border-solid border-black-divider bg-white'>
      <div className='h-inherit min-w-7/25 relative w-auto border-l border-solid border-black-divider flex items-center pr-12 pl-3'>
        <div className='mr-12'>
          {currentUser.firstName} {currentUser.lastName}
          <div>
            <a className='text-sm uppercase text-primary' href={`mailto:${currentUser.email}`}>
              {currentUser.email}
            </a>
          </div>
        </div>

        <div className='cursor-pointer' onClick={() => handleSwitch()}>
          {open ? <i className='bi bi-chevron-up text-2xl' /> : <i className='bi bi-chevron-down text-2xl' />}
        </div>

        {open && (
          <div className='top min-w-full absolute flex flex-col bg-white text-black rounded-lg z-10 -top-2.5 right-0 py-1 px-0 mt-25 shadow-dropdown'>
            <Link
              className='navLink hover:text-primary flex text-gray-dark no-underline font-bold cursor-pointer transition-all duration-500 ease-out px-12 my-4'
              to='/user/profile'
              replace
            >
              <i className='bi bi-person text-gray-light navLink_icon' />
              <span className='mx-2'>{t('layout.profile')}</span>
            </Link>
            <Link
              className='navLink hover:text-primary flex text-gray-dark no-underline font-bold cursor-pointer transition-all duration-500 ease-out px-12 my-4'
              to='/user/preferences'
              replace
            >
              <i className='bi bi-person text-gray-light navLink_icon' />
              <span className='mx-2'>{t('layout.preferences')}</span>
            </Link>
            <a
              className='navLink hover:text-primary flex text-gray-dark no-underline font-bold cursor-pointer transition-all duration-500 ease-out px-12 my-4'
              onClick={() => {
                logout();
              }}
            >
              <i className='bi bi-door-closed text-gray-light navLink_icon' />
              <span className='mx-2'>{t('layout.logout')}</span>
            </a>
          </div>
        )}
      </div>
      <LangSwitcher/>
    </div>
  );
};

export default Header;
