import { InstitutionService } from './../service/institution.service';
import { InstitutionResponseDto } from './../service/dto/institution.dto';

import { UserService } from './../service/user.service';

import { IRouteComponent } from './../routes';
import { UserResponseDto } from '../service/dto/user.dto';
import { ICurrentInstitution, ICurrentUser, IUserInstitution } from '../common/interfaces';
import { USER_ROLES } from '../common/enums';

export const chooseInstitution = (institution: InstitutionResponseDto, successCb: Function, errorCb: Function) => {
  UserService.switchInstitutionContext(
    institution.id,
    () => {
      successCb(institution);
    },
    () => {
      errorCb();
    },
  );
};

export const ifNoInstitutionSetDefault = (
  user: UserResponseDto,
  cbNo: Function,
  cbYes: Function,
  redirect: Function,
) => {
  const currentInstitution = localStorage.getItem('institution');
  if (!currentInstitution) {
    InstitutionService.my(
      (data: Array<InstitutionResponseDto>) => {
        if (data.length) {
          const findInstitution = data.find(institution => institution.id === user.institutions[0].institutionId);
          if (findInstitution) {
            cbNo(findInstitution);
          } else {
            cbNo(data[0]);
          }
        } else {
          redirect();
        }
      },
      error => {},
    );
  } else {
    cbYes();
  }
};

export const setCurrentUserInstitution = ({ currentUser, currentInstitution }: IRouteComponent) => {
  const userInstitution = currentUser.institutions.filter((ins: any) => ins.institutionId == currentInstitution.id)[0];
  return userInstitution;
};

export const isInstitutionAdmin = (currentUser: ICurrentUser, currentInstitution: ICurrentInstitution) => {
  const institution = currentUser.institutions.filter(
    (ins: IUserInstitution) => ins.institutionId == currentInstitution.id,
  )[0];
  if (!institution) {
    return false;
  }

  return institution.roles.includes(USER_ROLES.Admin) || currentUser.isSuperAdmin;
};
