import { IRouteComponent } from '../../routes';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import SearchField from '../../components/SearchField';
import AbstractModal from '../../components/AbstractModal';
import ModalContent from '../../components/ModalContent';
import { CategoryCreateDto, CategoryListItemDto } from '../../service/dto/category.dto';
import { CategoryService } from '../../service/category.service';
import Alert from '../../utils/alert';
import CategoryTable from './CategoryTable';
import CategoryCreate from './CategoryCreate';
import DefaultHeader from '../../components/DefaultHeader';
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {setCategorySearchSelector, setFilteredCategoriesSelector} from "../../redux/actions";

enum COMPONENT_STATE {
  LIST,
  CREATE,
  EDIT,
  DELETE,
}

const CategoryList = ({ currentUser, currentInstitution }: IRouteComponent) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [state, setState] = useState(COMPONENT_STATE.LIST);

  const [allCategories, setAllCategories] = useState<CategoryListItemDto[]>([]);
  const [filteredCategories, setFilteredCategories] = useState<CategoryListItemDto[]>([]);

  const [index, setIndex] = useState<number>(-1);

  const [nameValidation, setNameValidation] = useState('');

  const [category, setCategory] = useState<CategoryListItemDto | CategoryCreateDto>({ name: '', description: '' });

  const { searchCategory }: { searchCategory: string } = useAppSelector((state: any) => state.categorySearchReducer);

  const onSearch = (search: string) => {
    dispatch(setCategorySearchSelector({ searchCategory: search }));
    filterCategories(search);
  };

  const filterCategories = (search: string, categories: CategoryListItemDto[] = allCategories) => {
    if (search) {
      setFilteredCategories(
        categories.filter(
          category =>
            category.name.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
            category.description.toLowerCase().indexOf(search.toLowerCase()) >= 0,
        ),
      );
    } else {
      setFilteredCategories([...categories]);
    }
  };

  useEffect(() => {
    CategoryService.list(
      (data: CategoryListItemDto[]) => {
        setAllCategories([...data]);
        setFilteredCategories([...data]);
      },
      () => {
        setAllCategories([]);
        setFilteredCategories([]);
      },
    );
  }, []);

  useEffect(() => {
      if (searchCategory) {
          filterCategories(searchCategory);
      }
  }, [allCategories]);

  const createCategory = (category: CategoryCreateDto) => {
    CategoryService.create(
      category,
      (data: CategoryListItemDto) => {
        setAllCategories([...allCategories, data]);
        filterCategories(searchCategory, [...allCategories, data]);
        Alert.success(t('successMessages.categoryCreated'));
        setState(COMPONENT_STATE.LIST);
      },
      () => {
        Alert.warning(t('errorMessages.categoryCreateError'));
      },
    );
  };

  const editCategory = (category: CategoryListItemDto) => {
    CategoryService.update(
      category,
      (data: CategoryListItemDto) => {
        allCategories.splice(
          allCategories.findIndex(findCategory => findCategory.id === data.id),
          1,
        );
        setAllCategories([...allCategories, data]);
        filteredCategories.splice(
          filteredCategories.findIndex(findCategory => findCategory.id === data.id),
          1,
        );
        setFilteredCategories([...filteredCategories, data]);
        Alert.success(t('successMessages.categoryEdited'));
        setState(COMPONENT_STATE.LIST);
      },
      () => {
        Alert.warning(t('errorMessages.categoryEditError'));
      },
    );
  };

  const handleNameChange = (value: string) => {
    if (category) {
      if (value.length < 5) {
        setNameValidation('category.validationError.name');
      } else {
        setNameValidation('');
      }
      let newCategory = Object.assign({}, category);
      newCategory.name = value;
      setCategory(newCategory);
    }
  };

  const handleDescriptionChange = (value: string) => {
    if (category) {
      let newCategory = Object.assign({}, category);
      newCategory.description = value;
      setCategory(newCategory);
    }
  };

  const handleDelete = () => {
    const category = filteredCategories[index];
    if (category) {
      CategoryService.delete(
        category.id,
        () => {
          filteredCategories.splice(filteredCategories.indexOf(category), 1);
          setFilteredCategories([...filteredCategories]);
          allCategories.splice(allCategories.indexOf(category), 1);
          setAllCategories([...allCategories]);
          setState(COMPONENT_STATE.LIST);
          Alert.success(t('successMessages.categoryDeleted'));
        },
        () => {
          Alert.warning(t('errorMessages.categoryDeleteError'));
        },
      );
    }
  };

  const handleSubmit = () => {
    if (nameValidation) {
      return;
    }
    if (state === COMPONENT_STATE.CREATE) {
      createCategory(category);
    }
    if (state === COMPONENT_STATE.EDIT) {
      editCategory(category as CategoryListItemDto);
    }
  };

  const title = t('category.list.title') + ' (' + filteredCategories.length + ')';
  return (
    <div className='rounded-lg bg-white my-6 shadow pb-2 mx-4 2xl:mx-6'>
      <DefaultHeader
        title={title}
        primaryAction={{
          title: t('category.list.createNew'),
          onClick: () => {
            setState(COMPONENT_STATE.CREATE);
          },
        }}
      />

      <div className='p-6'>
        <div className='flex items-center'>
          <div className='mr-6'>
            <SearchField onSearch={onSearch} value={searchCategory}/>
          </div>
        </div>
      </div>

      <CategoryTable
        list={filteredCategories}
        onDelete={(index: number) => {
          setIndex(index);
          setState(COMPONENT_STATE.DELETE);
        }}
        onEdit={(index: number) => {
          setCategory(filteredCategories[index]);
          setState(COMPONENT_STATE.EDIT);
        }}
      />

      {(state == COMPONENT_STATE.CREATE || state == COMPONENT_STATE.EDIT) && (
        <AbstractModal
          label={
            state == COMPONENT_STATE.CREATE
              ? t('category.list.modal.title-create')
              : t('category.list.modal.title-edit')
          }
          leftBtn={{
            label: t('buttons.cancel'),
            onClick: () => {
              setState(COMPONENT_STATE.LIST);
            },
          }}
          rightBtn={{
            label: t('buttons.save'),
            icon: 'bi-check2-circle',
            onClick: () => {
              handleSubmit();
            },
          }}
        >
          <CategoryCreate
            category={category}
            errorName={nameValidation}
            handleChangeName={handleNameChange}
            handleChangeDescription={handleDescriptionChange}
          />
        </AbstractModal>
      )}

      {state == COMPONENT_STATE.DELETE && (
        <AbstractModal
          label={t('category.list.modal.title-delete')}
          leftBtn={{
            label: t('buttons.cancel'),
            onClick: () => {
              setState(COMPONENT_STATE.LIST);
            },
          }}
          rightBtn={{
            label: t('buttons.yesDelete'),
            onClick: () => {
              handleDelete();
            },
          }}
          toDelete
        >
          <ModalContent entity='scoring' title={t('category.list.modal.description-delete')} subtitle='' />
        </AbstractModal>
      )}
    </div>
  );
};

export default CategoryList;
