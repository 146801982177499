import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../../components/PrimaryButton';
import { AuthService } from '../../service/auth.service';
import { signout } from '../../redux/actions';
import { useAppDispatch } from '../../redux/store';

const IsNoInstitution = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const logout = () => {
    AuthService.signout(
      () => {
        dispatch(signout());
        navigate('/signin');
      },
      error => {
        console.log(error);
      },
    );
  };
  return (
    <div className='flex justify-center'>
      <div className='w-112.5'>
        <div className='text-2xl font-bold my-10 text-center'>{t('institutions.noInstitution')}</div>
        <div className='w-full mb-12 flex justify-center'>
          <PrimaryButton title={t('buttons.loginAnotherAccount')} onClick={() => logout()} />
        </div>
      </div>
    </div>
  );
};

export default IsNoInstitution;
