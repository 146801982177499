import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type ICustomSelectItem = {
  value?: string;
  label?: string;
};

type ICustomSelectProps = {
  placeholder: string;
  value?: string;
  notFoundLabel?: string;
  list: Array<ICustomSelectItem>;
  filteredList?: Array<ICustomSelectItem>;
  handleChange: Function;
  disabled?: boolean | (() => boolean);
  reset?: boolean;
  clearable?: boolean;
};

enum ICustomSelectDirection {
  Top = 'direction-top',
  Bottom = 'direction-bottom',
}

const CustomSelect: React.FunctionComponent<ICustomSelectProps> = ({
                                                                     placeholder,
                                                                     value,
                                                                     list,
                                                                     filteredList = list,
                                                                     notFoundLabel = 'NOT FOUND',
                                                                     disabled,
                                                                     handleChange,
                                                                     reset,
                                                                     clearable = false,
                                                                   }) => {
  const { t } = useTranslation();

  let containerRef: HTMLDivElement | null;
  let dropdownRef: HTMLDivElement | null;

  const [isOpen, setOpen] = useState(false);

  const [selectedValue, setSelectedValue] = useState<string | undefined>(value ? value : undefined);

  const [direction, setDirection] = useState<ICustomSelectDirection>(ICustomSelectDirection.Bottom);
  const [dropdownHeight, setDropdownHeight] = useState(0);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  useEffect(() => {
    setOpen(false);
  }, [list]);

  const isDisabled = (): boolean => {
    if (typeof disabled != 'undefined') {
      if (disabled instanceof Function) {
        return disabled();
      }
      return disabled;
    }
    return false;
  };

  const toggleDropdown = () => setOpen(!isOpen);

  const handleItemClick = (item: ICustomSelectItem) => {
    setSelectedValue(item.value);
    setOpen(false);
    handleChange(item);
  };

  useEffect(() => {
    if (containerRef && dropdownRef) {
      const freeSpaceToBottom = window.innerHeight - containerRef.getBoundingClientRect().bottom;
      const freeSpaceToTop = containerRef.getBoundingClientRect().top;
      const dropdownHeight = dropdownRef.getBoundingClientRect().height;
      setDropdownHeight(dropdownHeight);
      if (freeSpaceToBottom < dropdownHeight && freeSpaceToTop >= dropdownHeight) {
        setDirection(ICustomSelectDirection.Top);
      } else {
        setDirection(ICustomSelectDirection.Bottom);
      }
    }
  }, [isOpen]);

  let selectedLabel = placeholder;
  if (selectedValue && !reset) {
    const find = list.find((item: ICustomSelectItem) => item.value == selectedValue);
    selectedLabel = find && find.label ? find.label : notFoundLabel;
  }

  return (
    <>
      {list.length > 0 && (
        <div
          className={`dropdown rounded-lg text-black w-full bg-white relative border border-solid border-black-divider ${
            selectedValue !== null && 'text-gray-dark'
          } ${
            isOpen &&
            `${
              direction === ICustomSelectDirection.Bottom
                ? 'rounded-t-lg rounded-b-none'
                : 'rounded-b-lg rounded-t-none'
            } border-primary-hover`
          } ${direction} ${isDisabled() ? '' : 'cursor-pointer'}`}
        >
          <div
            className='flex justify-between items-center p-2'
            onClick={() => {
              if (!isDisabled()) {
                toggleDropdown();
              }
            }}
            ref={node => {
              containerRef = node;
            }}
          >
            {selectedLabel}

            <div className={'flex flex-row gap-4'}>
              {selectedValue && clearable && !isOpen && (
                <i className={`bi bi-x-lg cursor-pointer`} onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setSelectedValue(undefined);
                  handleChange(null)
                }}></i>
              )}

              {filteredList.length > 0 &&
                !isDisabled() &&
                (isOpen ? (
                  <i className="bi bi-chevron-up cursor-pointer"></i>
                ) : (
                  <i className="bi bi-chevron-down cursor-pointer"></i>
                ))}
            </div>
          </div>
          {isOpen && filteredList.length > 0 && (
            <div
              className={`w-inherit max-h-44 scrollbar flex flex-col absolute overflow-auto border border-solid border-primary-hover bg-white z-10 top-10 left-0 py-1 px-0 shadow-dropdown ${
                direction === ICustomSelectDirection.Bottom
                  ? 'rounded-b-lg rounded-t-none'
                  : 'rounded-t-lg rounded-b-none'
              }`}
              ref={node => {
                dropdownRef = node;
              }}
              style={{ top: direction === ICustomSelectDirection.Top ? `-${dropdownHeight}px` : 'initial' }}
            >
              {filteredList.map((item: ICustomSelectItem) => (
                <div
                  onClick={_ => {
                    if (!isDisabled()) {
                      handleItemClick(item);
                    }
                  }}
                  className={`${item.value == selectedValue && 'text-primary-lightest font-bold'}`}
                  key={item.value}
                >
                  <label className='dropdownItem text-black block relative cursor-pointer select-none py-2 pl-2'>
                    {item.label}
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CustomSelect;
