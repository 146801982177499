import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// SERVICE
import { CaseService } from '../../service/case.service';
import { CaseResponseDto } from '../../service/dto/case.dto';

import Tag, { TagType } from '../../components/Tag';
import StatisticItem from '../../components/StatisticItem';

const CaseArrange = ({}) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<CaseResponseDto>();
  const [loading, setLoading] = useState(false);

  // Fetch
  useEffect(() => {
    CaseService.getById(
      id!,
      (data: CaseResponseDto) => {
        setLoading(false);
        setData(data);
      },
      error => {
        setLoading(false);
      },
    );
  }, [loading]);

  return (
    <div className='my-6 mx-4 2xl:mx-6'>
      <div className='flex justify-between'>
        <div className='w-4/6 lg:w-1/2'>
          <div className='text-gray-dark font-bold mb-1'>{t('cases.name')}</div>
          <div className='text-2xl font-bold mb-6'>{data && data.name}</div>
          <div className='text-gray-dark font-bold mb-1'>{t('cases.description')}</div>
          <div className='mb-6'>
            <p className='text-black text-lg'>{data && data.description}</p>
          </div>
          <div className='text-gray-dark font-bold mb-1'>{t('cases.scoringItems')}</div>
          <ul className='m-0 font-bold'>{data && data.scoring_items.map(i => <li key={i.name}>{i.name}</li>)}</ul>
        </div>
        <div className='w-2/6 lg:w-3/12'>
          <div className='rounded-lg bg-white p-4 shadow mb-4'>
            <div className='text-gray-dark font-bold'>{t('cases.statistics')}</div>
            <StatisticItem
              title={data ? `${data.feedback_duration} + ${data.interview_duration}` : '' + ' min'}
              description={t('cases.totalDuration')}
              icon='bi bi-stopwatch-fill'
            />
            <StatisticItem
              title={data ? `${data.interview_duration}` : '' + ' min'}
              description={t('cases.interviewDuration')}
              icon='bi bi-hourglass-bottom'
            />
            <StatisticItem
              title={data ? `${data.feedback_duration}` : '' + ' min'}
              description={t('cases.feedbackDuration')}
              icon='bi bi-hourglass'
            />
          </div>
          <div className='bg-white p-4 shadow'>
            <div className='text-primary font-bold mb-1'>{t('cases.schedule')}</div>
            <div className='text-gray-dark text-lg mb-2'></div>
            <div className='text-primary font-bold mb-1'>{t('cases.previousSessions')}</div>
            <div className='text-gray-dark text-lg mb-2'></div>
            <Tag label='fair - 52' type={TagType.Yellow} />
          </div>
        </div>
      </div>
      <hr className='w-full h-px bg-black-divider border-0 my-6'></hr>
      <div className='text-center'>CALENDAR HERE</div>
    </div>
  );
};

export default CaseArrange;
