import React, { RefObject, useEffect, useState } from 'react';
import Video, {
  LocalAudioTrack,
  LocalAudioTrackPublication,
  LocalTrack,
  LocalTrackPublication,
  LocalVideoTrack,
  LocalVideoTrackPublication,
  RemoteParticipant,
  Room,
} from 'twilio-video';
import { USER_ROLES } from '../../common/enums';
import { createConstrains, MediaDeviceDto } from '../../service/dto/MediaDevice.dto';
import ParticipantComponent from '../../module/schedule/Video/ParticipantComponent';
import VideoControl from '../../module/schedule/Video/VideoControl';
import { getFirstLetter } from '../../utils';
import { calculateSessionRecordedTimeInSeconds } from '../../utils/funcs';
import { CertificationResponseDto, CertificationSessionProgress } from '../../service/dto/certification.dto';
import { CertificationService } from '../../service/certification.service';

const renderFirstLetters = (participant: { firstName: string; lastName: string }) => {
  return (
    <>
      <span className='pr-2'>{getFirstLetter(participant.firstName)}</span>
      <span>{getFirstLetter(participant.lastName)}</span>
    </>
  );
};

const IndependentRoomView = ({
  room,
  onRemoteAudioMute,
  onRemoteVideoMute,
  role,
  userId,
  mediaDevices,
  setMediaDevices,
  currentUser,
  partnerUser,
  certification,
  progress,
}: {
  room: Room;
  onRemoteAudioMute: (isMuted: boolean) => void;
  onRemoteVideoMute: (isMuted: boolean) => void;
  role: USER_ROLES;
  userId: string;
  currentUser: { firstName: string; lastName: string };
  partnerUser: { firstName: string; lastName: string };
  mediaDevices: MediaDeviceDto[];
  setMediaDevices: (devices: MediaDeviceDto[]) => void;
  certification: CertificationResponseDto;
  progress: CertificationSessionProgress;
}) => {
  const [externalParticipant, setExternalParticipant] = useState<RemoteParticipant | null>(null);
  const [localVideoMute, setLocalVideoMute] = useState(false);
  const [localAudioMute, setLocalAudioMute] = useState(false);
  const [speaker, setSpeaker] = useState<MediaDeviceDto>();
  const [remoteAudioPaused, setRemoteAudioPaused] = useState(false);
  const [sessionRecordingStatus, setSessionRecordingStatus] = useState({
    isRecordingActive: false,
    isUserCoach: false,
    allowRecording: true,
  });
  const [sessionRecordingTime, setSessionRecordingTime] = useState(0);
  const [sessionRecordingButtonEnabled, setSessionRecordingButtonEnabled] = useState(true);
  let sessionRecordingTimeHandler: NodeJS.Timer | null = null;
  useEffect(() => {
    setLocalAudioMute(!mediaDevices.find(device => device.active && device.kind === 'audioinput')?.enabled);
    setLocalVideoMute(!mediaDevices.find(device => device.active && device.kind === 'videoinput')?.enabled);
  }, [mediaDevices]);

  useEffect(() => {
    let isMounted = true;
    room.participants.forEach((participant: RemoteParticipant) => {
      handleRemoteParticipantSubscription(participant);
    });

    // Log new Participants as they connect to the Room
    room.on('participantConnected', (participant: RemoteParticipant) => {
      if (!isMounted) {
        return;
      }
      handleRemoteParticipantSubscription(participant);
    });

    // Log Participants as they disconnect from the Room
    room.on('participantDisconnected', () => {
      if (!isMounted) {
        return;
      }
      setExternalParticipant(null);
    });

    room.on('disconnected', () => {
      room.localParticipant.tracks.forEach((publication: LocalTrackPublication) => {
        publication.unpublish();
        const track =
          publication.track.kind === 'video'
            ? (publication.track as LocalVideoTrack)
            : (publication.track as LocalAudioTrack);
        track.stop();
        track.detach();
      });
    });

    return () => {
      room.localParticipant.tracks.forEach((publication: LocalTrackPublication) => {
        publication.unpublish();
        const track =
          publication.track.kind === 'video'
            ? (publication.track as LocalVideoTrack)
            : (publication.track as LocalAudioTrack);
        track.stop();
        track.detach();
      });
      isMounted = false;
      room.disconnect();
    };
  }, [room]);

  const handleRemoteParticipantSubscription = (participant: RemoteParticipant) => {
    setExternalParticipant(participant);
  };

  const onLocalVideoMute = () => {
    room.localParticipant.videoTracks.forEach((publication: LocalVideoTrackPublication) => {
      if (mediaDevices) {
        mediaDevices.find((deviceF: MediaDeviceDto) => deviceF.kind === 'videoinput' && deviceF.active)!.enabled =
          !publication.track.isEnabled;
        setMediaDevices(mediaDevices);
      }

      if (publication.track.isEnabled) {
        publication.track.disable();
        setLocalVideoMute(true);
      } else {
        publication.track.enable();
        setLocalVideoMute(false);
      }
    });
  };

  const onLocalAudioMute = () => {
    room.localParticipant.audioTracks.forEach((publication: LocalAudioTrackPublication) => {
      if (mediaDevices) {
        mediaDevices.find((deviceF: MediaDeviceDto) => deviceF.kind === 'audioinput' && deviceF.active)!.enabled =
          !publication.track.isEnabled;
        setMediaDevices(mediaDevices);
      }

      if (publication.track.isEnabled) {
        publication.track.disable();
        setLocalAudioMute(true);
      } else {
        publication.track.enable();
        setLocalAudioMute(false);
      }
    });
  };

  const changeCameraOrMic = async (device: MediaDeviceDto) => {
    let isOldTrackEnabled = true;
    (device.kind === 'videoinput' ? room.localParticipant.videoTracks : room.localParticipant.audioTracks).forEach(
      (publication: LocalTrackPublication) => {
        publication.unpublish();
        const track =
          publication.track.kind === 'video'
            ? (publication.track as LocalVideoTrack)
            : (publication.track as LocalAudioTrack);
        isOldTrackEnabled = track.isEnabled;
        track.stop();
        track.detach();
      },
    );
    let oldDevice = mediaDevices!.find((deviceF: MediaDeviceDto) => deviceF.kind === device.kind && deviceF.active);
    if (oldDevice) {
      oldDevice.active = false;
      oldDevice.enabled = true;
    }

    device.active = true;
    const newTrack: LocalTrack =
      device.kind === 'videoinput'
        ? await Video.createLocalVideoTrack(createConstrains(device))
        : await Video.createLocalAudioTrack({ deviceId: device.deviceId });
    if (!isOldTrackEnabled) {
      newTrack.disable();
    }
    room.localParticipant.publishTrack(newTrack);
    setMediaDevices(mediaDevices);
  };

  const changeSpeaker = (device: MediaDeviceDto) => {
    mediaDevices!.find((deviceF: MediaDeviceDto) => deviceF.kind === device.kind && deviceF.active)!.active = false;
    device.active = true;
    setSpeaker(device);
    setMediaDevices(mediaDevices);
  };

  useEffect(() => {
    updateSessionRecordingStatus();
  }, [progress, role]);

  const updateSessionRecordingStatus = () => {
    if (progress.isSimulateFeedback) {
      const isUserCoach = role === USER_ROLES.Trainee;
      const sessionRecordTime = calculateSessionRecordedTimeInSeconds(
        progress.feedbackSessionRecord?.recordingSequences ?? [],
      );
      setSessionRecordingStatus({
        isRecordingActive: progress.feedbackSessionRecord?.recordingActive ?? false,
        isUserCoach: isUserCoach,
        allowRecording:
          (progress.coachingSessionRecord?.allowRecording && progress.feedbackSessionRecord?.allowRecording) ?? false,
      });
      setSessionRecordingTime(sessionRecordTime);
    } else {
      const isUserCoach = role === USER_ROLES.SP;
      const sessionRecordTime = calculateSessionRecordedTimeInSeconds(
        progress.coachingSessionRecord?.recordingSequences ?? [],
      );
      setSessionRecordingStatus({
        isRecordingActive: progress.coachingSessionRecord?.recordingActive ?? false,
        isUserCoach: isUserCoach,
        allowRecording:
          (progress.coachingSessionRecord?.allowRecording && progress.feedbackSessionRecord?.allowRecording) ?? false,
      });
      setSessionRecordingTime(sessionRecordTime);
    }
  };

  useEffect(() => {
    const clearSessionInterval = () => {
      if (sessionRecordingTimeHandler != null) {
        clearInterval(sessionRecordingTimeHandler);
        sessionRecordingTimeHandler = null;
      }
    };

    if (sessionRecordingStatus.isRecordingActive && sessionRecordingTimeHandler == null) {
      sessionRecordingTimeHandler = setInterval(() => {
        setSessionRecordingTime(sessionRecordingTime + 1);
      }, 1000);
    } else if (!sessionRecordingStatus.isRecordingActive && sessionRecordingTimeHandler != null) {
      clearSessionInterval();
    }

    return clearSessionInterval;
  }, [sessionRecordingStatus, sessionRecordingTime]);

  const onRecordingClick = () => {
    if (!sessionRecordingStatus.allowRecording && !sessionRecordingStatus.isRecordingActive) {
      return null;
    }

    if (!sessionRecordingButtonEnabled) {
      return null;
    }

    setSessionRecordingButtonEnabled(false);

    return CertificationService.toggleSessionRecording(certification.id)
      .then(response => {
        setSessionRecordingStatus({
          isRecordingActive: response.recordingActive,
          isUserCoach: sessionRecordingStatus.isUserCoach,
          allowRecording:
            (progress.coachingSessionRecord?.allowRecording && progress.feedbackSessionRecord?.allowRecording) ?? false,
        });
      })
      .catch(error => {
        setSessionRecordingStatus({
          isRecordingActive: sessionRecordingStatus.isRecordingActive,
          isUserCoach: sessionRecordingStatus.isUserCoach,
          allowRecording: false,
        });
      })
      .finally(() => {
        setSessionRecordingButtonEnabled(true);
      });
  };

  const audioPlayPause = (audioRef: RefObject<HTMLAudioElement>) => {
    const el = audioRef.current;
    if (el) {
      if (remoteAudioPaused) {
        el.pause();
      } else {
        el.play().catch(error => {
          console.log(error);
          setRemoteAudioPaused(true);
        });
      }
    }
  };

  const onRemoteAudioPlayPause = () => {
    setRemoteAudioPaused(!remoteAudioPaused);
  };

  if (!room) return null;

  return (
    <div className='rounded-lg room'>
      <div className='local-participant absolute mt-0 z-10 w-55 h-37.5'>
        <ParticipantComponent
          key={room.localParticipant.sid}
          participant={room.localParticipant}
          onRemoteAudioMute={(isMuted: boolean) => {}}
          onRemoteVideoMute={(isMuted: boolean) => {}}
          isRemote={false}
          audioPlayPause={audioPlayPause}
          classNameMuted={''}
          remoteAudioPaused={remoteAudioPaused}
          speaker={speaker}
          placeholder={renderFirstLetters(currentUser)}
          className='text-primary-dark rounded-tl-lg rounded-br-lg h-37.5 w-50'
        />
      </div>

      <div className='remote-participant relative w-full'>
        {externalParticipant ? (
          <ParticipantComponent
            key={externalParticipant.sid}
            placeholder={renderFirstLetters(partnerUser)}
            participant={externalParticipant}
            onRemoteAudioMute={onRemoteAudioMute}
            onRemoteVideoMute={onRemoteVideoMute}
            remoteAudioPaused={remoteAudioPaused}
            audioPlayPause={audioPlayPause}
            isRemote={true}
            speaker={speaker}
            className='min-w-full min-h-full w-auto h-auto'
          />
        ) : (
          <div className='flex flex-col items-center justify-center bg-primary rounded-lg h-122.5 min-w-112.5'>
            <div className='text-center text-white w-1/2 waiting-user_content'>
              <div className='mb-6 font-bold text-xl'>Waiting for your partner...</div>
              <div className='text-base'>
                It looks like your partner has either not yet joined the session or is experiencing network issues,
                please wait for her or him to join.
              </div>
            </div>
          </div>
        )}
        <VideoControl
          changeCameraOrMic={changeCameraOrMic}
          changeSpeaker={changeSpeaker}
          onLocalVideoMute={onLocalVideoMute}
          onLocalAudioMute={onLocalAudioMute}
          localAudioMute={localAudioMute}
          isSpectator={false}
          localVideoMute={localVideoMute}
          remoteAudioPaused={remoteAudioPaused}
          onRemoteAudioPlayPause={onRemoteAudioPlayPause}
          isRecordingActive={sessionRecordingStatus.isRecordingActive}
          allowRecording={sessionRecordingStatus.allowRecording}
          isUserCoach={sessionRecordingStatus.isUserCoach}
          sessionRecordedTimeInSeconds={sessionRecordingTime}
          onRecordingClick={onRecordingClick}
        />
      </div>
    </div>
  );
};

export default IndependentRoomView;
