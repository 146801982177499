import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CertificationResponseDto, CertificationStatus } from '../../../../service/dto/certification.dto';
import Tag, { TagType } from '../../../../components/Tag';
import { selectorCertificationList } from '../../selectors';
import { thunkCertificationListGetCreatedCertifications } from '../thunks';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';

interface ITableProps {
  onCertificationDetail: (id: string, number: number) => void;
}

const CertificationTable = ({ onCertificationDetail }: ITableProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const certificationListState = useAppSelector(selectorCertificationList);
  const { casesToFilter, showCertified } = certificationListState.filter;
  const { certifications } = certificationListState;

  useEffect(() => {
    dispatch(thunkCertificationListGetCreatedCertifications());
  }, [casesToFilter, showCertified]);

  switch (certifications.type) {
    case 'EMPTY':
      return null;
    case 'LOADING':
      return null;
    case 'ERROR':
      return <div>{`error: ${certifications.error.toString()}`}</div>;
    case 'SUCCESS':
      return (
        <div>
          <table className='dataTable table-fixed w-full bg-white px-4'>
            <thead>
              <tr className='font-bold bg-ghostwhite border border-solid border-gray-lighter'>
                <td className='text-gray-dark font-bold py-4 w-1/4'>{t('table.case')}</td>
                <td className='text-gray-dark font-bold py-4 w-1/8'>{t('table.noviceCoach')}</td>
                <td className='text-gray-dark font-bold py-4 w-1/8'>{t('table.certifiedCoach')}</td>
                <td className='text-gray-dark font-bold py-4 w-1/8'>{t('table.status')}</td>
                <td className='text-gray-light py-4 w-1/5'>{t('table.actions')}</td>
              </tr>
            </thead>

            <tbody>
              {certifications.result.map((certification: CertificationResponseDto, index: number) => {
                return (
                  <tr className='border border-solid border-black-divider' key={certification.id}>
                    <td className='text-gray-dark py-4'>{certification.caseTemplate.name}</td>
                    <td className='text-gray-dark py-4'>
                      {certification.noviceCoach &&
                        certification.noviceCoach.firstName + ' ' + certification.noviceCoach.lastName}
                    </td>
                    <td className='text-gray-dark py-4'>
                      {certification.certifiedCoach &&
                        certification.certifiedCoach.firstName + ' ' + certification.certifiedCoach.lastName}
                    </td>

                    <td className='py-4'>
                      {certification.status == CertificationStatus.Open && <Tag label='Open' type={TagType.Red} />}
                      {certification.status == CertificationStatus.Accepted && (
                        <Tag label='Accepted' type={TagType.Yellow} />
                      )}
                      {certification.status == CertificationStatus.Certified && (
                        <Tag label='Certified' type={TagType.Green} />
                      )}
                    </td>

                    <td className='py-4'>
                      <div className='flex justify-between flex-wrap'>
                        <span
                          className='text-primary cursor-pointer pr-4'
                          onClick={() => {
                            onCertificationDetail(certification.id, index);
                          }}
                        >
                          {t('buttons.session_details')}
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    default:
      throw `Not defined type ${certifications}`;
  }
};

export default CertificationTable;
