import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface INavTabProps {
  title: string;
  link: string;
  icon: string;
  onClick?: Function | undefined;
}

const NavTab = ({ title, link, icon, onClick }: INavTabProps) => {
  const { t } = useTranslation();

  return (
    <NavLink
      className={navigationData =>
        `flex no-underline text-gray-dark font-bold top my-6 hover:text-primary navLink ${
          navigationData.isActive && 'navLink--active'
        }`
      }
      to={link}
      onClick={() => onClick && onClick()}
      replace
    >
      <i className={`${icon} text-gray-light navLink_icon`}></i>
      <span className='mx-2'>{t(title)}</span>
    </NavLink>
  );
};

export default NavTab;
