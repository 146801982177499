import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import tDE from './de/translation.json';
import tEN from './en/translation.json';
import LanguageDetector from 'i18next-browser-languagedetector';

export const resources = {
  de: {
    translation: tDE,
  },
  en: {
    translation: tEN,
  },
} as const;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: ['en', 'de'],
    supportedLngs: ['en', 'de'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
