import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// FORM
import { useForm } from '../../hooks/useForm';
import { UserPaswordForgotValidation } from '../../validation/auth.validation';

// SERVICE
import { AuthService } from '../../service/auth.service';
import { UserPaswordForgotDto } from '../../service/dto/auth.dto';

// UI COMPONETS
import PrimaryButton from '../../components/PrimaryButton';
import InputLabel from '../../components/InputLabel';
import EmailInput from '../../components/EmailInput';
import SuccessButton from '../../components/SuccessButton';

enum COMPONENT_STATE {
  INIT,
  SUCCESS,
  FAIL,
}

const ForgotPassword: React.FC = () => {
  const { t } = useTranslation();
  const [state, setState] = useState(COMPONENT_STATE.INIT);

  const {
    handleSubmit,
    handleChange,
    data: payload,
    errors,
  } = useForm<UserPaswordForgotDto>({
    validations: UserPaswordForgotValidation,
    onSubmit: () => {
      AuthService.passwordForgot(
        payload,
        () => {
          setState(COMPONENT_STATE.SUCCESS);
        },
        () => {
          // Also Success state - security measure, user should not know if email is registered in the system.
          setState(COMPONENT_STATE.SUCCESS);
        },
      );
    },
  });

  return (
    <div className='flex justify-center'>
      <div className='w-112.5'>
        {(state == COMPONENT_STATE.INIT || state == COMPONENT_STATE.FAIL) && (
          <>
            <div className='text-center'>
              <div className='text-2xl font-bold my-10'>{t('auth.forgotPassword.title')}</div>
              <div className='text-lg mb-10'>{t('auth.forgotPassword.enterEmail')}</div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className='w-full mb-10'>
                <InputLabel label={t('inputLabels.email')} />
                <EmailInput
                  placeholder={t('placeholders.email')}
                  value={payload.email || ''}
                  handleChange={handleChange('email')}
                  error={errors.email}
                  required
                />
              </div>

              <div className='w-full mb-10 flex justify-center'>
                <PrimaryButton title={t('buttons.restorePassword')} type='submit' />
              </div>

              {state == COMPONENT_STATE.FAIL && (
                <div className='rounded-lg text-center text-negative w-full border border-solid border-negative'>
                  {t('errorMessages.failStateFor')} {payload.email}
                </div>
              )}
            </form>

            <hr className='w-full h-px bg-black-divider border-0 mb-10'></hr>

            <div className='text-base mb-10 text-center'>
              <Link className='text-primary underline' to='/signin' replace>
                {t('auth.linkTo.backLogin')}
              </Link>
            </div>
          </>
        )}

        {state == COMPONENT_STATE.SUCCESS && (
          <>
            <div className='text-center'>
              <div className='text-2xl font-bold my-10'>{t('auth.forgotPassword.title')}</div>
              <div className='text-base mb-10'>
                {t('successMessages.sentLetter')} <span className='font-bold'>{payload.email}</span>
              </div>
              <div className='mb-10'>
                <SuccessButton label={t('buttons.sentEmail')} />
              </div>
              <div className='text-gray-dark mb-10'>{t('auth.forgotPassword.note')}</div>
            </div>
            <hr className='w-full h-px bg-black-divider border-0 mb-10'></hr>
            <div className='text-base mb-10 text-center'>
              <Link className='text-primary underline' to='/signin' replace>
                {t('auth.linkTo.backLogin')}
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
