import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// UTILS
import { ScoreTypes } from '../common/enums';
import { scoreColors, scoreI18NKey } from '../utils/constants';

interface IRecordedOnProps {
  listNumber: number;
  title: string;
  recordedTime?: string;
  savedRatingMark: ScoreTypes;
}

const RecordedOn = ({ listNumber, title, recordedTime, savedRatingMark }: IRecordedOnProps) => {
  const { t } = useTranslation();
  const [markColor, setMarkColor] = useState<string>();
  useEffect(() => {
    setMarkColor(scoreColors.get(savedRatingMark) || scoreColors.get(ScoreTypes.None));
  }, [savedRatingMark]);

  return (
    <div>
      <div className='mb-2 leading-4'>
        <strong>{listNumber}.</strong> {title}
      </div>
      <div className='flex items-center'>
        <div
          className='w-4 h-4 border-3 border-solid border-primary-hover rounded-full'
          style={{ backgroundColor: markColor }}
        />
        {recordedTime && <div className='pl-2 text-gray'>{`${t('session.recordedOn')} ${recordedTime}`}</div>}
      </div>
    </div>
  );
};

export default RecordedOn;
