import React, { useEffect, useState } from 'react';
import {
  SessionEvaluationCaseType,
  SessionEvaluationCreateRequest,
  SessionEvaluationResponse,
  SessionEvaluationTechnicalType,
  SessionEvaluationType,
} from '../../service/dto/session-evaluate.dto';
import PrimaryButton from '../../components/PrimaryButton';
import InputLabel from '../../components/InputLabel';
import CustomSelect, { ICustomSelectItem } from '../../components/CustomSelect';
import Textarea from '../../components/Textarea';
import { useTranslation } from 'react-i18next';
import { SessionEvaluateService } from '../../service/session-evaluate.service';
import { SessionHistoryResponseDto, SessionResponseDto } from '../../service/dto/session.dto';
import { initialSessionEvaluationState } from '../../redux/reducer';
import { SessionEvaluationState } from '../../redux/types';
import { setSessionEvaluation } from '../../redux/actions';
import { UserResponseDto } from '../../service/dto/user.dto';
import { CaseTemplateSummaryDto } from '../../service/dto/case.dto';
import SecondaryButton from '../../components/SecondaryButton';
import Alert from '../../utils/alert';
import { useAppDispatch, useAppSelector } from '../../redux/store';

const initialData: SessionEvaluationCreateRequest = initialSessionEvaluationState.sessionEvaluation;
let initialValueHash: string;

const numberList: Array<ICustomSelectItem> = new Array(11)
  .fill(0)
  .map((item, index) => {
    return {
      value: index.toString(),
      label: index.toString(),
    };
  })
  .reverse();

enum COMPONENT_STATE {
  EDITABLE,
  DISABLE_EDIT,
}

const SessionEvaluate = ({
  isCoach,
  session,
}: {
  isCoach?: boolean;
  session: SessionResponseDto;
  sessionEvaluate?: SessionEvaluationResponse;
}) => {
  const { t } = useTranslation();
  const sessionEvaluateState: SessionEvaluationState = useAppSelector((state: any) => state.sessionEvaluationReducer);
  const [state, setState] = useState<COMPONENT_STATE>(COMPONENT_STATE.DISABLE_EDIT);
  const [isUnsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const [data, setData] = useState<SessionEvaluationCreateRequest>({
    ...initialData,
    type: isCoach ? SessionEvaluationType.CoachEvaluation : SessionEvaluationType.TraineeEvaluation,
    trainee: session.trainee.id,
    coach: session.coach.id,
    session: session.id,
    caseTemplate: session.caseTemplateSummary.id,
  });
  const dispatch = useAppDispatch();

  useEffect(() => {
    if ((sessionEvaluateState.sessionEvaluation as SessionEvaluationResponse).id) {
      setData(sessionEvaluateState.sessionEvaluation);
      setState(COMPONENT_STATE.DISABLE_EDIT);
    } else {
      setState(COMPONENT_STATE.EDITABLE);
    }
    initialValueHash = window.btoa(encodeURIComponent(JSON.stringify(sessionEvaluateState.sessionEvaluation)));
  }, [sessionEvaluateState]);

  const handleSubmit = () => {
    const evaluationId = (data as SessionEvaluationResponse).id;
    if (evaluationId) {
      setState(COMPONENT_STATE.DISABLE_EDIT);
      SessionEvaluateService.update(
        evaluationId,
        {
          ...data,
          id: evaluationId,
          author: ((data as SessionEvaluationResponse).author as UserResponseDto).id,
          trainee: ((data as SessionEvaluationResponse).trainee as UserResponseDto).id,
          coach: ((data as SessionEvaluationResponse).coach as UserResponseDto).id,
          caseTemplate: ((data as SessionEvaluationResponse).caseTemplate as CaseTemplateSummaryDto).id,
          session: ((data as SessionEvaluationResponse).session as SessionHistoryResponseDto).id,
        },
        response => {
          dispatch(setSessionEvaluation({ sessionEvaluation: response }));
          Alert.success(t('successMessages.evaluationSaved'));
        },
        () => {
          Alert.warning(t('errorMessages.evaluationSaveError'));
        },
      );
    } else {
      SessionEvaluateService.create(
        data,
        response => {
          dispatch(setSessionEvaluation({ sessionEvaluation: response }));
          Alert.success(t('successMessages.evaluationSaved'));
        },
        () => {
          Alert.warning(t('errorMessages.evaluationSaveError'));
        },
      );
    }
  };

  const handleChange = (fieldName: string, value: any) => {
    const newData: any = Object.assign({}, data);
    newData[fieldName] = value;
    setData(newData);
  };

  useEffect(() => {
    detectChanges();
  }, [data]);

  const detectChanges = () => {
    setTimeout(() => {
      setUnsavedChanges(window.btoa(encodeURIComponent(JSON.stringify(data))) !== initialValueHash);
    }, 100);
  };

  return (
    <div>
      <form className='w-full'>
        <div className='flex items-center justify-between py-2'>
          <div>
            <div className='font-bold text-lg text-black'>{t('session.result.evaluation.title')}</div>
          </div>

          <div className='flex items-center'>
            <SecondaryButton
              className='text-primary border-primary hover:text-primary hover:bg-background-black mr-4'
              title={t('buttons.edit')}
              onClick={() => {
                setState(COMPONENT_STATE.EDITABLE);
              }}
              disabled={isUnsavedChanges || state === COMPONENT_STATE.EDITABLE}
            />
            <PrimaryButton
              title={t('buttons.save')}
              icon=''
              className='mr-4'
              disabled={!isUnsavedChanges}
              onClick={() => handleSubmit()}
            />
          </div>
        </div>
        <hr className='w-full h-px bg-black-divider border-0 mt-4 mb-6' />
        <div className='flex justify-between'>
          <div className='w-full '>
            {data.type === SessionEvaluationType.TraineeEvaluation && (
              <div>
                <div className='mb-4'>
                  <div className='font-bold text-lg text-black'>{t('session.result.evaluation.coachSectionTitle')}</div>
                </div>
                <div className='ml-4'>
                  <div className='mb-4'>
                    <InputLabel label={t('inputLabels.coachOverall')} />
                    <div className='mb-2'>
                      <span className='text-black text-lg'>{t('session.result.evaluation.coachOverallHint')}</span>
                    </div>
                    <CustomSelect
                      placeholder=''
                      list={numberList}
                      value={(data && data.coachOverall != undefined && data.coachOverall.toString()) || ''}
                      handleChange={(item: ICustomSelectItem) => {
                        handleChange('coachOverall', Number.parseInt(item.value || '10'));
                      }}
                      disabled={state === COMPONENT_STATE.DISABLE_EDIT}
                    />
                  </div>
                  <div className='mb-4'>
                    <InputLabel label={t('inputLabels.coachComment')} />
                    <div className='mb-2'>
                      <span className='text-black text-lg'>{t('session.result.evaluation.coachCommentHint')}</span>
                    </div>
                    <Textarea
                      placeholder={t('placeholders.coachComment')}
                      value={(data && data.coachComment) || ''}
                      handleChange={(e: any) => handleChange('coachComment', e.target.value)}
                      required
                      disabled={state === COMPONENT_STATE.DISABLE_EDIT}
                    />
                  </div>
                </div>
              </div>
            )}
            {data.type === SessionEvaluationType.CoachEvaluation && (
              <div>
                <div className='mb-4'>
                  <div className='font-bold text-lg text-black'>
                    {t('session.result.evaluation.traineeSectionTitle')}
                  </div>
                </div>
                <div className='ml-4'>
                  <div className='mb-4'>
                    <InputLabel label={t('inputLabels.traineeOverall')} />
                    <div className='mb-2'>
                      <span className='text-black text-lg'>{t('session.result.evaluation.traineeOverallHint')}</span>
                    </div>
                    <CustomSelect
                      placeholder=''
                      list={numberList}
                      value={(data && data.traineeOverall && data.traineeOverall.toString()) || ''}
                      handleChange={(item: ICustomSelectItem) => {
                        handleChange('traineeOverall', Number.parseInt(item.value || '10'));
                      }}
                      disabled={state === COMPONENT_STATE.DISABLE_EDIT}
                    />
                  </div>
                  <div className='mb-4'>
                    <InputLabel label={t('inputLabels.traineeComment')} />
                    <div className='mb-2'>
                      <span className='text-black text-lg'>{t('session.result.evaluation.traineeCommentHint')}</span>
                    </div>
                    <Textarea
                      placeholder={t('placeholders.traineeComment')}
                      value={(data && data.traineeComment) || ''}
                      handleChange={(e: any) => handleChange('traineeComment', e.target.value)}
                      required
                      disabled={state === COMPONENT_STATE.DISABLE_EDIT}
                    />
                  </div>
                </div>
              </div>
            )}
            <div>
              <div className='mb-4'>
                <div className='font-bold text-lg text-black'>{t('session.result.evaluation.sessionSectionTitle')}</div>
              </div>
              <div className='ml-4'>
                {data &&
                  data.technical.map((item, index) => {
                    return (
                      <div className='mb-4' key={index}>
                        <InputLabel
                          label={t(
                            item.type === SessionEvaluationTechnicalType.TechnicalEvaluationAudio
                              ? 'inputLabels.technicalEvaluationAudio'
                              : 'inputLabels.technicalEvaluationVideo',
                          )}
                        />
                        <div className='mb-2'>
                          <span className='text-black text-lg'>
                            {t(
                              item.type === SessionEvaluationTechnicalType.TechnicalEvaluationAudio
                                ? 'session.result.evaluation.technicalEvaluationAudioHint'
                                : 'session.result.evaluation.technicalEvaluationVideoHint',
                            )}
                          </span>
                        </div>
                        <CustomSelect
                          placeholder=''
                          list={numberList}
                          value={item.value.toString() || ''}
                          handleChange={(changedItem: ICustomSelectItem) => {
                            data.technical.splice(index, 1, {
                              type: item.type,
                              value: Number.parseInt(changedItem.value || '10'),
                            });
                            handleChange('technical', data.technical);
                          }}
                          disabled={state === COMPONENT_STATE.DISABLE_EDIT}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
            <div>
              <div className='mb-4'>
                <div className='font-bold text-lg text-black'>{t('session.result.evaluation.caseSectionTitle')}</div>
              </div>
              <div className='ml-4'>
                {data &&
                  data.caseEvaluation.map((item, index) => {
                    return (
                      <div className='mb-4' key={index}>
                        <InputLabel
                          label={t(
                            item.type === SessionEvaluationCaseType.CaseEvaluationScoringItems
                              ? 'inputLabels.caseEvaluationScoringItems'
                              : item.type === SessionEvaluationCaseType.CaseEvaluationOverall
                              ? 'inputLabels.caseEvaluationOverall'
                              : 'inputLabels.caseEvaluationMaterial',
                          )}
                        />
                        <div className='mb-2'>
                          <span className='text-black text-lg'>
                            {t(
                              item.type === SessionEvaluationCaseType.CaseEvaluationScoringItems
                                ? 'session.result.evaluation.caseEvaluationScoringItemsHint'
                                : item.type === SessionEvaluationCaseType.CaseEvaluationOverall
                                ? 'session.result.evaluation.caseEvaluationOverallHint'
                                : 'session.result.evaluation.caseEvaluationMaterialHint',
                            )}
                          </span>
                        </div>
                        <CustomSelect
                          placeholder=''
                          list={numberList}
                          value={item.value.toString() || ''}
                          handleChange={(changedItem: ICustomSelectItem) => {
                            data.caseEvaluation.splice(index, 1, {
                              type: item.type,
                              value: Number.parseInt(changedItem.value || '10'),
                            });
                            handleChange('caseEvaluation', data.caseEvaluation);
                          }}
                          disabled={state === COMPONENT_STATE.DISABLE_EDIT}
                        />
                      </div>
                    );
                  })}
                <div className='mb-4'>
                  <InputLabel label={t('inputLabels.caseComment')} />
                  <div className='mb-2'>
                    <span className='text-black text-lg'>{t('session.result.evaluation.caseCommentHint')}</span>
                  </div>
                  <Textarea
                    placeholder={t('placeholders.caseComment')}
                    value={(data && data.caseComment) || ''}
                    handleChange={(e: any) => handleChange('caseComment', e.target.value)}
                    required
                    disabled={state === COMPONENT_STATE.DISABLE_EDIT}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className='mb-4'>
                <div className='font-bold text-lg text-black'>{t('session.result.evaluation.generalSectionTitle')}</div>
              </div>
              <div className='ml-4'>
                <div className='mb-4'>
                  <InputLabel label={t('inputLabels.generalComment')} />
                  <div className='mb-2'>
                    <span className='text-black text-lg'>{t('session.result.evaluation.generalSectionHint')}</span>
                  </div>
                  <Textarea
                    placeholder={t('placeholders.generalComment')}
                    value={(data && data.generalComment) || ''}
                    handleChange={(e: any) => handleChange('generalComment', e.target.value)}
                    required
                    disabled={state === COMPONENT_STATE.DISABLE_EDIT}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SessionEvaluate;
