import { ApiBaseService } from './api.service';
import { SessionEvaluationCreateRequest, SessionEvaluationResponse } from './dto/session-evaluate.dto';
import { SessionInstitutionResponseDto } from './dto/session.dto';
import { CaseResponseDto, UpdateCaseRequestDto } from './dto/case.dto';

export class SessionEvaluateService {
  public static basePath() {
    if (!process.env.API_URL_SESSION_EVALUATE_BASE) {
      throw new Error('API_URL_SESSION_BASE does not exist');
    }
    return process.env.API_URL_SESSION_EVALUATE_BASE;
  }

  public static create(
    payload: SessionEvaluationCreateRequest,
    handleSuccess: (_data: SessionEvaluationResponse) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = SessionEvaluateService.basePath();

    ApiBaseService.Post(
      uriPath,
      payload,
      (_code: number, _data: SessionEvaluationResponse) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static getBySessionId(
    id: string,
    handleSuccess: (_data: SessionEvaluationResponse) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = SessionEvaluateService.basePath() + `session/${id}`;

    ApiBaseService.Get(
      uriPath,
      {},
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static update(
    id: string,
    payload: SessionEvaluationResponse,
    handleSuccess: (_data: SessionEvaluationResponse) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = SessionEvaluateService.basePath() + id;

    ApiBaseService.Patch(
      uriPath,
      payload,
      (_code: number, _data: SessionEvaluationResponse) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }
}
