import { StateEmpty, StateError, StateLoading, StateSuccess } from '../../../redux/common';
import { CertificationResponseDto, CertificationSessionProgress } from '../../../service/dto/certification.dto';

type CertificationModelEmpty = StateEmpty;
type CertificationModelLoading = StateLoading;
type CertificationModelError = StateError;
type CertificationModelSuccess = StateSuccess<CertificationResponseDto>;
export type CertificationModel =
  | CertificationModelEmpty
  | CertificationModelLoading
  | CertificationModelError
  | CertificationModelSuccess;

type CertificationProgressEmpty = StateEmpty;
type CertificationProgressSuccess = StateSuccess<CertificationSessionProgress>;
export type CertificationProgress = CertificationProgressEmpty | CertificationProgressSuccess;

export type CertificationRoomState = {
  certification: CertificationModel;
  progress: CertificationProgress;
};

export const defaultCertificationRoomState: CertificationRoomState = {
  certification: {
    type: 'EMPTY',
  },
  progress: {
    type: 'EMPTY',
  },
};
