import React from 'react';
import { useTranslation } from 'react-i18next';

// DTO
import { CreateInstitutionRequestDto } from '../../service/dto/institution.dto';

// UI COMPONENTS
import InputLabel from '../../components/InputLabel';
import TextInput from '../../components/TextInput';

interface ICreateInstitutionFormProps {
  errors: Object;
  payload: CreateInstitutionRequestDto;
  handleChange: Function;
}

const CreateInstitutionForm = ({ errors, payload, handleChange }: ICreateInstitutionFormProps) => {
  const { t } = useTranslation();
  return (
    <>
      <div className='text-base mt-2 mb-4 text-gray-dark'>{t('institutions.description')}</div>
      <form className='w-full'>
        <div className='grid grid-1'>
          <div className='mb-6'>
            <InputLabel label={t('inputLabels.institutionName')} />
            <TextInput
              placeholder={t('placeholders.institutionName')}
              value={payload.name || ''}
              handleChange={(e: any) => handleChange('name', e.target.value)}
              error={errors.name}
              required
            />
          </div>

          <div className='mb-6'>
            <InputLabel label={t('inputLabels.institutionCode')} additionalLabel={'4-digits'} />
            <TextInput
              placeholder={t('placeholders.institutionCode')}
              value={payload.code || ''}
              handleChange={(e: any) => handleChange('code', e.target.value)}
              error={errors.code}
              required
            />
          </div>

          <div className='mb-6'>
            <InputLabel label={t('inputLabels.institutionTimezone')} additionalLabel={t('inputLabels.institutionTimezoneInfo')} />
            <TextInput
              placeholder={t('placeholders.institutionTimezone')}
              value={payload.timezone || 'Europe/Zurich'}
              handleChange={(e: any) => handleChange('timezone', e.target.value)}
              error={errors.timezone}
              disabled
            />
          </div>

        </div>
      </form>
    </>
  );
};

export default CreateInstitutionForm;
