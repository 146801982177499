import { IRouteComponent } from './../routes';
import { USER_ROLES } from './../common/enums';
import { setCurrentUserInstitution } from './institution';

export const setUserRole = ({ currentUser, currentInstitution }: IRouteComponent) => {
  // MOVE TO USER
  const currentUserInstitution = setCurrentUserInstitution({
    currentUser,
    currentInstitution,
  });

  // FIND ROLES
  if (currentUserInstitution) {
    return currentUserInstitution.roles;
  }
  return [USER_ROLES.Trainee];
};
