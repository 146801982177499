import React from 'react';
import { SessionTimePlayingState } from '../redux/types';
import { useAppSelector } from '../redux/store';
interface IStaticRatingStarProps {
  rate: number;
  onClick?: () => void;
  tmstmp?: string;
}
const iconByRate = new Map([
  [3, 'bi bi-star-fill text-background-page'],
  [2, 'bi bi-star-half text-background-page'],
  [1, 'bi bi-star text-background-page'],
]);

const classByRate = new Map([
  [3, 'bg-positive'],
  [2, 'bg-warning'],
  [1, 'bg-negative'],
]);

const StaticRatingStar = ({ rate, onClick, tmstmp }: IStaticRatingStarProps) => {
  const sessionTimePlaying = useAppSelector((state: any) => state.sessionTimePlayingReducer) as SessionTimePlayingState;

  return (
    <button onClick={onClick}>
      <div
        className={`flex items-center justify-center rounded-lg relative cursor-pointer w-12 h-12 star_block ${classByRate.get(
          rate,
        )} ${sessionTimePlaying.selectedScoreItemTimestamp === tmstmp ? 'border-4 border-primary' : ''}`}
      >
        <i className={`${iconByRate.get(rate)} text-2xl `} />
      </div>
    </button>
  );
};
export default StaticRatingStar;
