import React from 'react';

// UI COMPONENTS
import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';

interface IAbstractModalProps {
  label?: string;
  children?: React.ReactNode;
  cancelButton?: { onClick: Function };
  leftBtn?: {
    label: string;
    icon?: string;
    onClick: Function;
  };
  rightBtn?: {
    label: string;
    icon?: string;
    onClick: Function;
    disabled?: boolean;
  };
  toDelete?: boolean;
  className?: string;
  position?: { width: string; left: string };
}

const AbstractModal = ({
  label,
  children,
  cancelButton,
  leftBtn,
  rightBtn,
  toDelete,
  className,
  position,
}: IAbstractModalProps) => {
  if (!position) {
    position = { width: 'w-2/3', left: 'left-1/4' };
  }
  return (
    <div
      className={`modal block absolute top-28 ${position.left} ${position.width} m-auto -b-lg-lg rounded-lg shadow-xl`}
      id='abstractModal'
      tabIndex={-1}
      role='dialog'
      aria-labelledby='abstractModalLabel'
      aria-hidden='true'
    >
      <div className='flex items-center w-auto pointer-events-none relative ' role='document'>
        <div className='relative flex flex-col w-full pointer-events-auto bg-white outline-none rounded-lg bg-clip-padding'>
          <div className={`flex items-center shrink-0 p-4 justify-between`}>
            <div className='text-black font-bold text-xl' id='abstractModalLabel'>
              {label}
            </div>
            {cancelButton && (
              <div onClick={() => cancelButton.onClick()}>
                <i className='bi bi-x text-2xl text-gray cursor-pointer' />
              </div>
            )}
          </div>

          <div className={`modal-body relative flex-auto p-4 bg-background-modal b-white`}>{children}</div>

          <div className={`flex flex-row py-6 px-3 justify-center content-end}`}>
            {leftBtn && (
              <SecondaryButton
                className='text-primary border-primary hover:text-primary hover:bg-background-black mr-4'
                title={leftBtn.label}
                icon={leftBtn.icon}
                onClick={() => leftBtn.onClick()}
              />
            )}

            {rightBtn && (
              <PrimaryButton
                title={rightBtn.label}
                icon={rightBtn.icon}
                onClick={() => rightBtn.onClick()}
                disabled={rightBtn.disabled}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AbstractModal;
