import React, { useState, useEffect } from 'react';

export enum CheckboxInputStyle {
  VERTICAL,
  HORIZONTAL,
}

export interface ICheckboxItem {
  value: string;
  label: string;
}

interface ICheckboxInputProps {
  list: ICheckboxItem[];
  values: string[];
  style: CheckboxInputStyle;
  handleChange: Function;
  multipleSelect?: boolean;
}

const CheckboxInput = ({ list, values, style, handleChange, multipleSelect = true }: ICheckboxInputProps) => {
  const [roles, setRoles] = useState(values);
  const handleRoles = (e: any, value: string) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      if (multipleSelect) {
        setRoles([...roles, value]);
      } else {
        setRoles([value]);
      }
    } else {
      setRoles(roles.filter(role => role !== value));
    }
  };
  useEffect(() => {
    handleChange(roles);
  }, [roles]);

  return (
    <div className={'pt-3 flex ' + (style == CheckboxInputStyle.VERTICAL ? 'flex-col' : 'flex-row ')}>
      {list.map(i => {
        return (
          <label
            className={
              'text-black block relative cursor-pointer select-none mb-5 pr-6 pl-8 checkboxInputs__item ' +
              (roles.includes(i.value) && 'font-bold')
            }
            key={i.label}
          >
            {i.label}
            <input
              name='roles'
              type='checkbox'
              className='cursor-pointer absolute w-0 h-0 opacity-0'
              checked={roles.includes(i.value)}
              onChange={e => {
                handleRoles(e, i.value);
              }}
              multiple
            />
            <span className='absolute top-0 left-0 bg-transparent rounded w-5 h-5 border border-solid border-primary checkbox-checkmark' />
          </label>
        );
      })}
    </div>
  );
};

export default CheckboxInput;
