import React, { useEffect, useState } from 'react';
import Alert from '../utils/alert';
import { useTranslation } from 'react-i18next';
import { UserService } from '../service/user.service';

// UI components
import PrimaryButton from './PrimaryButton';
import { USER_ROLES } from '../common/enums';

interface Invites {
  institutionId: string;
  appendRole: USER_ROLES;
}

const Chips = ({ institutionId, appendRole }: Invites) => {
  const [chips, setChips] = useState<string[]>([]);
  const [buttonOff, setButtonOff] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (chips.length == 0) {
      setButtonOff(true);
    } else {
      setButtonOff(false);
    }
  }, [chips]);

  const validateEmail = (email: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const addChips = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || (event.key === ' ' && event.currentTarget.value !== '') || event.key === ',') {
      if (validateEmail(event.currentTarget.value)) {
        if (!chips.includes(event.currentTarget.value)) {
          setChips([...chips, event.currentTarget.value]);
          event.currentTarget.value = '';
          event.preventDefault();
        } else {
          Alert.warning(t('cases.theSameEmail'));
          event.currentTarget.value = '';
        }
      } else {
        Alert.warning(t('cases.validationErrors.emailValidation'));
      }
    }
  };

  const addOnPasteChips = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const splitElement = event.clipboardData.getData('Text').includes(',') ? ',' : ' ';
    const emails = event.clipboardData
      .getData('Text')
      .split(splitElement)
      .map(email => email.trim());
    const uniqueEmails = emails.filter((item, pos) => {
      if (!chips.includes(item) && validateEmail(item)) {
        return emails.indexOf(item) == pos;
      }
    });
    setChips([...chips, ...uniqueEmails]);
    event.currentTarget.value = '';
    event.preventDefault();
  };

  const deleteChip = (indexToRemove: number) => {
    setChips(chips.filter((_, index) => index !== indexToRemove));
  };

  const deleteLastChip = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.currentTarget.value == '') {
      setChips(chips.filter((element, index) => index < chips.length - 1));
    }
  };

  const serviceSendUsersInvites = () => {
    UserService.sendUsersInvites(
      {
        institutionId: institutionId,
        usersEmails: chips,
        appendRole: appendRole,
      },
      () => {
        Alert.success(t('successMessages.invitesSuccessSent'));
      },
      () => {},
    );
  };

  return (
    <div className='flex flex-col items-center 2xl:flex-row mt-3'>
      <div className='custom-input transition py-1.5 px-3 text-base rounded-lg flex flex-wrap bg-white bg-clip-padding text-gray-dark placeholder-gray-light hover:border-primary-hover focus:text-black focus:border-primary-hover focus:outline-none border border-solid border-black-divider appearance-none w-full font-normal'>
        {chips &&
          chips.map((chip: string, index: number) => (
            <div className={`bg-primary-hover text-white rounded mr-3 p-1 align-middle my-1.5`} key={index}>
              <span className='chip-value '>{chip}</span>
              <button type='button' className='ml-2' onClick={() => deleteChip(index)}>
                <i className='bi bi-x-circle' />
              </button>
            </div>
          ))}
        <input
          type='text'
          className={`border-0 hover:border-primary-hover focus:text-black focus:border-primary-hover focus:outline-none ps-1`}
          placeholder={'Enter an email'}
          onKeyDown={e =>
            (e.key === 'Enter' || ' ' || ',' ? addChips(e) : null) || (e.key === 'Backspace' ? deleteLastChip(e) : null)
          }
          onPaste={e => addOnPasteChips(e)}
        />
      </div>
      <PrimaryButton
        title={t('buttons.sendInvites')}
        type={'button'}
        className={'mt-2 2xl:mt-0 2xl:ml-6 2xl:w-1/3'}
        onClick={() => {
          serviceSendUsersInvites();
          setChips([]);
        }}
        disabled={buttonOff}
      />
    </div>
  );
};

export default Chips;
