import React from 'react';

interface SingleCheckbox<TValue> {
  value?: TValue;
  id: string;
  label: string;
  style?: string;
  handleChange?: (event?: any) => void;
  disabled?: boolean;
  className?: string;
  labelLeft?: boolean;
  checked?: boolean;
}

type SingleCheckboxType = string | number;

const SingleCheckboxInput = <TValue extends SingleCheckboxType>({
  value,
  label,
  style = '',
  handleChange,
  disabled = false,
  className = '',
  labelLeft = false,
  checked = false,
  id,
}: SingleCheckbox<TValue>) => {
  const getInputStyle = () => {
    const baseStyle = 'cursor-pointer absolute w-5 h-5 mt-0.5';
    if (disabled) {
      return [baseStyle, style].join(' ');
    }

    return [baseStyle, style].join(' ');
  };

  const renderLabel = () => {
    if (!label) {
      return null;
    }

    return (
      <label
        htmlFor={id}
        className={'text-black block relative cursor-pointer select-none mb-5 pr-6 pl-8 checkboxInputs__item'}
      >
        {label}
      </label>
    );
  };

  return (
    <div className={`flex ${className}`}>
      {labelLeft && renderLabel()}
      <input
        type={'checkbox'}
        id={id}
        className={getInputStyle()}
        disabled={disabled}
        onChange={handleChange}
        checked={checked}
      />
      {!labelLeft && renderLabel()}
    </div>
  );
};

export default SingleCheckboxInput;
