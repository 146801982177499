import { CASE_NOTE } from './enums';

export const LIST_GRADES = [
  { value: '2', label: '2' },
  { value: '3', label: '3' },
];

export const LIST_WEIGHT = () => {
  const weightList = [];

  for (let i = 0.5; i <= 2; i = i + 0.5) {
    weightList.push({ value: i.toString(), label: i.toString() });
  }

  return weightList;
};

export const DEFAULT_WEIGHT = 1;

export const LIST_NOTE = [
  { value: CASE_NOTE.None, label: 'None' },
  { value: CASE_NOTE.Open, label: 'Open Text' },
  { value: CASE_NOTE.Patient, label: 'Patient Note' },
];
