import { createReducer } from '@reduxjs/toolkit';
import { defaultCertificationState } from './state';
import {
  actionCertificationDetailsUpdate,
  actionCertificationListUpdate,
  actionCertificationReset,
  actionCertificationRoomReset,
  actionCertificationRoomUpdate,
} from './actions';

export const certificationDetailsReducers = createReducer(defaultCertificationState, builder => {
  builder
    .addCase(actionCertificationReset, _ => defaultCertificationState)
    .addCase(actionCertificationDetailsUpdate, (state, action) => ({
      ...state,
      details: {
        ...state.details,
        ...action.payload,
      },
    }))
    .addCase(actionCertificationListUpdate, (state, action) => ({
      ...state,
      list: {
        ...state.list,
        ...action.payload,
      },
    }))
    .addCase(actionCertificationRoomUpdate, (state, { payload }) => ({
      ...state,
      room: {
        ...state.room,
        ...payload,
      },
    }))
    .addCase(actionCertificationRoomReset, state => ({
      ...state,
      room: defaultCertificationState.room,
    }));
});
