import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DefaultHeader from '../../components/DefaultHeader';
import InsightsFilterFromUntil from './InsightsFilterFromUntil';
import EvaluationTabs, { EvaluationTabsTabContent } from './EvaluationTabs';
import { InsightsService } from '../../service/insightsService';
import { evaluationPageSize, sessionLogPageSize } from '../../common/constants';
import { USER_ROLES } from '../../common/enums';
import {
  InsightsEvaluationCaseResponseDto,
  InsightsEvaluationCoachResponseDto,
  InsightsEvaluationSystemDto,
  InsightsEvaluationTraineeResponseDto,
} from '../../service/dto/insights.dto';
import {
    setCaseEvaluationSelector, setEvaluationCoachSearchSelector,
    setEvaluationTraineeSearchSelector,
    setInsightsEvaluationFilterFromUntil
} from '../../redux/actions';
import SearchField from '../../components/SearchField';
import CustomSelect, { ICustomSelectItem } from '../../components/CustomSelect';
import { CaseService } from '../../service/case.service';
import { CaseTemplateSummaryDto } from '../../service/dto/case.dto';
import { addedDay } from '../../utils/funcs';
import { useAppDispatch, useAppSelector } from '../../redux/store';

const Evaluation = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [pageTrainee, setPageTrainee] = useState<number>(0);
  const [pageCoach, setPageCoach] = useState<number>(0);
  const [pageCases, setPageCases] = useState<number>(0);
  const [pageSystem, setPageSystem] = useState<number>(0);
  const [totalPagesTrainee, setTotalPagesTrainee] = useState<number>(0);
  const [totalPagesCoach, setTotalPagesCoach] = useState<number>(0);
  const [totalPagesCases, setTotalPagesCases] = useState<number>(0);
  const [totalPagesSystem, setTotalPagesSystem] = useState<number>(0);
  const { from, until }: { from: Date; until: Date } = useAppSelector(
    (state: any) => state.insightsEvaluationFilterReducer,
  );
  const {coachSearch}: {coachSearch: string} = useAppSelector((state: any) => state.evaluationCoachSearchReducer);
  const {traineeSearch}: {traineeSearch: string} = useAppSelector((state: any) => state.evaluationTraineeSearchReducer);
  const {chooseCase}: {chooseCase: string} = useAppSelector((state: any) => state.caseEvaluationReducer);
  const [search, setSearch] = useState(traineeSearch);
  const [traineeEvaluation, setTraineeEvaluation] = useState<InsightsEvaluationTraineeResponseDto[]>();
  const [coachEvaluation, setCoachEvaluation] = useState<InsightsEvaluationCoachResponseDto[]>();
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);
  const [cases, setCases] = useState<ICustomSelectItem[]>([]);
  const [caseEvaluation, setCaseEvaluation] = useState<InsightsEvaluationCaseResponseDto>();
  const [systemEvaluation, setSystemEvaluation] = useState<InsightsEvaluationSystemDto[]>();

    useEffect(() => {
        if (currentTabIndex == 1) {
            setSearch(coachSearch);
        } else if (currentTabIndex == 0) {
            setSearch(traineeSearch);
        }
    }, [currentTabIndex]);

  useEffect(() => {
    serviceGetEvaluationTrainee();
    serviceGetEvaluationCoach();
    serviceGetCaseList();
    serviceGetEvaluationCase();
    serviceGetEvaluationSystem();
    serviceGetEvaluationTraineeCount();
    serviceGetEvaluationCoachCount();
    serviceGetEvaluationCasesCount();
    serviceGetEvaluationSystemCount();
  }, [from, until, pageTrainee, pageCoach, pageCases, pageSystem, search, chooseCase]);

  const onSearch = (value: string) => {
    setSearch(value);
    switch (currentTabIndex) {
        case 0:
            dispatch(setEvaluationTraineeSearchSelector({ traineeSearch: value}));
            break;
        default:
            dispatch(setEvaluationCoachSearchSelector({ coachSearch: value}));
            break;
    }
  };

  const onChange = (ICustomSelectItem: ICustomSelectItem) => {
    dispatch(setCaseEvaluationSelector({ chooseCase: ICustomSelectItem.value as string}));
  };

  // Service fetch
  const serviceGetEvaluationTrainee = () => {
    InsightsService.getEvaluationTrainee(
      {
        from,
        until: addedDay(until),
        page: pageTrainee,
        pageSize: evaluationPageSize,
        role: USER_ROLES.Trainee,
        search,
      },
      (data: InsightsEvaluationTraineeResponseDto[]) => {
        setTraineeEvaluation(data);
      },
      () => {
        setTotalPagesTrainee(0);
        setPageTrainee(0);
      },
    );
  };

  const serviceGetEvaluationCoach = () => {
    InsightsService.getEvaluationCoach(
      {
        from,
        until,
        page: pageCoach,
        pageSize: evaluationPageSize,
        role: USER_ROLES.SP,
        search,
      },
      (data: InsightsEvaluationCoachResponseDto[]) => {
        setCoachEvaluation(data);
      },
      () => {
        setTotalPagesCoach(0);
        setPageCoach(0);
      },
    );
  };

  const serviceGetCaseList = () => {
    CaseService.listPublished(
      (data: Array<CaseTemplateSummaryDto>) => {
        setCases(
          data.map(casesList => {
            return { value: casesList.id, label: casesList.name };
          }),
        );
        if (data.length && (chooseCase == '')) {
          dispatch(setCaseEvaluationSelector({ chooseCase: data[0].id}))
        }
      },
      () => {},
    );
  };

  const serviceGetEvaluationCase = () => {
    InsightsService.getEvaluationCase(
      {
        from,
        until,
        caseId: chooseCase,
      },
      (data: InsightsEvaluationCaseResponseDto) => {
        setCaseEvaluation(data);
      },
      () => {},
    );
  };

  const serviceGetEvaluationSystem = () => {
    InsightsService.getEvaluationSystem(
      {
        from,
        until,
      },
      (data: InsightsEvaluationSystemDto[]) => {
        setSystemEvaluation(data);
      },
      () => {},
    );
  };

  const serviceGetEvaluationTraineeCount = () => {
    InsightsService.getEvaluationTraineeCount(
      {
        from,
        until,
        page: pageTrainee,
        pageSize: evaluationPageSize,
      },
      (data: number) => {
        setTotalPagesTrainee(Math.floor((data - 1) / sessionLogPageSize) + 1);
      },
      () => {
        setTotalPagesTrainee(0);
        setPageTrainee(0);
      },
    );
  };

  const serviceGetEvaluationCoachCount = () => {
    InsightsService.getEvaluationCoachCount(
      {
        from,
        until,
        page: pageCoach,
        pageSize: evaluationPageSize,
      },
      (data: number) => {
        setTotalPagesCoach(Math.floor((data - 1) / sessionLogPageSize) + 1);
      },
      () => {
        setTotalPagesCoach(0);
        setPageCoach(0);
      },
    );
  };

  const serviceGetEvaluationCasesCount = () => {
    InsightsService.getEvaluationCasesCount(
      {
        from,
        until,
        page: pageCases,
        pageSize: evaluationPageSize,
      },
      (data: number) => {
        setTotalPagesCases(Math.floor((data - 1) / sessionLogPageSize) + 1);
      },
      () => {
        setTotalPagesCases(0);
        setPageCases(0);
      },
    );
  };

  const serviceGetEvaluationSystemCount = () => {
    InsightsService.getEvaluationSystemCount(
      {
        from,
        until,
        page: pageSystem,
        pageSize: evaluationPageSize,
      },
      (data: number) => {
        setTotalPagesSystem(Math.floor((data - 1) / sessionLogPageSize) + 1);
      },
      () => {
        setTotalPagesSystem(0);
        setPageSystem(0);
      },
    );
  };

  const renderEvaluationTabs = () => {
    const filteredEvaluation: EvaluationTabsTabContent[] = [
      {
        title: t('insights.evaluation.traineeEvaluation'),
        trainee: traineeEvaluation,
        forcePage: pageTrainee,
        pageCount: totalPagesTrainee,
        onPageChange: (event: { selected: number }) => {
          setPageTrainee(event.selected);
        },
      },
      {
        title: t('insights.evaluation.coachEvaluation'),
        coach: coachEvaluation,
        forcePage: pageCoach,
        pageCount: totalPagesCoach,
        onPageChange: (event: { selected: number }) => {
          setPageCoach(event.selected);
        },
      },
      {
        title: t('insights.evaluation.caseTemplateEvaluation'),
        cases: caseEvaluation,
        forcePage: pageCases,
        pageCount: totalPagesCases,
        onPageChange: (event: { selected: number }) => {
          setPageCases(event.selected);
        },
      },
      {
        title: t('insights.evaluation.system'),
        system: systemEvaluation,
        forcePage: pageSystem,
        pageCount: totalPagesSystem,
        onPageChange: (event: { selected: number }) => {
          setPageSystem(event.selected);
        },
      },
    ].filter(x => x.title);
    return (
      <EvaluationTabs
        tabs={filteredEvaluation}
        currentTabIndex={currentTabIndex}
        setCurrentTabIndex={setCurrentTabIndex}
      />
    );
  };

  return (
    <div className='rounded-lg bg-white my-6 shadow pb-2 mx-4 2xl:mx-6'>
      <DefaultHeader title={t('sidebar.evaluation')} />
      <InsightsFilterFromUntil
        from={from}
        until={until}
        styleFrom={'w-96'}
        onChange={({ from, until }) => {
          dispatch(setInsightsEvaluationFilterFromUntil({ from, until }));
        }}
      />
      <div className='px-7 pb-5 flex justify-between'>
        {currentTabIndex <= 1 && (
          <div className={'w-96'}>
            <SearchField
                onSearch={onSearch}
                placeholderLabel='placeholders.search'
                width='w-full'
                value={currentTabIndex == 1 ? coachSearch : traineeSearch}/>
          </div>
        )}
        {currentTabIndex == 2 && (
          <div className='w-96'>
            <CustomSelect placeholder={'Cases'} list={cases} handleChange={onChange} value={chooseCase ?? cases[0].value} />
          </div>
        )}
      </div>
      {renderEvaluationTabs()}
    </div>
  );
};

export default Evaluation;
