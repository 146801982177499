import React, { RefObject, useEffect, useState } from 'react';
import Video, {
  LocalAudioTrack,
  LocalAudioTrackPublication,
  LocalTrack,
  LocalTrackPublication,
  LocalVideoTrack,
  LocalVideoTrackPublication,
  RemoteParticipant,
  Room,
} from 'twilio-video';
import ParticipantComponent from './ParticipantComponent';
import VideoControl from './VideoControl';
import { SessionStatus, USER_ROLES } from '../../../common/enums';
import { createConstrains, MediaDeviceDto } from '../../../service/dto/MediaDevice.dto';
import { setDevices } from '../../../redux/actions';
import { DeviceState } from '../../../redux/types';
import { SessionRecordResponseDto, SessionResponseDto } from '../../../service/dto/session.dto';
import { SessionService } from '../../../service/session.service';
import { calculateSessionRecordedTimeInSeconds } from '../../../utils/funcs';
import { getFirstLetter } from '../../../utils';
import { UserService } from '../../../service/user.service';
import { UserResponseDto } from '../../../service/dto/user.dto';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../../../components/PrimaryButton';
// @ts-ignore
import silence from '../../../assets/audio/silence.mp3';
import { isIOS } from 'react-device-detect';
import { useAppDispatch, useAppSelector } from '../../../redux/store';

const RoomView = ({
  room,
  onRemoteAudioMute,
  onRemoteVideoMute,
  role,
}: {
  room: Room;
  onRemoteAudioMute: (isMuted: boolean, id?: string) => void;
  onRemoteVideoMute: (isMuted: boolean, id?: string) => void;
  role: USER_ROLES;
}) => {
  const { t } = useTranslation();
  const deviceState: DeviceState = useAppSelector((state: any) => state.devicesConfig);
  const { session }: { session: SessionResponseDto } = useAppSelector((state: any) => state.sessionConfig);
  const userId = useAppSelector((state: any) => state.auth.user.id as string);
  let sessionRecordRx: SessionRecordResponseDto = useAppSelector(
    (state: any) => state.socketRecordConfig,
  ).sessionRecord;
  const dispatch = useAppDispatch();

  const [coachParticipant, setCoachParticipant] = useState<RemoteParticipant | null>(null);
  const [traineeParticipant, setTraineeParticipant] = useState<RemoteParticipant | null>(null);
  const [spectatorParticipants, setSpectatorParticipants] = useState<
    Array<{ id: string; firstName: string; lastName: string }>
  >([]);
  const [localVideoMute, setLocalVideoMute] = useState(false);
  const [localAudioMute, setLocalAudioMute] = useState(false);
  const [remoteAudioPaused, setRemoteAudioPaused] = useState(false);
  const [currentCamera, setCurrentCamera] = useState<MediaDeviceDto>();
  const [currentMic, setCurrentMic] = useState<MediaDeviceDto>();
  const [speaker, setSpeaker] = useState<MediaDeviceDto>();
  const [sessionRecordingStatus, setSessionRecordingStatus] = useState({
    isRecordingActive: false,
    isUserCoach: false,
    allowRecording: true,
    recordedTime: 0,
  });
  const [sessionRecordingButtonEnabled, setSessionRecordingButtonEnabled] = useState(true);
  const { user: currentUser } = useAppSelector((state: any) => state.auth);
  let sessionRecordingTimeHandler: NodeJS.Timer | null = null;
  const [playAllowed, setPlayAllowed] = useState<boolean>(false);

  useEffect(() => {
    updateSessionRecordingStatus();
  }, [session]);

  useEffect(() => {
    checkAutoplay();
  }, [playAllowed]);

  useEffect(() => {
    checkAutoplay();
  }, []);

  useEffect(() => {
    setLocalAudioMute(!deviceState.devices.find(device => device.active && device.kind === 'audioinput')?.enabled);
    setLocalVideoMute(!deviceState.devices.find(device => device.active && device.kind === 'videoinput')?.enabled);
    const videoDevice = deviceState.devices.find(device => device.active && device.kind === 'videoinput');
    if (currentCamera && videoDevice && videoDevice.deviceId != currentCamera.deviceId) {
      changeCameraOrMic(videoDevice);
    }
    if (!currentCamera) {
      setCurrentCamera(videoDevice);
    }
    const audioDevice = deviceState.devices.find(device => device.active && device.kind === 'audioinput');
    if (currentMic && audioDevice && audioDevice.deviceId != currentMic.deviceId) {
      changeCameraOrMic(audioDevice);
    }
    if (!currentMic) {
      setCurrentMic(audioDevice);
    }
  }, [deviceState]);

  useEffect(() => {
    let isMounted = true;
    room.participants.forEach((participant: RemoteParticipant) => {
      handleRemoteParticipantSubscription(participant);
    });

    // Log new Participants as they connect to the Room
    room.on('participantConnected', (participant: RemoteParticipant) => {
      if (!isMounted) {
        return;
      }
      handleRemoteParticipantSubscription(participant);
    });

    // Log Participants as they disconnect from the Room
    room.on('participantDisconnected', (participant: RemoteParticipant) => {
      if (!isMounted) {
        return;
      }
      if (session.coach.id === participant.identity) {
        setCoachParticipant(null);
      } else if (session.trainee.id === participant.identity) {
        setTraineeParticipant(null);
      } else {
        setSpectatorParticipants(participants =>
          participants.filter(spectatorParticipant => spectatorParticipant.id != participant.identity),
        );
      }
    });

    room.on('disconnected', () => {
      room.localParticipant.tracks.forEach((publication: LocalTrackPublication) => {
        publication.unpublish();
        const track =
          publication.track.kind === 'video'
            ? (publication.track as LocalVideoTrack)
            : (publication.track as LocalAudioTrack);
        track.stop();
        track.detach();
      });
    });

    return () => {
      room.localParticipant.tracks.forEach((publication: LocalTrackPublication) => {
        publication.unpublish();
        const track =
          publication.track.kind === 'video'
            ? (publication.track as LocalVideoTrack)
            : (publication.track as LocalAudioTrack);
        track.stop();
        track.detach();
      });
      isMounted = false;
      room.disconnect();
    };
  }, [room]);

  useEffect(() => {
    if (sessionRecordRx.roomSid !== '' && room.sid == sessionRecordRx.roomSid) {
      setSessionRecordingStatus({
        ...sessionRecordingStatus,
        allowRecording: sessionRecordRx.allowRecording,
        isRecordingActive: sessionRecordRx.recordingActive,
      });
    }
  }, [sessionRecordRx]);

  const checkAutoplay = async () => {
    try {
      const silenceAudio = new Audio(silence);
      await silenceAudio.play();
      setPlayAllowed(true);
    } catch (e) {
      console.log(e);
      setPlayAllowed(false);
    }
  };

  const updateSessionRecordingStatus = () => {
    if (!session) {
      return;
    }

    const isUserCoach = session.coach.id == userId;
    if (session.status === SessionStatus.Coaching) {
      const recordActive = session.coachingSessionRecord?.recordingActive ?? false;
      setSessionRecordingStatus({
        isRecordingActive: recordActive,
        isUserCoach: isUserCoach,
        allowRecording: session.coachingSessionRecord?.allowRecording ?? false,
        recordedTime: calculateSessionRecordedTimeInSeconds(session.coachingSessionRecord?.recordingSequences ?? []),
      });
    } else if (session.status === SessionStatus.Feedback) {
      const recordActive = session.feedbackSessionRecord?.recordingActive ?? false;
      setSessionRecordingStatus({
        isRecordingActive: recordActive,
        isUserCoach: isUserCoach,
        allowRecording: session.feedbackSessionRecord?.allowRecording ?? false,
        recordedTime: calculateSessionRecordedTimeInSeconds(session.feedbackSessionRecord?.recordingSequences ?? []),
      });
    }
  };

  useEffect(() => {
    const clearSessionInterval = () => {
      if (sessionRecordingTimeHandler != null) {
        clearInterval(sessionRecordingTimeHandler);
        sessionRecordingTimeHandler = null;
      }
    };

    if (sessionRecordingStatus.isRecordingActive && sessionRecordingTimeHandler == null) {
      sessionRecordingTimeHandler = setInterval(() => {
        setSessionRecordingStatus({
          ...sessionRecordingStatus,
          recordedTime: sessionRecordingStatus.recordedTime + 1,
        });
      }, 1000);
    } else if (!sessionRecordingStatus.isRecordingActive && sessionRecordingTimeHandler != null) {
      clearSessionInterval();
    }

    return clearSessionInterval;
  }, [sessionRecordingStatus]);

  const handleRemoteParticipantSubscription = (participant: RemoteParticipant) => {
    if (session.coach.id === participant.identity) {
      setCoachParticipant(participant);
      onRemoteAudioMute(false, participant.identity);
      onRemoteVideoMute(false, participant.identity);
    } else if (session.trainee.id === participant.identity) {
      setTraineeParticipant(participant);
      onRemoteAudioMute(false, participant.identity);
      onRemoteVideoMute(false, participant.identity);
    } else {
      addSpectatorParticipant(participant);
    }
  };

  const addSpectatorParticipant = (participant: RemoteParticipant) => {
    if (!spectatorParticipants.find(spectatorParticipant => spectatorParticipant.id == participant.identity)) {
      UserService.getById(
        participant.identity,
        (data: UserResponseDto) => {
          const newParticipant = { id: data.id, firstName: data.firstName, lastName: data.lastName };
          setSpectatorParticipants(participants => [...participants, newParticipant]);
        },
        console.error,
      );
    }
  };

  const onLocalVideoMute = () => {
    room.localParticipant.videoTracks.forEach((publication: LocalVideoTrackPublication) => {
      if (deviceState.devices) {
        deviceState.devices.find(
          (deviceF: MediaDeviceDto) => deviceF.kind === 'videoinput' && deviceF.active,
        )!.enabled = !publication.track.isEnabled;
        dispatch(setDevices({ devices: deviceState.devices }));
      }
      if (publication.track.isEnabled) {
        publication.track.disable();
        setLocalVideoMute(true);
      } else {
        publication.track.enable();
        setLocalVideoMute(false);
      }
    });
  };

  const onLocalAudioMute = () => {
    room.localParticipant.audioTracks.forEach((publication: LocalAudioTrackPublication) => {
      if (deviceState.devices) {
        deviceState.devices.find(
          (deviceF: MediaDeviceDto) => deviceF.kind === 'audioinput' && deviceF.active,
        )!.enabled = !publication.track.isEnabled;
        dispatch(setDevices({ devices: deviceState.devices }));
      }
      if (publication.track.isEnabled) {
        publication.track.disable();
        setLocalAudioMute(true);
      } else {
        publication.track.enable();
        setLocalAudioMute(false);
      }
    });
  };

  const onRemoteAudioPlayPause = () => {
    setRemoteAudioPaused(!remoteAudioPaused);
  };

  const changeCameraOrMic = async (device: MediaDeviceDto) => {
    let isOldTrackEnabled = true;
    let oldDevice = deviceState.devices!.find(
      (deviceF: MediaDeviceDto) => deviceF.kind === device.kind && deviceF.active,
    );
    (device.kind === 'videoinput' ? room.localParticipant.videoTracks : room.localParticipant.audioTracks).forEach(
      (publication: LocalTrackPublication) => {
        publication.unpublish();
        const track =
          publication.track.kind === 'video'
            ? (publication.track as LocalVideoTrack)
            : (publication.track as LocalAudioTrack);
        isOldTrackEnabled = track.isEnabled;
        track.stop();
        track.detach();
      },
    );
    if (oldDevice) {
      oldDevice.active = false;
      oldDevice.enabled = true;
    }
    device.active = true;
    const newTrack: LocalTrack =
      device.kind === 'videoinput'
        ? await Video.createLocalVideoTrack(createConstrains(device))
        : await Video.createLocalAudioTrack({ deviceId: device.deviceId });
    if (!isOldTrackEnabled) {
      newTrack.disable();
    }
    room.localParticipant.publishTrack(newTrack);
    if (device.kind === 'audioinput') {
      setCurrentMic(device);
    }
    if (device.kind === 'videoinput') {
      setCurrentCamera(device);
    }
    dispatch(setDevices({ devices: deviceState.devices }));
  };

  const changeSpeaker = (device: MediaDeviceDto) => {
    deviceState.devices!.find((deviceF: MediaDeviceDto) => deviceF.kind === device.kind && deviceF.active)!.active =
      false;
    device.active = true;
    setSpeaker(device);
    dispatch(setDevices({ devices: deviceState.devices }));
  };

  const onRecordingClick = () => {
    if (!sessionRecordingStatus.allowRecording && !sessionRecordingStatus.isRecordingActive) {
      return null;
    }

    if (!sessionRecordingButtonEnabled) {
      return null;
    }

    setSessionRecordingButtonEnabled(false);

    return SessionService.toggleSessionRecording(session.id)
      .then(response => {
        setSessionRecordingStatus({
          isRecordingActive: response.recordingActive,
          isUserCoach: sessionRecordingStatus.isUserCoach,
          allowRecording: response.allowRecording,
          recordedTime: sessionRecordingStatus.recordedTime,
        });
      })
      .catch(error => {
        console.error(error);
        setSessionRecordingStatus({
          isRecordingActive: sessionRecordingStatus.isRecordingActive,
          isUserCoach: sessionRecordingStatus.isUserCoach,
          allowRecording: false,
          recordedTime: sessionRecordingStatus.recordedTime,
        });
      })
      .finally(() => {
        setSessionRecordingButtonEnabled(true);
      });
  };

  if (!room) return null;

  const renderFirstLetters = (participant: { firstName: string; lastName: string }) => {
    return (
      <>
        <span className='pr-2'>{getFirstLetter(participant.firstName)}</span>
        <span>{getFirstLetter(participant.lastName)}</span>
      </>
    );
  };

  const audioPlayPause = (audioRef: RefObject<HTMLAudioElement>) => {
    const el = audioRef.current;
    if (el) {
      if (remoteAudioPaused) {
        el.pause();
      } else {
        el.play().catch(error => {
          console.log(error);
          setRemoteAudioPaused(true);
        });
      }
    }
  };

  const largeViewParticipant = role === USER_ROLES.Trainee ? coachParticipant : traineeParticipant;
  const smallViewParticipant = role === USER_ROLES.Spectator ? coachParticipant : room.localParticipant;
  const largeViewPlaceholder = renderFirstLetters(role === USER_ROLES.Trainee ? session.coach : session.trainee);
  const smallViewPlaceholder = renderFirstLetters(role === USER_ROLES.Trainee ? session.trainee : session.coach);

  if (!playAllowed && !isIOS) {
    return (
      <div className='flex justify-center'>
        <div>
          <div>{t('session.clickHereToStart')}</div>
          <PrimaryButton
            title={t('session.clickHereToStart')}
            type={'button'}
            onClick={() => {
              setPlayAllowed(true);
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className='rounded-lg room'>
      <div className='local-participant absolute mt-0 z-10 w-55 h-37.5'>
        {smallViewParticipant ? (
          <ParticipantComponent
            key={smallViewParticipant.sid}
            participant={smallViewParticipant}
            onRemoteAudioMute={
              role === USER_ROLES.Spectator ? onRemoteAudioMute : (isMuted: boolean, id?: string) => {}
            }
            onRemoteVideoMute={
              role === USER_ROLES.Spectator ? onRemoteVideoMute : (isMuted: boolean, id?: string) => {}
            }
            remoteAudioPaused={USER_ROLES.Spectator ? remoteAudioPaused : false}
            audioPlayPause={audioPlayPause}
            isRemote={role === USER_ROLES.Spectator}
            placeholder={smallViewPlaceholder}
            speaker={speaker}
            className='text-primary-dark rounded-tl-lg rounded-br-lg h-37.5 w-50'
          />
        ) : (
          <div className='flex flex-col items-center justify-center bg-primary rounded-lg h-37.5 min-w-55'>
            <div className='text-center text-white w-1/2 waiting-user_content'>
              <div className='mb-6 font-bold text-xl'>Waiting for your partner...</div>
            </div>
          </div>
        )}
      </div>
      <div className='remote-participant relative w-full'>
        {largeViewParticipant ? (
          <ParticipantComponent
            key={largeViewParticipant.sid}
            participant={largeViewParticipant}
            onRemoteAudioMute={onRemoteAudioMute}
            onRemoteVideoMute={onRemoteVideoMute}
            remoteAudioPaused={remoteAudioPaused}
            audioPlayPause={audioPlayPause}
            isRemote={true}
            placeholder={largeViewPlaceholder}
            speaker={speaker}
            className='min-w-full min-h-full w-auto h-auto'
            classNameNoVideo='h-122.5 w-40 min-w-112.5'
            classNameMuted='h-122.5 w-40 min-w-112.5'
          />
        ) : (
          <div className='flex flex-col items-center justify-center bg-primary rounded-lg h-122.5 min-w-112.5'>
            <div className='text-center text-white w-1/2 waiting-user_content'>
              <div className='mb-6 font-bold text-xl'>Waiting for your partner...</div>
              <div className='text-base'>
                It looks like your partner has either not yet joined the session or is experiencing network issues,
                please wait for her or him to join.
              </div>
            </div>
          </div>
        )}
        <VideoControl
          changeCameraOrMic={changeCameraOrMic}
          changeSpeaker={changeSpeaker}
          onLocalVideoMute={onLocalVideoMute}
          onLocalAudioMute={onLocalAudioMute}
          onRemoteAudioPlayPause={onRemoteAudioPlayPause}
          localAudioMute={localAudioMute}
          localVideoMute={localVideoMute}
          remoteAudioPaused={remoteAudioPaused}
          isRecordingActive={sessionRecordingStatus.isRecordingActive}
          allowRecording={sessionRecordingStatus.allowRecording}
          isUserCoach={sessionRecordingStatus.isUserCoach}
          isSpectator={role === USER_ROLES.Spectator}
          sessionRecordedTimeInSeconds={sessionRecordingStatus.recordedTime}
          onRecordingClick={onRecordingClick}
          spectatorList={spectatorParticipants}
        />
      </div>
    </div>
  );
};

export default RoomView;
