import React from 'react';

interface IEmailInputProps {
  value: string;
  placeholder?: string;
  handleChange: any;
  error: string;
  required?: boolean;
  disabled?: boolean;
}

const EmailInput = ({ value, placeholder, handleChange, error, required, disabled }: IEmailInputProps) => {
  return (
    <div className='relative focus-within:text-gray-dark'>
      <i className='bi bi-envelope-fill absolute top-0 left-0 pointer-events-none py-2 px-3 text-gray-lighter'></i>
      <input
        className={`custom-input transition py-1.5 px-3 text-base block pl-9 rounded-lg bg-white bg-clip-padding text-gray-dark placeholder-gray-light hover:border-primary-hover focus:text-black focus:border-primary-hover focus:outline-none border border-solid border-black-divider appearance-none w-full font-normal ${
          error && 'border-negative hover:border-negative focus:border-negative input-error'
        }`}
        type='email'
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        required={required}
        disabled={disabled}
      />
      {error && <span className='uppercase text-negative pt-1'>{error}</span>}
    </div>
  );
};

export default EmailInput;
