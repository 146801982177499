import React from 'react';
import { useTranslation } from 'react-i18next';

import { CaseResponseDto } from '../../service/dto/case.dto';

import InputLabel from '../../components/InputLabel';

interface ICaseInfoProps {
  data: CaseResponseDto;
}
const CaseInfo = ({ data }: ICaseInfoProps) => {
  const { t } = useTranslation();
  return (
    <div className='mt-2 mb-4'>
      <InputLabel label={t('inputLabels.description')} otherMB='mb-0' />
      <p className='text-black text-lg mb-6'>{data.description}</p>
      <InputLabel label={t('inputLabels.length')} otherMB='mb-0' />
      <p className='text-black text-lg mb-6'>
        {t('inputLabels.interview')} {data.interview_duration} {t('table.min')}. {t('inputLabels.feedback')}{' '}
        {data.feedback_duration} {t('table.min')}.
      </p>
      <InputLabel label={t('inputLabels.instructions')} otherMB='mb-0' />
      <p className='text-black text-lg mb-6'>{data.instructions}</p>
      {data.scoring_items && data.scoring_items.length !=0 && (
        <>
          <InputLabel label={t('inputLabels.scoringItems')} otherMB='mb-0' />
          <ul className='ml-8 list-disc'>
            {data.scoring_items.map(i => (
              <li className='text-black text-lg' key={i.name}>
                {i.name}
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default CaseInfo;
