import { ApiBaseService } from './api.service';

import { VideoRoomTokenResponseDto, VideoRoomTokenRequestDto } from './dto/video-room.dto';

export class VideoRoomService {
  public static basePath() {
    if (!process.env.API_URL_VIDEO_ROOM_BASE) {
      throw new Error('API_URL_VIDEO_ROOM_BASE does not exist');
    }
    return process.env.API_URL_VIDEO_ROOM_BASE;
  }

  public static getTokenPromise(payload: VideoRoomTokenRequestDto): Promise<VideoRoomTokenResponseDto> {
    return new Promise((resolve, reject) => {
      VideoRoomService.getToken(payload, resolve, reject);
    });
  }

  public static getToken(
    payload: VideoRoomTokenRequestDto,
    handleSuccess: (_data: VideoRoomTokenResponseDto) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = VideoRoomService.basePath() + 'token';

    ApiBaseService.Post(
      uriPath,
      payload,
      (_code: number, _data: VideoRoomTokenResponseDto) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }
}
