import React, { useEffect, useState } from 'react';

import { ScoringService } from '../../service/scoring.service';
import { UserService } from '../../service/user.service';

import EntitySelectContent from './EntitySelectContent';

export interface IEntitySelectItem {
  draggableId: string;
  value?: string | any;
  label?: string;
}

interface IEntitySelectProps {
  entity: string;
  values: Array<string | any>;
  handleChange: Function;
  enableDragNDrop?: boolean;
  error?: string;
  categoryId?: string;
  compareValuesBy?: Function;
  valuePropToShow?: Function;
  createItem?: Function;
  showPositionButtons?: boolean;
}

interface IServiceConfiguration {
  service: { method: Function; query: { search: string; role?: string; pageSize?: number } };
  label: string;
  buttonLabel: string;
  notFoundLabel: string;
  placeholder: string;
  hasChangePriority: boolean;
  listModify: Function;
}

interface IServiceConfigurationList {
  [key: string]: IServiceConfiguration;
}

const serviceByEntity: IServiceConfigurationList = {
  USER: {
    service: {
      method: UserService.list,
      query: { search: '', role: 'role:sp', pageSize: 100 },
    },
    label: '',
    buttonLabel: 'add one more coach',
    placeholder: 'Please select',
    notFoundLabel: '-COACH NOT FOUND-',
    hasChangePriority: false,
    listModify: (list: Array<any>) => {
      return list.map(item => {
        return { value: item.id, label: item.firstName + ' ' + item.lastName };
      });
    },
  },
  SCORING: {
    service: { method: ScoringService.list, query: { search: '' } },
    label: '',
    buttonLabel: 'add one more scoring item',
    placeholder: 'Please select',
    notFoundLabel: '-SCORING ITEM NOT FOUND-',
    hasChangePriority: true,
    listModify: (list: Array<any>) => {
      return list.map(item => {
        return { value: item.id, label: item.name };
      });
    },
  },
};

export const EntitySelect = ({
  entity,
  values,
  handleChange,
  enableDragNDrop,
  error,
  categoryId,
  compareValuesBy,
  valuePropToShow,
  createItem,
  showPositionButtons = false,
}: IEntitySelectProps) => {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState<any[]>([]);

  const fetchList = (service: any) => {
    service.method(
      service.query,
      (data: Array<any>) => {
        setLoading(false);
        if (entity === 'SCORING' && categoryId) {
          data = data.filter(item => item.categoryId === categoryId);
        }
        setList(data);
      },
      () => {
        setLoading(false);
        setList([]);
      },
    );
  };

  const entityItem = serviceByEntity[entity];

  useEffect(() => {
    fetchList(entityItem.service);
  }, [loading, categoryId]);

  return (
    <>
      {loading == false && (
        <EntitySelectContent
          label={entityItem.label}
          buttonLabel={entityItem.buttonLabel}
          placeholder={entityItem.placeholder}
          hasChangePriority={entityItem.hasChangePriority}
          values={values}
          list={entityItem.listModify(list)}
          enableDragNDrop={enableDragNDrop}
          handleChange={handleChange}
          compareValuesBy={compareValuesBy || ((item: any) => item)}
          valuePropToShow={valuePropToShow || ((item: any) => item)}
          createItem={createItem || ((value: string) => value)}
          showPositionButtons={showPositionButtons}
        />
      )}
      {error && <span className='text-uppercase text-negative uppercase error-text pt-1'>{error}</span>}
    </>
  );
};

export default EntitySelect;
