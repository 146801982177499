import React, { useState, useEffect } from 'react';

export type ICustomSelectedItem = {
  value: any;
  label: string;
};

type ICustomSelectProps = {
  placeholderAll: string;
  placeholderSelected: string;
  values?: any[] | undefined;
  list: Array<ICustomSelectedItem>;
  handleChange: Function;
};

const CustomMultiSelect: React.FunctionComponent<ICustomSelectProps> = ({
  placeholderAll,
  placeholderSelected,
  values,
  list,
  handleChange,
}) => {
  const [isOpen, setOpen] = useState(false);

  const [selectedValues, setSelectedValues] = useState<string[]>(values && values.length > 0 ? values : []);

  useEffect(() => {
    setSelectedValues(values || []);
  }, [values]);

  const toggleDropdown = () => setOpen(!isOpen);

  const handleItemClick = (item: ICustomSelectedItem) => {
    let newArray: string[] = [...selectedValues];

    var index = newArray.indexOf(item.value);
    if (index !== -1) {
      newArray.splice(index, 1);
    } else {
      newArray.push(item.value);
    }

    setSelectedValues(newArray);
    setOpen(false);
    handleChange(newArray);
  };

  const label =
    selectedValues.length == list.length
      ? placeholderAll + ' (' + list.length + ')'
      : selectedValues.length + '/' + list.length + ' ' + placeholderSelected;

  return (
    <div
      className={`dropdown cursor-pointer relative h-9 w-full hover:border-primary-hover rounded-lg text-gray-dark bg-white border border-solid border-black-divider ${
        isOpen && list.length > 0 && 'rounded-t-lg rounded-b-none border-primary-hover'
      }`}
    >
      <div className='flex justify-between cursor-pointer p-1.5' onClick={toggleDropdown}>
        {label}
        {isOpen && list.length > 0 ? <i className='bi bi-chevron-up'></i> : <i className='bi bi-chevron-down'></i>}
      </div>
      {isOpen && list.length > 0 && (
        <div className='w-inherit max-h-44 scrollbar flex flex-col absolute overflow-auto border border-solid border-primary-hover bg-white z-10 top-9 py-1 px-0 rounded-b-lg shadow-dropdown'>
          {list.map((item: ICustomSelectedItem) => {
            const ifActive = selectedValues?.includes(item.value);

            return (
              <div onClick={e => handleItemClick(item)} key={item.value.getValue ? item.value.getValue() : item.value}>
                <label className='dropdownItem checkboxInputs__item text-black block relative cursor-pointer select-none py-2 pr-4 pl-11 mb-5 font-bold'>
                  {item.label}
                  <input
                    checked={ifActive}
                    type='checkbox'
                    className='cursor-pointer absolute w-0 h-0 opacity-0'
                    readOnly
                    multiple
                  />
                  <span className='checkbox-checkmark absolute top-3 left-3 bg-transparent rounded w-5 h-5 border border-solid border-primary'></span>
                </label>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CustomMultiSelect;
