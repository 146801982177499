import React from 'react';
import { useTranslation } from 'react-i18next';
import Tag, { TagType } from '../../components/Tag';
import { CASE_STATE } from './../../common/enums';

interface ITableProps {
  list: Array<any>;
  onDelete: Function;
  onEdit: Function;
}

const CaseTable = ({ list, onDelete, onEdit }: ITableProps) => {
  const { t } = useTranslation();
  return (
    <table className='dataTable table-fixed w-full bg-white px-4'>
      <thead>
        <tr className='font-bold bg-ghostwhite border border-solid border-gray-lighter'>
          <td className='py-4 w-2/12'>{t('table.status')}</td>
          <td className='text-gray-dark font-bold py-4 w-4/12'>{t('table.caseName')}</td>
          <td className='text-gray-dark font-bold py-4 w-2/12'>{t('table.category')}</td>
          <td className='text-gray-dark font-bold py-4 w-2/12'>{t('table.duration')}</td>
          <td className='text-gray-light py-4 w-2/12 xl:w-1/5 2xl:2/12'>{t('table.actions')}</td>
        </tr>
      </thead>
      <tbody>
        {list.map((i, j) => {
          return (
            <tr className='border border-solid border-black-divider' key={'item_' + j}>
              <td className='py-4'>
                {i.status == CASE_STATE.Draft && <Tag label='Draft' type={TagType.Yellow} />}
                {i.status == CASE_STATE.Published && <Tag label='Published' type={TagType.Green} />}
              </td>
              <td className='text-gray-dark py-4'>{i.name}</td>
              <td className='text-gray-dark py-4'>{i.categoryName}</td>
              <td className='text-gray-dark py-4'>
                {i.interview_duration + i.feedback_duration} {t('table.min')}
                <div className='text-gray text-sm'>
                  {i.interview_duration}/{i.feedback_duration}
                </div>
              </td>
              <td className='py-4 flex flex-wrap'>
                <>
                  <span className='text-primary cursor-pointer pr-2' onClick={() => onEdit(j)}>
                    {t('buttons.edit')}
                  </span>
                  {/* <span className='text-negative-darker cursor-pointer pr-2' onClick={() => onDelete(j)}>
                    {t('buttons.delete')}
                  </span> */}
                </>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default CaseTable;
