import { AppDispatchType, RootState } from '../../../redux/store';
import { CertificationResponseDto, CertificationStatus } from '../../../service/dto/certification.dto';
import { CertificationService } from '../../../service/certification.service';
import { actionCertificationDetailsUpdate } from '../actions';
import { UserSummaryResponseDto } from '../../../service/dto/user-summary.response.dto';
import { CaseService } from '../../../service/case.service';
import { selectorCertificationDetails } from '../selectors';

export const thunkCertificationInfoLoad = (certificationId: string): any => {
  return (dispatch: AppDispatchType): Promise<void> => {
    const serviceCallbackToPromise = () =>
      new Promise<CertificationResponseDto>((resolve, reject) =>
        CertificationService.getById(certificationId, resolve, reject),
      );
    dispatch(
      actionCertificationDetailsUpdate({
        certification: {
          type: 'LOADING',
          startTime: new Date(),
        },
        certifiedCoaches: {
          type: 'EMPTY',
        },
      }),
    );
    return serviceCallbackToPromise()
      .then(response => {
        dispatch(
          actionCertificationDetailsUpdate({
            certification: {
              type: 'SUCCESS',
              result: {
                ...response,
                caseTemplate: {
                  ...response.caseTemplate,
                  scoring_items_positions: new Map(Object.entries(response.caseTemplate.scoring_items_positions)),
                },
              },
            },
          }),
        );
        dispatch(thunkCertificationGetCertifiedCoaches());
      })
      .catch(err => {
        console.error(err);
        dispatch(
          actionCertificationDetailsUpdate({
            certification: {
              type: 'ERROR',
              error: err,
            },
          }),
        );
      });
  };
};

export const thunkCertificationGetCertifiedCoaches = (): any => {
  return async (dispatch: AppDispatchType, getState: () => RootState): Promise<void> => {
    const { certification } = getState().certificationDetailsState.details;
    if (certification.type != 'SUCCESS') {
      return;
    }

    const callbackToPromise = () =>
      new Promise<UserSummaryResponseDto[]>((resolve, reject) =>
        CaseService.certifiedCoachesForCase(certification.result.caseTemplate.id, resolve, reject),
      );
    dispatch(
      actionCertificationDetailsUpdate({
        certifiedCoaches: {
          type: 'LOADING',
          startTime: new Date(),
        },
      }),
    );
    return callbackToPromise()
      .then(response => {
        dispatch(
          actionCertificationDetailsUpdate({
            certifiedCoaches: {
              type: 'SUCCESS',
              result: response,
            },
          }),
        );
      })
      .catch(err => {
        console.error(err);
        dispatch(
          actionCertificationDetailsUpdate({
            certifiedCoaches: {
              type: 'ERROR',
              error: err,
            },
          }),
        );
      });
  };
};

export const thunkCertificationAcceptRequest = (): any => {
  return async (dispatch: AppDispatchType, getState: () => RootState): Promise<void> => {
    const { certification } = getState().certificationDetailsState.details;
    if (certification.type != 'SUCCESS') {
      return;
    }

    const serviceCallbackToPromise = () =>
      new Promise<CertificationResponseDto>((resolve, reject) =>
        CertificationService.accept(certification.result.id, resolve, reject),
      );
    dispatch(
      actionCertificationDetailsUpdate({
        certification: {
          type: 'LOADING',
          startTime: new Date(),
        },
      }),
    );
    return serviceCallbackToPromise()
      .then(response => {
        dispatch(
          actionCertificationDetailsUpdate({
            certification: {
              type: 'SUCCESS',
              result: {
                ...response,
                caseTemplate: {
                  ...response.caseTemplate,
                  scoring_items_positions: new Map(Object.entries(response.caseTemplate.scoring_items_positions)),
                },
              },
            },
          }),
        );
      })
      .catch(err => {
        console.error(err);
        dispatch(
          actionCertificationDetailsUpdate({
            certification: {
              type: 'ERROR',
              error: err,
            },
          }),
        );
      });
  };
};

export const thunkCertificationApproveRequest = (): any => {
  return async (dispatch: AppDispatchType, getState: () => RootState): Promise<void> => {
    const { certification } = selectorCertificationDetails(getState());
    if (certification.type != 'SUCCESS') {
      return;
    }

    const callbackToPromise = () =>
      new Promise<unknown>((resolve, reject) => CertificationService.certify(certification.result.id, resolve, reject));
    return callbackToPromise()
      .then(() => {
        dispatch(
          actionCertificationDetailsUpdate({
            certification: {
              type: 'SUCCESS',
              result: {
                ...certification.result,
                isCertified: true,
                status: CertificationStatus.Certified,
              },
            },
          }),
        );
      })
      .catch(console.error);
  };
};

export const thunkCertificationDeleteRequest = (): any => {
  return async (dispatch: AppDispatchType, getState: () => RootState): Promise<void> => {
    const { certification } = getState().certificationDetailsState.details;
    if (certification.type != 'SUCCESS') {
      return;
    }

    const callbackToPromise = () =>
      new Promise<void>((resolve, reject) => CertificationService.delete(certification.result.id, resolve, reject));
    return callbackToPromise()
      .then(() => {
        dispatch(
          actionCertificationDetailsUpdate({
            certification: {
              type: 'DELETED',
            },
            certifiedCoaches: {
              type: 'EMPTY',
            },
          }),
        );
      })
      .catch(err => {
        dispatch(
          actionCertificationDetailsUpdate({
            certification: {
              type: 'ERROR',
              error: err,
            },
          }),
        );
      });
  };
};
