import { AppDispatchType, RootState } from '../../../redux/store';
import { CaseTemplateSummaryDto } from '../../../service/dto/case.dto';
import { CaseService } from '../../../service/case.service';
import { actionCertificationListUpdate } from '../actions';
import { CertificationResponseDto } from '../../../service/dto/certification.dto';
import { CertificationService } from '../../../service/certification.service';

export const thunkCertificationFetchPublishedCases = (userId: string): any => {
  return async (dispatch: AppDispatchType, getState: () => RootState): Promise<void> => {
    const callbackToPromise = () =>
      new Promise<CaseTemplateSummaryDto[]>((resolve, reject) => CaseService.listPublished(resolve, reject));
    const { filter } = getState().certificationDetailsState.list;

    if (!userId) {
      return;
    }

    dispatch(
      actionCertificationListUpdate({
        filter: {
          ...filter,
          casesToFilter: [],
        },
        notCertifiedCases: [],
        certifications: {
          type: 'EMPTY',
        },
        availableCases: {
          type: 'LOADING',
          startTime: new Date(),
        },
      }),
    );
    return callbackToPromise()
      .then(response => {
        const notCertifiedCases = response.filter(x => !x.certified_coaches.includes(userId));
        dispatch(
          actionCertificationListUpdate({
            availableCases: {
              type: 'SUCCESS',
              result: response,
            },
            notCertifiedCases,
            selectedCaseForCertification: notCertifiedCases.length
              ? {
                  value: notCertifiedCases[0].id,
                  label: notCertifiedCases[0].name,
                }
              : null,
            filter,
          }),
        );
      })
      .catch(err => {
        dispatch(
          actionCertificationListUpdate({
            availableCases: {
              type: 'ERROR',
              error: err,
            },
          }),
        );
      });
  };
};

export const thunkCertificationListGetCreatedCertifications = (): any => {
  return async (dispatch: AppDispatchType, getState: () => RootState): Promise<unknown> => {
    const { filter } = getState().certificationDetailsState.list;
    const callbackToPromise = () =>
      new Promise<CertificationResponseDto[]>((resolve, reject) =>
        CertificationService.list(
          {
            cases: filter.casesToFilter,
            onlyOwn: false,
            showCertified: filter.showCertified,
          },
          resolve,
          reject,
        ),
      );
    dispatch(
      actionCertificationListUpdate({
        certifications: {
          type: 'LOADING',
          startTime: new Date(),
        },
      }),
    );
    return callbackToPromise()
      .then(response => {
        dispatch(
          actionCertificationListUpdate({
            certifications: {
              type: 'SUCCESS',
              result: response,
            },
          }),
        );
      })
      .catch(err => {
        console.error(err);
        dispatch(
          actionCertificationListUpdate({
            certifications: {
              type: 'ERROR',
              error: err,
            },
          }),
        );
      });
  };
};

export const thunkCertificationCreate = (): any => {
  return async (dispatch: AppDispatchType, getState: () => RootState): Promise<void> => {
    const id = getState().certificationDetailsState.list.selectedCaseForCertification?.value;
    if (!id) {
      return;
    }

    const callbackToPromise = () =>
      new Promise<CertificationResponseDto>((resolve, reject) =>
        CertificationService.create(
          {
            caseTemplate: id,
          },
          resolve,
          reject,
        ),
      );
    dispatch(
      actionCertificationListUpdate({
        created: {
          type: 'LOADING',
          startTime: new Date(),
        },
      }),
    );
    return callbackToPromise()
      .then(response => {
        const notCertified = getState().certificationDetailsState.list.notCertifiedCases.filter(x => x.id != id);
        dispatch(
          actionCertificationListUpdate({
            created: {
              type: 'SUCCESS',
              result: response,
            },
            notCertifiedCases: notCertified,
            selectedCaseForCertification: notCertified.length
              ? {
                  value: notCertified[0].id,
                  label: notCertified[0].id,
                }
              : null,
          }),
        );
      })
      .catch(err => {
        dispatch(
          actionCertificationListUpdate({
            created: {
              type: 'ERROR',
              error: err,
            },
          }),
        );
      });
  };
};
