import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InsightsInstitutionDto } from '../../service/dto/insights.dto';
import { InsightsService } from '../../service/insightsService';
import { IRouteComponent } from '../../routes';

import InsightsFilterFromUntil from './InsightsFilterFromUntil';
import DefaultHeader from '../../components/DefaultHeader';
import { setInsightsUsageFilterFromUntil } from '../../redux/actions';
import { addedDay } from '../../utils/funcs';
import { useAppDispatch, useAppSelector } from '../../redux/store';

const Usage = ({ currentInstitution }: IRouteComponent) => {
  const { t } = useTranslation();
  const institutionId = currentInstitution.id;
  const [usageNumber, setUsageNumber] = useState<InsightsInstitutionDto>();
  const { from, until }: { from: Date; until: Date } = useAppSelector((state: any) => state.insightsUsageFilterReducer);
  const dispatch = useAppDispatch();

  useEffect(() => {
    serviceGetUsageList();
  }, [from, until]);

  useEffect(() => {}, [usageNumber]);

  // Service fetch
  const serviceGetUsageList = () => {
    InsightsService.getInstitution(
      {
        from: from,
        until: addedDay(until),
        institutionId: institutionId,
      },
      (data: InsightsInstitutionDto) => {
        setUsageNumber(data);
      },
      () => {},
    );
  };

  let utilization;
  if (usageNumber) {
    if (usageNumber.completedSessionsNumber != 0 || usageNumber.totalSessionsNumber != 0) {
      utilization = Math.round((usageNumber.completedSessionsNumber / usageNumber.totalSessionsNumber) * 100);
    } else {
      utilization = 0;
    }
  }
  return (
    <div className='rounded-lg bg-white my-6 shadow pb-2 mx-4 2xl:mx-6'>
      <DefaultHeader title={t('sidebar.usage')} />
      <InsightsFilterFromUntil
        from={from}
        until={until}
        styleFrom={'w-96'}
        onChange={({ from, until }) => {
          dispatch(setInsightsUsageFilterFromUntil({ from, until }));
        }}
      />
      {usageNumber && (
        <div className='bg-ghostwhite m-5 py-2 mt-6'>
          <div className='m-5 flex justify-between border-b-2 text-gray-dark'>
            <i className='bi bi-person-lines-fill text-2xl py-3 pl-3' />
            <p className='font-bold py-4 w-1/5 flex-auto pl-5'>{t('insights.usage.usersInInstitution')}</p>
            <p className='font-bold py-4 w-1/5 '>{usageNumber.totalUsersNumber}</p>
          </div>
          <div className='m-5 flex justify-between border-b-2 text-gray-dark'>
            <i className='bi bi-check-lg text-2xl py-3 pl-3' />
            <p className='font-bold py-4 w-1/5 flex-auto pl-5'>{t('insights.usage.completedCoachingSessions')}</p>
            <p className='font-bold py-4 w-1/5 '>{usageNumber.completedSessionsNumber}</p>
          </div>
          <div className='m-5 flex justify-between border-b-2 text-gray-dark'>
            <i className='bi bi-hourglass text-2xl py-3 pl-3' />
            <p className='font-bold py-4 w-1/5 flex-auto pl-5'>{t('insights.usage.createdTimeSlots')}</p>
            <p className='font-bold py-4 w-1/5 '>{usageNumber.totalSessionsNumber}</p>
          </div>
          <div className='m-5 flex justify-between text-gray-dark'>
            <i className='bi bi-archive-fill text-2xl py-3 pl-3' />
            <p className='font-bold py-4 w-1/5 flex-auto pl-5'>{t('insights.usage.utilization')}</p>
            <p className='font-bold py-4 w-1/5 '>{utilization}%</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Usage;
