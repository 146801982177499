import { createAction } from '@reduxjs/toolkit';
import { CertificationListState } from './list/state';
import { CertificationDetailsState } from './detail/state';
import { CertificationRoomState } from './room/state';

export enum CertificationActions {
  updateDetails = 'certification:details:update',
  updateList = 'certification:list:update',
  reset = 'certification:reset',
  roomUpdate = 'certification:room:update',
  roomReset = 'certification:room:reset',
}

export const actionCertificationReset = createAction(CertificationActions.reset);
export const actionCertificationDetailsUpdate = createAction<Partial<CertificationDetailsState>>(
  CertificationActions.updateDetails,
);
export const actionCertificationListUpdate = createAction<Partial<CertificationListState>>(
  CertificationActions.updateList,
);
export const actionCertificationRoomUpdate = createAction<Partial<CertificationRoomState>>(
  CertificationActions.roomUpdate,
);
export const actionCertificationRoomReset = createAction(CertificationActions.roomReset);
