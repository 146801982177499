import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { IRouteComponent } from '../../routes';
import HistoryTable from './HistoryTable';
import CustomMultiSelect, { ICustomSelectedItem } from '../../components/CustomMultiSelect';
import { CategoryService } from '../../service/category.service';
import { CategoryListItemDto } from '../../service/dto/category.dto';
import { CaseService } from '../../service/case.service';
import { CaseTemplateSummaryDto } from '../../service/dto/case.dto';
import DefaultHeader from '../../components/DefaultHeader';
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {setHistoryCaseSelector, setHistoryCategorySelector} from "../../redux/actions";

const ScheduleHistoryList = ({ currentInstitution }: IRouteComponent) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [caseList, setCaseList] = useState<ICustomSelectedItem[]>([]);
  const {selectedCases}: {selectedCases: string[]} = useAppSelector((state:any) => state.historyCaseSelectorReducer);
  const {selectedCategories}: {selectedCategories: string[]} = useAppSelector((state:any) => state.historyCategorySelectorReducer);

  const [categoryNames, setCategoryNames] = useState<ICustomSelectedItem[]>([]);

  useEffect(() => {
    serviceGetCaseList();
    serviceCategoryFetch();
  }, []);

  const handleCategoryChange = (categoryIds: string[]) => {
    dispatch(setHistoryCategorySelector({ selectedCategories: categoryIds}));
  };
  const serviceCategoryFetch = () => {
    CategoryService.list(
      (data: CategoryListItemDto[]) => {
        setCategoryNames(
          data.map(category => {
            return { value: category.id, label: category.name };
          }),
        );
      },
      () => {},
    );
  };

  const handleCaseChange = (caseIds: string[]) => {
    dispatch(setHistoryCaseSelector({ selectedCases: caseIds}));
  };
  const serviceGetCaseList = () => {
    CaseService.listPublished(
      (data: Array<CaseTemplateSummaryDto>) => {
        setCaseList(
          data.map(ct => {
            return { label: ct.name, value: ct.id };
          }),
        );
      },
      () => {},
    );
  };

  const detailsSession = (id: string) => {
    navigate(`/session/result/${id}`);
  };

  return (
    <div className='rounded-lg bg-white my-6 shadow pb-2 mx-4 2xl:mx-6'>
      <DefaultHeader title={t('history.title')} />

      <div className='p-6'>
        <div className='flex mt-4'>
          <div className='w-4/12 mr-9'>
            <CustomMultiSelect
              placeholderAll='All Cases'
              placeholderSelected='Cases Selected'
              list={caseList}
              handleChange={handleCaseChange}
              values={selectedCases}
            />
          </div>
          <div className='w-4/12 mr-9'>
            <CustomMultiSelect
              placeholderAll='All Categories'
              placeholderSelected='Categories Selected'
              list={categoryNames}
              handleChange={handleCategoryChange}
              values={selectedCategories}
            />
          </div>
        </div>
      </div>

      <HistoryTable
        selectedCases={selectedCases}
        selectedCategories={selectedCategories}
        detailsSession={detailsSession}
      />
    </div>
  );
};

export default ScheduleHistoryList;
