import React from 'react';

interface ITextInputProps {
  inputType?: string;
  value: string | number;
  defaultValue?: number;
  placeholder?: string;
  handleChange?: any;
  error?: string;
  required?: boolean;
  disabled?: boolean;
  minValue?: string;
  maxValue?: string;
  readOnly?: boolean;
  startIcon?: string;
  endIcon?: string;
  onClick?: () => void;
}

const TextInput = ({
  startIcon,
  endIcon,
  inputType,
  value,
  defaultValue,
  placeholder,
  handleChange,
  error,
  required,
  disabled,
  minValue,
  maxValue,
  readOnly = false,
  onClick,
}: ITextInputProps) => {
  return (
    <>
      <div className='relative'>
        {startIcon && (
          <i className={`absolute left-3 top-1/2 transform -translate-y-1/2 ${startIcon}`} onClick={onClick} />
        )}
        <input
          className={`custom-input transition py-1.5 px-3 text-base rounded-lg block bg-white bg-clip-padding text-gray-dark placeholder-gray-light hover:border-primary-hover focus:text-black focus:border-primary-hover focus:outline-none border border-solid border-black-divider appearance-none w-full font-normal ${
            error && 'border-negative hover:border-negative focus:border-negative input-error'
          }`}
          type={inputType ? inputType : 'text'}
          placeholder={placeholder}
          value={value}
          defaultValue={defaultValue}
          onChange={handleChange}
          required={required}
          min={minValue}
          max={maxValue}
          disabled={disabled}
          readOnly={readOnly}
          style={startIcon ? { paddingLeft: '2.5rem' } : {}}
        />
        {endIcon && (
          <i className={`absolute right-3 top-1/2 transform -translate-y-1/2 ${endIcon}`} onClick={onClick} />
        )}
      </div>
      {error && <span className='uppercase text-negative pt-1'>{error}</span>}
    </>
  );
};

export default TextInput;
