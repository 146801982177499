import React from 'react';

interface ISecondaryButtonProps {
  type?: 'button' | 'submit' | 'reset';
  title: string;
  icon?: string;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
}

const SecondaryButton = ({ type, title, icon, onClick, disabled, className }: ISecondaryButtonProps) => {
  return (
    <button
      type={type ? type : 'button'}
      className={`inline-block border border-solid font-bold text-tiny text-center py-1.5 px-4 select-none rounded-lg bg-transparent ${className} ${
        disabled ? 'opacity-50 cursor-not-allowed' : ''
      }`}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && <i className={icon}></i>}
      <span className={`uppercase${icon ? ' pl-2' : ''}`}>{title}</span>
    </button>
  );
};

export default SecondaryButton;
