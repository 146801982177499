import { ApiBaseService, queryToUrl } from './api.service';
import { SearchQueryDto } from './dto/general';
import { CreateScoringRequestDto, ScoringResponseDto, UpdateScoringRequestDto } from './dto/scoring.dto';
export class ScoringService {
  static debounceTimer: any;

  public static basePath() {
    if (!process.env.API_URL_SCORING_BASE) {
      throw new Error('API_URL_SCORING_BASE does not exist');
    }
    return process.env.API_URL_SCORING_BASE;
  }

  public static create(
    payload: CreateScoringRequestDto,
    handleSuccess: (_data: ScoringResponseDto) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = ScoringService.basePath();
    ApiBaseService.Post(
      uriPath,
      payload,
      (_code: number, _data: ScoringResponseDto) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static update(
    id: string,
    payload: UpdateScoringRequestDto,
    handleSuccess: (_data: ScoringResponseDto) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = ScoringService.basePath() + id;

    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      ApiBaseService.Patch(
        uriPath,
        payload,
        (_code: number, _data: ScoringResponseDto) => {
          handleSuccess(_data);
        },
        (err: Error) => {
          handleError(err);
        },
      );
    }, 1000);
  }

  public static list(
    query: SearchQueryDto,
    handleSuccess: (_data: Array<ScoringResponseDto>) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = ScoringService.basePath() + queryToUrl(query);

    ApiBaseService.Get(
      uriPath,
      {},
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static grade(id: string, handleSuccess: (_data: number) => void, handleError: (err: Error) => void) {
    const uriPath = ScoringService.basePath() + id + '/grade';

    ApiBaseService.Get(
      uriPath,
      {},
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static delete(id: string, handleSuccess: () => void, handleError: (err: Error) => void) {
    const uriPath = ScoringService.basePath() + id;

    ApiBaseService.Delete(
      uriPath,
      (_code: number, _data: void) => {
        handleSuccess();
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }
}
