import React, {useEffect, useState} from "react";
import { useTranslation} from "react-i18next";
import {IRouteComponent} from "../../routes";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {setInsightsReportingFilterFromUntil, setReportingSearchSelector} from "../../redux/actions";
import {addedDay} from "../../utils/funcs";
import {InsightsService} from "../../service/insightsService";
import {schedulePageSize} from "../../common/constants";
import {SessionService} from "../../service/session.service";
import ReactPaginate from "react-paginate";

import {InsightsReportingResponseDto} from "../../service/dto/insights.dto";

import DefaultHeader from "../../components/DefaultHeader";
import InsightsFilterFromUntil from "./InsightsFilterFromUntil";
import Alert from "../../utils/alert";
import SearchField from "../../components/SearchField";



const Reporting = ({ currentInstitution }: IRouteComponent) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const institutionId = currentInstitution.id;
    const { from, until }: { from: Date; until: Date } = useAppSelector((state: any) => state.insightsReportingFilterReducer);
    const {search}: {search: string} = useAppSelector((state: any) => state.reportingSearchReducer);
    const [reportingList, setReportingList] = useState<InsightsReportingResponseDto[]>();
    const [totalPages, setTotalPages] = useState<number>(0);
    const [page, setPage] = useState<number>(0);

    useEffect(() => {
        serviceGetReportingListCount();
        serviceGetReportingList();
    }, [from, until, page, search]);

    const onPageChange = (event: { selected: number }) => {
        setPage(event.selected);
    };

    const onSearch = (value: string) => {
        dispatch(setReportingSearchSelector({ search: value}));
    };

    const serviceGetReportingListCount = () => {
        InsightsService.getEvaluationCoachCount(
            {
                search,
                from,
                until,
                page,
                pageSize: schedulePageSize,
            },
            (data: number) => {
                setTotalPages(Math.floor((data - 1) / schedulePageSize) + 1);
            },
            () => {
                setTotalPages(0);
                setPage(0);
            },
        );
    };

    const serviceGetReportingList = () => {
        InsightsService.getReporting(
            {
                search,
                from: from,
                until: addedDay(until),
                page,
                pageSize: schedulePageSize
            },
            (data: InsightsReportingResponseDto[]) => {
                setReportingList(data);
            },
            () => {}
        );
    };

    const download = (coachId: string) => {
        SessionService.exportReportingSessionLog(
            {
                from,
                until,
                coachId,
                institutionId
            },
            async response => {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(
                    new Blob([new Uint8Array(response, 0, response.byteLength)], { type: 'text/csv' }),
                );
                link.download = 'ReportingSessionLog.xlsx';
                link.click();
            },
            (err) =>{
                Alert.error("Invalid date range provided");
            },
        );
    };

    return (
        <div className='rounded-lg bg-white my-6 shadow pb-2 mx-4 2xl:mx-6'>
            <DefaultHeader
                title={t('sidebar.reporting')}
            />
            <InsightsFilterFromUntil from={from} until={until} styleFrom={'w-96'} onChange={({from, until}) => {
                dispatch(setInsightsReportingFilterFromUntil({from, until}));
            }}/>
            <div className='ml-7 my-0.5'>
                <SearchField onSearch={onSearch} width='w-96' value={search}/>
            </div>
            {reportingList && (
            <table className='dataTable table-auto w-full bg-white px-4 mt-4'>
                <thead>
                <tr  className='font-bold bg-ghostwhite border border-solid border-gray-lighter'>
                    <td className='text-gray-dark font-bold py-4'>{t('table.coach')}</td>
                    <td className='text-gray-dark font-bold py-4'>{t('table.email')}</td>
                    <td className='text-gray-dark font-bold py-4 text-center'>{t('insights.reporting.slotsCreated')}</td>
                    <td className='text-gray-dark font-bold py-4 text-center'>{t('insights.reporting.sessionsBooked')}</td>
                    <td className='text-gray-dark font-bold py-4 text-center'>{t('insights.reporting.sessionsStarted')}</td>
                    <td className='text-gray-dark font-bold py-4 text-center'>{t('insights.reporting.sessionsFinished')}</td>
                    <td className='text-gray-dark font-bold py-4'>{t('table.actions')}</td>
                </tr>
                </thead>
                <tbody>
                {reportingList.map((value, index) => (
                    <tr key={index} className='border border-solid border-black-divider'>
                        <td className='text-gray-dark font-bold py-4'>{value.coachFirstName + ' ' +value.coachLastName}</td>
                        <td className='text-gray-dark font-bold py-4'>{value.coachEmail}</td>
                        <td className='text-gray-dark font-bold py-4 text-center'>{value.slotsCreated}</td>
                        <td className='text-gray-dark font-bold py-4 text-center'>{value.sessionsBooked}</td>
                        <td className='text-gray-dark font-bold py-4 text-center'>{value.sessionsStarted}</td>
                        <td className='text-gray-dark font-bold py-4 text-center'>{value.sessionsFinished}</td>
                        <td>
                        <span
                          className='text-primary cursor-pointer pr-4 font-bold uppercase'
                          onClick={() => {
                              download(value.coachId);
                          }}
                        >
                        <i className='bi bi-plus-circle text-primary-hover pr-1' /> {t('buttons.exportXLS')}
                        </span>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            )}
            <ReactPaginate
                pageCount={totalPages}
                containerClassName={'pagination flex justify-center text-gray-dark'}
                previousLabel={<i className='bi bi-chevron-left' />}
                breakLabel={'...'}
                nextLabel={<i className='bi bi-chevron-right' />}
                onPageChange={onPageChange}
                pageRangeDisplayed={5}
                pageClassName={'page-item'}
                pageLinkClassName='page-link'
                previousClassName='page-item'
                previousLinkClassName='page-link'
                nextClassName='page-item'
                nextLinkClassName='page-link'
                activeClassName='active'
                breakClassName='page-item'
                breakLinkClassName='page-link'
                forcePage={page}
            />
        </div>
    );
};

export default Reporting;