import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IRouteComponent } from '../../../routes';
import CustomMultiSelect, { ICustomSelectedItem } from '../../../components/CustomMultiSelect';
import CertificationTable from './components/CertificationTable';
import AbstractModal from '../../../components/AbstractModal';
import Alert from '../../../utils/alert';
import CustomSelect from '../../../components/CustomSelect';
import SwitchButton from '../../../components/SwitchButton';
import DefaultHeader from '../../../components/DefaultHeader';
import {
  thunkCertificationCreate,
  thunkCertificationFetchPublishedCases,
  thunkCertificationListGetCreatedCertifications,
} from './thunks';
import { selectorCertificationList } from '../selectors';
import { actionCertificationListUpdate } from '../actions';
import { useAppDispatch, useAppSelector } from '../../../redux/store';

const CertificationList = ({ currentUser, currentInstitution }: IRouteComponent) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { availableCases, selectedCaseForCertification, filter, created, notCertifiedCases } =
    useAppSelector(selectorCertificationList);

  const [createCertificationModalOpen, setCreateCertificationModalOpen] = useState<boolean>(false);

  useEffect(() => {
    dispatch(thunkCertificationFetchPublishedCases(currentUser.id));
  }, []);

  useEffect(() => {
    const clearCreated = () => dispatch(actionCertificationListUpdate({ created: { type: 'EMPTY' } }));
    switch (created.type) {
      case 'SUCCESS':
        Alert.success(t('successMessages.certificationCreateSuccess'));
        dispatch(thunkCertificationListGetCreatedCertifications());
        clearCreated();
        break;
      case 'ERROR':
        Alert.error(t('errorMessages.certificationCreateError'));
        clearCreated();
        break;
      default:
        break;
    }
  }, [created]);

  const pushCertificationDetailView = (id: string) => {
    navigate(`/certification/detail/${id}`);
  };

  return (
    <div className='rounded-lg bg-white my-6 shadow pb-2 mx-4 2xl:mx-6'>
      <DefaultHeader
        title={t('certification.title')}
        primaryAction={{
          title: t('certification.primaryAction'),
          onClick: () => {
            setCreateCertificationModalOpen(true);
          },
        }}
      />

      <div className='p-6'>
        <div className='flex mt-4'>
          <div className='w-4/12 mr-9'>
            <CustomMultiSelect
              placeholderAll='All Cases'
              placeholderSelected='Cases Selected'
              list={
                availableCases.type == 'SUCCESS'
                  ? availableCases.result.map(x => ({
                      value: x.id,
                      label: x.name,
                    }))
                  : []
              }
              handleChange={(ids: string[]) =>
                dispatch(
                  actionCertificationListUpdate({
                    filter: {
                      ...filter,
                      casesToFilter: ids,
                    },
                  }),
                )
              }
              values={filter.casesToFilter}
            />
          </div>
          <div className='pt-1'>
            <SwitchButton
              option={{
                id: '',
                title: t('certification.showCertified'),
                status: filter.showCertified,
              }}
              handleOption={({ status }) => {
                dispatch(
                  actionCertificationListUpdate({
                    filter: {
                      ...filter,
                      showCertified: !status,
                    },
                  }),
                );
              }}
            />
          </div>
        </div>
      </div>

      <CertificationTable onCertificationDetail={pushCertificationDetailView} />

      {createCertificationModalOpen && (
        <AbstractModal
          position={{ width: 'w-1/3', left: 'left-1/3' }}
          label={t('createCertificationRequestModal.title')}
          leftBtn={{
            label: t('createCertificationRequestModal.cancel'),
            onClick: () => {
              setCreateCertificationModalOpen(false);
            },
          }}
          rightBtn={{
            label: t('createCertificationRequestModal.submit'),
            onClick() {
              setCreateCertificationModalOpen(false);
              dispatch(thunkCertificationCreate());
            },
            disabled: selectedCaseForCertification == null,
          }}
        >
          <div className='mr-9'>
            <div className='mb-2'>{t('createCertificationRequestModal.info')}</div>
            <CustomSelect
              placeholder=''
              list={notCertifiedCases.map(x => ({ label: x.name, value: x.id }))}
              value={selectedCaseForCertification?.value}
              handleChange={(item: ICustomSelectedItem) => {
                dispatch(
                  actionCertificationListUpdate({
                    selectedCaseForCertification: item,
                  }),
                );
              }}
            />
          </div>
        </AbstractModal>
      )}
    </div>
  );
};

export default CertificationList;
