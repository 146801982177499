import React from 'react';
import { Link } from 'react-router-dom';

import PrimaryButton from '../../components/PrimaryButton';

const Main: React.FC = () => {
  // Just mock for better navigation
  return (
    <div className='container text-center'>
      <div className='text-2xl font-bold my-12'>Social Communication Competence</div>
      <p className='my-12'>An application by WLWS GmbH.</p>
      <div className='text-base mb-12'>
        <Link className='text-primary underline mx-6' to='/signin' replace>
          <PrimaryButton title='sign in' type='submit' />
        </Link>
      </div>
    </div>
  );
};

export default Main;
