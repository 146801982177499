import React, { useEffect, useState } from 'react';
import { IRouteComponent } from '../../routes';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import DefaultHeader from '../../components/DefaultHeader';
import { setInsightsEvaluationFilterFromUntil } from '../../redux/actions';
import InsightsFilterFromUntil from './InsightsFilterFromUntil';
import { InsightsEvaluationTraineeDetailsResponseDto } from '../../service/dto/insights.dto';
import { InsightsService } from '../../service/insightsService';
import EvaluationTraineeTabs, { EvaluationTraineeTabsTabContent } from './EvaluationTraineeTabs';
import { useAppDispatch, useAppSelector } from '../../redux/store';

const EvaluationTraineeDetails = ({ currentInstitution }: IRouteComponent) => {
  const { id } = useParams<{ id: string }>();
  const institutionId = currentInstitution.id;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { from, until }: { from: Date; until: Date } = useAppSelector(
    (state: any) => state.insightsEvaluationFilterReducer,
  );
  const [traineeDetails, setTraineeDetails] = useState<InsightsEvaluationTraineeDetailsResponseDto | null>(null);

  useEffect(() => {
    serviceGetTraineeDetails();
  }, [from, until, institutionId]);

  useEffect(() => {
    serviceGetTraineeDetails();
  }, []);

  // Service fetch
  const serviceGetTraineeDetails = () => {
    InsightsService.getEvaluationTraineeDetails(
      {
        from: from,
        until: until,
        institutionId: institutionId,
        traineeId: id!,
      },
      (data: InsightsEvaluationTraineeDetailsResponseDto) => {
        setTraineeDetails(data);
      },
      () => {},
    );
  };

  const renderEvaluationTraineeTabs = () => {
    const filteredEvaluationDetails: EvaluationTraineeTabsTabContent[] = [
      {
        title: t('insights.evaluation.metrics'),
        averageScore: traineeDetails?.averageScore,
        averageEvaluationScore: traineeDetails?.averageEvaluationScore,
      },
      {
        title: t('table.sessions'),
        sessionTab: traineeDetails?.traineeSessionsTab,
      },
    ];
    return <EvaluationTraineeTabs tabs={filteredEvaluationDetails} />;
  };

  return (
    <div className='rounded-lg bg-white my-6 shadow pb-2 mx-4 2xl:mx-6'>
      {traineeDetails && (
        <DefaultHeader
          title={
            t('sidebar.evaluation') + ' of ' + traineeDetails.traineeFirstName + ' ' + traineeDetails.traineeLastName
          }
        />
      )}
      <InsightsFilterFromUntil
        from={from}
        until={until}
        onChange={({ from, until }) => {
          dispatch(setInsightsEvaluationFilterFromUntil({ from, until }));
        }}
      />
      {renderEvaluationTraineeTabs()}
    </div>
  );
};

export default EvaluationTraineeDetails;
