import React, {useEffect} from 'react';
import InputLabel from '../../components/InputLabel';
import { combineDateAndTime } from '../../utils';
import {useTranslation} from "react-i18next";

interface FromUntilProps {
  from?: Date;
  until?: Date;
  minFrom?: Date;
  maxUntil?: Date;
  onChange: (dates: { from?: Date; until?: Date }) => unknown;
  labelFrom?: string;
  labelUntil?: string;
  styleFrom?: string;
  styleUntil?: string;
}

const InsightsFilterFromUntil = ({ from, until, maxUntil, minFrom, onChange, labelUntil, labelFrom, styleUntil, styleFrom }: FromUntilProps) => {
  const { t } = useTranslation();

  const dateCreate = (rawDate: string): Date | undefined => {
    if (!rawDate) {
      return;
    }
    const startTime = new Date().getHours() + ':00';
    return combineDateAndTime(new Date(rawDate), startTime);
  };

  return (
    <div className='flex mx-2'>
      <div className={'mt-5 ml-5 mb-5'}>
        <div className={styleFrom ?? 'w-full'}>
          <InputLabel label={labelFrom ?? t('inputLabels.from')} otherMB='mb-1' />
          <div className='md-form relative custom-date'>
            <i className='bi bi-calendar absolute z-10 text-gray text-lg top-1.5 left-3.5' />
            <input
              type='date'
              className='custom-input transition text-base py-1.5 px-3 block rounded-lg bg-clip-padding w-full font-normal relative custom-date_input text-gray placeholder-gray-light pl-12 pr-2.5 shadow border border-solid border-black-divider focus:border-primary-hover focus:outline-none appearance-none min-h-38'
              onChange={e => onChange({ from: dateCreate(e.target.value), until })}
              max={until?.toISOString().slice(0, 10) ?? new Date().toISOString().slice(0, 10)}
              min={minFrom?.toISOString().slice(0, 10)}
              required
              value={from?.toISOString().slice(0, 10)}
            />
          </div>
        </div>
      </div>
      <div className='mt-5 ml-5 mb-5'>
        <div className={styleUntil ?? 'w-full'}>
          <InputLabel label={labelUntil ?? t('inputLabels.until')} otherMB='mb-1' />
          <div className='md-form relative custom-date'>
            <i className='bi bi-calendar absolute z-10 text-gray text-lg top-1.5 left-3.5' />
            <input
              type='date'
              className='custom-input transition text-base py-1.5 px-3 block rounded-lg bg-clip-padding w-full font-normal relative custom-date_input text-gray placeholder-gray-light pl-12 pr-2.5 shadow border border-solid border-black-divider focus:border-primary-hover focus:outline-none appearance-none min-h-38'
              onChange={e => onChange({ from: from, until: dateCreate(e.target.value) })}
              max={maxUntil?.toISOString().slice(0, 10)}
              min={from?.toISOString().slice(0, 10)}
              required
              value={until?.toISOString().slice(0, 10)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsightsFilterFromUntil;
