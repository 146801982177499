import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { thunkCertificationRoomJoin } from './thunks';
import { useNavigate, useParams } from 'react-router-dom';
import { selectorCertificationRoom } from './selectors';
import { RecordStatus, USER_ROLES } from '../../../common/enums';
import PrimaryButton from '../../../components/PrimaryButton';
import SecondaryButton from '../../../components/SecondaryButton';
import RecordedOnList from '../../../components/RecordedOnList';
import ScoringMarksList from '../../../components/ScoringMarksList';
import { IRouteComponent } from '../../../routes';
import { useTranslation } from 'react-i18next';
import Video, { LocalAudioTrack, LocalTrackPublication, LocalVideoTrack, Room } from 'twilio-video';
import { VideoRoomService } from '../../../service/video-room.service';
import { VideoRoomTokenResponseDto } from '../../../service/dto/video-room.dto';
import { actionSetDevices, setDevices } from '../../../redux/actions';
import Alert from '../../../utils/alert';
import { DeviceState } from '../../../redux/types';
import { ConnectOptions } from 'twilio-video/tsdef/types';
import { createConstrains, MediaDeviceDto } from '../../../service/dto/MediaDevice.dto';
import IndependentRoomView from '../../../components/stream/IndependentRoomView';
import { WsType } from '../../../common/interfaces';
import { WebSocketContext } from '../../../context/socket';
import { CertificationService } from '../../../service/certification.service';
import AbstractModal from '../../../components/AbstractModal';
import ModalContent from '../../../components/ModalContent';
import { actionCertificationRoomReset } from '../actions';
import TeachableMomentInput from '../../../components/TeachableMomentInput';
import TeachableMomentList from '../../../components/TeachableMomentList';
import { retryOperation } from '../../../utils/funcs';
import { SocketSessionRecordManipulated } from '../../schedule/socket-session-record-manipulated.interface';
import RecordPlayer, { IRecordPlayerManipulationInfo } from '../../../components/RecordPlayerModal/RecordPlayer';
import DraggableModal from '../../../components/RecordPlayerModal/DraggableModal';
import { SessionRecordDownloadResponseDto, SessionRecordResponseDto } from '../../../service/dto/session.dto';
import { DurationMS, scoreColors, scoreI18NKey } from '../../../utils/constants';
import { isMobile } from 'react-device-detect';
import { useAppDispatch, useAppSelector } from '../../../redux/store';

const CertificationRoomView = ({ currentUser }: IRouteComponent) => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const socket: WsType | null = useContext(WebSocketContext);

  const { certification, progress } = useAppSelector(selectorCertificationRoom);
  const deviceState = useAppSelector((state: any) => state.devicesConfig) as DeviceState;
  const videoRecordTitleConfig = useAppSelector((state: any) => state.videoTitleConfig);
  let sessionManipulation: SocketSessionRecordManipulated = useAppSelector(
    (state: any) => state.socketManipulationConfig,
  ).sessionManipulation;

  const [role, setRole] = useState<USER_ROLES | 'UNKNOWN'>('UNKNOWN');
  const [room, setRoom] = useState<Room | null>(null);
  const [isFeedbackButtonDisabled, setIsFeedbackButtonDisabled] = useState(false);
  const [isSwapButtonDisabled, setIsSwapButtonDisabled] = useState(false);
  const [isResetButtonDisabled, setIsResetButtonDisabled] = useState(false);
  const [isEndButtonDisabled, setIsEndButtonDisabled] = useState(false);
  const [scoreResult, setScoreResult] = useState({});
  const [devicesFetched, setDevicesFetched] = useState<boolean>(false);
  const [isFinishModalVisible, setIsFinishModalVisible] = useState(false);
  const [teachableMomentText, setTeachableMomentText] = useState<string>('');
  const [isReplayCoachingModalVisible, setIsReplayCoachingModalVisible] = useState<boolean>(false);
  const [isReplayFeedbackModalVisible, setIsReplayFeedbackModalVisible] = useState<boolean>(false);
  const [coachingRecord, setCoachingRecord] = useState<SessionRecordResponseDto>();
  const [feedbackRecord, setFeedbackRecord] = useState<SessionRecordResponseDto>();
  const [coachingRecordDownloadResponse, setCoachingRecordDownloadResponse] = useState<
    SessionRecordDownloadResponseDto | undefined
  >();
  const [feedbackRecordDownloadResponse, setFeedbackRecordDownloadResponse] = useState<
    SessionRecordDownloadResponseDto | undefined
  >();
  const [coachingRecordDownloadError, setCoachingRecordDownloadError] = useState<string | undefined>();
  const [feedbackRecordDownloadError, setFeedbackRecordDownloadError] = useState<string | undefined>();
  const [coachingRecordManipulation, setCoachingRecordManipulation] = useState<
    IRecordPlayerManipulationInfo | undefined
  >();
  const [feedbackRecordManipulation, setFeedbackRecordManipulation] = useState<
    IRecordPlayerManipulationInfo | undefined
  >();

  const clearRoom = (room: Room) => {
    room.localParticipant.tracks.forEach((track: LocalTrackPublication) => {
      track.unpublish();
      if (track instanceof LocalAudioTrack || track instanceof LocalVideoTrack) {
        track.detach();
        track.stop();
      }
    });
    room.removeAllListeners();
    room.disconnect();
  };

  const initiateSockets = () => {
    socket!.joinRoom(id!, currentUser.id);
    socket!.getRoomStatus(id!, currentUser.id);
  };

  const getDeviceList = (): Promise<unknown> => {
    setDevicesFetched(false);
    return navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(async (result: MediaStream) => {
        updateDeviceList(result);
      })
      .catch(e => {
        console.error(e);
        Alert.error(t('errorMessages.devicesPermissionDenied'));
      })
      .finally(() => {
        setDevicesFetched(true);
      });
  };

  const updateDeviceList = async (result?: MediaStream) => {
    const devices: Array<MediaDeviceInfo> = await navigator.mediaDevices.enumerateDevices();
    if (result) {
      result.getTracks().forEach(t => t.stop());
    }
    let newDevices: MediaDeviceDto[] = devices.map(device => {
      return {
        label: device.label.replace(/\([\d\w]{4}:[\d\w]{4}\)/, '').trim(),
        deviceId: device.deviceId,
        kind: device.kind,
        active: false,
        groupId: device.groupId,
        enabled: true,
        isMobile: false,
      };
    });
    if (isMobile) {
      newDevices = newDevices.filter(d => d.kind !== 'videoinput');
      newDevices.push({
        label: t('devices.frontCamera'),
        deviceId: '1',
        kind: 'videoinput' as MediaDeviceKind,
        active: true,
        groupId: '',
        enabled: true,
        isMobile: true,
        facingMode: 'user',
      });
      newDevices.push({
        label: t('devices.backCamera'),
        deviceId: '2',
        kind: 'videoinput' as MediaDeviceKind,
        active: false,
        groupId: '',
        enabled: true,
        isMobile: true,
        facingMode: 'environment',
      });
    }
    if (deviceState?.devices != null && deviceState.devices.length) {
      for (const device of newDevices) {
        const storedDevice = deviceState.devices.find(x => x.deviceId == device.deviceId);
        if (storedDevice) {
          device.active = storedDevice.active;
        }
      }
    }

    const tryFindAndActivate = <T extends MediaDeviceDto>(collection: T[], query: (entity: T) => boolean): void => {
      const found = collection.find(query);
      if (found) {
        found.active = true;
      }
    };

    if (!newDevices.find(x => x.kind == 'audiooutput' && x.active)) {
      tryFindAndActivate(newDevices, device => device.kind === 'audiooutput');
    }

    if (!newDevices.find(x => x.kind == 'audioinput' && x.active)) {
      tryFindAndActivate(newDevices, device => device.kind === 'audioinput');
    }

    if (!newDevices.find(x => x.kind == 'videoinput' && x.active)) {
      tryFindAndActivate(newDevices, device => device.kind === 'videoinput');
    }

    dispatch(actionSetDevices({ devices: newDevices }));
  };

  const onSwapRolesClick = () => {
    setIsSwapButtonDisabled(true);
    CertificationService.swapRoles(id!)
      .catch(console.error)
      .finally(() => {
        setIsSwapButtonDisabled(false);
      });
  };

  const onFeedbackClick = () => {
    setIsFeedbackButtonDisabled(true);
    CertificationService.toggleFeedback(id!)
      .catch(console.error)
      .finally(() => {
        setIsFeedbackButtonDisabled(false);
      });
  };

  const handleScoreStack = useRef<(() => Promise<unknown>)[]>([]);
  const handleScoreInProgress = useRef<Promise<unknown> | null>(null);

  const handleScoreClick = (scoreId: string, value: number) => {
    handleScoreStack.current = [
      ...handleScoreStack.current,
      () =>
        CertificationService.score(id!, {
          id: scoreId,
          value,
        }).catch(console.error),
    ];

    const popAndProcess = () => {
      if (handleScoreStack.current.length) {
        const process = handleScoreStack.current[0];
        handleScoreStack.current = handleScoreStack.current.slice(1);
        handleScoreInProgress.current = process().finally(() => {
          handleScoreInProgress.current = null;
          popAndProcess();
        });
      }
    };

    if (handleScoreInProgress.current == null) {
      popAndProcess();
    }
  };

  const noteTeachableMoment = () => {
    if (teachableMomentText.trim().length > 0) {
      CertificationService.note(id!, { text: teachableMomentText })
        .then(() => {
          handleTeachableInput('');
        })
        .catch(console.error);
    }
  };

  const handleTeachableInput = (value: string) => {
    setTeachableMomentText(value);
  };

  const onRestartClick = () => {
    if (isResetButtonDisabled) {
      return;
    }

    setIsResetButtonDisabled(true);
    CertificationService.restart(id!)
      .catch(console.error)
      .finally(() => {
        setIsResetButtonDisabled(false);
      });
  };

  const onEndClick = () => {
    setIsFinishModalVisible(true);
  };

  const onSessionFinish = () => {
    if (isEndButtonDisabled) {
      return;
    }

    setIsEndButtonDisabled(true);
    CertificationService.end(id!)
      .catch(console.log)
      .finally(() => {
        setIsEndButtonDisabled(false);
      });
  };

  useEffect(() => {
    if (!id) {
      navigate('/certification');
      return;
    }
    getDeviceList()
      .then(() => dispatch(thunkCertificationRoomJoin(id)))
      .then(() => {
        initiateSockets();
      });
    navigator.mediaDevices.ondevicechange = event => {
      updateDeviceList();
    };
    return () => {
      socket?.emitDisconnect();
      room != null && clearRoom(room);
    };
  }, []);

  useEffect(() => {
    if (coachingRecord?.status === RecordStatus.Available && coachingRecord.mediaUri) {
      setCoachingRecordDownloadError(undefined);
      setCoachingRecordDownloadResponse({ url: coachingRecord.mediaUri });
    }
  }, [coachingRecord]);

  useEffect(() => {
    if (feedbackRecord?.status === RecordStatus.Available && feedbackRecord.mediaUri) {
      setFeedbackRecordDownloadError(undefined);
      setFeedbackRecordDownloadResponse({ url: feedbackRecord.mediaUri });
    }
  }, [feedbackRecord]);

  useEffect(() => {
    if (progress.type == 'EMPTY') {
      setRole('UNKNOWN');
    } else {
      const { result } = progress;
      setRole(result.traineeId == currentUser.id ? USER_ROLES.Trainee : USER_ROLES.SP);
      setScoreResult(result.scoreResult);
      setFeedbackRecord(result.feedbackSessionRecord);
      setFeedbackRecordDownloadError(undefined);
      setCoachingRecord(result.coachingSessionRecord);
      setCoachingRecordDownloadError(undefined);
      if (!room) {
        VideoRoomService.getToken(
          {
            userId: currentUser.id,
            roomSid: result.roomSid,
          },
          async (data: VideoRoomTokenResponseDto) => {
            const { token } = data;
            if (!token) {
              return;
            }

            const options: ConnectOptions = { name: result.roomName };
            options.tracks = [];
            const filteredDevices = deviceState.devices.filter(
              device => device.kind !== 'audiooutput' && device.active,
            );
            for (let i = 0; i < filteredDevices.length; i++) {
              const device1: MediaDeviceDto = filteredDevices[i];
              const newTrack =
                device1.kind === 'videoinput'
                  ? await Video.createLocalVideoTrack(createConstrains(device1))
                  : await Video.createLocalAudioTrack({ deviceId: device1.deviceId });
              options.tracks!.push(newTrack);
            }
            room && clearRoom(room);
            Video.connect(token, options)
              .then(room => {
                setRoom(room);
                if (certification.type == 'SUCCESS') {
                  return retryOperation(
                    () =>
                      CertificationService.addParticipantIdToSession(certification.result.id, {
                        participantId: room.localParticipant.sid,
                      }),
                    100,
                    10,
                  );
                }
              })
              .catch(console.error);
          },
          err => {
            console.error(err);
          },
        );
      }
      if (result.isFinished) {
        dispatch(actionCertificationRoomReset());
        navigate({
          pathname: `/certification/detail/${id}`,
        });
      }
    }
  }, [progress]);

  useEffect(() => {
    if (sessionManipulation) {
      if (certification.type === 'SUCCESS' && sessionManipulation.sessionId === certification.result.id) {
        if (progress.type === 'SUCCESS') {
          if (progress.result.isSimulateFeedback) {
            setIsReplayCoachingModalVisible(sessionManipulation.isVisible);
            setCoachingRecordManipulation({
              isMuted: sessionManipulation.isMuted,
              isPlaying: sessionManipulation.isPlaying,
              timeInSeconds: sessionManipulation.timeInSeconds,
            });
          } else {
            setIsReplayFeedbackModalVisible(sessionManipulation.isVisible);
            setFeedbackRecordManipulation({
              isMuted: sessionManipulation.isMuted,
              isPlaying: sessionManipulation.isPlaying,
              timeInSeconds: sessionManipulation.timeInSeconds,
            });
          }
        }
      }
    }
  }, [sessionManipulation]);

  const _getVideoRecordModalTitle = (): ReactNode => {
    return videoRecordTitleConfig.index != null ? (
      <div className='boldTitle'>
        <span>{(videoRecordTitleConfig.index + 1).toString()}. </span>
        <span>{videoRecordTitleConfig.name}</span>
        <span> - </span>
        {t(scoreI18NKey.get(videoRecordTitleConfig.value)!) ? (
          <span style={{ color: scoreColors.get(videoRecordTitleConfig.value) }}>
            {t(scoreI18NKey.get(videoRecordTitleConfig.value)!)}
          </span>
        ) : (
          <span>{t('session.teachableMoment')}</span>
        )}
      </div>
    ) : (
      <div className='grayTitle'>{t('session.loading')}</div>
    );
  };

  console.log(role);
  if (progress.type == 'SUCCESS') {
    console.log(progress && progress.result.isSimulateFeedback);
  }

  switch (certification.type) {
    case 'EMPTY':
      return <div />;
    case 'LOADING':
      return <div />;
    case 'ERROR':
      return (
        <div>
          <div>Error</div>
          <div>{certification.error.toString()}</div>
        </div>
      );
    case 'SUCCESS':
      if (progress.type == 'EMPTY' || !devicesFetched) {
        return <div></div>;
      }

      const progressStatus = progress.result;
      return (
        <>
          <div className={'my-6 mx-4 2xl:mx-6'}>
            <div className='flex flex-row justify-between mb-4'>
              <div className='flex shrink-0'>
                <div>
                  <div className='font-bold text-lg text-black'>{certification.result.caseTemplate.name}</div>
                  {/*{state === COMPONENT_STATE.STREAM && (*/}
                  {/*  <div className='inline-block'>*/}
                  {/*    <div className='inline-block rounded-lg text-negative ml-2.5'>*/}
                  {/*      <i className={`bi bi-${remoteVideoMute ? 'camera-video-off' : 'camera-video'}`} />*/}
                  {/*    </div>*/}
                  {/*    <div className='inline-block rounded-lg text-negative ml-2.5'>*/}
                  {/*      <i className={`bi bi-${remoteAudioMute ? 'mic-mute' : 'mic'}`} />*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*)}*/}
                  <div>
                    <>
                      <span className='text-gray-light font-bold'>{t('session.stream.actingAs')}</span> &nbsp;
                      <span className='text-gray-dark font-bold'>
                        {t(role === USER_ROLES.Trainee ? 'inputLabels.trainee' : 'inputLabels.coach')}
                      </span>
                    </>
                  </div>
                </div>
              </div>
              <div className='flex items-center justify-end text-right space-x-3'>
                <div className='border-r flex flex-col justify-center border-solid border-black-divider pr-3'>
                  <div className='text-lg text-black font-bold'>
                    {progressStatus.isSimulateFeedback ? 'Feedback Session' : 'Coaching Session'}
                  </div>
                </div>
                <div className='space-y-2 lg:space-y-0 lg:space-x-4 flex flex-row items-center justify-center'>
                  {role == USER_ROLES.SP && (
                    <>
                      <PrimaryButton
                        className='h-12'
                        title={progress.result.isSimulateFeedback ? 'Simulate coaching' : 'Simulate feedback'}
                        onClick={onFeedbackClick}
                        disabled={isFeedbackButtonDisabled}
                      />
                    </>
                  )}
                  {role == USER_ROLES.SP && (
                    <>
                      <PrimaryButton
                        className='h-12'
                        title='Swap roles'
                        onClick={onSwapRolesClick}
                        disabled={isSwapButtonDisabled}
                      />
                      <PrimaryButton
                        className='h-12'
                        title='Reset'
                        onClick={onRestartClick}
                        disabled={isResetButtonDisabled}
                      />
                    </>
                  )}
                  {role == USER_ROLES.SP &&
                    progress.result.isSimulateFeedback &&
                    (coachingRecord?.status === RecordStatus.Available ||
                      coachingRecord?.status === RecordStatus.Progress ||
                      coachingRecord?.status === RecordStatus.Enqueued) && (
                      <SecondaryButton
                        className='text-primary border-primary hover:text-primary hover:bg-background-black h-12'
                        title={
                          coachingRecord && coachingRecord.status === RecordStatus.Available
                            ? isReplayCoachingModalVisible
                              ? t('session.recording.hideRecording')
                              : t('session.recording.showRecording')
                            : t('session.recording.prepareRecording')
                        }
                        onClick={() => {
                          const isVisible = !isReplayCoachingModalVisible;
                          setIsReplayCoachingModalVisible(isVisible);
                          setCoachingRecordManipulation({
                            isMuted: true,
                            isPlaying: false,
                            timeInSeconds: 0,
                          });
                          socket!.sessionRecordManipulated({
                            isMuted: true,
                            isPlaying: false,
                            isVisible: isVisible,
                            sessionId: certification.result.id,
                            timeInSeconds: 0,
                          } as SocketSessionRecordManipulated);
                        }}
                        disabled={!coachingRecord || coachingRecord.status !== RecordStatus.Available}
                      />
                    )}
                  {role == USER_ROLES.Trainee &&
                    !progress.result.isSimulateFeedback &&
                    (feedbackRecord?.status === RecordStatus.Available ||
                      feedbackRecord?.status === RecordStatus.Progress ||
                      feedbackRecord?.status === RecordStatus.Enqueued) && (
                      <SecondaryButton
                        className='text-primary border-primary hover:text-primary hover:bg-background-black h-12'
                        title={
                          feedbackRecord && feedbackRecord.status === RecordStatus.Available
                            ? isReplayFeedbackModalVisible
                              ? t('session.recording.hideRecording')
                              : t('session.recording.showRecording')
                            : t('session.recording.prepareRecording')
                        }
                        onClick={() => {
                          const isVisible = !isReplayFeedbackModalVisible;
                          setIsReplayFeedbackModalVisible(isVisible);
                          setFeedbackRecordManipulation({
                            isMuted: true,
                            isPlaying: false,
                            timeInSeconds: 0,
                          });
                          socket!.sessionRecordManipulated({
                            isMuted: true,
                            isPlaying: false,
                            isVisible: isVisible,
                            sessionId: certification.result.id,
                            timeInSeconds: 0,
                          } as SocketSessionRecordManipulated);
                        }}
                        disabled={!feedbackRecord || feedbackRecord.status !== RecordStatus.Available}
                      />
                    )}
                  <SecondaryButton
                    className='text-negative border-negative hover:text-negative hover:bg-background-black h-12'
                    title='End Session'
                    icon='bi bi-x'
                    onClick={onEndClick}
                  />
                </div>
              </div>
            </div>
            <hr className='w-full h-px bg-black-divider border-0 my-4' />

            {room && (
              <div className='grid grid-cols-12 w-full gap-6'>
                <div className='col-span-7'>
                  <IndependentRoomView
                    room={room}
                    userId={currentUser.id}
                    onRemoteAudioMute={() => {}}
                    onRemoteVideoMute={() => {}}
                    role={role as USER_ROLES}
                    mediaDevices={deviceState.devices}
                    currentUser={
                      certification.result.noviceCoach.id == currentUser.id
                        ? currentUser
                        : certification.result.certifiedCoach
                    }
                    partnerUser={
                      certification.result.noviceCoach.id != currentUser.id
                        ? certification.result.certifiedCoach
                        : currentUser
                    }
                    setMediaDevices={devices => dispatch(actionSetDevices({ devices: deviceState.devices }))}
                    certification={certification.result}
                    progress={progress.result}
                  />
                  <div className='col-span-2 pt-6'>
                    {role === USER_ROLES.SP &&
                      progress &&
                      progress.result.teachableMoments &&
                      progress.result.teachableMoments?.length > 0 &&
                      !progress.result.isSimulateFeedback && (
                        <>
                          <div className='text-black font-bold text-xl mb-4'>{t('session.teachableMoments')}</div>
                          <TeachableMomentList
                            isIcon={true}
                            showTime={true}
                            sessionFromResult={{
                              coachingSessionStart: progress.result.coachingSessionStart,
                            }}
                            teachableMoments={progress.result.teachableMoments}
                          />
                        </>
                      )}
                  </div>
                </div>

                <div className='col-span-5'>
                  {progress.result.isSimulateFeedback && (
                    <div
                      className={`mb-4 mt-6 col-span-5 ${
                        role !== USER_ROLES.Trainee
                          ? '5xl:grid 5xl:grid-cols-2 5xl:gap-4'
                          : '3xl:grid 3xl:grid-cols-2 3xl:gap-4'
                      }`}
                    >
                      <RecordedOnList
                        sessionFromResult={{ coachingSessionStart: progress.result.coachingSessionStart }}
                        scoreResult={progress.result.scoreResult}
                        isReplay={role !== USER_ROLES.Trainee}
                        recordStatus={coachingRecord?.status}
                        onClick={(ms: number) => {
                          const newTimeInSeconds = Math.max(0, Math.floor(ms / DurationMS.SEC - 10));
                          setCoachingRecordManipulation({
                            timeInSeconds: 0,
                            isMuted: coachingRecordManipulation?.isMuted ?? true,
                            isPlaying: false,
                          });

                          setTimeout(() => {
                            setCoachingRecordManipulation({
                              timeInSeconds: newTimeInSeconds,
                              isMuted: coachingRecordManipulation?.isMuted ?? true,
                              isPlaying: false,
                            });

                            setIsReplayCoachingModalVisible(true);
                            socket!.sessionRecordManipulated({
                              isMuted: coachingRecordManipulation?.isMuted ?? true,
                              isPlaying: false,
                              isVisible: true,
                              sessionId: certification.result.id,
                              timeInSeconds: newTimeInSeconds,
                            } as SocketSessionRecordManipulated);
                          });
                        }}
                        isStar
                      />
                    </div>
                  )}
                  <div className='col-span-2 pt-6'>
                    {progress &&
                      progress.result.teachableMoments &&
                      progress.result.teachableMoments?.length > 0 &&
                      progress.result.isSimulateFeedback && (
                        <>
                          <TeachableMomentList
                            isIcon={true}
                            showTime={true}
                            sessionFromResult={{
                              coachingSessionStart: progress.result.coachingSessionStart,
                            }}
                            teachableMoments={progress.result.teachableMoments}
                          />
                        </>
                      )}
                  </div>
                  {role === USER_ROLES.SP &&
                    certification.result.caseTemplate.allowTeachableMoments &&
                    progress.result &&
                    !progress.result.isSimulateFeedback && (
                      <>
                        <div className='mb-2 text-lg text-primary-dark font-bold'>{t('session.teachableMoment')}</div>
                        <TeachableMomentInput
                          value={teachableMomentText}
                          handleInput={handleTeachableInput}
                          noteTeachableMoment={noteTeachableMoment}
                        />
                      </>
                    )}
                  {role === USER_ROLES.SP && progress.result && !progress.result.isSimulateFeedback && (
                    <ScoringMarksList
                      caseData={certification.result.caseTemplate}
                      scoreResult={scoreResult}
                      handleScore={handleScoreClick}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
          {isFinishModalVisible && (
            <AbstractModal
              position={{ width: 'w-1/3', left: 'left-1/3' }}
              label={t('modals.endSession')}
              leftBtn={{
                label: t('buttons.noBackToSession'),
                onClick: () => {
                  setIsFinishModalVisible(false);
                },
              }}
              rightBtn={{
                label: t('buttons.yesEndSession'),
                onClick: () => {
                  onSessionFinish();
                  setIsFinishModalVisible(false);
                },
              }}
              toDelete
            >
              <ModalContent entity='streamFinish' title={''} subtitle={t('session.finishModal.subtitle')} />
            </AbstractModal>
          )}

          {isReplayCoachingModalVisible && coachingRecord && (
            <DraggableModal
              isCloseVisible={role === USER_ROLES.SP}
              title={_getVideoRecordModalTitle()}
              onCloseClick={() => {
                setIsReplayCoachingModalVisible(false);
                socket!.sessionRecordManipulated({
                  isMuted: true,
                  isPlaying: false,
                  isVisible: false,
                  sessionId: certification.result.id,
                  timeInSeconds: 0,
                } as SocketSessionRecordManipulated);
              }}
            >
              <RecordPlayer
                scoreResult={scoreResult}
                teachableMoments={progress.result.teachableMoments}
                record={coachingRecord}
                error={coachingRecordDownloadError}
                download={coachingRecordDownloadResponse}
                isReadonly={role !== USER_ROLES.SP}
                watchManipulationInfo={coachingRecordManipulation}
                onManipulationCallback={newManipulationInfo => {
                  if (role === USER_ROLES.SP) {
                    setCoachingRecordManipulation({
                      ...coachingRecordManipulation,
                      ...newManipulationInfo,
                    });
                    socket!.sessionRecordManipulated({
                      isMuted: newManipulationInfo.isMuted,
                      isPlaying: newManipulationInfo.isPlaying,
                      isVisible: isReplayCoachingModalVisible,
                      sessionId: certification.result.id,
                      timeInSeconds: newManipulationInfo.timeInSeconds,
                    } as SocketSessionRecordManipulated);
                  }
                }}
                isModal
              />
            </DraggableModal>
          )}
          {isReplayFeedbackModalVisible && feedbackRecord && (
            <DraggableModal
              isCloseVisible={role === USER_ROLES.Trainee}
              title={_getVideoRecordModalTitle()}
              onCloseClick={() => {
                setIsReplayFeedbackModalVisible(false);
                socket!.sessionRecordManipulated({
                  isMuted: true,
                  isPlaying: false,
                  isVisible: false,
                  sessionId: certification.result.id,
                  timeInSeconds: 0,
                } as SocketSessionRecordManipulated);
              }}
            >
              <RecordPlayer
                scoreResult={scoreResult}
                teachableMoments={progress.result.teachableMoments}
                record={feedbackRecord}
                error={feedbackRecordDownloadError}
                download={feedbackRecordDownloadResponse}
                isReadonly={role !== USER_ROLES.Trainee}
                watchManipulationInfo={feedbackRecordManipulation}
                onManipulationCallback={newManipulationInfo => {
                  if (role === USER_ROLES.Trainee) {
                    setFeedbackRecordManipulation({
                      ...feedbackRecordManipulation,
                      ...newManipulationInfo,
                    });
                    socket!.sessionRecordManipulated({
                      isMuted: newManipulationInfo.isMuted,
                      isPlaying: newManipulationInfo.isPlaying,
                      isVisible: isReplayFeedbackModalVisible,
                      sessionId: certification.result.id,
                      timeInSeconds: newManipulationInfo.timeInSeconds,
                    } as SocketSessionRecordManipulated);
                  }
                }}
                isModal
              />
            </DraggableModal>
          )}
        </>
      );
    default:
      return <div>{`Unknown status: ${certification}`}</div>;
  }
};

export default CertificationRoomView;
