import React from 'react';
import SquarePrimaryButton from './SquarePrimaryButton';

interface ITextInputProps {
  value: string;
  handleInput: (value: string) => void;
  noteTeachableMoment: () => void;
}

const TeachableMomentInput = ({ value, handleInput, noteTeachableMoment }: ITextInputProps) => {
  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter' && value.trim().length >= 1) {
      noteTeachableMoment();
    }
  };
  return (
    <div className='flex flex-row items-center justify-content-between mb-6'>
      <input
        className={`custom-input transition py-1 px-3 text-base rounded-lg block bg-white bg-clip-padding text-gray-dark placeholder-gray-light hover:border-primary-hover focus:text-black focus:border-primary-hover focus:outline-none border border-solid border-black-divider appearance-none font-normal w-full mr-4`}
        type='text'
        value={value}
        onChange={(e: any) => handleInput(e.target.value)}
        onKeyPress={handleKeyPress}
        min={1}
        required
      />
      <SquarePrimaryButton
        icon='bi bi-plus'
        onClick={() => noteTeachableMoment()}
        size='w-8 h-8'
        additionalStyles={`${value.trim().length < 1 && 'select-none pointer-events-none bg-primary-light py-0 px-2'}`}
      />
    </div>
  );
};

export default TeachableMomentInput;
