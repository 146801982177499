import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// SERVICE
import { UserResponseDto } from '../../service/dto/user.dto';

import DefaultHeader from '../../components/DefaultHeader';

interface IUserProfile {
  currentUser: UserResponseDto;
}

const UserProfile = ({ currentUser }: IUserProfile) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className='rounded-lg bg-white my-6 shadow pb-2 mx-4 2xl:mx-6'>
      <div className='container p-6'>
        <div className='my-6 mx-4 2xl:mx-6'>
          <div className='w-full'>
            <DefaultHeader
              title={t('user.profileInfo')}
              style={'pb-2'}
              primaryAction={{
                title: t('buttons.edit'),
                icon: 'bi bi-pencil',
                onClick: () => {
                  navigate('/user/edit');
                },
              }}
            />

            <hr className='w-full h-px bg-black-divider border-0 mt-4 mb-6' />
            <div>
              <span className='uppercase text-gray-dark text-base'>{t('user.firstName')}</span>
              <span className='text-black text-lg px-2'>{currentUser.firstName}</span>
            </div>
            <div>
              <span className='uppercase text-gray-dark text-base'>{t('user.lastName')}</span>
              <span className='text-black text-lg px-2'>{currentUser.lastName}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
