import React from 'react';
import { useTranslation } from 'react-i18next';

interface ISearchField {
  onSearch: Function;
  placeholderLabel?: string;
  width?: string;
  value?: number | string;
  inputType?: string;
  icon?: string;
}

const SearchField = ({ onSearch, placeholderLabel, width, value, inputType, icon }: ISearchField) => {
  const { t } = useTranslation();
  return (
    <>
      <div className='relative'>
        {icon && <i className={`absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none ${icon}`} />}
        <input
          className={`custom-input transition py-1.5 pl-10 pr-3 text-base block rounded-lg bg-white bg-clip-padding text-gray-dark placeholder-gray-light hover:text-primary-hover focus:text-gray-dark focus:border-primary-hover focus:outline-none border border-solid border-black-divider appearance-none font-normal max-w-full ${
            width ?? 'w-64'
          }`}
          type={inputType ?? 'text'}
          placeholder={t(placeholderLabel ? placeholderLabel : 'placeholders.search')}
          onChange={e => onSearch(e.target.value)}
          value={value}
        />
      </div>
    </>
  );
};

export default SearchField;
