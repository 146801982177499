import React from 'react';

interface IStatisticItem {
  title: string;
  description: string;
  icon: string;
}

const StatisticItem = ({ title, description, icon }: IStatisticItem) => {
  return (
    <div className='flex justify-between mt-2'>
      <div className='text-primary font-bold'>
        <i className={`${icon} text-3xl`}></i>
      </div>
      <div className='w-4/5'>
        <div className='text-primary font-bold'>{title}</div>
        <div className='uppercase text-gray-dark text-sm'>{description}</div>
      </div>
    </div>
  );
};
export default StatisticItem;
