import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

// FORM
import { useForm } from '../../hooks/useForm';
import { UserPaswordResetValidation } from '../../validation/auth.validation';

// SERVICE
import { AuthService } from '../../service/auth.service';
import { UserPaswordResetDto } from '../../service/dto/auth.dto';

// UI COMPONENTS
import PrimaryButton from '../../components/PrimaryButton';
import PasswordInput from '../../components/PasswordInput';
import InputLabel from '../../components/InputLabel';

enum COMPONENT_STATE {
  INIT,
  SUCCESS,
  FAIL,
}

const ResetPassword: React.FC = () => {
  const { t } = useTranslation();
  const { resetPasswordId } = useParams<{ resetPasswordId: string }>();
  const [state, setState] = useState(COMPONENT_STATE.INIT);

  const {
    handleSubmit,
    handleChange,
    data: payload,
    errors,
  } = useForm<UserPaswordResetDto>({
    validations: UserPaswordResetValidation,

    onSubmit: () => {
      payload.resetPasswordId = resetPasswordId;
      delete payload.password_confirm;

      AuthService.passwordReset(
        payload,
        () => {
          setState(COMPONENT_STATE.SUCCESS);
        },
        () => {
          setState(COMPONENT_STATE.FAIL);
        },
      );
    },
  });

  return (
    <>
      {(state === COMPONENT_STATE.INIT || state === COMPONENT_STATE.FAIL) && (
        <>
          <div className='text-2xl font-bold my-10 text-center'>{t('auth.resetPassword.title')}</div>
          <form onSubmit={handleSubmit}>
            <div className='w-full mb-6'>
              <InputLabel label={t('inputLabels.password')} />
              <div className='text-gray-dark mb-0'>{t('inputLabels.minPasswordLength')}</div>
              <div className='text-gray-dark mb-2'>{t('inputLabels.passwordRules')}</div>
              <PasswordInput
                placeholder={t('placeholders.password')}
                value={payload.password || ''}
                handleChange={handleChange('password')}
                error={errors.password}
                required
              />
            </div>
            <div className='w-full mb-10'>
              <InputLabel label={t('inputLabels.repeatPassword')} />
              <PasswordInput
                placeholder={t('placeholders.repeatPassword')}
                value={payload.password_confirm || ''}
                handleChange={handleChange('password_confirm')}
                error={errors.password_confirm}
                required
              />
            </div>

            <div className='w-full mb-10 flex justify-center'>
              <PrimaryButton title={t('buttons.changePassword')} type='submit' />
            </div>

            {state == COMPONENT_STATE.FAIL && (
              <div className='rounded-lg text-center text-negative w-full bg-negative'>
                {t('errorMessages.failState')}E
              </div>
            )}
          </form>
        </>
      )}

      {state === COMPONENT_STATE.SUCCESS && (
        <>
          <div className='text-center'>
            <div className='text-2xl font-bold my-10'>{t('auth.resetPassword.title')}</div>
            <div className='text-base mb-10'>{t('successMessages.passwordChanged')}</div>
          </div>
          <hr className='w-full h-px bg-black-divider border-0 mb-10'></hr>
          <div className='text-base mb-10 text-center'>
            <Link className='text-primary underline' to='/signin' replace>
              {t('auth.linkTo.backLogin')}
            </Link>
          </div>
        </>
      )}
    </>
  );
};

export default ResetPassword;
