import React from 'react';

export enum RadioInputStyle {
  VERTICAL,
  HORIZONTAL,
}

export interface IRadioItem {
  value: string | number | boolean;
  label: string;
}

interface IRadioInputProps {
  list: IRadioItem[];
  value: string | number | boolean;
  style: RadioInputStyle;
  handleChange: Function;
}

const RadioInput = ({ list, value, style, handleChange }: IRadioInputProps) => {
  return (
    <div className={'pt-3 flex ' + (style == RadioInputStyle.VERTICAL ? 'flex-col ' : 'flex-row ')}>
      {list.map(i => {
        return (
          <label
            className={
              'block cursor-pointer relative text-black pr-6 pl-7 mb-5 select-none radioInputs__item ' +
              (value == i.value && 'font-bold')
            }
            key={i.label}
          >
            {i.label}
            <input
              type='radio'
              className='cursor-pointer absolute w-0 h-0 opacity-0'
              checked={value == i.value}
              onChange={() => {
                handleChange(i);
              }}
            />
            <span className='radio-checkmark rounded-full absolute top-0.8 left-0 bg-transparent w-5 h-5 border border-solid border-primary'></span>
          </label>
        );
      })}
    </div>
  );
};

export default RadioInput;
