import React from 'react';

interface ISquarePrimaryButtonProps {
  icon: string;
  onClick?: () => void;
  size?: string;
  additionalStyles?: string;
}

const SquarePrimaryButton = ({ icon, onClick, size, additionalStyles }: ISquarePrimaryButtonProps) => {
  return (
    <button
      type='button'
      className={`flex items-center justify-center border border-solid rounded-lg font-bold text-tiny text-white bg-primary hover:text-white hover:bg-primary-hover p-4 ${
        size ? size : 'w-12 h-12'
      } ${additionalStyles}`}
      onClick={onClick}
    >
      <i className={`${icon} text-2xl`}></i>
    </button>
  );
};

export default SquarePrimaryButton;
