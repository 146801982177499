import React, { useEffect, useState } from 'react';
import TextInput from './TextInput';
import SearchField from './SearchField';
import { UserService } from '../service/user.service';
import { AvailableCoachesResponseDto } from '../service/dto/available-coaches.response.dto';
import { ICustomSelectItem } from './CustomSelect';
import InputLabel from './InputLabel';
import { useTranslation } from 'react-i18next';
import Alert from '../utils/alert';
import { SessionService } from '../service/session.service';
import { InviteCoachDto } from '../service/dto/invite-coach.dto';

const InviteCoaches = ({ url }: { url: string }) => {
  const [coaches, setCoaches] = useState<ICustomSelectItem[]>([]);
  const [filteredCoaches, setFilteredCoaches] = useState<ICustomSelectItem[]>([]);
  const [invitedCoaches, setInvitedCoaches] = useState<string[]>([]);

  const { t } = useTranslation();

  useEffect(() => {
    serviceAvailableCoaches();
  }, []);

  const onSearch = (value: string) => {
    const coachList = coaches.filter(coach => {
      return !!(coach.label && coach.label.toLowerCase().includes(value));
    });
    setFilteredCoaches(coachList);
  };

  const sendInvite = (id: string) => {
    const invite: InviteCoachDto = {
      coachId: id,
      sessionLink: url,
    };
    serviceSendInvite(invite);
  };

  const serviceAvailableCoaches = () => {
    UserService.availableCoaches(
      (_data: AvailableCoachesResponseDto[]) => {
        const coachList: ICustomSelectItem[] = _data.map(user => {
          return {
            label: user.coachName,
            value: user.coachId,
          };
        });
        setCoaches(coachList);
        setFilteredCoaches(coachList);
      },
      error => {
        setCoaches([]);
      },
    );
  };

  const serviceSendInvite = (invite: InviteCoachDto) => {
    SessionService.sendInvite(
      invite,
      _code => {
        if (!invitedCoaches.includes(invite.coachId)) {
          setInvitedCoaches([...invitedCoaches, invite.coachId]);
        }
        Alert.success(t('successMessages.inviteSent'));
      },
      console.error,
    );
  };

  return (
    <div className='mt-2'>
      <div>
        <InputLabel label={t('inputLabels.shareLink')} otherMB={'mb-2 !text-black'} />
        <TextInput
          readOnly
          value={url}
          onClick={() => {
            navigator.clipboard.writeText(url).then(response => Alert.success(t('stream.link_copied')));
          }}
          startIcon={'bi bi-link cursor-pointer'}
          endIcon={'bi bi-files cursor-pointer'}
        />
      </div>
      <div className='mt-4'>
        <InputLabel label={t('session.stream.invite')} otherMB={'mb-2 !text-black'} />
        <SearchField
          placeholderLabel={t('placeholders.searchByName')}
          onSearch={onSearch}
          width={'w-full'}
          icon={'bi bi-search'}
        />
        {filteredCoaches.map((coach, index) => {
          return (
            <div className='flex justify-between my-4' key={index}>
              <p className='text-md pl-2'>{coach.label}</p>
              <i
                className={`bi text-lg pr-1 cursor-pointer ${
                  invitedCoaches.includes(coach.value!)
                    ? 'bi-check-lg text-positive'
                    : 'bi-send-fill text-primary-dark '
                }`}
                onClick={() => sendInvite(coach.value!)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default InviteCoaches;
