import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

// SERVICE
import { AuthService } from '../../service/auth.service';

// UI COMPONENTS
import PrimaryButton from '../../components/PrimaryButton';
import SuccessButton from '../../components/SuccessButton';

enum COMPONENT_STATE {
  INIT,
  SUCCESS,
  FAIL,
}

const UserConfirm: React.FC = () => {
  const { t } = useTranslation();
  const { confirmationId } = useParams<{ confirmationId: string }>();
  const [state, setState] = useState(COMPONENT_STATE.INIT);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AuthService.userConfirmation(
      confirmationId,
      () => {
        setState(COMPONENT_STATE.SUCCESS);
      },
      () => {
        setState(COMPONENT_STATE.FAIL);
      },
    );
  }, [loading]);

  return (
    <div className='text-center'>
      <div className='text-2xl font-bold my-12'>{t('auth.confirmation.title')}</div>
      {state == COMPONENT_STATE.INIT && <div className='text-base mb-12'>{t('auth.confirmation.takingPlace')}</div>}

      {state === COMPONENT_STATE.SUCCESS && (
        <>
          <div>
            <div className='text-base mb-10'>{t('auth.confirmation.confirmed')}</div>
            <div className='mb-10'>
              <SuccessButton label={t('buttons.userConfirmed')} />
            </div>

            <div className='text-base mb-12 text-center'>
              <hr className='w-full h-px bg-black-divider border-0 mb-10'></hr>
              <Link className='text-primary mx-4' to='/signin' replace>
                <PrimaryButton title={t('buttons.signin')} type='submit' />
              </Link>
            </div>
          </div>
        </>
      )}

      {state === COMPONENT_STATE.FAIL && (
        <>
          <div className='text-base mb-10'>{t('errorMessages.linkExpired')}</div>

          <div className='text-base mb-12 text-center'>
            <hr className='w-full h-px bg-black-divider border-0 mb-10'></hr>
            <Link className='text-primary mx-4' to='/signin' replace>
              <PrimaryButton title={t('buttons.signin')} type='submit' />
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default UserConfirm;
