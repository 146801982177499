import { CategoryCreateDto, CategoryListItemDto } from '../../service/dto/category.dto';
import { useTranslation } from 'react-i18next';
import InputLabel from '../../components/InputLabel';
import TextInput from '../../components/TextInput';
import React from 'react';

interface CategoryCreateProps {
  category: CategoryCreateDto | CategoryListItemDto;
  handleChangeName: (value: string) => void;
  handleChangeDescription: (value: string) => void;
  errorName: string;
}

const CategoryCreate = ({ category, handleChangeName, handleChangeDescription, errorName }: CategoryCreateProps) => {
  const { t } = useTranslation();

  return (
    <>
      <form className='w-full'>
        <div className='w-full mb-6'>
          <InputLabel label={t('inputLabels.categoryName')} />
          <TextInput
            placeholder={t('placeholders.categoryName')}
            value={category.name || ''}
            handleChange={(e: any) => handleChangeName(e.target.value)}
            error={t(errorName)}
            required
          />
        </div>
        <div className='w-full mb-6'>
          <InputLabel label={t('inputLabels.categoryDescription')} />
          <TextInput
            placeholder={t('placeholders.categoryDescription')}
            value={category.description || ''}
            handleChange={(e: any) => handleChangeDescription(e.target.value)}
            required
          />
        </div>
      </form>
    </>
  );
};

export default CategoryCreate;
