import { SessionRecordSequenceDto } from '../service/dto/session.dto';
import { DurationMS } from './constants';

export interface RecordSequenceRange {
  from: number;
  to: number;
  fromPercent: number;
  toPercent: number;
}

const wait = (ms: number): Promise<void> => new Promise(r => setTimeout(r, ms));

export const retryOperationAsync = async <T>(operation: () => Promise<T>, delay = 100, retries = 3): Promise<T> => {
  try {
    return await operation();
  } catch (reason) {
    if (retries > 0) {
      await wait(delay);
      return await retryOperationAsync(operation, delay, retries - 1);
    }

    throw reason;
  }
};

export const retryOperation = <T>(operation: () => Promise<T>, delay = 100, retries = 3): Promise<T> =>
  new Promise((resolve, reject) => {
    return operation()
      .then(resolve)
      .catch(reason => {
        if (retries > 0) {
          return wait(delay)
            .then(_ => retryOperation(operation, delay, retries - 1))
            .then(resolve)
            .catch(reject);
        }

        return reject(reason);
      });
  });

export const calculateSessionRecordedTimeInSeconds = (sequences: SessionRecordSequenceDto[]): number => {
  const sequencesFlatArray = sequences.length
    ? sequences[0].isRecordingActive
      ? [...sequences]
      : [...sequences.slice(1, sequences.length)]
    : [];

  if (!sequencesFlatArray.length) {
    return 0;
  }

  const sequencesPairedArray = [];
  for (let index = 0; index < sequencesFlatArray.length; index += 2) {
    const subArray = [sequencesFlatArray[index]];
    if (index + 1 < sequencesFlatArray.length) {
      subArray.push(sequencesFlatArray[index + 1]);
    }

    sequencesPairedArray.push(subArray);
  }

  const totalTimeMS = sequencesPairedArray.reduce(
    (total, [start, stop]) => (stop ? total + stop.timestamp - start.timestamp : total + Date.now() - start.timestamp),
    0,
  );
  return Math.floor(totalTimeMS / DurationMS.SEC);
};

export const timeSequencesToRanges = (
  sequences: SessionRecordSequenceDto[],
  durationInSeconds: number,
): RecordSequenceRange[] => {
  if (!sequences || !sequences.length || sequences.every(x => !x.isRecordingActive)) return [];
  const shiftedSequences = sequences[0].isRecordingActive ? [...sequences] : [...sequences.slice(1, sequences.length)];
  const result: RecordSequenceRange[] = [];
  let totalInMS = 0;
  for (let i = 0; i < sequences.length - 1; i += 2) {
    const from = shiftedSequences[i].timestamp;
    const to =
      i + 1 == shiftedSequences.length
        ? from + (durationInSeconds * DurationMS.SEC - totalInMS)
        : shiftedSequences[i + 1].timestamp;

    totalInMS += to - from;
    result.push({
      from,
      to,
      fromPercent: 0,
      toPercent: 0,
    });
  }

  const diffSum = result.reduce((sum, element) => sum + element.to - element.from, 0);
  let totalProgress = 0;
  for (const range of result) {
    const currentProgress = ((range.to - range.from) / diffSum) * 100;
    range.fromPercent = totalProgress;
    range.toPercent = totalProgress + currentProgress;
    totalProgress += currentProgress;
  }

  return result;
};

export const addedDay = (until: Date): Date => {
  const result = new Date(until);
  result.setHours(0, 0, 0, 0);
  result.setDate(result.getDate() + 1);
  return result;
};
