import React from 'react';
import { ICurrentInstitution, ICurrentUser } from '../../common/interfaces';

interface IDashboard {
  currentInstitution: ICurrentInstitution;
  currentUser: ICurrentUser;
}

const Dashboard: React.FC<IDashboard> = ({ currentInstitution, currentUser }) => {
  return (
    <>
      <div className='rounded-lg bg-white my-6 shadow pb-2 mx-4 2xl:mx-6 px-6'>
        <div className='container py-6'>
          <div className='text-xl font-bold'>
            Welcome{' '}
            <span className='text-primary-dark'>
              {currentUser.firstName} {currentUser.lastName}
            </span>
          </div>
          <div className='text'>You are currently using SoCoCo for {currentInstitution.name}. </div>
          <div className='italic text-primary-dark mt-6'>Visit the left side navigation to start using SoCoCo.</div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
