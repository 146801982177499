import React from 'react';
import { useTranslation } from 'react-i18next';

// DTO
import { UserEditListDto } from '../../service/dto/user.dto';

// UI COMPONENTS
import InputLabel from '../../components/InputLabel';
import TextInput from '../../components/TextInput';
import EmailInput from '../../components/EmailInput';
import CheckboxInput, { CheckboxInputStyle, ICheckboxItem } from '../../components/CheckboxInput';
import { USER_ROLES } from '../../common/enums';

interface IEditUserFormProps {
  errors: {
    firstName: string;
    lastName: string;
    email: string;
  };
  payload: UserEditListDto;
  handleChange: Function;
  currentInstitution: any;
}

const EditUserForm = ({ errors, payload: user, handleChange, currentInstitution }: IEditUserFormProps) => {
  const { t } = useTranslation();
  if (user.roles.includes(USER_ROLES.Admin)) {
    user.roles = [USER_ROLES.Admin];
  } else if (user.roles.includes(USER_ROLES.SP)) {
    user.roles = [USER_ROLES.SP];
  } else if (user.roles.includes(USER_ROLES.Trainee)) {
    user.roles = [USER_ROLES.Trainee];
  }
  return (
    <form>
      <div className='grid grid-cols-2 gap-6'>
        <div className='mb-6'>
          <InputLabel label={t('inputLabels.firstName')} />
          <TextInput
            disabled
            placeholder={t('placeholders.firstName')}
            value={user.firstName || ''}
            handleChange={(e: any) => handleChange('firstName', e.target.value)}
            error={errors.firstName}
            required
          />
        </div>
        <div className='mb-6'>
          <InputLabel label={t('inputLabels.lastName')} />
          <TextInput
            disabled
            placeholder={t('placeholders.lastName')}
            value={user.lastName || ''}
            handleChange={(e: any) => handleChange('lastName', e.target.value)}
            error={errors.lastName}
            required
          />
        </div>
      </div>
      <div className='mb-6'>
        <InputLabel label={t('inputLabels.usersEmail')} />
        <EmailInput
          disabled
          placeholder={t('placeholders.usersEmail')}
          value={user.email || ''}
          handleChange={(e: any) => handleChange('email', e.target.value)}
          error={errors.email}
          required
        />
      </div>
      {!user.isSuperAdmin && (
        <>
          <hr className='w-full h-px bg-black-divider border-0 mb-4'></hr>
          <div className='mb-6'>
            <InputLabel label={t('inputLabels.roles')} />
            <CheckboxInput
              list={[
                { value: 'role:admin', label: t('inputLabels.admin') },
                { value: 'role:sp', label: t('inputLabels.coach') },
                { value: 'role:trainee', label: t('inputLabels.trainee') },
              ]}
              values={user.roles}
              style={CheckboxInputStyle.HORIZONTAL}
              handleChange={(roles: ICheckboxItem[]) => {
                handleChange('roles', roles);
              }}
              multipleSelect={false}
            />
          </div>
        </>
      )}
    </form>
  );
};

export default EditUserForm;
