import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import InputLabel from '../../components/InputLabel';
import PrimaryButton from '../../components/PrimaryButton';
import CustomSelect, { ICustomSelectItem } from '../../components/CustomSelect';
import { InstitutionResponseDto } from '../../service/dto/institution.dto';
import { InstitutionService } from '../../service/institution.service';
import { CaseService } from '../../service/case.service';
import { CaseResponseDto } from '../../service/dto/case.dto';
import { CategoryService } from '../../service/category.service';
import { CategoryListItemDto } from '../../service/dto/category.dto';
import SecondaryButton from '../../components/SecondaryButton';
import SquareSecondaryButton from '../../components/SquareSecondaryButton';
import { ScoringService } from '../../service/scoring.service';
import { ScoringResponseDto } from '../../service/dto/scoring.dto';
import Alert from '../../utils/alert';
import { CaseScoringFloat } from '../../common/enums';

enum ImportCaseScoringItemState {
  NONE,
  RECREATE,
  EXISTING,
  SKIP,
}

export interface ImportCaseScoringItem {
  state: ImportCaseScoringItemState;
  scoreItemRef: string | undefined;
  position: CaseScoringFloat;
}

const ImportCaseTemplate = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [institutionList, setInstitutionList] = useState<ICustomSelectItem[]>([]);
  const [sourceCaseTemplateList, setSourceCaseTemplateList] = useState<ICustomSelectItem[]>([]);
  const [sourceCaseTemplate, setSourceCaseTemplate] = useState<CaseResponseDto>();
  const [categoryList, setCategoryList] = useState<ICustomSelectItem[]>([]);
  const [institution, setInstitution] = useState<string>();
  const [category, setCategory] = useState<string>();
  const [sourceCaseId, setSourceCaseId] = useState<string>();
  const [importCaseScoringItems, setImportCaseScoringItems] = useState<ImportCaseScoringItem[]>([
    {
      state: ImportCaseScoringItemState.NONE,
      scoreItemRef: undefined,
      position: CaseScoringFloat.None,
    },
  ]);
  const [scoringItems, setScoringItems] = useState<ScoringResponseDto[]>([]);
  const [filteredScoringItems, setFilteredScoringItems] = useState<ICustomSelectItem[]>([]);
  const [importDisabled, setImportDisabled] = useState<boolean>(true);

  useEffect(() => {
    serviceInstitutionDataFetch();
    serviceScoringItemsFetch();
  }, []);

  useEffect(() => {
    if (institution) {
      serviceCasesDataFetch(institution);
    }
  }, [institution]);

  useEffect(() => {
    if (sourceCaseId) {
      serviceCaseTemplateFetch(sourceCaseId);
    }
  }, [sourceCaseId]);

  useEffect(() => {
    if (sourceCaseTemplate) {
      setImportCaseScoringItems(
        sourceCaseTemplate.scoring_items.map(() => {
          return {
            state: ImportCaseScoringItemState.NONE,
            scoreItemRef: undefined,
            position: CaseScoringFloat.None,
          };
        }),
      );
    }
  }, [sourceCaseTemplate, category]);

  useEffect(() => {
    setImportDisabled(
      !!importCaseScoringItems.find(sI => sI.state === ImportCaseScoringItemState.NONE) ||
        importCaseScoringItems.filter(sI => sI.state === ImportCaseScoringItemState.SKIP).length ===
          importCaseScoringItems.length,
    );
  }, [importCaseScoringItems]);

  useEffect(() => {
    setFilteredScoringItems(
      scoringItems
        .filter(sI => sI.categoryId === category)
        .map(sI => {
          return {
            value: sI.id,
            label: sI.name,
          };
        }),
    );
  }, [category]);

  const serviceScoringItemsFetch = () => {
    ScoringService.list(
      { search: '' },
      (scoringItemsResponse: Array<ScoringResponseDto>) => {
        setScoringItems(scoringItemsResponse);
        serviceCategoryDataFetch();
      },
      () => {
        setScoringItems([]);
        serviceCategoryDataFetch();
      },
    );
  };

  const serviceCaseTemplateFetch = (caseId: string) => {
    CaseService.getById(
      caseId,
      (caseTemplateResponse: CaseResponseDto) => {
        setSourceCaseTemplate(caseTemplateResponse);
      },
      () => {},
    );
  };

  const serviceInstitutionDataFetch = () => {
    InstitutionService.list(
      { search: '' },
      (responseData: Array<InstitutionResponseDto>) => {
        setInstitutionList(
          responseData.map(institution => {
            return { value: institution.id, label: institution.name };
          }),
        );
        setInstitution(responseData[0].id);
      },
      error => {
        setInstitutionList([]);
      },
    );
  };

  const serviceCasesDataFetch = (institutionId: string) => {
    CaseService.listForInstitution(
      institutionId,
      (responseData: Array<CaseResponseDto>) => {
        setSourceCaseTemplateList(
          responseData.map(caseTemplate => {
            return { value: caseTemplate.id, label: caseTemplate.name };
          }),
        );
        setSourceCaseId(responseData[0].id);
      },
      error => {
        setSourceCaseTemplateList([]);
      },
    );
  };

  const serviceCategoryDataFetch = () => {
    CategoryService.list(
      (categories: CategoryListItemDto[]) => {
        setCategoryList(
          categories.map(category => {
            return { value: category.id, label: category.name };
          }),
        );
        setCategory(categories[0].id);
      },
      () => {
        setCategoryList([]);
      },
    );
  };

  const onScoringItemSelect = (item: ICustomSelectItem, index: number) => {
    importCaseScoringItems[index].scoreItemRef = item.value;
    setImportCaseScoringItems([...importCaseScoringItems]);
  };

  const scoringItemsForSelect = (index: number): ICustomSelectItem[] => {
    const selectedSI = importCaseScoringItems.filter((item, i) => i != index).map(item => item.scoreItemRef);
    return filteredScoringItems.filter(sI => selectedSI.indexOf(sI.value) === -1);
  };

  const handleCancel = () => {
    navigate('/cases');
  };

  const handleImport = () => {
    if (sourceCaseTemplate && category) {
      CaseService.import(
        {
          originalTemplateId: sourceCaseTemplate?.id,
          scoringItems: importCaseScoringItems,
          category: category,
        },
        caseResponse => {
          navigate('/case/edit/' + caseResponse.id);
          Alert.success(t('successMessages.caseCreated'));
        },
        error => {
          Alert.warning(t('errorMessages.caseNotCreated'));
          console.error(error);
        },
      );
    }
  };

  return (
    <div className='my-6 mx-4 2xl:mx-6'>
      <form className='w-full'>
        <div className='flex items-center justify-between py-2'>
          <div>
            <div className='font-bold text-lg text-black'>{t('cases.importCase.title')}</div>
          </div>

          <div className='flex items-center'>
            <PrimaryButton title={t('buttons.cancel')} icon='' className='mr-4' onClick={() => handleCancel()} />
            <PrimaryButton
              title={t('buttons.import')}
              icon='bi bi-check2-circle'
              onClick={() => !importDisabled && handleImport()}
              className={importDisabled ? 'opacity-50 cursor-not-allowed' : ''}
            />
          </div>
        </div>
        <hr className='w-full h-px bg-black-divider border-0 mt-4 mb-6' />
        <div className='flex justify-between flex-col'>
          <div className='w-3/5 lg:w-7/12 xl:w-1/4'>
            <div className='mb-4'>
              <InputLabel label={t('inputLabels.sourceInstitution')} />
              <CustomSelect
                placeholder=''
                list={institutionList}
                value={institution || ''}
                handleChange={(item: ICustomSelectItem) => {
                  setInstitution(item.value);
                }}
              />
            </div>
          </div>
          <div className='w-3/5 lg:w-7/12 xl:w-1/4'>
            <div className='mb-4'>
              <InputLabel label={t('inputLabels.sourceCaseTemplate')} />
              <CustomSelect
                placeholder=''
                list={sourceCaseTemplateList}
                value={sourceCaseId || ''}
                handleChange={(item: ICustomSelectItem) => {
                  setSourceCaseId(item.value);
                }}
              />
            </div>
          </div>
          <div className='w-3/5 lg:w-7/12 xl:w-1/4'>
            <div className='mb-4'>
              <InputLabel label={t('inputLabels.targetCategory')} />
              <CustomSelect
                placeholder=''
                list={categoryList}
                value={category || ''}
                handleChange={(item: ICustomSelectItem) => {
                  setCategory(item.value);
                }}
              />
            </div>
          </div>
        </div>
        <hr className='w-full h-px bg-black-divider border-0 mt-4 mb-6' />
        <div>
          <div className='font-bold text-black'>{t('cases.importCase.mapScoringTitle')}</div>
        </div>
        <div className='my-6 mx-4 2xl:mx-6'>
          <div className='flex justify-between flex-wrap w-3/5 lg:w-7/12 xl:w-2/4'>
            <div className='w-3/5 lg:w-6/12 xl:w-1/2 text-black'>{t('cases.importCase.sourceCaseTitle')}</div>
            <div className='w-3/5 lg:w-6/12 xl:w-1/2 text-black'>{t('cases.importCase.resultCaseTitle')}</div>
          </div>
          <hr className='w-full h-px bg-black-divider border-0 mt-8 mb-6' />
          {sourceCaseTemplate &&
            sourceCaseTemplate.scoring_items.map((scoringItem, index) => {
              return (
                <div key={index} className='flex justify-between flex-wrap w-3/5 lg:w-7/12 xl:w-2/4 mb-2'>
                  <div className='flex items-center w-3/5 lg:w-4/12 xl:w-3/8 text-black'>
                    {t('cases.importCase.sourceCase')}: {scoringItem.name}
                  </div>
                  <div className='w-3/5 lg:w-8/12 xl:w-5/8 text-black pl-4'>
                    {importCaseScoringItems[index] &&
                      importCaseScoringItems[index].state === ImportCaseScoringItemState.NONE && (
                        <div className='flex justify-between'>
                          <SecondaryButton
                            className='text-primary border-primary hover:text-primary hover:bg-background-black mr-4'
                            title={t('cases.importCase.recreate')}
                            onClick={() => {
                              importCaseScoringItems[index].state = ImportCaseScoringItemState.RECREATE;
                              importCaseScoringItems[index].scoreItemRef = scoringItem.id;
                              setImportCaseScoringItems([...importCaseScoringItems]);
                            }}
                          />
                          <SecondaryButton
                            className='text-primary border-primary hover:text-primary hover:bg-background-black mr-4'
                            title={t('cases.importCase.skip')}
                            onClick={() => {
                              importCaseScoringItems[index].state = ImportCaseScoringItemState.SKIP;
                              importCaseScoringItems[index].scoreItemRef = '';
                              setImportCaseScoringItems([...importCaseScoringItems]);
                            }}
                          />
                          <SecondaryButton
                            className='text-primary border-primary hover:text-primary hover:bg-background-black'
                            title={t('cases.importCase.useExisting')}
                            onClick={() => {
                              importCaseScoringItems[index].state = ImportCaseScoringItemState.EXISTING;
                              setImportCaseScoringItems([...importCaseScoringItems]);
                            }}
                          />
                        </div>
                      )}
                    {importCaseScoringItems[index] &&
                      importCaseScoringItems[index].state === ImportCaseScoringItemState.RECREATE && (
                        <div className='flex justify-between'>
                          <PrimaryButton
                            className='cursor-not-allowed  mr-4'
                            title={t('cases.importCase.recreate')}
                            onClick={() => {}}
                          />
                          <SecondaryButton
                            className='text-primary border-primary hover:text-primary hover:bg-background-black  mr-4'
                            title={t('cases.importCase.skip')}
                            onClick={() => {
                              importCaseScoringItems[index].state = ImportCaseScoringItemState.SKIP;
                              importCaseScoringItems[index].scoreItemRef = '';
                              setImportCaseScoringItems([...importCaseScoringItems]);
                            }}
                          />
                          <SecondaryButton
                            className='text-primary border-primary hover:text-primary hover:bg-background-black'
                            title={t('cases.importCase.useExisting')}
                            onClick={() => {
                              importCaseScoringItems[index].state = ImportCaseScoringItemState.EXISTING;
                              setImportCaseScoringItems([...importCaseScoringItems]);
                            }}
                          />
                        </div>
                      )}
                    {importCaseScoringItems[index] &&
                      importCaseScoringItems[index].state === ImportCaseScoringItemState.SKIP && (
                        <div className='flex justify-between'>
                          <SecondaryButton
                            className='text-primary border-primary hover:text-primary hover:bg-background-black mr-4'
                            title={t('cases.importCase.recreate')}
                            onClick={() => {
                              importCaseScoringItems[index].state = ImportCaseScoringItemState.RECREATE;
                              importCaseScoringItems[index].scoreItemRef = scoringItem.id;
                              setImportCaseScoringItems([...importCaseScoringItems]);
                            }}
                          />
                          <PrimaryButton
                            className='cursor-not-allowed  mr-4'
                            title={t('cases.importCase.skip')}
                            onClick={() => {}}
                          />
                          <SecondaryButton
                            className='text-primary border-primary hover:text-primary hover:bg-background-black'
                            title={t('cases.importCase.useExisting')}
                            onClick={() => {
                              importCaseScoringItems[index].state = ImportCaseScoringItemState.EXISTING;
                              setImportCaseScoringItems([...importCaseScoringItems]);
                            }}
                          />
                        </div>
                      )}
                    {importCaseScoringItems[index] &&
                      importCaseScoringItems[index].state === ImportCaseScoringItemState.EXISTING && (
                        <div className='flex justify-between'>
                          <CustomSelect
                            placeholder=''
                            list={scoringItemsForSelect(index)}
                            value={importCaseScoringItems[index].scoreItemRef || ''}
                            handleChange={(item: ICustomSelectItem) => {
                              onScoringItemSelect(item, index);
                            }}
                          />
                          <SquareSecondaryButton
                            className={`ml-3 border-primary w-12 h-12 ${
                              importCaseScoringItems[index].position &&
                              importCaseScoringItems[index].position === CaseScoringFloat.Left
                                ? 'bg-primary text-white'
                                : 'text-primary hover:text-primary hover:bg-background-black'
                            }`}
                            icon='bi bi-justify-left'
                            onClick={() => {
                              importCaseScoringItems[index].position = CaseScoringFloat.Left;
                              setImportCaseScoringItems([...importCaseScoringItems]);
                            }}
                          />
                          <SquareSecondaryButton
                            className={`ml-3 border-primary w-12 h-12 ${
                              importCaseScoringItems[index].position &&
                              importCaseScoringItems[index].position === CaseScoringFloat.Right
                                ? 'bg-primary text-white'
                                : 'text-primary hover:text-primary hover:bg-background-black'
                            }`}
                            icon='bi bi-justify-right'
                            onClick={() => {
                              importCaseScoringItems[index].position = CaseScoringFloat.Right;
                              setImportCaseScoringItems([...importCaseScoringItems]);
                            }}
                          />
                          <SquareSecondaryButton
                            className={`ml-3 text-negative border-negative hover:text-negative hover:bg-background-black w-10 h-10`}
                            icon='bi bi-trash-fill'
                            onClick={() => {
                              importCaseScoringItems[index].state = ImportCaseScoringItemState.NONE;
                              importCaseScoringItems[index].scoreItemRef = undefined;
                              setImportCaseScoringItems([...importCaseScoringItems]);
                            }}
                          />
                        </div>
                      )}
                  </div>
                </div>
              );
            })}
        </div>
      </form>
    </div>
  );
};

export default ImportCaseTemplate;
