import React, { useEffect, useState } from 'react';

import { IEntitySelectItem } from './EntitySelect';

import CustomSelect from '../CustomSelect';
import SecondaryButton from '../SecondaryButton';
import SquareSecondaryButton from '../SquareSecondaryButton';

import { arrayMove } from './../../utils/index';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { CaseScoringFloat } from '../../common/enums';

interface IEntitySelectContent {
  hasChangePriority: boolean;
  label: string;
  notFoundLabel?: string;
  buttonLabel: string;
  placeholder: string;
  list: Array<IEntitySelectItem>;
  values: Array<string | any>;
  handleChange: Function;
  enableDragNDrop?: boolean;
  compareValuesBy: Function;
  valuePropToShow: Function;
  createItem: Function;
  showPositionButtons: boolean;
}

enum DIRECTION {
  UP,
  DOWN,
}

const EntitySelectContent = ({
  buttonLabel,
  notFoundLabel = 'NOT FOUND',
  list,
  values,
  placeholder,
  handleChange,
  enableDragNDrop,
  compareValuesBy,
  valuePropToShow,
  createItem,
  showPositionButtons = false,
}: IEntitySelectContent) => {
  const [items, setItems] = useState<IEntitySelectItem[]>([]);
  const [filteredList, setFilteredList] = useState<IEntitySelectItem[]>([]);

  useEffect(() => {
    let undefinedItemsCount = 0;
    const initialValues =
      values && values.length > 0
        ? values.map(i => {
            return { value: i, label: '', draggableId: i || `newItem${undefinedItemsCount++}` };
          })
        : [];
    setItems(initialValues);
  }, [values]);

  useEffect(() => {
    var filtered = list
      .filter(el => items.filter(e => compareValuesBy(e.value) == el.value).length == 0)
      .map(item => {
        return { ...item, value: typeof item.value === 'object' ? valuePropToShow(item.value) : item.value };
      });
    setFilteredList(filtered);
  }, [list, values]);

  const changePriorityItem = (currentPosition: number, direction: DIRECTION) => {
    const newPosition =
      direction == DIRECTION.DOWN
        ? currentPosition + 1 >= items.length
          ? 0
          : currentPosition + 1
        : currentPosition == 0
        ? items.length - 1
        : currentPosition - 1;
    const newItems = arrayMove(items, currentPosition, newPosition);

    setItems(newItems);
    handleChange(newItems);
  };

  const addItem = () => {
    const undefinedItemsCount = items.filter(item => !item.value).length;
    const newItems = [...items, { value: createItem(undefined), draggableId: `newItem${undefinedItemsCount}` }];

    setItems(newItems);
    handleChange(newItems);
  };

  const deleteItem = (i: IEntitySelectItem, j: number) => {
    const newItems = [...items];
    newItems.splice(j, 1);

    setItems(newItems);
    handleChange(newItems);
  };

  const setItemPosition = (j: number, position: CaseScoringFloat) => {
    const newItems = [...items];
    if (newItems[j].value && newItems[j].value.position) {
      newItems[j].value.position = position;
    }

    setItems(newItems);
    handleChange(newItems);
  };

  const handleChangeCustomSelect = (i: IEntitySelectItem, j: number) => {
    const newItems = [...items];
    newItems.splice(j, 1, { ...i, value: createItem(i.value) });

    setItems(newItems);
    handleChange(newItems);
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const newItems: IEntitySelectItem[] = Array.from(items);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    setItems(newItems);
    handleChange(newItems);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='EntitySelectContent'>
        {provided => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {[...items].map((item, index) => (
              <Draggable
                key={index.toString()}
                index={index}
                draggableId={index.toString()}
                isDragDisabled={!enableDragNDrop}
              >
                {provided => (
                  <div
                    className='mb-4'
                    key={'entitySelectItem_' + index}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <div className='flex items-center'>
                      <CustomSelect
                        placeholder={placeholder}
                        notFoundLabel={notFoundLabel}
                        list={list}
                        filteredList={filteredList}
                        handleChange={(item: IEntitySelectItem) => handleChangeCustomSelect(item, index)}
                        value={item.value && valuePropToShow(item.value)}
                      />
                      {showPositionButtons && (
                        <SquareSecondaryButton
                          className={`ml-3 border-primary w-12 h-12 ${
                            item.value && item.value.position && item.value.position === CaseScoringFloat.Left
                              ? 'bg-primary text-white'
                              : 'text-primary hover:text-primary hover:bg-background-black'
                          }`}
                          icon='bi bi-justify-left'
                          onClick={() => setItemPosition(index, CaseScoringFloat.Left)}
                        />
                      )}
                      {showPositionButtons && (
                        <SquareSecondaryButton
                          className={`ml-3 border-primary w-12 h-12 ${
                            item.value && item.value.position && item.value.position === CaseScoringFloat.Right
                              ? 'bg-primary text-white'
                              : 'text-primary hover:text-primary hover:bg-background-black'
                          }`}
                          icon='bi bi-justify-right'
                          onClick={() => setItemPosition(index, CaseScoringFloat.Right)}
                        />
                      )}
                      <SquareSecondaryButton
                        className={`ml-3 text-negative border-negative hover:text-negative hover:bg-background-black w-12 h-12`}
                        icon='bi bi-trash-fill'
                        onClick={() => deleteItem(item, index)}
                      />
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      {filteredList.length > 0 && (
        <div className='mb-4'>
          <SecondaryButton
            className='text-primary border-primary hover:text-primary hover:bg-background-black'
            title={buttonLabel}
            icon='bi bi-plus-circle'
            onClick={() => addItem()}
          />
        </div>
      )}
    </DragDropContext>
  );
};

export default EntitySelectContent;
