import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

// REDUX
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { toggleInstitution } from '../../../redux/actions';

// SERVICE
import { InstitutionService } from '../../../service/institution.service';
import { InstitutionResponseDto } from '../../../service/dto/institution.dto';

import { chooseInstitution } from '../../../utils/institution';
import { ICurrentInstitution } from '../../../common/interfaces';

import Alert from '../../../utils/alert';

const Switcher: React.FC = () => {
  const { t } = useTranslation();
  // Redux
  const dispatch = useAppDispatch();
  const currentInstitution = useAppSelector((state: any) => state.institution);

  // State
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<InstitutionResponseDto[]>([]);
  const [isMoreThanOne, setIfMoreThanOne] = useState(false);

  useEffect(() => {
    InstitutionService.my(
      (data: Array<InstitutionResponseDto>) => {
        setLoading(false);
        setList(data);
        setIfMoreThanOne(data.length > 1);
      },
      error => {
        setLoading(false);
        setList([]);
      },
    );
  }, [loading]);

  const handleSwitch = () => {
    setOpen(!open);
  };

  const handleChose = (institution: ICurrentInstitution) => {
    chooseInstitution(
      institution,
      () => {
        dispatch(toggleInstitution(institution));
        window.location.reload();
      },
      () => {
        Alert.error(t('errorMessages.switchInstitution'));
      },
    );
    setOpen(false);
  };

  const renderSwitchList = () => {
    return (
      <>
        <span className='cursor-pointer px-1' onClick={() => handleSwitch()}>
          {' '}
          <i className={`bi bi-caret-${open ? 'up' : 'down'}-fill`}></i>{' '}
        </span>
        {open && (
          <div className='scrollbar w-inherit max-h-43.5 flex flex-col absolute overflow-auto text-black bg-white py-1 px-0 shadow-dropdown rounded-lg z-10'>
            {list.map((i: any, j) => (
              <div className={`${currentInstitution.id == i.id && 'font-bold'}`} key={i.id}>
                <label className='dropdownItem block relative cursor-pointer select-none py-4 pr-4 pl-9 text-black'>
                  {i.name}
                  <input
                    type='radio'
                    name='institutions'
                    id={i.code}
                    className='cursor-pointer absolute w-0 h-0 opacity-0'
                    onChange={() => {
                      handleChose(i);
                    }}
                  />
                  <span
                    className={`absolute top-4.5 left-2.5 rounded-full border border-solid w-5 h-5 radioCheck ${
                      currentInstitution.id == i.id
                        ? 'radioCheck--active bg-primary border-primary'
                        : 'bg-transparent border-gray'
                    }`}
                  ></span>
                </label>
              </div>
            ))}
          </div>
        )}
      </>
    );
  };

  return (
    <div className='insBlock px-6'>
      <span className='font-bold text-lg text-black'> {currentInstitution.name} </span>
      {isMoreThanOne && renderSwitchList()}
    </div>
  );
};

export default Switcher;
