import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import Alert from '../../utils/alert';
import {SessionStatus, USER_ROLES} from '../../common/enums';
import AbstractModal from '../../components/AbstractModal';
import CustomMultiSelect, {ICustomSelectedItem} from '../../components/CustomMultiSelect';
import ModalContent from '../../components/ModalContent';
import SwitchButton from '../../components/SwitchButton';
import {CaseService} from '../../service/case.service';
import {CategoryService} from '../../service/category.service';
import {CaseResponseDto, CaseTemplateSummaryDto} from '../../service/dto/case.dto';
import {CategoryListItemDto} from '../../service/dto/category.dto';
import {SessionResponseDto} from '../../service/dto/session.dto';
import {SessionService} from '../../service/session.service';
import {getDateAndTimeDuration} from '../../utils';
import {setUserRole} from '../../utils/role';
import {IRouteComponent} from '../../routes';
import BookSessionContent from './BookSessionContent';
import ScheduleTable from './ScheduleTable';
import {schedulePageSize} from '../../common/constants';
import ReactPaginate from 'react-paginate';
import DefaultHeader from '../../components/DefaultHeader';
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {
  setOnlyMineSelector,
  setOnlyScheduledSelector,
  setScheduleCasesFilterSelector,
  setScheduleCategoriesFilterSelector
} from "../../redux/actions";
import CustomSelect, {ICustomSelectItem} from "../../components/CustomSelect";
import {AvailableCoachesResponseDto} from "../../service/dto/available-coaches.response.dto";
import { UserService } from '../../service/user.service';

enum COMPONENT_STATE {
  LIST,
  CANCEL,
  BOOK,
}

const ScheduleList = ({ currentUser, currentInstitution }: IRouteComponent) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState(COMPONENT_STATE.LIST);
  const [sessionList, setSessionList] = useState<SessionResponseDto[]>([]);
  const [caseList, setCaseList] = useState<ICustomSelectedItem[]>([]);
  const [caseData, setCaseData] = useState<CaseResponseDto>();
  const [certifiedCaseList, setCertifiedCaseList] = useState<ICustomSelectedItem[]>([]);
  const [roles, setRoles] = useState<USER_ROLES[]>([USER_ROLES.Trainee]);
  const { selectedCases }: { selectedCases: string[] } = useAppSelector((state: any) => state.scheduleCasesFilterReducer);
  const { selectedCategories }: {selectedCategories: string[]} = useAppSelector((state: any) => state.scheduleCategoriesFilterReducer);
  const { onlyScheduled }: { onlyScheduled: boolean } = useAppSelector((state: any) => state.onlyScheduledReducer);
  const { onlyMine }: { onlyMine: boolean } = useAppSelector((state: any) => state.onlyMineReducer);
  const [avaibleCoachFilter, setAvaibleCoachFilter] = useState('');
  const [selectedSession, setSelectedSession] = useState<SessionResponseDto>();
  const [categoryNames, setCategoryNames] = useState<ICustomSelectedItem[]>([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [coaches, setCoaches] = useState<ICustomSelectItem[]>([]);

  useEffect(() => {
    const userRoles = setUserRole({ currentUser, currentInstitution });
    setRoles(userRoles);
    setPage(0);
    serviceAvailableCoaches();
    serviceGetCaseList();
    serviceCategoryFetch();
    serviceGetCertifiedCases();
  }, []);

  useEffect(() => {
    serviceGetSessionCount();
    serviceGetSessionList();
  }, [onlyScheduled, selectedCases, selectedCategories, onlyMine, roles, avaibleCoachFilter]);

  useEffect(() => {
    serviceGetSessionList();
  }, [page]);

  const handleCategoryChange = (categoryIds: string[]) => {
    dispatch(setScheduleCategoriesFilterSelector({ selectedCategories: categoryIds }));
  };

  const serviceCategoryFetch = () => {
    CategoryService.list(
      (data: CategoryListItemDto[]) => {
        setCategoryNames(
          data.map(category => {
            return { value: category.id, label: category.name };
          }),
        );
      },
      () => {},
    );
  };

  const onPageChange = (event: { selected: number }) => {
    setPage(event.selected);
  };

  // SERVICE FETCH
  const serviceGetSessionList = () => {
    SessionService.list(
        getQueryData(),
      (data: Array<SessionResponseDto>) => {
        setSessionList(data);
      },
      error => {
        setSessionList([]);
      },
    );
  };

  const serviceAvailableCoaches = () => {
    UserService.availableCoaches(
        (_data: AvailableCoachesResponseDto[]) => {
          const coachList: ICustomSelectItem[] = _data
              .map(user => {
                return {
                  label: user.coachName,
                  value: user.coachId
                }
              });
          setCoaches(coachList)
        },
        error => {
          setCoaches([]);
        }
    )
  }

  const serviceGetSessionCount = () => {
    SessionService.count(
      getQueryData(),
      (data: number) => {
        setTotalPages(Math.floor((data - 1) / schedulePageSize) + 1);
      },
      error => {
        setTotalPages(0);
        setPage(0);
      },
    );
  };

  const getQueryData = () => {
    return {
      cases: selectedCases,
      onlyScheduled: onlyScheduled,
      onlyMine: onlyMine,
      role: roles,
      page: page,
      pageSize: schedulePageSize,
      categories: selectedCategories,
      coach: avaibleCoachFilter}
  }

  const handleCaseChange = (caseIds: string[]) => {
    dispatch(setScheduleCasesFilterSelector({ selectedCases: caseIds }));
  };

  const serviceGetCaseList = () => {
    CaseService.listPublished(
      (data: Array<CaseTemplateSummaryDto>) => {
        setCaseList(
          data.map(ct => {
            return { label: ct.name, value: ct.id };
          }),
        );
      },
      () => {},
    );
  };

  const serviceGetCertifiedCases = () => {
    CaseService.listCertified(
      { search: '', rolesFilter: roles },
      (data: Array<CaseResponseDto>) => {
        const modifiedCaseList = data.map(v => {
          return { value: v.id, label: v.name };
        });
        setCertifiedCaseList(modifiedCaseList);
      },
      () => {},
    );
  };

  const serviceGetGyId = (chosenCaseId: string) => {
    CaseService.getById(
      chosenCaseId,
      (data: CaseResponseDto) => {
        setCaseData(data);
      },
      error => {},
    );
  };

  // SERVICE CREATE
  const serviceBook = () => {
    SessionService.book(
      selectedSession!.id,
      (data: SessionResponseDto) => {
        serviceGetSessionList();
        setState(COMPONENT_STATE.LIST);
        Alert.success(t('successMessages.sessionBooked'));
      },
      error => {
        console.log(error);
        Alert.warning(t('errorMessages.sessionNotBooked'));
      },
    );
  };

  const serviceCancel = () => {
    SessionService.cancel(
      selectedSession!.id,
      (data: SessionResponseDto) => {
        serviceGetSessionList();
        setState(COMPONENT_STATE.LIST);
        Alert.success(t('successMessages.sessionCanceled'));
      },
      error => {
        console.log(error);
        Alert.warning(t('errorMessages.sessionNotCanceled'));
      },
    );
  };

  // SESSION ACTIONS
  const cancelSession = (id: string, number: number) => {
    const chosenSession = sessionList[number];
    setSelectedSession(chosenSession);
    setState(COMPONENT_STATE.CANCEL);
  };

  const bookSession = (id: string, number: number) => {
    const chosenSession = sessionList[number];
    serviceGetGyId(chosenSession.caseTemplateSummary.id);
    setSelectedSession(chosenSession);
    setState(COMPONENT_STATE.BOOK);
  };

  const detailsSession = (id: string, number: number) => {
    navigate('/session/details/' + id);
  };

  const resultSession = (id: string, number: number) => {
    navigate(`/session/result/${id}`);
  };

  const handleBookSessionSubmit = () => {
    serviceBook();
  };

  const handleCancelSessionSubmit = () => {
    serviceCancel();
  };

  const renderTime = (item: SessionResponseDto) => {
    const { startTime, endTime, monthLabel, day } = getDateAndTimeDuration(item);
    return `${monthLabel} ${day}, ${startTime} - ${endTime}`;
  };

  let button;
  if (roles.includes(USER_ROLES.SP)) {
    button = {
      title: t('buttons.createSessionSlot'),
      onClick: () => {
        navigate('/session/create/regular');
      },
      disabled: !certifiedCaseList || certifiedCaseList.length <= 0,
    };
  }
  return (
    <div className='rounded-lg bg-white my-6 shadow pb-2 mx-4 2xl:mx-6'>
      <DefaultHeader title={t('schedule.title')} primaryAction={button} />

      <div className='p-6'>
        <div className='flex mt-4'>
          <div className='w-4/12 mr-9'>
            <CustomMultiSelect
              placeholderAll='All Cases'
              placeholderSelected='Cases Selected'
              list={caseList}
              handleChange={handleCaseChange}
              values={selectedCases}
            />
          </div>

          <div className='flex ml-4 mr-9 w-4/12'>
            <CustomMultiSelect
              placeholderAll='All Categories'
              placeholderSelected='Categories Selected'
              list={categoryNames}
              handleChange={handleCategoryChange}
              values={selectedCategories}
            />
          </div>
          <div className='flex ml-4 mr-9 w-4/12'>
            <CustomSelect
                placeholder={'Coaches'}
                list={coaches}
                clearable={true}
                handleChange={(coach: ICustomSelectItem | null)=>{
                  setAvaibleCoachFilter(coach?.value ?? '');
                }}
            />
          </div>

          {roles.includes(USER_ROLES.Trainee) && (
            <div className='w-4/12 flex items-center'>
              <SwitchButton
                option={{
                  id: '',
                  title: 'Show Only Scheduled',
                  status: onlyScheduled,
                }}
                handleOption={o => {
                  dispatch(setOnlyScheduledSelector({ onlyScheduled: !o.status }));
                }}
              />
            </div>
          )}

          {roles.includes(USER_ROLES.Trainee) && (
            <div className='w-4/12 flex items-center'>
              <SwitchButton
                option={{
                  id: '',
                  title: 'Show Only Mine',
                  status: onlyMine,
                }}
                handleOption={o => {
                  dispatch(setOnlyMineSelector({ onlyMine: !o.status }));
                }}
              />
            </div>
          )}
        </div>
      </div>

      <ScheduleTable
        sessionList={sessionList}
        roles={roles}
        bookSession={bookSession}
        detailsSession={detailsSession}
        resultSession={resultSession}
        cancelSession={cancelSession}
        currentUser={currentUser}
        currentInstitution={currentInstitution}
      />
      {!!totalPages && (
        <ReactPaginate
          previousLabel={<i className='bi bi-chevron-left' />}
          breakLabel='...'
          nextLabel={<i className='bi bi-chevron-right' />}
          onPageChange={onPageChange}
          pageRangeDisplayed={5}
          pageCount={totalPages}
          containerClassName='pagination flex justify-center text-gray-dark'
          pageClassName='page-item'
          pageLinkClassName='page-link'
          previousClassName='page-item'
          previousLinkClassName='page-link'
          nextClassName='page-item'
          nextLinkClassName='page-link'
          activeClassName='active'
          breakClassName='page-item'
          breakLinkClassName='page-link'
          forcePage={page}
        />
      )}

      {state == COMPONENT_STATE.BOOK && (
        <AbstractModal
          label={selectedSession?.caseTemplateSummary.name}
          leftBtn={{
            label: t('buttons.cancel'),
            onClick: () => {
              setState(COMPONENT_STATE.LIST);
            },
          }}
          rightBtn={{
            label: t('buttons.bookThisSession'),
            icon: 'bi-check2-circle',
            onClick: () => {
              handleBookSessionSubmit();
            },
          }}
          cancelButton={{
            onClick: () => {
              setState(COMPONENT_STATE.LIST);
            },
          }}
        >
          <BookSessionContent session={selectedSession!} caseData={caseData} />
        </AbstractModal>
      )}

      {state == COMPONENT_STATE.CANCEL && (
        <AbstractModal
          position={{ width: 'w-1/4', left: 'left-1/3' }}
          label={t('modals.cancelSession')}
          leftBtn={{
            label: t('buttons.cancel'),
            onClick: () => {
              setState(COMPONENT_STATE.LIST);
            },
          }}
          rightBtn={{
            label: t('buttons.yesCancel'),
            onClick: () => {
              handleCancelSessionSubmit();
            },
          }}
          toDelete
        >
          <ModalContent
            entity='schedule'
            title=''
            subtitle={`${t('schedule.cancelModal.subtitleFirst')} “${selectedSession!.caseTemplateSummary.name}.” ${t(
              'schedule.cancelModal.subtitleSecond',
            )} “${renderTime(selectedSession!)}”.`}
          />
        </AbstractModal>
      )}
    </div>
  );
};

export default ScheduleList;
