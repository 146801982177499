import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// FORM
import { useForm } from '../../hooks/useForm';
import { signupRequestValidation } from '../../validation/auth.validation';

// SERVICE
import { AuthService } from '../../service/auth.service';
import { SignupRequestDto } from '../../service/dto/auth.dto';

import { InstitutionService } from '../../service/institution.service';
import { InstitutionResponseDto } from '../../service/dto/institution.dto';

// REDUX
import { actionSignup, signup } from '../../redux/actions';
import { useAppDispatch } from '../../redux/store';

// UI COMPONENTS
import PrimaryButton from '../../components/PrimaryButton';
import InputLabel from '../../components/InputLabel';
import TextInput from '../../components/TextInput';
import EmailInput from '../../components/EmailInput';
import PasswordInput from '../../components/PasswordInput';
import SecondaryButton from '../../components/SecondaryButton';
import SuccessButton from '../../components/SuccessButton';

enum COMPONENT_STATE {
  INIT,
  SUCCESS,
  FAIL,
  FAIL_INSTITUTION,
}

const Signuot: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { institutionCode, role } = useParams<{ institutionCode: string; role: string }>();
  const [state, setState] = useState(COMPONENT_STATE.INIT);
  const [institutionName, setinstitutionName] = useState('');
  const [readTerms, setReadTerms] = useState(false);
  const email = window.location.search.split('=')[1];

  useEffect(() => {
    InstitutionService.byCode(
      institutionCode!,
      (data: InstitutionResponseDto) => {
        setState(COMPONENT_STATE.INIT);
        setinstitutionName(data.name);
      },
      error => {
        setState(COMPONENT_STATE.FAIL_INSTITUTION);
      },
    );
  }, [institutionName]);

  const {
    handleSubmit,
    handleChange,
    data: payload,
    errors,
  } = useForm<SignupRequestDto>({
    validations: signupRequestValidation,

    onSubmit: () => {
      payload.institutionCode = institutionCode!;
      payload.role = 'role:' + role;
      if (email) {
        payload.email = email;
      }
      AuthService.signup(
        payload,
        () => {
          dispatch(actionSignup());
          setState(COMPONENT_STATE.SUCCESS);
        },
        () => {
          setState(COMPONENT_STATE.FAIL);
        },
      );
    },
  });

  const handleReadTerms = (checked: boolean) => {
    setReadTerms(checked);
  };

  return (
    <div className='flex justify-center'>
      <div className='w-112.5'>
        {(state === COMPONENT_STATE.INIT || state === COMPONENT_STATE.FAIL) && (
          <>
            <div className='text-center'>
              <div className='text-2xl font-bold mt-10'>{t('auth.signup.title')}</div>
              <div className='text-base my-4'>
                {t('auth.signup.invited')} <span className='font-bold'>{institutionName}.</span>
              </div>
              <div className='text-base mt-4'>{t('auth.signup.note')}</div>
            </div>
            <hr className='w-full h-px bg-black-divider border-0 my-6' />
            <form onSubmit={handleSubmit} className='w-full'>
              <div className='grid grid-cols-2 gap-6'>
                <div className='mb-6'>
                  <InputLabel label={t('inputLabels.firstName')} />
                  <TextInput
                    placeholder={t('placeholders.firstName')}
                    value={payload.firstName || ''}
                    handleChange={handleChange('firstName')}
                    error={errors.firstName}
                    required
                  />
                </div>
                <div className='mb-6'>
                  <InputLabel label={t('inputLabels.lastName')} />
                  <TextInput
                    placeholder={t('placeholders.lastName')}
                    value={payload.lastName || ''}
                    handleChange={handleChange('lastName')}
                    error={errors.lastName}
                    required
                  />
                </div>
              </div>
              <div className='mb-6'>
                <InputLabel label={t('inputLabels.email')} />
                <div className='text-gray-dark mb-2'>{t('inputLabels.emailToId')}</div>
                <EmailInput
                  placeholder={t('placeholders.email')}
                  value={payload.email || email || ''}
                  handleChange={handleChange('email')}
                  error={errors.email}
                  required
                />
              </div>
              <div className='mb-6'>
                <InputLabel label={t('inputLabels.createPassword')} />
                <div className='text-gray-dark mb-0'>{t('inputLabels.minPasswordLength')}</div>
                <div className='text-gray-dark mb-2'>{t('inputLabels.passwordRules')}</div>
                <PasswordInput
                  placeholder={t('placeholders.password')}
                  value={payload.password || ''}
                  handleChange={handleChange('password')}
                  error={errors.password}
                  required
                />
              </div>
              <div className='mb-12'>
                <InputLabel label={t('inputLabels.repeatPassword')} />
                <PasswordInput
                  placeholder={t('placeholders.repeatPassword')}
                  value={payload.password_confirm || ''}
                  handleChange={handleChange('password_confirm')}
                  error={errors.password_confirm}
                  required
                />
              </div>
              <div className='mb-12'>
                <label className='block cursor-pointer relative select-none	h-auto text-black pl-7 acceptTerms'>
                  <span className='font-bold'>{t('inputLabels.readAccept')}</span>{' '}
                  <Link className='text-primary underline' to='/terms-and-conditions' target='_blank' replace>
                    {t('auth.linkTo.generalTerms')}
                  </Link>
                  <input
                    type='checkbox'
                    className='absolute cursor-pointer opacity-0 h-0 w-0'
                    onChange={e => handleReadTerms(e.target.checked)}
                    checked={readTerms}
                  />
                  <span className='absolute top-0.5 left-0 h-5 w-5 bg-white border border-solid border-black-divider rounded checkmark'></span>
                </label>
              </div>
              <div className='flex justify-center'>
                <PrimaryButton title={t('buttons.createAccount')} type='submit' disabled={!readTerms} />
              </div>
            </form>

            {state == COMPONENT_STATE.FAIL && (
              <div className='rounded-lg text-center text-negative w-full border border-solid border-negative'>
                {t('errorMessages.failState')}
              </div>
            )}

            <hr className='w-full h-px bg-black-divider border-0 my-10' />

            <div className='text-base mb-10 text-center'>
              {t('errorMessages.haveAnAccount')}{' '}
              <Link className='text-primary underline' to='/signin' replace>
                {t('auth.linkTo.login')}
              </Link>
              .
            </div>
          </>
        )}

        {state === COMPONENT_STATE.FAIL_INSTITUTION && (
          <>
            <div className='text-center'>
              <div className='text-2xl font-bold my-10'>{t('errorMessages.smthIsWrong')}</div>
              <div className='text-base mb-10'>
                {t('errorMessages.linkNotExists')} <span className='font-bold'>{t('errorMessages.askForLink')}</span>.
              </div>
              <div className='mb-10'>
                <SecondaryButton
                  className='text-primary border-primary hover:text-primary hover:bg-background-black'
                  title={t('buttons.goToLogin')}
                  onClick={() => navigate('/signin')}
                />
              </div>
            </div>
          </>
        )}

        {state == COMPONENT_STATE.SUCCESS && (
          <>
            <div className='text-center'>
              <div className='text-2xl font-bold my-10'>{t('auth.signup.title')}</div>
              <div className='text-base mb-10'>
                {t('successMessages.profileCreated')} <span className='font-bold'>{payload.email}</span>
              </div>
              <div className='mb-10'>
                <SuccessButton label={t('buttons.sentEmail')} />
              </div>
              <div className='text-gray-dark mb-10'>{t('auth.forgotPassword.note')}</div>
            </div>
            <hr className='w-full h-px bg-black-divider border-0 mb-10' />
            <div className='text-base mb-10 text-center'>
              <Link className='text-primary underline' to='/signin' replace>
                {t('auth.linkTo.backLogin')}
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Signuot;
