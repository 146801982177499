import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IRouteComponent } from '../../routes';
import { InsightsService } from '../../service/insightsService';
import InsightsFilterFromUntil from './InsightsFilterFromUntil';
import { InsightsCasesCoachResponseDto, InsightsCasesDto } from '../../service/dto/insights.dto';
import DefaultHeader from '../../components/DefaultHeader';
import { setInsightsCasesFilterFromUntil } from '../../redux/actions';
import { addedDay } from '../../utils/funcs';
import { useAppDispatch, useAppSelector } from '../../redux/store';

const CaseInsights = ({ currentInstitution }: IRouteComponent) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const institutionId = currentInstitution.id;
  const [insights, setInsights] = useState<InsightsCasesDto>();
  const { from, until }: { from: Date; until: Date } = useAppSelector((state: any) => state.insightsCasesFilterReducer);
  const dispatch = useAppDispatch();

  useEffect(() => {
    serviceGetInsightsList();
  }, [from, until]);

  useEffect(() => {}, [insights]);

  // Service fetch
  const serviceGetInsightsList = () => {
    InsightsService.getCases(
      {
        from: from,
        until: addedDay(until),
        institutionId: institutionId,
        cases: id,
      },
      (data: InsightsCasesDto) => {
        setInsights(data);
      },
      () => {},
    );
  };

  const convertSecondsToTime = (e: number) => {
    const h = Math.floor(e / 3600)
        .toString()
        .padStart(2, '0'),
      m = Math.floor((e % 3600) / 60)
        .toString()
        .padStart(2, '0'),
      s = Math.floor(e % 60)
        .toString()
        .padStart(2, '0');
    return h + ':' + m + ':' + s;
  };

  const scoringItem = (item: Map<string, { name: string; number: number }>) => {
    const s: Array<[string, { name: string; number: number }]> = [];
    item.forEach((value, index) => {
      s.push([index, value]);
    });
    return (
      <table className='dataTable table-fixed w-full bg-white px-4'>
        <thead>
          <tr className='font-bold bg-ghostwhite border border-solid border-gray-lighter text-center'>
            <td className='text-gray-dark text-lg font-bold py-4 '>{t('table.scoringItem')}</td>
            <td className='text-gray-dark text-lg py-4'>{t('table.averageScore')}</td>
          </tr>
        </thead>
        <tbody>
          {s.map(([key, value]) => {
            return (
              <tr className='border border-solid border-black-divider text-center' key={key}>
                <td className='text-gray-dark py-4 text-lg border-r'>{value.name}</td>
                <td className='text-gray-dark py-4 text-lg'>{Math.round(value.number * 100)}%</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };
  return (
    <div className='rounded-lg bg-white my-6 shadow pb-2 mx-4 2xl:mx-6'>
      {insights && <DefaultHeader title={t('sidebar.insightsMenu') + ' of ' + insights.caseName} />}
      <InsightsFilterFromUntil
        from={from}
        until={until}
        styleFrom={'w-96'}
        onChange={({ from, until }) => {
          dispatch(setInsightsCasesFilterFromUntil({ from, until }));
        }}
      />
      {insights && (
        <div className='m-5 py-2 mt-0'>
          <div className='m-5 flex justify-between border-b-2 text-gray-dark'>
            <i className='bi bi-check-lg text-2xl py-3 pl-3' />
            <p className='font-bold py-4 w-1/5 flex-auto pl-5'>{t('insights.cases.sessionsCompleted')}</p>
            <p className='font-bold py-4 w-1/5 '>{insights.sessionsCompleted}</p>
          </div>
          <div className='m-5 flex justify-between border-b-2 text-gray-dark'>
            <i className='bi bi-trophy text-2xl py-3 pl-3' />
            <p className='font-bold py-4 w-1/5 flex-auto pl-5'>{t('insights.cases.averageTotalScoreOverall')}</p>
            <p className='font-bold py-4 w-1/5 '>{Math.round(insights.averageScore * 100) + '%'}</p>
          </div>
          <div className='m-5 flex justify-between border-b-2 text-gray-dark'>
            <i className='bi bi-hourglass text-2xl py-3 pl-3' />
            <p className='font-bold py-4 w-1/5 flex-auto pl-5'>{t('insights.cases.averageSessionsDuration')}</p>
            <p className='font-bold py-4 w-1/5 '>{convertSecondsToTime(insights.averageSessionsDuration)}</p>
          </div>
          {insights.coachToAverageScore.length != 0 && (
            <div className='text-lg font-bold py-4 text-center border border-b-0 mt-10'>
              {t('insights.cases.averageTotalScoreByCoach')}
            </div>
          )}
          {insights.coachToAverageScore.length != 0 && (
            <table className='dataTable table-fixed w-full bg-white px-4'>
              <thead>
                <tr className='font-bold bg-ghostwhite border border-solid border-gray-lighter text-center'>
                  <td className='text-gray-dark text-lg font-bold py-4 '>{t('table.coach')}</td>
                  <td className='text-gray-dark text-lg py-4'>{t('table.averageScore')}</td>
                </tr>
              </thead>
              <tbody>
                {insights.coachToAverageScore.map((coachToAverageScore: InsightsCasesCoachResponseDto) => {
                  return (
                    <tr
                      className='border border-solid border-black-divider text-center'
                      key={coachToAverageScore.coach.id}
                    >
                      <td className='text-gray-dark py-4 text-lg border-r'>{coachToAverageScore.coach.firstName}</td>
                      <td className='text-gray-dark py-4 text-lg'>
                        {Math.round(coachToAverageScore.totalAverageScore * 100)}%
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          {insights.averageScoringItem.size != 0 && (
            <div className='text-lg font-bold py-4 text-center border border-b-0 mt-10'>
              {t('insights.cases.averageMark')}
            </div>
          )}
          {insights.averageScoringItem.size != 0 && scoringItem(insights.averageScoringItem)}
        </div>
      )}
    </div>
  );
};

export default CaseInsights;
