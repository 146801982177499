import React from 'react';
import { useTranslation } from 'react-i18next';

// SERVICES
import { SessionResponseDto } from '../../service/dto/session.dto';
import { CaseResponseDto } from '../../service/dto/case.dto';

// UI COMPONENTS
import InputLabel from '../../components/InputLabel';

// UTILS
import { getDateAndTimeDuration } from '../../utils';
import { SessionType } from '../../common/enums';

interface IBookSessionContentProps {
  session: SessionResponseDto;
  caseData?: CaseResponseDto;
}

const BookSessionContent = ({ session, caseData }: IBookSessionContentProps) => {
  const { t } = useTranslation();

  const renderTime = () => {
    const { startTime, endTime, year, monthLabel, day } = getDateAndTimeDuration(session);
    return `${day} ${monthLabel} ${year}, ${startTime} - ${endTime}`;
  };

  return (
    <div className='grid grid-cols-1'>
      <div className='grid grid-cols-3'>
        {session.type === SessionType.RegularSession && (
          <div className='col-span-1'>
            <InputLabel label={t('inputLabels.timeAndDate')} />
            <p className='font-bold text-black'>{renderTime()}</p>
          </div>
        )}
        <div className='col-span-1'>
          <InputLabel label={t('inputLabels.sessionCoach')} />
          <p className='text-black mb-6'>{session.coach.firstName + ' ' + session.coach.lastName}</p>
        </div>
        <div className='col-span-1'>
          {caseData && (
            <>
              <InputLabel label={t('inputLabels.sessionLength')} />
              <p className='text-black mb-6'>
                {t('inputLabels.interview')} {session.caseTemplateSummary.interview_duration} {t('table.min')}.{' '}
                {t('inputLabels.feedback')} {session.caseTemplateSummary.feedback_duration} {t('table.min')}.
              </p>
            </>
          )}
        </div>
      </div>
      <div>
        {caseData && (
          <>
            <InputLabel label={t('inputLabels.description')} />
            <p className='text-black'>{caseData.description}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default BookSessionContent;
