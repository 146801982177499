import { AppDispatchType, RootState } from '../../../redux/store';
import { CertificationService } from '../../../service/certification.service';
import { actionCertificationRoomUpdate } from '../actions';

export const thunkCertificationRoomJoin = (id: string): any => {
  return async (dispatch: AppDispatchType, getState: () => RootState): Promise<void> => {
    dispatch(
      actionCertificationRoomUpdate({
        certification: {
          type: 'LOADING',
          startTime: new Date(),
        },
        progress: {
          type: 'EMPTY',
        },
      }),
    );

    CertificationService.join(id)
      .then(response => {
        dispatch(
          actionCertificationRoomUpdate({
            certification: {
              type: 'SUCCESS',
              result: {
                ...response,
                caseTemplate: {
                  ...response.caseTemplate,
                  scoring_items_positions: new Map(Object.entries(response.caseTemplate.scoring_items_positions)),
                },
              },
            },
            progress: {
              type: 'SUCCESS',
              result: response.sessionProgress,
            },
          }),
        );
      })
      .catch(error => {
        dispatch(
          actionCertificationRoomUpdate({
            certification: {
              type: 'ERROR',
              error,
            },
          }),
        );
      });
  };
};
