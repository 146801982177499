import React from 'react';
import { NavLink } from 'react-router-dom';

import logo from '../../../assets/icons/logo.svg';

const Header = () => (
  <div className='text-center'>
    <NavLink to={'/dashboard'}>
      <img src={logo} alt='logo' className='inline' />
    </NavLink>
  </div>
);

export default Header;
