import React, { useEffect, useState } from 'react';

// DTO
import { CaseResponseDto } from '../service/dto/case.dto';
import { ScoringResponseDto } from '../service/dto/scoring.dto';
import { ScoreResultDto } from '../service/dto/session.dto';

// UI
import InputLabel from './InputLabel';
import RatingMarks from './RatingMarks';

// UTILS
import { getScoreValuesLength } from '../utils';
import { CaseScoringFloat } from '../common/enums';

interface IScoringMarksListProps {
  caseData: CaseResponseDto;
  scoreResult: ScoreResultDto;
  handleScore: (id: string, value: number) => void;
}

const ScoringMarksList = ({ caseData, scoreResult, handleScore }: IScoringMarksListProps) => {
  const [columns, setColumns] = useState<{ left: Array<ScoringResponseDto>; right: Array<ScoringResponseDto> }>({
    left: new Array<ScoringResponseDto>(),
    right: new Array<ScoringResponseDto>(),
  });

  useEffect(() => {
    const newColumns = { left: new Array<ScoringResponseDto>(), right: new Array<ScoringResponseDto>() };
    const _get = (target: any, value: string) => {
      return target.get ? target.get(value) : target[value];
    };

    newColumns.left = caseData.scoring_items.filter(
      sI => _get(caseData.scoring_items_positions, sI.id) === CaseScoringFloat.Left,
    );
    newColumns.right = caseData.scoring_items.filter(
      sI => _get(caseData.scoring_items_positions, sI.id) === CaseScoringFloat.Right,
    );
    caseData.scoring_items.forEach(sI => {
      const position = _get(caseData.scoring_items_positions, sI.id);
      if (position === CaseScoringFloat.None) {
        if (newColumns.right.length < newColumns.left.length) {
          newColumns.right.push(sI);
        } else {
          newColumns.left.push(sI);
        }
      }
    });
    setColumns(newColumns);
  }, [caseData]);

  return (
    <div className='h-122.5 overflow-y-auto overflow-x-hidden'>
      <div className='mb-3 text-lg'>
        <InputLabel
          label={'Scoring Items'}
          additionalLabel={
            caseData && `${getScoreValuesLength(scoreResult) ?? 0}/${caseData.scoring_items?.length ?? 0}`
          }
        />
      </div>
      <hr className='w-full h-px bg-black-divider border-0 mb-3' />
      <div className='flex w-full flex-row flex-wrap'>
        <div className='flex w-1/2 flex-col min-w-250'>
          {columns.left.map((data: ScoringResponseDto) => (
            <div className='flex' key={data.id + data.name}>
              <div className='flex flex-row justify-between align-center mb-1'>
                <RatingMarks
                  data={data}
                  handleScore={handleScore}
                  savedScore={scoreResult[data.id] ? scoreResult[data.id].value : undefined}
                />
              </div>
            </div>
          ))}
        </div>
        <div className='flex w-1/2 flex-col min-w-250'>
          {columns.right.map((data: ScoringResponseDto) => (
            <div className='flex w-1/2' key={data.id + data.name}>
              <div className='flex flex-col lg:flex-row justify-between align-center mb-1'>
                <RatingMarks
                  data={data}
                  handleScore={handleScore}
                  savedScore={scoreResult[data.id] ? scoreResult[data.id].value : undefined}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ScoringMarksList;
