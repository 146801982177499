import React from 'react';

interface ISquareSecondaryButtonProps {
  className: string;
  icon: string;
  onClick?: () => void;
}

const SquareSecondaryButton = ({ className, icon, onClick }: ISquareSecondaryButtonProps) => {
  return (
    <button
      type='button'
      className={`flex items-center justify-center border border-solid rounded-lg font-bold text-tiny select-none p-4 bg-transparent w-12 h-12 ${className}`}
      onClick={onClick}
    >
      <i className={`${icon} text-2xl`}></i>
    </button>
  );
};

export default SquareSecondaryButton;
