import React, {useState} from "react";
import {useTranslation} from "react-i18next";

interface Language {
    nativeName: string;
}

interface Languages {
    [key: string]: Language;
}

const lngs: Languages = {
    en: { nativeName: 'En' },
    de: { nativeName: 'De'}
}
const LangSwitcher = () => {
    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(false);

    const handleSwitch = () => {
        setOpen(!open);
    };

    return (
        <div className='h-22 flex flex-row-reverse border-l border-solid border-black-divider bg-white'>
            <div className='h-inherit min-w-7/25 relative w-auto flex items-center px-4'>
                <div className='mr-2 capitalize'>
                    {lngs[i18n.language].nativeName}
                </div>
                <div className='cursor-pointer' onClick={() =>handleSwitch()}>
                    {open ? <i className='bi bi-chevron-bar-up text-2xl' /> : <i className='bi bi-chevron-down text-2xl' />}
                </div>
                {open && (
                    <div className='top min-w-full absolute flex flex-col bg-white text-black rounded-lg z-10 -top-2.5 right-0 py-1 px-0 mt-25 shadow-dropdown'>
                        {Object.keys(lngs).map((lng) => (
                            <button
                                key={lng}
                                onClick={() => i18n.changeLanguage(lng).then(() => handleSwitch()) }
                                className='navLink hover:text-primary flex text-gray-dark no-underline font-bold cursor-pointer transition-all duration-500 ease-out px-8 my-2'>
                                {lngs[lng].nativeName}
                            </button>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default LangSwitcher;