import { UserResponseDto } from './user.dto';
import { CaseTemplateSummaryDto } from './case.dto';
import { SessionHistoryResponseDto } from './session.dto';

export enum SessionEvaluationCaseType {
  CaseEvaluationScoringItems = 'session-evaluation-case-evaluation-type:scoringItems',
  CaseEvaluationMaterial = 'session-evaluation-case-evaluation-type:material',
  CaseEvaluationOverall = 'session-evaluation-case-evaluation-type:overall',
}

export enum SessionEvaluationTechnicalType {
  TechnicalEvaluationVideo = 'session-evaluation-technical-type:video',
  TechnicalEvaluationAudio = 'session-evaluation-technical-type:audio',
}

export enum SessionEvaluationType {
  CoachEvaluation = 'session-evaluation-type:coach',
  TraineeEvaluation = 'session-evaluation-type:trainee',
}

export interface SessionEvaluationCase {
  type: SessionEvaluationCaseType;
  value: number;
}

export interface SessionEvaluationTechnical {
  type: SessionEvaluationTechnicalType;
  value: number;
}

export interface SessionEvaluationCreateRequest {
  type: SessionEvaluationType;
  caseTemplate: string | CaseTemplateSummaryDto;
  session: string | SessionHistoryResponseDto;
  trainee?: string | UserResponseDto;
  coach?: string | UserResponseDto;
  comment: string;
  technical: Array<SessionEvaluationTechnical>;
  coachOverall?: number;
  coachComment?: string;
  traineeOverall?: number;
  traineeComment?: string;
  caseComment: string;
  generalComment: string;
  caseEvaluation: Array<SessionEvaluationCase>;
}

export interface SessionEvaluationResponse extends SessionEvaluationCreateRequest {
  id: string;
  author: string | UserResponseDto;
}
