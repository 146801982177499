import { ApiBaseService, queryToUrl } from './api.service';
import { UserResponseDto, UpdateUserRequestDto, UpdateUserByAdminRequestDto } from './dto/user.dto';

import { SearchQueryDto } from './dto/general';
import { SendUserInvitesDto } from './dto/send-user-invites.dto';
import { AvailableCoachesResponseDto } from './dto/available-coaches.response.dto';

export class UserService {
  public static basePath() {
    if (!process.env.API_URL_USER) {
      throw new Error('API_URL_USER does not exist');
    }
    return process.env.API_URL_USER;
  }

  public static update(
    id: string,
    payload: UpdateUserRequestDto,
    handleSuccess: (_data: UserResponseDto) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = UserService.basePath() + id;

    ApiBaseService.Patch(
      uriPath,
      payload,
      (_code: number, _data: UserResponseDto) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static updateByAdmin(
    id: string,
    payload: UpdateUserByAdminRequestDto,
    handleSuccess: (_data: UserResponseDto) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = UserService.basePath() + 'update/' + id;

    ApiBaseService.Patch(
      uriPath,
      payload,
      (_code: number, _data: UserResponseDto) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static removeUserFromCurrentInstitution(
    id: string,
    payload: {},
    handleSuccess: () => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = UserService.basePath() + 'remove/' + id;
    ApiBaseService.Patch(
      uriPath,
      payload,
      (_code: number, _data: void) => {
        handleSuccess();
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static switchInstitutionContext(
    institutionId: string,
    handleSuccess: (_data: UserResponseDto) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = UserService.basePath() + 'context/institution';

    ApiBaseService.Post(
      uriPath,
      { institutionId: institutionId },
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static list(
    query: SearchQueryDto,
    handleSuccess: (_data: Array<UserResponseDto>) => void,
    handleError: (err: Error) => void,
  ) {
    ApiBaseService.Post(
      UserService.basePath(),
      query,
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static count(
    query: SearchQueryDto,
    handleSuccess: (_data: number) => void,
    handleError: (err: Error) => void,
  ) {
    ApiBaseService.Post(
      UserService.basePath() + 'count',
      query,
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static getById(
    id: string,
    handleSuccess: (_data: UserResponseDto) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = UserService.basePath() + id;

    ApiBaseService.Get(
      uriPath,
      {},
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static delete(id: string, handleSuccess: () => void, handleError: (err: Error) => void) {
    const uriPath = UserService.basePath() + id;
    ApiBaseService.Delete(
      uriPath,
      (_code: number, _data: void) => {
        handleSuccess();
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static sendUsersInvites(
    sendUserInvitesDto: SendUserInvitesDto,
    handleSuccess: () => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = UserService.basePath() + 'invite';
    ApiBaseService.Post(
      uriPath,
      sendUserInvitesDto,
      (_code: number, _data: void) => {
        handleSuccess();
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static availableCoaches(
    handleSuccess: (_data: AvailableCoachesResponseDto[]) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = UserService.basePath() + 'availableCoaches';

    ApiBaseService.Post(
      uriPath,
      {},
      (_code: number, data: any) => {
        handleSuccess(data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }
}
