import React from 'react';

// IMG
import deleteUser from '../assets/icons/deleteUser.svg';
import deleteCase from '../assets/icons/deleteCase.svg';
import deleteScoring from '../assets/icons/deleteScoring.svg';
import deleteInstitution from '../assets/icons/deleteInstitution.svg';
import cancelSession from '../assets/icons/cancelSession.svg';
import streamConnection from '../assets/icons/connection.svg';
import streamFinish from '../assets/icons/finish.svg';

interface IDeleteModalContent {
  entity: string;
  title: string;
  subtitle: string;
}

const imgByEntity = new Map([
  ['user', deleteUser],
  ['case', deleteCase],
  ['scoring', deleteScoring],
  ['institution', deleteInstitution],
  ['schedule', cancelSession],
  ['streamConnection', streamConnection],
  ['streamFinish', streamFinish],
]);

const ModalContent = ({ entity, title, subtitle }: IDeleteModalContent) => {
  return (
    <>
      <div className='flex justify-center mb-12'>
        <img src={imgByEntity.get(entity)} />
      </div>
      <p className='text-center mb-4'>{title}</p>
      <p className='text-center text-gray-light mb-2'>{subtitle}</p>
    </>
  );
};

export default ModalContent;
