import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Alert from '../../utils/alert';
import { actionSetDevices, actionSetSessionConfig, setDevices, setSessionConfig } from '../../redux/actions';
import { DeviceState } from '../../redux/types';
import { IRouteComponent } from '../../routes';
import { CaseService } from '../../service/case.service';
import { CaseResponseDto } from '../../service/dto/case.dto';
import { SessionResponseDto } from '../../service/dto/session.dto';
import { SessionService } from '../../service/session.service';
import { retryOperation } from '../../utils/funcs';
import VideoChat from './Video/VideoChat';
import { isMobile } from 'react-device-detect';
import { MediaDeviceDto } from '../../service/dto/MediaDevice.dto';
import { useAppDispatch, useAppSelector } from '../../redux/store';

const SessionStream = ({ currentUser }: IRouteComponent) => {
  const { isLoaded: isSessionLoaded }: { session: SessionResponseDto; isLoaded: boolean } = useAppSelector(
    (state: any) => state.sessionConfig,
  );
  const deviceState = useAppSelector((state: any) => state.devicesConfig) as DeviceState;
  const socketFeedbackConfig = useAppSelector((state: any) => state.socketFeedbackConfig);
  const dispatch = useAppDispatch();
  const { id, shareToken } = useParams<{ id: string; shareToken: string }>();
  const { t } = useTranslation();
  const [caseData, setCaseData] = useState<CaseResponseDto>();
  const [devicesFetched, setDevicesFetched] = useState<boolean>(false);

  const serviceFetchSession = (sessionId: string) => {
    const getSessionToPromise = () =>
      new Promise<SessionResponseDto>((resolve, reject) => SessionService.getById(sessionId, resolve, reject)).then(
        (data: SessionResponseDto) => {
          dispatch(actionSetSessionConfig({ start: true, session: data }));
          caseServiceDataFetch(data.caseTemplateSummary.id);
        },
      );
    retryOperation(getSessionToPromise).catch(console.error);
  };

  const caseServiceDataFetch = (caseId: string) => {
    CaseService.getById(
      caseId,
      (data: CaseResponseDto) => {
        setCaseData({ ...data, scoring_items_positions: new Map(Object.entries(data.scoring_items_positions)) });
      },
      () => {
        console.error('issue retrieving the session');
      },
    );
  };

  const getDeviceList = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(async (result: MediaStream) => {
        updateDeviceList(result);
      })
      .catch(e => {
        console.error(e);
        setDevicesFetched(true);
        Alert.error(t('errorMessages.devicesPermissionDenied'));
      });
  };

  const updateDeviceList = async (result?: MediaStream) => {
    const devices: Array<MediaDeviceInfo> = await navigator.mediaDevices.enumerateDevices();
    if (result) {
      result.getTracks().forEach(t => t.stop());
    }
    let newDevices: MediaDeviceDto[] = devices.map(device => {
      return {
        label: device.label.replace(/\([\d\w]{4}:[\d\w]{4}\)/, '').trim(),
        deviceId: device.deviceId,
        kind: device.kind,
        active: false,
        groupId: device.groupId,
        enabled: true,
        isMobile: false,
      };
    });

    if (isMobile) {
      newDevices = newDevices.filter(d => d.kind !== 'videoinput');
      newDevices.push({
        label: t('devices.frontCamera'),
        deviceId: '1',
        kind: 'videoinput' as MediaDeviceKind,
        active: true,
        groupId: '',
        enabled: true,
        isMobile: true,
        facingMode: 'user',
      });
      newDevices.push({
        label: t('devices.backCamera'),
        deviceId: '2',
        kind: 'videoinput' as MediaDeviceKind,
        active: false,
        groupId: '',
        enabled: true,
        isMobile: true,
        facingMode: 'environment',
      });
    }

    if (deviceState?.devices != null && deviceState.devices.length) {
      for (const device of newDevices) {
        const storedDevice = deviceState.devices.find(x => x.deviceId == device.deviceId);
        if (storedDevice) {
          device.active = storedDevice.active;
        }
      }
    }

    const tryFindAndActivate = <T extends MediaDeviceDto>(collection: T[], query: (entity: T) => boolean): void => {
      const found = collection.find(query);
      if (found) {
        found.active = true;
      }
    };

    if (!newDevices.find(x => x.kind == 'audiooutput' && x.active)) {
      tryFindAndActivate(newDevices, device => device.kind === 'audiooutput');
    }

    if (!newDevices.find(x => x.kind == 'audioinput' && x.active)) {
      tryFindAndActivate(newDevices, device => device.kind === 'audioinput');
    }

    if (!newDevices.find(x => x.kind == 'videoinput' && x.active)) {
      tryFindAndActivate(newDevices, device => device.kind === 'videoinput');
    }
    dispatch(actionSetDevices({ devices: newDevices }));
    setDevicesFetched(true);
  };

  // Fetch
  useEffect(() => {
    if (!shareToken) {
      getDeviceList();
      navigator.mediaDevices.ondevicechange = event => {
        updateDeviceList();
      };
    }
    serviceFetchSession(id!);
  }, [socketFeedbackConfig]);

  return (
    <div className='my-6 mx-4 2xl:mx-6'>
      {isSessionLoaded && caseData && (!!shareToken || devicesFetched) && (
        <VideoChat caseData={caseData} currentUser={currentUser} shareToken={shareToken!} />
      )}
    </div>
  );
};

export default SessionStream;
