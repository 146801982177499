import React, { Suspense } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import WebSocketProvider from './context/socket';
import Alert from '../src/utils/alert';
import { PrivateRoute, PublicRoute, privateRouteConfigurations, publicRouteConfigurations } from './routes';
import './i18n/config';
import LayoutPrivate from './components/layout/private';
import LayoutPublic, { LayoutPublicOutlet } from './components/layout/public';
import PageNotFound from './module/static/PageNotFound';
import { useAppSelector } from './redux/store';

Alert.init();

const App = () => {
  return (
    <WebSocketProvider>
      <Suspense fallback='loading'>
        <HashRouter>
          <Routes>
            {publicRouteConfigurations.map(({ path, element: Element }, i) => {
              return (
                <Route key={i} element={<PublicRoute path={path} />}>
                  <Route path={path} element={<LayoutPublic children={<Element />} />} />
                </Route>
              );
            })}
            {privateRouteConfigurations.map(({ path, element: Element, roles }, i) => {
              const { user: currentUser } = useAppSelector((state: any) => state.auth);
              const currentInstitution = useAppSelector((state: any) => state.institution);
              return (
                <Route key={i} element={<PrivateRoute roles={roles ?? []} />}>
                  <Route
                    path={path}
                    element={
                      <LayoutPrivate
                        children={<Element currentUser={currentUser} currentInstitution={currentInstitution} />}
                      />
                    }
                  />
                </Route>
              );
            })}
            <Route element={<LayoutPublicOutlet />}>
              <Route path={'*'} element={<PageNotFound />} />
            </Route>
          </Routes>
        </HashRouter>
      </Suspense>
    </WebSocketProvider>
  );
};

export default App;
