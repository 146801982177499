import React, { useEffect, useState } from 'react';
import {
  ScoreResultDto,
  SessionRecordResponseDto,
  SessionResponseDto,
  TeachableMomentDto,
} from '../../../service/dto/session.dto';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import RecordPlayer from '../../../components/RecordPlayerModal/RecordPlayer';
import { SessionEvaluationState, SessionResultState } from '../../../redux/types';
import SessionEvaluate from '../SessionEvaluate';
import { SessionEvaluationResponse } from '../../../service/dto/session-evaluate.dto';
import { useAppSelector } from '../../../redux/store';

export enum RecordTabType {
  RecordType = 'record-tab-type:record',
  SessionEvaluate = 'record-tab-type:session-evaluate',
  OtherType = 'record-tab-type:other',
}

export interface TabContent {
  title: string;
  type: RecordTabType;
  data: RecordTabContentData | SessionEvaluationTabContentData;
}

export interface RecordTabContentData {
  isReadonly: boolean;
  record?: SessionRecordResponseDto;
  score?: ScoreResultDto;
  teachableMoments?: TeachableMomentDto[];
  error?: string;
}

export interface SessionEvaluationTabContentData {
  isCoach: boolean;
  session: SessionResponseDto;
  sessionEvaluate: SessionEvaluationResponse;
}

const RecordsTabs = ({ tabs, onTabSelected }: { tabs: TabContent[]; onTabSelected?: Function }) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [lastTimeManipulation, setLastTimeManipulation] = useState<number | undefined>();
  const [playing, setPlaying] = useState<boolean | undefined>();
  const [unMute, setUnMuting] = useState<boolean | undefined>();
  const sessionResult = useAppSelector((state: any) => state.sessionResultReducer) as SessionResultState;
  const sessionEvaluateState: SessionEvaluationState = useAppSelector((state: any) => state.sessionEvaluationReducer);

  if (!tabs || !tabs.length) {
    return null;
  }

  useEffect(() => {
    if (onTabSelected) {
      onTabSelected(tabs[selectedTabIndex]);
    }
  }, [selectedTabIndex]);

  useEffect(() => {
    if (sessionResult.sessionRecord.coachingSession.setTimeManipulation != lastTimeManipulation) {
      setLastTimeManipulation(sessionResult.sessionRecord.coachingSession.setTimeManipulation);
    }
    if (sessionResult.sessionRecord.coachingSession.setPlaying != playing) {
      setPlaying(sessionResult.sessionRecord.coachingSession.setPlaying);
    }
    if (sessionResult.sessionRecord.coachingSession.setMuted != unMute) {
      setUnMuting(sessionResult.sessionRecord.coachingSession.setMuted);
    }
  }, [sessionResult]);

  return (
    <Tabs selectedIndex={selectedTabIndex} onSelect={setSelectedTabIndex}>
      <TabList
        className={'flex justify-start space-x-8 text-black border-b border-separate text-xl select-none font-bold'}
      >
        {tabs.map(({ title, type }, index) => {
          const highlightRed =
            type === RecordTabType.OtherType &&
            !(sessionEvaluateState.sessionEvaluation as SessionEvaluationResponse).id &&
            selectedTabIndex !== index;
          return (
            <Tab className={`cursor-pointer ${selectedTabIndex == index ? 'text-primary' : ''}`} key={title + index}>
              <div className={highlightRed ? 'text-negative-darker' : ''}>{title}</div>
              <div className={'h-4'} />
              <div
                className={`h-1 w-full bg-primary rounded-full ${selectedTabIndex == index ? '' : 'opacity-0'}`}
              ></div>
            </Tab>
          );
        })}
      </TabList>
      <hr className={'w-full mb-4 border-separate'} />
      {tabs.map(({ type, data }, index) => {
        if (type === RecordTabType.RecordType) {
          const recordData = data as RecordTabContentData;
          if (recordData.record) {
            return (
              <TabPanel key={`record-tab-panel-${index}`}>
                <RecordPlayer
                  record={recordData.record}
                  scoreResult={recordData.score}
                  teachableMoments={recordData.teachableMoments}
                  download={{ url: recordData.record?.mediaUri ?? '' }}
                  error={recordData.error}
                  isReadonly={recordData.isReadonly}
                  watchManipulationInfo={{
                    timeInSeconds: lastTimeManipulation ?? 0,
                    isMuted: unMute ?? false,
                    isPlaying: playing ?? false,
                  }}
                />
              </TabPanel>
            );
          } else {
            return <div></div>;
          }
        }
        if (type === RecordTabType.SessionEvaluate) {
          const sessionEvaluateData = data as SessionEvaluationTabContentData;
          if (sessionEvaluateData.session) {
            return (
              <TabPanel key={`record-tab-panel-${index}`}>
                <SessionEvaluate
                  isCoach={sessionEvaluateData.isCoach}
                  session={sessionEvaluateData.session}
                  sessionEvaluate={sessionEvaluateData.sessionEvaluate}
                ></SessionEvaluate>
              </TabPanel>
            );
          }
        }
      })}
    </Tabs>
  );
};

export default RecordsTabs;
