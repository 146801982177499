import React from 'react';

interface ITagProps {
  type: string;
  label: string;
}

export enum TagType {
  Blue = 'blueTag',
  Red = 'redTag',
  Green = 'greenTag',
  Yellow = 'yellowTag',
  NA = 'naTag',
  Tooltip = 'toolTipTag',
}
interface TagStyle {
  [type: string]: string;
}

const tagStyles: TagStyle = {
  [TagType.Blue]: 'text-primary-dark bg-primary-lightest border-primary-lightest',
  [TagType.Red]: 'text-negative-darker bg-negative-lightest border-negative-lightest',
  [TagType.Green]: 'text-positive-darker bg-positive-lightest border-positive-lightest',
  [TagType.Yellow]: 'text-warning-darker bg-warning-lightest border-warning-lightest',
  [TagType.NA]: 'text-gray-light border-gray-light',
  [TagType.Tooltip]: 'text-white bg-gray-dark',
};

const Tag = ({ label, type }: ITagProps) => {
  return (
    <div
      className={`inline-block rounded-lg border border-solid text-base font-normal text-center align-baseline whitespace-nowrap py-1 px-2.5 leading-5 ${tagStyles[type]}`}
    >
      {label}
    </div>
  );
};

export default Tag;
