import { ApiBaseService, queryToUrl } from './api.service';

import { CaseResponseDto, CaseTemplateSummaryDto, ImportCaseRequestDto, UpdateCaseRequestDto } from './dto/case.dto';

import { SearchQueryDto } from './dto/general';
import { UserSummaryResponseDto } from './dto/user-summary.response.dto';

export class CaseService {
  public static basePath() {
    if (!process.env.API_URL_CASE_BASE) {
      throw new Error('API_URL_CASE_BASE does not exist');
    }
    return process.env.API_URL_CASE_BASE;
  }

  public static create(
    payload: UpdateCaseRequestDto,
    handleSuccess: (_data: CaseResponseDto) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = CaseService.basePath();

    ApiBaseService.Post(
      uriPath,
      { ...payload, scoring_items_positions: Array.from(payload.scoring_items_positions.entries()) },
      (_code: number, _data: CaseResponseDto) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static import(
    payload: ImportCaseRequestDto,
    handleSuccess: (_data: CaseResponseDto) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = CaseService.basePath() + 'import/';

    ApiBaseService.Post(
      uriPath,
      payload,
      (_code: number, _data: CaseResponseDto) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static updateSave(
    id: string,
    payload: UpdateCaseRequestDto,
    handleSuccess: (_data: CaseResponseDto) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = CaseService.basePath() + id;

    ApiBaseService.Patch(
      uriPath,
      { ...payload, scoring_items_positions: Array.from(payload.scoring_items_positions.entries()) },
      (_code: number, _data: CaseResponseDto) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static updatePublish(
    id: string,
    payload: UpdateCaseRequestDto,
    handleSuccess: (_data: CaseResponseDto) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = CaseService.basePath() + 'publish/' + id;

    ApiBaseService.Patch(
      uriPath,
      { ...payload, scoring_items_positions: Array.from(payload.scoring_items_positions.entries()) },
      (_code: number, _data: CaseResponseDto) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static getById(
    id: string,
    handleSuccess: (_data: CaseResponseDto) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = CaseService.basePath() + id;

    ApiBaseService.Get(
      uriPath,
      {},
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static list(
    query: SearchQueryDto,
    handleSuccess: (_data: Array<CaseResponseDto>) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = CaseService.basePath() + queryToUrl(query);

    ApiBaseService.Get(
      uriPath,
      {},
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static listForInstitution(
    institutionId: string,
    handleSuccess: (_data: Array<CaseResponseDto>) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = CaseService.basePath() + 'institutionTemplates/' + queryToUrl({ institutionId });

    ApiBaseService.Get(
      uriPath,
      {},
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static listCertified(
    query: SearchQueryDto,
    handleSuccess: (_data: Array<CaseResponseDto>) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = CaseService.basePath() + 'certified/' + queryToUrl(query);

    ApiBaseService.Get(
      uriPath,
      {},
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static certifiedCoachesForCase(
    id: string,
    handleSuccess: (_data: UserSummaryResponseDto[]) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = CaseService.basePath() + 'certifiedcoaches/' + id;

    ApiBaseService.Get(
      uriPath,
      {},
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static listPublished(
    handleSuccess: (_data: CaseTemplateSummaryDto[]) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = CaseService.basePath() + 'published';

    ApiBaseService.Get(
      uriPath,
      {},
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static delete(id: string, handleSuccess: () => void, handleError: (err: Error) => void) {
    const uriPath = CaseService.basePath() + id;

    ApiBaseService.Delete(
      uriPath,
      (_code: number, _data: void) => {
        handleSuccess();
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }
}
