import React from 'react';
import { useTranslation } from 'react-i18next';

import { UserEditListDto } from '../../service/dto/user.dto';

import Tag, { TagType } from '../../components/Tag';

interface ITableProps {
  list: Array<UserEditListDto>;
  currentInstitution?: any;
  actions?: Array<{ buttonTitle: string; action: Function }>;
  showRoles?: boolean;
}

const UserTable = ({ list, actions, showRoles = true, currentInstitution }: ITableProps) => {
  const { t } = useTranslation();
  const renderRole = (user: UserEditListDto) => {
    let [isAdmin, isCoach, isTrainee] = [false, false, false];
    if (user.roles && user.roles.length) {
      isAdmin = user.roles.includes('role:admin');
      isCoach = user.roles.includes('role:sp');
      isTrainee = user.roles.includes('role:trainee');
    }

    return (
      <>
        <td className='text-gray-dark font-bold py-4 text-center'>
          {user.isSuperAdmin ? (
            <div className='inline-block w-1/3'>
              <Tag label={t('user.superAdmin')} type={TagType.Red} />
            </div>
          ) : isAdmin ? (
            <div className='inline-block w-1/3'>
              <Tag label={t('user.admin')} type={TagType.Blue} />
            </div>
          ) : isCoach ? (
            <div className='inline-block w-1/3'>
              <Tag label={t('user.coach')} type={TagType.Yellow} />
            </div>
          ) : (
            isTrainee && (
              <div className='inline-block w-1/3'>
                <Tag label={t('user.trainee')} type={TagType.Green} />
              </div>
            )
          )}
        </td>
      </>
    );
  };

  return (
    <table className='dataTable table-fixed w-full bg-white px-4'>
      <thead>
      <tr className='font-bold bg-ghostwhite border border-solid border-gray-lighter'>
        <td className='py-4 w-1/12 lg:w-1/6 3xl:w-2/12'>{t('table.firstName')}</td>
        <td className='pr-4 w-1/12 lg:w-1/6 3xl:w-2/12'>{t('table.lastName')}</td>
        <td className='text-gray-dark font-bold py-4 w-2/12 2xl:w-3/12'>{t('table.email')}</td>
        {showRoles && (
          <td className='text-gray-dark font-bold py-4 text-center w-1/4 xl:w-1/5 2xl:w-3/12'>{t('table.role')}</td>
        )}
          <td className='text-gray-dark py-4 text-center w-1/10 lg:w-2/12 2xl:w-1/5'>{t('table.registrationDate')}</td>
        <td className='text-gray-light py-4 pl-4 w-1/10 lg:w-2/12 2xl:w-1/5 2xl:pl-8'>{t('table.actions')}</td>
      </tr>
      </thead>
      <tbody>
        {list.map((i, j) => {
          return (
            <tr className='border border-solid border-black-divider' key={'item_' + j}>
              <td className='text-gray-dark font-bold py-4'>{i.firstName}</td>
            <td className='text-gray-dark font-bold py-4'>{i.lastName}</td>
              <td className='py-4'>
                <a className='text-primary' href={`mailto:${i.email}`}>
                  {i.email}
                </a>
              </td>
              {showRoles && renderRole(i)}
              {i.registrationDate && (
                  <td className='text-gray-dark font-bold py-4 text-center'>{i.registrationDate.toLocaleDateString('en-GB')}</td>
              )}
              <td className='py-4 pl-4 2xl:pl-8 flex flex-wrap'>
                {actions &&
                  actions.map((action, actionIndex) => {
                    return (
                      <span
                        key={'item_' + actionIndex}
                        className='text-primary cursor-pointer pr-2'
                        onClick={() => action.action(j)}
                      >
                        {action.buttonTitle}
                      </span>
                    );
                  })}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default UserTable;
