import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// DTO
import { ITeachableSession, SessionResponseDto, TeachableMomentDto } from '../service/dto/session.dto';

// UTILS
import { DurationMS } from '../utils/constants';

// REDUX
import { timeSequencesToRanges } from '../utils/funcs';
import { SessionTimePlayingState } from '../redux/types';
import { useAppSelector } from '../redux/store';

interface ITeachableMomentList {
  teachableMoments?: TeachableMomentDto[];
  sessionFromResult?: ITeachableSession;
  isIcon?: boolean;
  showTime?: boolean;
}

const TeachableMomentList = ({
  teachableMoments,
  sessionFromResult,
  isIcon,
  showTime = true,
}: ITeachableMomentList) => {
  const { session }: { session: SessionResponseDto } = useAppSelector((state: any) => state.sessionConfig);
  const [scoreItemTimestampToTime, setTeachableMomentTimestampToTime] = useState<Map<string, number>>(new Map());
  const sessionTimePlaying = useAppSelector((state: any) => state.sessionTimePlayingReducer) as SessionTimePlayingState;
  const { t } = useTranslation();

  useEffect(() => {
    const _session = sessionFromResult ?? session;
    if (!_session || !teachableMoments) {
      setTeachableMomentTimestampToTime(new Map());
      return;
    }

    const ranges = timeSequencesToRanges(
      _session.coachingSessionRecord ? _session.coachingSessionRecord.recordingSequences : [],
      _session.coachingSessionRecord ? _session.coachingSessionRecord.durationInSeconds ?? 1 : 1,
    );
    let totalTimeMS = ranges.reduce((sum, el) => sum + el.to - el.from, 0);
    const newItemTimestampToTime = new Map();
    for (const _teachableMomentItem of teachableMoments) {
      const timestamp = new Date(_teachableMomentItem.tmstmp).getTime();
      if (totalTimeMS == 0) {
        newItemTimestampToTime.set(
          _teachableMomentItem.tmstmp,
          timestamp - new Date(_session.coachingSessionStart).getTime(),
        );
        continue;
      }

      const matchedRange = ranges.find(tr => timestamp >= tr.from && timestamp <= tr.to);
      if (!matchedRange) {
        continue;
      }

      const progress =
        ((timestamp - matchedRange.from) / (matchedRange.to - matchedRange.from)) * matchedRange.toPercent +
        matchedRange.fromPercent;
      newItemTimestampToTime.set(_teachableMomentItem.tmstmp, (totalTimeMS * progress) / 100);
    }
    setTeachableMomentTimestampToTime(newItemTimestampToTime);
  }, [teachableMoments, session]);

  const getDiffDatesStr = (firstDateStr: string, secondDateStr: string): number => {
    const firstDateInMS: number = new Date(firstDateStr).getTime();
    const secondDateInMS: number = new Date(secondDateStr).getTime();

    return secondDateInMS - firstDateInMS;
  };

  const msToStrViewMS = (ms: number): string => {
    let seconds: number = ms / DurationMS.SEC;
    const minutes = parseInt((ms / DurationMS.MIN).toString());
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

    // Keep only seconds not extracted to minutes:
    seconds = parseInt((seconds % 60).toString());
    const formattedSeconds = seconds < 10 ? '0' + seconds : seconds;
    return formattedMinutes + ':' + formattedSeconds;
  };

  const recordedTime = (tmstmp: string) => {
    return tmstmp && scoreItemTimestampToTime.has(tmstmp)
      ? msToStrViewMS(scoreItemTimestampToTime.get(tmstmp)!)
      : undefined;
  };

  return (
    <>
      {teachableMoments &&
        Object.values(teachableMoments)
          .sort((a, b) => getDiffDatesStr(b.tmstmp, a.tmstmp))
          .map((item: TeachableMomentDto, j: number) => (
            <div key={item.tmstmp + j}>
              <div className='flex'>
                {isIcon && (
                  <div className='mr-6'>
                    <i
                      className={`bi bi-chat text-4xl ${
                        sessionTimePlaying.selectedScoreItemTimestamp === item.tmstmp
                          ? 'text-primary'
                          : 'text-gray-dark'
                      }`}
                    ></i>
                  </div>
                )}
                <div className='w-full'>
                  <div className='mb-2 leading-4'>
                    <strong>{j + 1}.</strong> {item.text}
                  </div>
                  {showTime && (
                    <div className='flex items-center'>
                      <div className='w-4 h-4 border-3 border-solid border-black bg-black rounded-full'></div>
                      {recordedTime(item.tmstmp) && (
                        <div className='pl-2 text-gray'>{`${t('session.recordedOn')} ${recordedTime(
                          item.tmstmp,
                        )}`}</div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <hr className='w-full h-px bg-black-divider border-0 mt-3 mb-4'></hr>
            </div>
          ))}
    </>
  );
};

export default TeachableMomentList;
