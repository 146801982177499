import React, { useState } from 'react';

interface IPasswordInputProps {
  value: string;
  placeholder?: string;
  handleChange: any;
  error: string;
  required?: boolean;
  disabled?: boolean;
}

const PasswordInput = ({ value, placeholder, handleChange, error, required, disabled }: IPasswordInputProps) => {
  const [visibility, setVisibility] = useState(false);
  const showPassword = () => {
    setVisibility(!visibility);
  };

  return (
    <div className='relative focus-within:text-gray-dark'>
      <i className='bi bi-shield-lock absolute text-gray top-0 left-0 pointer-events-none py-2 px-3' />
      <input
        className={`custom-input transition py-1.5 text-base block px-9 rounded-lg bg-white bg-clip-padding text-gray-dark placeholder-gray-light hover:border-primary-hover focus:text-black focus:border-primary-hover focus:outline-none border border-solid border-black-divider appearance-none w-full font-normal ${
          error && 'border-negative hover:border-negative focus:border-negative input-error'
        }`}
        type={visibility ? 'text' : 'password'}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        required={required}
        disabled={disabled}
      />
      <i
        className={`bi ${
          visibility ? 'bi-eye-fill' : 'bi-eye-slash-fill'
        } absolute cursor-pointer text-gray top-0 right-0 py-2 px-3`}
        onClick={() => showPassword()}
      />
      {error && <span className='uppercase text-negative pt-1'>{error}</span>}
    </div>
  );
};

export default PasswordInput;
