import React from 'react';

import { useTranslation } from 'react-i18next';

// DTO
import { CreateScoringRequestDto } from '../../service/dto/scoring.dto';

// UI COMPONENTS
import InputLabel from '../../components/InputLabel';
import TextInput from '../../components/TextInput';

import CustomSelect, { ICustomSelectItem } from '../../components/CustomSelect';
import RadioInput, { IRadioItem, RadioInputStyle } from '../../components/RadioInput';

import { DEFAULT_WEIGHT, LIST_GRADES, LIST_WEIGHT } from './../../common/lists';

interface ICreateScoringItemFromProps {
  errors: any;
  payload: CreateScoringRequestDto;
  handleChange: Function;
  categoryList: ICustomSelectItem[];
  isSelectCategoryDisabled: boolean;
}

const CreateScoringForm = ({
  errors,
  payload,
  handleChange,
  categoryList,
  isSelectCategoryDisabled,
}: ICreateScoringItemFromProps) => {
  const { t } = useTranslation();

  return (
    <>
      <form className='w-full'>
        <div className='w-full mb-6'>
          <InputLabel label={t('inputLabels.category')} />
          <CustomSelect
            placeholder=''
            disabled={isSelectCategoryDisabled}
            list={categoryList}
            value={payload.categoryId}
            handleChange={(item: ICustomSelectItem) => {
              handleChange('categoryId', item.value);
            }}
          />
        </div>
        <div className='w-full mb-6'>
          <InputLabel label={t('inputLabels.scoringItem')} />
          <TextInput
            placeholder={t('placeholders.klartAgenda')}
            value={payload.name || ''}
            handleChange={(e: any) => handleChange('name', e.target.value)}
            error={errors.name}
            required
          />
        </div>
        <div className='w-full mb-6'>
          <InputLabel label={t('inputLabels.mnemonic')} />
          <TextInput
            placeholder={t('placeholders.agenda')}
            value={payload.mnemonic || ''}
            handleChange={(e: any) => handleChange('mnemonic', e.target.value)}
            error={errors.mnemonic}
            required
          />
        </div>
        <div className='flex'>
          <div className='w-1/3 mr-4 mb-6'>
            <InputLabel label={t('inputLabels.grades')} />
            <RadioInput
              list={LIST_GRADES}
              value={payload.grades.toString()}
              style={RadioInputStyle.HORIZONTAL}
              handleChange={(item: IRadioItem) => {
                handleChange('grades', parseInt(item.value.toString()));
              }}
            />
          </div>

          <div className='w-2/3 mb-6'>
            <InputLabel label={t('inputLabels.weight')} additionalLabel={t('inputLabels.minMaxWeight')} />
            <CustomSelect
              placeholder={t('placeholders.selectWeight')}
              list={LIST_WEIGHT()}
              value={payload.weight.toString()}
              handleChange={(item: ICustomSelectItem) => {
                handleChange('weight', Number.parseFloat(item.value || '1'));
              }}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default CreateScoringForm;
