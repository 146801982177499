import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { sessionHistoryPageSize, sessionLogPageSize } from '../../common/constants';
import { SessionHistoryRequestDto, SessionResponseDto } from '../../service/dto/session.dto';
import { SessionService } from '../../service/session.service';
import { decimalToPercent, getDateAndTimeDuration } from '../../utils';

interface ITableProps {
  selectedCases: string[];
  selectedCategories: string[];
  detailsSession: (id: string, number: number) => void;
}

const HistoryTable = ({ selectedCases, selectedCategories, detailsSession }: ITableProps) => {
  const { t } = useTranslation();
  const [totalPages, setTotalPages] = useState<number>(0);
  const [sessionList, setSessionList] = useState<SessionResponseDto[]>([]);
  const [page, setPage] = useState(0);

  useEffect(() => {
    setPage(0);
    serviceGetHistoryCount();
    serviceGetSessionList();
  }, [selectedCases, selectedCategories]);

  useEffect(() => {
    serviceGetSessionList();
  }, [page]);

  const serviceGetSessionList = () => {
    const payload: SessionHistoryRequestDto = {
      cases: selectedCases,
      categories: selectedCategories,
      pageSize: sessionHistoryPageSize,
      page,
    };
    SessionService.history(
      payload,
      (data: Array<SessionResponseDto>) => {
        setSessionList(data);
      },
      error => {
        setSessionList([]);
      },
    );
  };

  const serviceGetHistoryCount = () => {
    const payload: SessionHistoryRequestDto = {
      cases: selectedCases,
      categories: selectedCategories,
      pageSize: sessionHistoryPageSize,
      page,
    };
    SessionService.historyCount(
      payload,
      (data: number) => {
        setTotalPages(Math.floor((data - 1) / sessionLogPageSize) + 1);
      },
      error => {
        setTotalPages(0);
        setPage(0);
      },
    );
  };

  const renderTime = (index: number) => {
    const sessionItem: SessionResponseDto = sessionList[index];
    const { startTime, endTime, diff, monthLabel, month, day } = getDateAndTimeDuration(sessionItem);
    const today: Date = new Date();

    let showDateLabel = false;
    if (index != 0 && sessionList[index - 1]) {
      const prev = sessionList[index - 1];
      const prevDate = new Date(prev.startDate);
      if (prevDate.getMonth() != month || prevDate.getDate() != day) {
        showDateLabel = true;
      }
    } else {
      showDateLabel = true;
    }

    const dateLabel = today.getMonth() == month && today.getDate() == day ? 'Today' : day + ' ' + monthLabel;

    return (
      <div className='flex items-center'>
        <div className={`mr-2${showDateLabel ? '' : ' text-transparent'}`}>{dateLabel}</div>
        <div>
          <div>
            {startTime} - {endTime}
          </div>
          <span className='font-thin text-xs text-gray'>{diff} MIN</span>
        </div>
      </div>
    );
  };

  const onPageChange = (event: { selected: number }) => {
    setPage(event.selected);
  };

  return (
    <div>
      <table className='dataTable table-fixed w-full bg-white px-4'>
        <thead>
          <tr className='font-bold bg-ghostwhite border border-solid border-gray-lighter'>
            <td className='py-4 w-1/5'>{t('table.time')}</td>
            <td className='text-gray-dark font-bold py-4 w-1/4'>{t('table.case')}</td>
            <td className='text-gray-dark font-bold py-4 w-1/8'>{t('table.category')}</td>
            <td className='text-gray-dark font-bold py-4 w-1/8'>{t('table.coach')}</td>
            <td className='text-gray-dark font-bold py-4 w-1/8'>{t('table.trainee')}</td>
            <td className='text-gray-dark font-bold py-4 w-1/8'>{t('table.score')}</td>
            <td className='text-gray-light py-4 w-1/5'>{t('table.actions')}</td>
          </tr>
        </thead>

        <tbody>
          {sessionList.map((sessionItem: SessionResponseDto, index: number) => {
            return (
              <tr className='border border-solid border-black-divider' key={sessionItem.id}>
                <td className='text-gray-dark py-4'>{renderTime(index)}</td>
                <td className='text-gray-dark py-4'>{sessionItem.caseTemplateSummary.name}</td>
                <td className='text-gray-dark py-4'>{sessionItem.categoryName}</td>
                <td className='text-gray-dark py-4'>
                  {sessionItem.coach && sessionItem.coach.firstName + ' ' + sessionItem.coach.lastName}
                </td>
                <td className='text-gray-dark py-4'>
                  {sessionItem.trainee ? sessionItem.trainee.firstName + ' ' + sessionItem.trainee.lastName : '--'}
                </td>
                <td className='text-gray-dark py-4'>{decimalToPercent(sessionItem.totalScore)} %</td>

                <td className='py-4'>
                  <div className='flex justify-between flex-wrap'>
                    <span
                      className='text-primary cursor-pointer pr-1'
                      onClick={() => {
                        detailsSession(sessionItem.id, index);
                      }}
                    >
                      {t('buttons.session_details')}
                    </span>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {!!totalPages && (
        <ReactPaginate
          previousLabel={<i className='bi bi-chevron-left' />}
          breakLabel='...'
          nextLabel={<i className='bi bi-chevron-right' />}
          onPageChange={onPageChange}
          pageRangeDisplayed={5}
          pageCount={totalPages}
          containerClassName='pagination flex justify-center text-gray-dark'
          pageClassName='page-item'
          pageLinkClassName='page-link'
          previousClassName='page-item'
          previousLinkClassName='page-link'
          nextClassName='page-item'
          nextLinkClassName='page-link'
          activeClassName='active'
          breakClassName='page-item'
          breakLinkClassName='page-link'
          forcePage={page}
        />
      )}
    </div>
  );
};

export default HistoryTable;
