const passValidation = new RegExp('^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+,.\\/;:"-]).{8,}$');
const passMessage = 'enter valid password.';

export const signinRequestValidation = {
  email: {},
  password: {},
};

export const signupRequestValidation = {
  password: {
    pattern: {
      value: passValidation,
      message: passMessage,
    },
  },
  // password_confirm: {
  //     custom: {
  //       isValid: (value: string) => value == user.password,
  //       message: "passwords do not match.",
  //     },
  // },
};

export const UserPaswordForgotValidation = {
  email: {},
};

export const UserPaswordResetValidation = {
  password: {
    pattern: {
      value: passValidation,
      message: passMessage,
    },
  },
  // password_confirm: {
  //     custom: {
  //       isValid: (value: string) => value == user.password,
  //       message: "passwords do not match.",
  //     },
  // },
};
