import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

// SERVICE
import { CaseService } from '../../service/case.service';
import { CaseResponseDto } from '../../service/dto/case.dto';

// MODAL
import AbstractModal from '../../components/AbstractModal';
import ModalContent from '../../components/ModalContent';

// UI COMPONENTS
import CaseTable from './CaseTable';
import SearchField from '../../components/SearchField';

//
import Alert from '../../utils/alert';

import { IRouteComponent } from './../../routes';
import CustomMultiSelect, { ICustomSelectedItem } from '../../components/CustomMultiSelect';
import { CategoryService } from '../../service/category.service';
import { CategoryListItemDto } from '../../service/dto/category.dto';

import DefaultHeader from '../../components/DefaultHeader';
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {setCaseCategoriesSelector, setCaseSearchSelector} from "../../redux/actions";

enum COMPONENT_STATE {
  LIST,
  CREATE,
  EDIT,
  DELETE,
}

const CaseList = ({ currentUser, currentInstitution }: IRouteComponent) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [state, setState] = useState(COMPONENT_STATE.LIST);

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<CaseResponseDto[]>([]);
  const { search }: { search: string } = useAppSelector((state: any) => state.caseSearchReducer);
  const { selectedCategories }: { selectedCategories: string[] } = useAppSelector((state: any) => state.caseCategoriesSelectorReducer);

  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [categoryNames, setCategoryNames] = useState<ICustomSelectedItem[]>([]);

  // SERVICE FETCH
  const serviceDataFetch = () => {
    CaseService.list(
      { search: search },
      (data: Array<CaseResponseDto>) => {
        if (selectedCategories.length) {
          data = data.filter(caseR => selectedCategories.indexOf(caseR.categoryName) > -1);
        }
        setLoading(false);
        setList(data);
      },
      error => {
        setLoading(false);
        setList([]);
      },
    );
  };

  useEffect(() => {
    serviceDataFetch();
  }, [loading, search, selectedCategories]);

  useEffect(() => {
    serviceCategoryFetch();
  }, []);

  const onSearch = (value: string) => {
    dispatch(setCaseSearchSelector({ search: value }));
  };

  const serviceCategoryFetch = () => {
    CategoryService.list(
      (data: CategoryListItemDto[]) => {
        setCategoryNames(
          data.map(category => {
            return { value: category.name, label: category.name };
          }),
        );
      },
      () => {},
    );
  };

  // Delete
  const handleDelete = () => {
    CaseService.delete(
      list[selectedIndex].id,
      () => {
        serviceDataFetch();
        setState(COMPONENT_STATE.LIST);
        Alert.success(t('successMessages.caseDeleted'));
      },
      error => {
        setState(COMPONENT_STATE.LIST);
        Alert.warning(t('errorMessages.caseNotDeleted'));
      },
    );
  };

  const title = t('cases.title') + currentInstitution.name + ' (' + list.length + ')';
  return (
    <div className='rounded-lg bg-white my-6 shadow pb-2 mx-4 2xl:mx-6'>
      <DefaultHeader
        title={title}
        primaryAction={{
          title: t('buttons.createANewCase'),
          onClick: () => {
            navigate('/case/create');
          },
        }}
        secondaryAction={{
          title: t('buttons.import'),
          onClick: () => {
            navigate('/case/import');
          },
          show: currentUser.isSuperAdmin,
        }}
      />

      <div className='p-6'>
        <div className='flex  w-8/12'>
          <div className='mr-6 w-3/12'>
            <SearchField onSearch={onSearch} value={search}/>
          </div>

          <div className='flex ml-4 mr-4 w-5/12'>
            <CustomMultiSelect
              placeholderAll='All Categories'
              placeholderSelected='Categories Selected'
              list={categoryNames}
              handleChange={(values: string[]) => {
                dispatch(setCaseCategoriesSelector({ selectedCategories: values }));
              }}
              values={selectedCategories}
            />
          </div>
        </div>
      </div>

      <CaseTable
        list={list}
        onDelete={(index: number) => {
          setSelectedIndex(index);
          setState(COMPONENT_STATE.DELETE);
        }}
        onEdit={(index: number) => {
          navigate('/case/edit/' + list[index].id);
          setSelectedIndex(index);
          if (list[index]) {
          }
          setState(COMPONENT_STATE.EDIT);
        }}
      />

      {state == COMPONENT_STATE.DELETE && (
        <AbstractModal
          label={t('modals.deleteCase')}
          leftBtn={{
            label: t('buttons.cancel'),
            onClick: () => {
              setState(COMPONENT_STATE.LIST);
            },
          }}
          rightBtn={{
            label: t('buttons.yesDelete'),
            onClick: () => {
              handleDelete();
            },
          }}
          toDelete
        >
          <ModalContent entity='case' title={t('cases.deleteModal.title')} subtitle='' />
        </AbstractModal>
      )}
    </div>
  );
};

export default CaseList;
