import React, {
  MouseEvent as ReactMouseEvent,
  ReactNode,
  TouchEvent as ReactTouchEvent,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

interface IRecordPlayerModalState {
  current: { x: number; y: number };
  initial: { x: number; y: number };
  offset: { x: number; y: number };
  isDragging: boolean;
}

interface IDraggableModal {
  children?: React.ReactNode;
  isCloseVisible: boolean;
  onCloseClick?: (event: ReactMouseEvent) => void;
  title: ReactNode;
}

const defaultRecordPlayerModalState: IRecordPlayerModalState = {
  current: {
    x: 0,
    y: 0,
  },
  initial: {
    x: 0,
    y: 0,
  },
  offset: {
    x: 0,
    y: 0,
  },
  isDragging: false,
};

const DraggableModal = ({ children, isCloseVisible, onCloseClick, title }: IDraggableModal) => {
  const { t } = useTranslation();
  const [componentState, _setComponentState] = useState({
    ...defaultRecordPlayerModalState,
  });
  const componentStateRef = useRef(componentState);
  const setComponentState = (data: IRecordPlayerModalState) => {
    componentStateRef.current = data;
    _setComponentState(data);
  };
  const modalRef = useRef<HTMLDivElement>(null);

  const onCloseClickWrapper = (event: ReactMouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    onCloseClick && onCloseClick(event);
  };

  const onDragStart = (event: ReactMouseEvent | ReactTouchEvent) => {
    if (componentStateRef.current.isDragging) {
      return;
    }

    const _setState = ({ x, y }: { x: number; y: number }) => {
      setComponentState({
        ...componentStateRef.current,
        isDragging: true,
        initial: {
          x: x - componentStateRef.current.offset.x,
          y: y - componentStateRef.current.offset.y,
        },
      });
    };

    if (event.type === 'mousedown') {
      const mouseEvent = event as ReactMouseEvent;
      if (mouseEvent.button !== 0) {
        return;
      }

      _setState({
        x: mouseEvent.clientX,
        y: mouseEvent.clientY,
      });
    } else if (event.type === 'touchstart') {
      const touchEvent = event as ReactTouchEvent;
      if (!touchEvent.touches.length) {
        return;
      }

      _setState({
        x: touchEvent.touches[0].clientX,
        y: touchEvent.touches[0].clientY,
      });

      document.body.style.overflow = 'hidden';
    }

    document.addEventListener('mousemove', onDragMove);
    document.addEventListener('touchmove', onDragMove);
    document.addEventListener('mouseup', onDragEnd);
    document.addEventListener('touchend', onDragEnd);
  };

  const onDragMove = (event: MouseEvent | TouchEvent) => {
    if (!componentStateRef.current.isDragging) {
      return;
    }

    const _setState = ({ x, y }: { x: number; y: number }) => {
      const currentX = x - componentStateRef.current.initial.x,
        currentY = y - componentStateRef.current.initial.y;

      setComponentState({
        ...componentStateRef.current,
        current: {
          x: currentX,
          y: currentY,
        },
        offset: {
          x: currentX,
          y: currentY,
        },
      });
    };
    if (event.type === 'touchmove') {
      const touchEvent = event as TouchEvent;
      if (!touchEvent.touches.length) {
        return;
      }
      _setState({
        x: touchEvent.touches[0].clientX,
        y: touchEvent.touches[0].clientY,
      });
    } else if (event.type === 'mousemove') {
      const mouseEvent = event as MouseEvent;
      _setState({
        x: mouseEvent.clientX,
        y: mouseEvent.clientY,
      });
    }
  };

  const onDragEnd = () => {
    if (!componentStateRef.current.isDragging) {
      return;
    }

    document.removeEventListener('mousemove', onDragMove);
    document.removeEventListener('mouseup', onDragEnd);
    document.removeEventListener('touchmove', onDragMove);
    document.removeEventListener('touchend', onDragEnd);
    document.body.style.overflow = 'auto';
    setComponentState({
      ...componentStateRef.current,
      isDragging: false,
    });
  };

  const modalMoveStyle = {
    transform: `translate(${componentState.current.x}px, ${componentState.current.y}px)`,
  };

  return (
    <div
      className='modal block fixed draggable top-30 right-12.5 w-auto h-auto min-w-168 z-50'
      ref={modalRef}
      tabIndex={-1}
      style={modalMoveStyle}
    >
      <div className='m-0 min-w-full relative pointer-events-none'>
        <div className='relative flex flex-col w-full pointer-events-auto bg-transparent shadow-none outline-none bg-clip-padding border border-solid border-black/20 rounded-md '>
          <div
            className='flex items-center shrink-0 justify-between bg-white cursor-move border-t border-solid border-gray-light p-4 rounded-t-lg'
            onMouseDown={onDragStart}
            onTouchStart={onDragStart}
          >
            <div className='text-xl mb-0 leading-normal'>{title}</div>
            {isCloseVisible && (
              <div className='cursor-pointer' onClick={e => onCloseClickWrapper(e)}>
                <i className='bi bi-x text-2xl'></i>
              </div>
            )}
          </div>
          <div className='modal-body p-0 bg-transparent flex-auto relative'>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default DraggableModal;
