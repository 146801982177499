import React from 'react';

import { useTranslation } from 'react-i18next';

interface ITableProps {
  list: Array<any>;
  onDelete: Function;
  onEdit: Function;
}

const InstitutionTable = ({ list, onDelete, onEdit }: ITableProps) => {
  const { t } = useTranslation();

  return (
    <table className='dataTable table-fixed w-full bg-white px-4'>
      <thead>
        <tr className='font-bold bg-ghostwhite border border-solid border-gray-lighter'>
          <td className='py-4 w-1/2'>{t('table.institutionName')}</td>
          <td className='text-gray-dark font-bold py-4 w-1/3'>{t('table.institutionCode')}</td>
          <td className='text-gray-dark font-bold py-4 w-1/3'>{t('table.institutionTimezone')}</td>
          <td className='text-gray-light py-4 w-1/6'>{t('table.actions')}</td>
        </tr>
      </thead>
      <tbody>
        {list.map((i, j) => {
          return (
            <tr className='border border-solid border-black-divider' key={i.code}>
              <td className='text-gray-dark font-bold py-4'>{i.name}</td>
              <td className='uppercase text-gray-dark py-4'>{i.code}</td>
              <td className='text-gray-dark py-4'>{i.timezone}</td>
              <td className='py-4 flex justify-between flex-wrap'>
                <span className='text-primary cursor-pointer editLink' onClick={() => onEdit(j)}>
                  <i className='bi bi-pencil-fill text-primary-hover'></i> {t('buttons.edit')}
                </span>
                {/* <span className="text-negative-darker cursor-pointer deleteLink" onClick={() => onDelete(j)}>
                  <i className="bi bi-trash-fill text-negative"></i> {t('buttons.delete')}
                </span> */}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default InstitutionTable;
