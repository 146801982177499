import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Alert from '../../../utils/alert';
import Video, { LocalAudioTrack, LocalTrack, LocalTrackPublication, LocalVideoTrack, Room } from 'twilio-video';
import { ConnectOptions } from 'twilio-video/tsdef/types';
import { COMPONENT_STATE, RecordStatus, SessionStatus, USER_ROLES } from '../../../common/enums';
import { ICurrentUser, WsType } from '../../../common/interfaces';
import AbstractModal from '../../../components/AbstractModal';
import ModalContent from '../../../components/ModalContent';
import RecordedOnList from '../../../components/RecordedOnList';
import DraggableModal from '../../../components/RecordPlayerModal/DraggableModal';
import RecordPlayer, { IRecordPlayerManipulationInfo } from '../../../components/RecordPlayerModal/RecordPlayer';
import ScoringMarksList from '../../../components/ScoringMarksList';
import TeachableMomentInput from '../../../components/TeachableMomentInput';
import TeachableMomentList from '../../../components/TeachableMomentList';
import { WebSocketContext } from '../../../context/socket';
import {actionSetSessionConfig, actionSocketSessionEnd, setSessionScoreResult} from '../../../redux/actions';
import { DeviceState } from '../../../redux/types';
import { CaseResponseDto } from '../../../service/dto/case.dto';
import { createConstrains } from '../../../service/dto/MediaDevice.dto';
import {
  ScoreResultDto,
  SessionRecordDownloadResponseDto,
  SessionRecordResponseDto,
  SessionResponseDto,
  TeachableMomentDto,
} from '../../../service/dto/session.dto';
import { SessionService } from '../../../service/session.service';
import { VideoRoomService } from '../../../service/video-room.service';
import { DurationMS, scoreColors, scoreI18NKey } from '../../../utils/constants';
import { retryOperation, retryOperationAsync } from '../../../utils/funcs';
import { SocketSessionRecordManipulated } from '../socket-session-record-manipulated.interface';
import { StartFeedbackPayload } from '../socket-start-feedback-payload.interface';
import PrimaryButton from './../../../components/PrimaryButton';
import SecondaryButton from './../../../components/SecondaryButton';
import RoomView from './RoomView';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import InviteCoaches from '../../../components/InviteCoaches';

const VideoChat = ({
  caseData,
  currentUser,
  shareToken,
}: {
  caseData: CaseResponseDto;
  currentUser: ICurrentUser;
  shareToken: string;
}) => {
  const sessionSelector = (state: any) => state.sessionConfig;
  const { session, start: sessionStart }: { session: SessionResponseDto; start: boolean } =
    useAppSelector(sessionSelector);
  const deviceState: DeviceState = useAppSelector((state: any) => state.devicesConfig);
  const sessionScoreSelector = (state: any) => state.sessionSetScoreResultReducer?.session?.scoreResult;
  const sessionScoreFromSelector: ScoreResultDto = useAppSelector(sessionScoreSelector);
  const sessionScoreValueSelector = (state: any) => state.sessionSetScoreValueReducer;
  const sessionScoreValueFromSelector = useAppSelector(sessionScoreValueSelector);

  let state: COMPONENT_STATE = useAppSelector((state: any) => state.socketComponentConfig).componentState;
  let sessionStatusRx: SessionStatus = useAppSelector((state: any) => state.socketSessionConfig).sessionStatus;
  let sessionRecordRx: SessionRecordResponseDto = useAppSelector(
    (state: any) => state.socketRecordConfig,
  ).sessionRecord;
  let sessionManipulation: SocketSessionRecordManipulated = useAppSelector(
    (state: any) => state.socketManipulationConfig,
  ).sessionManipulation;

  let startFeedbackPayload: StartFeedbackPayload = useAppSelector((state: any) => state.socketFeedbackConfig);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const videoRecordTitleConfig = useAppSelector((state: any) => state.videoTitleConfig);
  const [room, setRoom] = useState<Room | null>(null);
  const [role, setRole] = useState<USER_ROLES>(USER_ROLES.SP);
  const [connectedRoomSid, setConnectedRoomSid] = useState<string>('');
  const [sessionStatus, setSessionStatus] = useState<SessionStatus>(SessionStatus.Coaching);
  const [finishModal, setFinishModal] = useState<boolean>(false);
  const socket: WsType | null = useContext(WebSocketContext);
  const [coachAudioMute, setCoachAudioMute] = useState<boolean>(true);
  const [coachVideoMute, setCoachVideoMute] = useState<boolean>(true);
  const [traineeAudioMute, setTraineeAudioMute] = useState<boolean>(true);
  const [traineeVideoMute, setTraineeVideoMute] = useState<boolean>(true);
  const [scoreResult, setScoreResult] = useState<ScoreResultDto>();
  const [sessionRecord, setSessionRecord] = useState<SessionRecordResponseDto>();
  const [sessionRecordDownloadResponse, setSessionRecordDownloadResponse] = useState<
    SessionRecordDownloadResponseDto | undefined
  >();
  const [sessionRecordDownloadError, setSessionRecordDownloadError] = useState<string | undefined>();
  const [sessionRecordManipulation, setSessionRecordManipulation] = useState<
    IRecordPlayerManipulationInfo | undefined
  >();
  const [isCoachingSessionRecordVisible, setIsCoachingSessionRecordVisible] = useState<boolean>(false);
  const [clickedFeedback, setClickedFeedback] = useState<boolean>(false);
  const [teachableMomentText, setTeachableMomentText] = useState<string>('');
  const [teachableMoments, setTeachableMoments] = useState<TeachableMomentDto[]>();
  const [sessionShareToken, setSessionShareToken] = useState<string>('');
  const [inviteCoach, setInviteCoach] = useState<boolean>(false);

  useEffect(() => {
      const newScoreResult = {...sessionScoreFromSelector};
      for (const key in sessionScoreValueFromSelector) {
        newScoreResult[key].value = sessionScoreValueFromSelector[key]
      }
      dispatch(setSessionScoreResult(
          {...newScoreResult }
      ))
  }, [sessionScoreValueFromSelector]);

  useEffect(() => {
    const cleanup = () => {
      room && cleanupRoom();
    };

    if (!session || !currentUser) {
      return cleanup;
    }

    dispatch(setSessionScoreResult(session.scoreResult))

    if (shareToken) {
      SessionService.validateShareToken(session.id, shareToken).then((data: any) => {
        const { status } = data.session;
        setSessionStatus(data.session.status);
        if (data.hasOwnProperty('room') && data.room) {
          const { sid, uniqueName } = data.room;
          videoConnect(sid, uniqueName, status, true);
        }
      });
      if (session && currentUser && sessionStart) {
        setRole(USER_ROLES.Spectator);
        setScoreResult(session.scoreResult);
        setTeachableMoments(session.teachableMoments);
        setSessionRecord(session.coachingSessionRecord);
        setSessionRecordDownloadResponse(undefined);
        initiateSockets();
      }

      return cleanup;
    }

    if (sessionStart) {
      retryOperationAsync(() => SessionService.startSessionPromise(session.id))
        .then((data: any) => {
          const { status } = data.session;
          setSessionStatus(data.session.status);
          if (data.hasOwnProperty('room') && data.room) {
            const { sid, uniqueName } = data.room;
            dispatch(actionSetSessionConfig({ start: false, session: data.session }));
            videoConnect(sid, uniqueName, status);
          }

          initiateSockets();
          setRole(currentUser.id === session.coach.id ? USER_ROLES.SP : USER_ROLES.Trainee);
          setScoreResult(session.scoreResult);
          setTeachableMoments(session.teachableMoments);
          setSessionRecord(session.coachingSessionRecord);
          setSessionRecordDownloadResponse(undefined);
        })
        .catch(console.error);
    }

    if (currentUser.id === session.coach.id) {
      SessionService.getShareToken(session.id)
        .then(response => {
          setSessionShareToken(response);
        })
        .catch(console.error);
    }

    return cleanup;
  }, [session, currentUser]);

  useEffect(() => {
    if (sessionStatus !== SessionStatus.Finished) {
      return;
    }

    if (shareToken) {
      Alert.warning(t('stream.session_passed'));
      navigate('/dashboard');
      return;
    }

    navigate(`/session/result/${session.id}`);
    dispatch(actionSocketSessionEnd());
  }, [sessionStatus]);

  useEffect(() => {
    if (sessionRecord?.status === RecordStatus.Available && sessionRecord.mediaUri) {
      setSessionRecordDownloadError(undefined);
      setSessionRecordDownloadResponse({ url: sessionRecord.mediaUri });
    }
  }, [sessionRecord]);

  useEffect(() => {
    if (sessionStatusRx === SessionStatus.Finished) {
      cleanupRoom();
      setSessionStatus(sessionStatusRx);
    }
  }, [sessionStatusRx]);

  useEffect(() => {
    if (!startFeedbackPayload?.startFeedbackPayload?.room) {
      return;
    }

    const { sid, uniqueName } = startFeedbackPayload.startFeedbackPayload.room;
    if (!sid || !sid.length) {
      return;
    }

    videoConnect(sid, uniqueName, SessionStatus.Feedback);
    SessionService.getById(
      session.id,
      _data => {
        if (_data.scoreResult) {
          setScoreResult(_data.scoreResult);
        }
      },
      console.error,
    );
  }, [startFeedbackPayload]);

  useEffect(() => {
    if (sessionRecordRx && !!sessionRecord && sessionRecord?.status != RecordStatus.Available) {
      setSessionRecord(sessionRecordRx);
    }
  }, [sessionRecordRx]);

  useEffect(() => {
    if (sessionManipulation) {
      if (sessionManipulation.sessionId === session.id) {
        setIsCoachingSessionRecordVisible(sessionManipulation.isVisible);
        setSessionRecordManipulation({
          isMuted: sessionManipulation.isMuted,
          isPlaying: sessionManipulation.isPlaying,
          timeInSeconds: sessionManipulation.timeInSeconds,
        });
      }
    }
  }, [sessionManipulation]);

  const initiateSockets = () => {
    socket!.joinRoom(session.id, currentUser.id);
    socket!.getRoomStatus(session.id, currentUser.id);
  };

  const stopLocalTrack = (track: LocalTrack) => {
    if (track instanceof LocalAudioTrack || track instanceof LocalVideoTrack) {
      track.detach();
      track.stop();
    }
  };

  const videoConnect = async (
    sid: string,
    uniqueName: string,
    status: SessionStatus,
    spectatorView = false,
  ): Promise<boolean> => {
    // Check if already connected to this room
    if (connectedRoomSid === sid) {
      return true;
    }

    try {
      const { token } = await VideoRoomService.getTokenPromise({ userId: currentUser.id, roomSid: sid });
      if (!token) {
        return false;
      }

      const options: ConnectOptions = { name: uniqueName, tracks: [] };
      if (!spectatorView) {
        const filteredDevices = deviceState.devices.filter(device => device.kind !== 'audiooutput' && device.active);
        for (const device1 of filteredDevices) {
          const newTrack =
            device1.kind === 'videoinput'
              ? await Video.createLocalVideoTrack(createConstrains(device1))
              : await Video.createLocalAudioTrack({ deviceId: device1.deviceId });
          options.tracks!.push(newTrack);
        }
      }

      cleanupRoom();

      const room = await Video.connect(token, options);
      setRoom(room);
      setSessionStatus(status);
      setConnectedRoomSid(room.sid);

      if (!spectatorView) {
        await retryOperation(
          () => SessionService.addParticipantIdToSession(session.id, { participantId: room.localParticipant.sid }),
          100,
          10,
        );
      }
    } catch (error) {
      console.error(error);
      return false;
    }

    return true;
  };

  const cleanupRoom = () => {
    room?.localParticipant.tracks.forEach((publication: LocalTrackPublication) => {
      publication.unpublish();
      stopLocalTrack(publication.track);
    });
    room?.removeAllListeners();
    room?.disconnect();
  };

  // to feedback part
  const serviceFeedbackStream = () => {
    const feedbackStreamPromise = () =>
      new Promise<any>((resolve, reject) => {
        SessionService.feedbackStream(
          session.id,
          (data: any) => {
            if (data?.session) {
              setClickedFeedback(true);
            }
            resolve(data);
          },
          (error: any) => {
            reject(error);
          },
        );
      });

    return retryOperationAsync(feedbackStreamPromise, 1000, 10);
  };
  // finish stream
  const serviceFinishStream = () => {
    SessionService.endStream(
      session.id,
      (data: any) => {
        handleFinishSessionModal();
        setSessionStatus(SessionStatus.Finished);
        cleanupRoom();
      },
      (error: any) => {
        console.error(error);
      },
    );
  };

  // Scoring item
  const serviceScore = (id: string, value: number) => {
    SessionService.score(
      session.id,
      {
        id,
        value,
      },
      (data: SessionResponseDto) => {
        setScoreResult(data.scoreResult);
      },
      console.error,
    );
  };

  const handleFinishSessionModal = () => {
    setFinishModal(!finishModal);
  };

  const handleScore = (id: string, value: number) => {
    serviceScore(id, value);
  };

  const onRemoteAudioMute = (isMuted: boolean, id?: string) => {
    if (session.trainee.id === currentUser.id || (id && id === session.coach.id)) {
      setCoachAudioMute(isMuted);
    }
    if (session.coach.id === currentUser.id || (id && id === session.trainee.id)) {
      setTraineeAudioMute(isMuted);
    }
  };

  const onRemoteVideoMute = (isMuted: boolean, id?: string) => {
    if (session.trainee.id === currentUser.id || (id && id === session.coach.id)) {
      setCoachVideoMute(isMuted);
    }
    if (session.coach.id === currentUser.id || (id && id === session.trainee.id)) {
      setTraineeVideoMute(isMuted);
    }
  };

  const handleTeachableInput = (value: string) => {
    setTeachableMomentText(value);
  };

  const noteTeachableMoment = () => {
    if (teachableMomentText.trim().length === 0) {
      return;
    }

    SessionService.note(
      session.id,
      { text: teachableMomentText },
      data => {
        handleTeachableInput('');
        setTeachableMoments(data.teachableMoments);
      },
      (error: any) => {
        console.error(error);
      },
    );
  };

  if (!room || !session) return null;

  const _getVideoRecordModalTitle = (): ReactNode => {
    return videoRecordTitleConfig.index != null ? (
      <div className='boldTitle'>
        <span>{(videoRecordTitleConfig.index + 1).toString()}. </span>
        <span>{videoRecordTitleConfig.name}</span>
        <span> - </span>
        {t(scoreI18NKey.get(videoRecordTitleConfig.value)!) ? (
          <span style={{ color: scoreColors.get(videoRecordTitleConfig.value) }}>
            {t(scoreI18NKey.get(videoRecordTitleConfig.value)!)}
          </span>
        ) : (
          <span>{t('session.teachableMoment')}</span>
        )}
      </div>
    ) : (
      <div className='grayTitle'>{t('session.loading')}</div>
    );
  };

  const isReadyForFeedback =
    clickedFeedback ||
    (session.coach.id === currentUser.id && session.coachReadyForFeedback) ||
    (session.trainee.id === currentUser.id && session.traineeReadyForFeedback);

  const renderParticipant = (
    label: string,
    participant: { firstName: string; lastName: string },
    videoMuted: boolean,
    audioMuted: boolean,
  ) => {
    return (
      <div className=''>
        <div className='inline-block'>
          <span className='text-gray-light font-bold'>{label}</span>
          <span className='text-gray-dark font-bold'>{` ${participant.firstName} ${participant.lastName}`}</span>
        </div>
        {(state === COMPONENT_STATE.STREAM || role === USER_ROLES.Spectator) && (
          <div className='inline-block'>
            <div className='inline-block rounded-lg text-negative ml-2.5'>
              <i className={`bi bi-${videoMuted ? 'camera-video-off' : 'camera-video'}`} />
            </div>
            <div className='inline-block rounded-lg text-negative ml-2.5'>
              <i className={`bi bi-${audioMuted ? 'mic-mute' : 'mic'}`} />
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className='flex flex-row justify-between mb-4'>
        <div className='flex shrink-0'>
          <div className=''>
            <div className='font-bold text-lg text-black'>{session.caseTemplateSummary.name}</div>
            {role !== USER_ROLES.SP &&
              session.coach &&
              renderParticipant(t('session.stream.coachName'), session.coach, coachVideoMute, coachAudioMute)}
            {role !== USER_ROLES.Trainee &&
              session.trainee &&
              renderParticipant(t('session.stream.traineeName'), session.trainee, traineeVideoMute, traineeAudioMute)}
          </div>
        </div>
        <div className='flex justify-end text-right space-x-3'>
          <div className='border-r flex flex-col justify-center border-solid border-black-divider pr-3'>
            <div className='text-lg text-black font-bold'>
              {sessionStatus === SessionStatus.Coaching &&
                (isReadyForFeedback ? t('session.stream.waitingForFeedback') : t('session.stream.coachingSession'))}
              {sessionStatus === SessionStatus.Feedback && t('session.stream.feedbackSession')}
              {sessionStatus === SessionStatus.Finished && t('session.stream.finishedSession')}
            </div>
          </div>
          {role !== USER_ROLES.Spectator && (
            <div className={'flex flex-row justify-between items-center'}>
              {sessionStatus === SessionStatus.Coaching && (
                <PrimaryButton
                  className='h-12'
                  title={t('session.stream.startFeedback')}
                  onClick={() => serviceFeedbackStream()}
                  disabled={isReadyForFeedback}
                />
              )}
              {sessionStatus !== SessionStatus.Finished && session.coach.id === currentUser.id && sessionShareToken && (
                <SecondaryButton
                  className='text-primary border-primary hover:text-primary hover:bg-background-black h-12 mr-2 ml-2'
                  title={t('session.stream.invite')}
                  onClick={() => {
                    setInviteCoach(!inviteCoach);
                  }}
                />
              )}
              {sessionStatus === SessionStatus.Feedback &&
                session.coach.id === currentUser.id &&
                sessionRecord?.status != RecordStatus.Aborted && (
                  <SecondaryButton
                    className='text-primary border-primary hover:text-primary hover:bg-background-black h-12  mr-2 ml-2'
                    title={
                      sessionRecord && sessionRecord.status === RecordStatus.Available
                        ? isCoachingSessionRecordVisible
                          ? t('session.recording.hideRecording')
                          : t('session.recording.showRecording')
                        : t('session.recording.prepareRecording')
                    }
                    onClick={() => {
                      const isVisible = !isCoachingSessionRecordVisible;
                      setIsCoachingSessionRecordVisible(isVisible);
                      setSessionRecordManipulation({
                        isMuted: true,
                        isPlaying: false,
                        timeInSeconds: 0,
                      });
                      socket!.sessionRecordManipulated({
                        isMuted: true,
                        isPlaying: false,
                        isVisible: isVisible,
                        sessionId: session.id,
                        timeInSeconds: 0,
                      } as SocketSessionRecordManipulated);
                    }}
                    disabled={!sessionRecord || sessionRecord.status !== RecordStatus.Available}
                  />
                )}
              {sessionStatus !== SessionStatus.Finished && (
                <SecondaryButton
                  className='text-negative border-negative hover:text-negative hover:bg-background-page h-12'
                  title={t('session.stream.endSession')}
                  icon='bi bi-x'
                  onClick={() => handleFinishSessionModal()}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <hr className='w-full h-px bg-black-divider border-0 my-4' />
      {sessionStatus !== SessionStatus.Finished && (
        <>
          <div className='grid grid-cols-12 w-full gap-6'>
            <div className='col-span-7'>
              <RoomView
                room={room}
                onRemoteAudioMute={onRemoteAudioMute}
                onRemoteVideoMute={onRemoteVideoMute}
                role={role}
              />
              <div className='col-span-2 pt-6'>
                {role === USER_ROLES.SP &&
                  sessionStatus === SessionStatus.Coaching &&
                  scoreResult &&
                  Object.values(scoreResult).filter(el => !!el.value).length > 0 && (
                    <>
                      <div className='text-black font-bold text-xl mb-4'>{t('session.scoreResults')}</div>
                      <RecordedOnList scoreResult={scoreResult} />
                    </>
                  )}
                {role === USER_ROLES.SP &&
                  sessionStatus === SessionStatus.Coaching &&
                  teachableMoments &&
                  teachableMoments?.length > 0 && (
                    <>
                      <div className='text-black font-bold text-xl mb-4'>{t('session.teachableMoments')}</div>
                      <TeachableMomentList teachableMoments={teachableMoments} />
                    </>
                  )}
              </div>
            </div>
            {role === USER_ROLES.SP && sessionStatus === SessionStatus.Coaching && !inviteCoach && (
              <div className='col-span-5'>
                {session.caseTemplateSummary.allowTeachableMoments && (
                  <>
                    <div className='mb-2 text-lg text-primary-dark font-bold'>{t('session.teachableMoment')}</div>
                    <TeachableMomentInput
                      value={teachableMomentText}
                      handleInput={handleTeachableInput}
                      noteTeachableMoment={noteTeachableMoment}
                    />
                  </>
                )}
                {scoreResult && (
                  <ScoringMarksList caseData={caseData} scoreResult={scoreResult} handleScore={handleScore} />
                )}
              </div>
            )}
            {inviteCoach && (
              <div className='col-span-5'>
                <div className='mb-2 flex justify-between'>
                  <div className='text-lg text-primary-dark font-bold'>{t('session.stream.inviteUsers')}</div>
                  <div
                    className='text-mg text-primary font-bold flex cursor-pointer'
                    onClick={() => setInviteCoach(false)}>
                    <i className='bi bi-chevron-left mr-2' />
                    <p className='capitalize'>{t('session.stream.backToScoring')}</p>
                  </div>
                </div>
                <hr className='w-full h-px bg-black-divider border-0 mt-3 my-4' />
                <div className='mx-2'>
                  <InviteCoaches url={window.location.href + '/' + sessionShareToken} />
                </div>
              </div>
            )}
            {sessionStatus === SessionStatus.Feedback && (
              <div className='mb-4 mt-6 col-span-5'>
                <div
                  className={
                    role == USER_ROLES.SP ? `grid grid-cols-1` : `2xl:grid 2xl:grid-cols-2 2xl:gap-4`
                  }>
                  <RecordedOnList
                    editRatingsPermission={currentUser.isSuperAdmin || currentUser.id == session.coach.id}
                    sessionStatus={sessionStatus}
                    scoreResult={sessionScoreFromSelector}
                    isStar
                    recordStatus={sessionRecord?.status}
                    isReplay={role == USER_ROLES.SP}
                    onClick={(ms: number) => {
                      const newTimeInSeconds = Math.max(0, Math.floor(ms / DurationMS.SEC));
                      setSessionRecordManipulation({
                        timeInSeconds: 0,
                        isMuted: sessionRecordManipulation?.isMuted ?? true,
                        isPlaying: false,
                      });

                      setTimeout(() => {
                        setSessionRecordManipulation({
                          timeInSeconds: newTimeInSeconds,
                          isMuted: sessionRecordManipulation?.isMuted ?? true,
                          isPlaying: false,
                        });

                        setIsCoachingSessionRecordVisible(true);
                        socket!.sessionRecordManipulated({
                          isMuted: sessionRecordManipulation?.isMuted ?? true,
                          isPlaying: false,
                          isVisible: true,
                          sessionId: session.id,
                          timeInSeconds: newTimeInSeconds,
                        } as SocketSessionRecordManipulated);
                      });
                    }}
                  />
                </div>
                {teachableMoments && teachableMoments?.length > 0 && (
                  <div className='mb-4 mt-6'>
                    <TeachableMomentList isIcon teachableMoments={teachableMoments} />
                  </div>
                )}
              </div>
            )}
          </div>
        </>
      )}
      {finishModal && (
        <AbstractModal
          position={{ width: 'w-1/3', left: 'left-1/3' }}
          label={t('modals.endSession')}
          leftBtn={{
            label: t('buttons.noBackToSession'),
            onClick: () => {
              handleFinishSessionModal();
            },
          }}
          rightBtn={{
            label: t('buttons.yesEndSession'),
            onClick: () => {
              serviceFinishStream();
            },
          }}
          toDelete>
          <ModalContent
            entity='streamFinish'
            title={t('session.finishModal.title')}
            subtitle={t('session.finishModal.subtitle')}
          />
        </AbstractModal>
      )}
      {sessionStatus === SessionStatus.Feedback && isCoachingSessionRecordVisible && sessionRecord && (
        <DraggableModal
          isCloseVisible={role === USER_ROLES.SP}
          title={_getVideoRecordModalTitle()}
          onCloseClick={() => {
            setIsCoachingSessionRecordVisible(false);
            socket!.sessionRecordManipulated({
              isMuted: true,
              isPlaying: false,
              isVisible: false,
              sessionId: session.id,
              timeInSeconds: 0,
            } as SocketSessionRecordManipulated);
          }}>
          <RecordPlayer
            scoreResult={scoreResult}
            teachableMoments={teachableMoments}
            record={sessionRecord}
            error={sessionRecordDownloadError}
            download={sessionRecordDownloadResponse}
            isReadonly={role !== USER_ROLES.SP}
            watchManipulationInfo={sessionRecordManipulation}
            onManipulationCallback={newManipulationInfo => {
              if (role === USER_ROLES.SP) {
                setSessionRecordManipulation({
                  ...sessionRecordManipulation,
                  ...newManipulationInfo,
                });
                socket!.sessionRecordManipulated({
                  isMuted: newManipulationInfo.isMuted,
                  isPlaying: newManipulationInfo.isPlaying,
                  isVisible: isCoachingSessionRecordVisible,
                  sessionId: session.id,
                  timeInSeconds: newManipulationInfo.timeInSeconds,
                } as SocketSessionRecordManipulated);
              }
            }}
            isModal
          />
        </DraggableModal>
      )}
    </>
  );
};

export default VideoChat;
