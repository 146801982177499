import { ApiBaseService, queryToUrl } from './api.service';

import {
  CreateInstitutionRequestDto,
  UpdateInstitutionRequestDto,
  InstitutionResponseDto,
} from './dto/institution.dto';

import { SearchQueryDto } from './dto/general';

export class InstitutionService {
  public static basePath() {
    if (!process.env.API_URL_INSTITUTION_BASE) {
      throw new Error('API_URL_INSTITUTION_BASE does not exist');
    }
    return process.env.API_URL_INSTITUTION_BASE;
  }

  public static create(
    payload: CreateInstitutionRequestDto,
    handleSuccess: (_data: InstitutionResponseDto) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = InstitutionService.basePath();

    ApiBaseService.Post(
      uriPath,
      payload,
      (_code: number, _data: InstitutionResponseDto) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static update(
    id: string,
    payload: UpdateInstitutionRequestDto,
    handleSuccess: (_data: InstitutionResponseDto) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = InstitutionService.basePath() + id;

    ApiBaseService.Patch(
      uriPath,
      payload,
      (_code: number, _data: InstitutionResponseDto) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static byCode(
    institutionCode: string,
    handleSuccess: (_data: InstitutionResponseDto) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = InstitutionService.basePath() + institutionCode;

    ApiBaseService.Get(
      uriPath,
      {},
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static list(
    query: SearchQueryDto,
    handleSuccess: (_data: Array<InstitutionResponseDto>) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = InstitutionService.basePath() + queryToUrl(query);

    ApiBaseService.Get(
      uriPath,
      {},
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static delete(id: string, handleSuccess: () => void, handleError: (err: Error) => void) {
    const uriPath = InstitutionService.basePath() + id;

    ApiBaseService.Delete(
      uriPath,
      (_code: number, _data: void) => {
        handleSuccess();
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static my(handleSuccess: (_data: Array<InstitutionResponseDto>) => void, handleError: (err: Error) => void) {
    const uriPath = InstitutionService.basePath() + 'my';

    ApiBaseService.Get(
      uriPath,
      {},
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }
}
