import { ApiBaseService } from './api.service';
import { CategoryCreateDto, CategoryListItemDto } from './dto/category.dto';

export class CategoryService {
  public static basePath() {
    if (!process.env.API_URL_CATEGORY_BASE) {
      throw new Error('API_URL_SCORING_BASE does not exist');
    }
    return process.env.API_URL_CATEGORY_BASE;
  }

  public static create(
    payload: CategoryCreateDto,
    handleSuccess: (_data: CategoryListItemDto) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = CategoryService.basePath();
    ApiBaseService.Post(
      uriPath,
      payload,
      (_code: number, _data: CategoryListItemDto) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static update(
    payload: CategoryListItemDto,
    handleSuccess: (_data: CategoryListItemDto) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = CategoryService.basePath() + payload.id;
    ApiBaseService.Patch(
      uriPath,
      payload,
      (_code: number, _data: CategoryListItemDto) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static list(handleSuccess: (_data: CategoryListItemDto[]) => void, handleError: (err: Error) => void) {
    const uriPath = CategoryService.basePath();
    ApiBaseService.Get(
      uriPath,
      {},
      (_code: number, _data: CategoryListItemDto[]) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static delete(id: string, handleSuccess: () => void, handleError: (err: Error) => void) {
    const uriPath = CategoryService.basePath() + id;
    ApiBaseService.Delete(
      uriPath,
      (_code: number) => {
        handleSuccess();
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }
}
