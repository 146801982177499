import React from 'react';

interface ITextareaProps {
  value: string | number;
  placeholder?: string;
  handleChange: any;
  error?: string;
  required?: boolean;
  disabled?: boolean;
}

const Textarea = ({ value, placeholder, handleChange, error, required, disabled }: ITextareaProps) => {
  return (
    <>
      <textarea
        className={`custom-input transition py-1.5 px-3 text-base block rounded-lg bg-white bg-clip-padding text-gray-dark placeholder-gray-light hover:border-primary-hover focus:text-black focus:border-primary-hover focus:outline-none resize-none border border-solid border-black-divider appearance-none w-full font-normal ${
          error && 'border-negative hover:border-negative focus:border-negative input-error'
        }`}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        required={required}
        disabled={disabled}
        rows={4}
      />
      {error && <span className='uppercase text-negative pt-1'>{error}</span>}
    </>
  );
};

export default Textarea;
