import React from 'react';

const SuccessButton = ({ label }: { label: string }) => {
  return (
    <button
      type='button'
      className='text-center py-1.5 px-4 select-none pointer-events-none flex items-center mx-auto rounded-lg text-white font-bold bg-positive-light'
    >
      <i className='bi bi-check2 text-lg mr-1'></i>
      <span className='uppercase text-base'>{label}</span>
    </button>
  );
};

export default SuccessButton;
