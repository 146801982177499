import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ScoreTypes } from '../common/enums';
import { isMobile } from 'react-device-detect';

interface IStarConfig {
  mark: ScoreTypes;
  color: string;
}

const starsConfig: IStarConfig[] = [
  {
    mark: ScoreTypes.Good,
    color: 'positive',
  },
  {
    mark: ScoreTypes.Satisfactory,
    color: 'warning',
  },
  {
    mark: ScoreTypes.Unsatisfactory,
    color: 'negative',
  },
];

export interface IRatingMarksProps {
  data: {
    id?: string;
    grades: number;
    name: string;
    listNumber?: number;
  };
  handleScore?: (id: string, value: number) => void;
  savedScore?: number;
  isDisabled?: boolean;
}

const RatingMarks = ({ data, handleScore, savedScore, isDisabled = false }: IRatingMarksProps) => {
  const { t } = useTranslation();
  const [rating, setRating] = useState<ScoreTypes>(savedScore ? savedScore : ScoreTypes.None);
  const [indexHoveredElement, setIndexHoveredElement] = useState<number | undefined>(undefined);

  useEffect(() => {
    setRating(savedScore ? savedScore : ScoreTypes.None);
  }, [savedScore]);

  const handleRating = (score: ScoreTypes) => {
    setRating(score);
    if (handleScore && data.id) {
      handleScore(data.id, score);
    }
  };

  const findStarConfigForRating = (config: IStarConfig[], rating: ScoreTypes) => {
    return config.find(con => con.mark === rating);
  };

  const filterStarsConfig = (config: IStarConfig[], grades: number) => {
    const twoGrades = 2;
    if (grades === twoGrades) {
      return config.filter(star => star.mark !== ScoreTypes.Satisfactory);
    } else {
      return config;
    }
  };

  const calculateIndex = (nativeEvent: any): number => {
    let itemContainer = nativeEvent
      .composedPath()
      .find((el: HTMLElement) => el.classList.contains('rating-mark-item-container'));
    let offsetRelativeToItem = nativeEvent.pageX - itemContainer.offsetLeft;
    let backgroundItems = itemContainer.querySelectorAll('.rating-mark-item-background-part');
    let itemIndex = Math.floor(offsetRelativeToItem / (itemContainer.clientWidth / backgroundItems.length));
    return itemIndex;
  };

  const mouseMove = (event: any) => {
    if (isDisabled || isMobile) {
      return;
    }
    const itemIndex = calculateIndex(event.nativeEvent);
    setIndexHoveredElement(itemIndex);
  };

  const mouseLeaveItem = () => {
    setIndexHoveredElement(undefined);
  };

  const selectSI = (event: any) => {
    if (isDisabled) {
      return;
    }
    const itemIndex = calculateIndex(event.nativeEvent);
    handleRating(filterStarsConfig(starsConfig, data.grades)[itemIndex].mark);
  };

  return (
    <div className='flex'>
      <div
        className={`rating-mark-item-container cursor-pointer ${
          findStarConfigForRating(starsConfig, rating) && !indexHoveredElement && indexHoveredElement != 0
            ? `bg-${findStarConfigForRating(starsConfig, rating)!.color}`
            : ''
        }`}
        onMouseMove={mouseMove}
        onMouseLeave={mouseLeaveItem}
        onClick={selectSI}
      >
        {filterStarsConfig(starsConfig, data.grades).map((star, j, all) => (
          <div
            key={j}
            className={`rating-mark-item-background-part ${!isDisabled && `border-${star.color}`} w-1/${all.length} ${
              star.color
            } ${j === indexHoveredElement && `bg-${star.color}-lightest`}`}
          ></div>
        ))}
        <div
          className={`rating-mark-text ${
            findStarConfigForRating(starsConfig, rating) && !indexHoveredElement && indexHoveredElement != 0
              ? `text-white`
              : `text-black`
          }`}
        >
          {data.listNumber && <strong>{data.listNumber}. </strong>} {data.name}
        </div>
      </div>
    </div>
  );
};

export default RatingMarks;
