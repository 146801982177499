import React from 'react';
import PrimaryButton from './PrimaryButton';
import TextInput from './TextInput';

interface ICopyInputProps {
  value: string;
}

const CopyInput = ({ value }: ICopyInputProps) => {
  return (
    <div className='flex flex-col items-center 2xl:flex-row'>
      <TextInput value={value} readOnly={true} />
      <PrimaryButton
        type='button'
        title='COPY LINK'
        className='mt-2 2xl:mt-0 2xl:ml-6 2xl:w-1/3'
        onClick={() => navigator.clipboard.writeText(value)}
      />
    </div>
  );
};

export default CopyInput;
