import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// UI COMPONENTS
import InputLabel from '../../components/InputLabel';

// UTILS
import { today } from '../../utils';

const ExportXLSForm = ({
  setDateFrom,
  setDateTo,
}: {
  setDateFrom: React.Dispatch<string>;
  setDateTo: React.Dispatch<string>;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className='text-base my-4 text-gray-dark'>{t('sessionLog.xlsExport.description')}</div>
      <div className='grid grid-cols-2 gap-6'>
        <div className='mb-4'>
          <InputLabel label={t('inputLabels.from')} />
          <div className='md-form relative custom-date'>
            <i className='bi bi-calendar absolute z-10 text-gray text-lg top-1.5 left-3.5'></i>
            <input
              type='date'
              className='custom-input transition text-base py-1.5 px-3 block rounded-lg bg-clip-padding w-full font-normal relative custom-date_input text-gray placeholder-gray-light pl-12 pr-2.5 shadow border border-solid border-black-divider focus:border-primary-hover focus:outline-none appearance-none min-h-38'
              onChange={e => {
                setDateFrom(e.target.value);
              }}
              required
            />
          </div>
        </div>
        <div className='mb-4'>
          <InputLabel label={t('inputLabels.to')} />
          <div className='md-form relative custom-date'>
            <i className='bi bi-calendar absolute z-10 text-gray text-lg top-1.5 left-3.5'></i>
            <input
              type='date'
              className='custom-input transition text-base py-1.5 px-3 block rounded-lg bg-clip-padding w-full font-normal relative custom-date_input text-gray placeholder-gray-light pl-12 pr-2.5 shadow border border-solid border-black-divider focus:border-primary-hover focus:outline-none appearance-none min-h-38'
              onChange={e => {
                setDateTo(e.target.value);
              }}
              max={today()}
              required
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ExportXLSForm;
