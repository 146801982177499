import { UpdateUserPreferencesRequestDto, UserPreferencesResponseDto } from './dto/user-preferences.dto';
import { ApiBaseService } from './api.service';

export class UserPreferencesService {
  public static basePath() {
    if (!process.env.API_URL_USER_PREFERENCES) {
      throw new Error('API_URL_USER_REFERENCES does not exist');
    }
    return process.env.API_URL_USER_PREFERENCES;
  }

  public static update(
    id: string,
    payload: UpdateUserPreferencesRequestDto,
    handleSuccess: (_data: UserPreferencesResponseDto) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = UserPreferencesService.basePath() + id;

    ApiBaseService.Patch(
      uriPath,
      payload,
      (_code: number, _data: UserPreferencesResponseDto) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static get(handleSuccess: (_data: UserPreferencesResponseDto) => void, handleError: (err: Error) => void) {
    const uriPath = UserPreferencesService.basePath() + 'current';

    ApiBaseService.Get(
      uriPath,
      {},
      (_code: number, _data: UserPreferencesResponseDto) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }
}
