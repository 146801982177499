import { ApiBaseService } from './api.service';
import {
  InsightsEvaluationCoachFilterDto,
  InsightsEvaluationFilterDto,
  InsightsEvaluationTraineeFilterDto,
  InsightsExportFilterDto,
} from './dto/insights-export.filter.dto';
import {
  InsightsCasesDto,
  InsightsEvaluationCaseResponseDto,
  InsightsEvaluationCoachDetailsResponseDto,
  InsightsEvaluationCoachResponseDto,
  InsightsEvaluationSystemDto,
  InsightsEvaluationTraineeDetailsResponseDto,
  InsightsEvaluationTraineeResponseDto,
  InsightsInstitutionDto, InsightsReportingResponseDto,
} from './dto/insights.dto';

export class InsightsService {
  public static basePath() {
    if (!process.env.API_URL_INSIGHTS) {
      throw new Error('API_URL_INSIGHTS does not exist');
    }
    return process.env.API_URL_INSIGHTS;
  }

  public static getInstitution(
    payload: InsightsExportFilterDto,
    handleSuccess: (_data: InsightsInstitutionDto) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = InsightsService.basePath() + 'institution';

    ApiBaseService.Post(
      uriPath,
      payload,
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static getCases(
    payload: InsightsExportFilterDto,
    handleSuccess: (_data: InsightsCasesDto) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = InsightsService.basePath() + 'case';
    ApiBaseService.Post(
      uriPath,
      payload,
      (_code: number, _data: any) => {
        const scoringItem = new Map();
        for (const key of Object.keys(_data.averageScoringItem)) {
          scoringItem.set(key.toString(), _data.averageScoringItem[key]);
        }
        _data.averageScoringItem = scoringItem;
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static getEvaluationTrainee(
    payload: InsightsEvaluationFilterDto,
    handleSuccess: (_data: InsightsEvaluationTraineeResponseDto[]) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = InsightsService.basePath() + 'trainee';
    ApiBaseService.Post(
      uriPath,
      payload,
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static getEvaluationCoach(
    payload: InsightsEvaluationFilterDto,
    handleSuccess: (_data: InsightsEvaluationCoachResponseDto[]) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = InsightsService.basePath() + 'coach';
    ApiBaseService.Post(
      uriPath,
      payload,
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static getEvaluationCase(
    payload: InsightsEvaluationFilterDto,
    handleSuccess: (_data: InsightsEvaluationCaseResponseDto) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = InsightsService.basePath() + 'cases';
    ApiBaseService.Post(
      uriPath,
      payload,
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static getEvaluationSystem(
    payload: InsightsEvaluationFilterDto,
    handleSuccess: (_data: InsightsEvaluationSystemDto[]) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = InsightsService.basePath() + 'system';
    ApiBaseService.Post(
      uriPath,
      payload,
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static getEvaluationTraineeCount(
    payload: InsightsEvaluationFilterDto,
    handleSuccess: (_data: number) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = InsightsService.basePath() + 'trainee/count';
    ApiBaseService.Post(
      uriPath,
      payload,
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static getEvaluationCoachCount(
    payload: InsightsEvaluationFilterDto,
    handleSuccess: (_data: number) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = InsightsService.basePath() + 'coach/count';
    ApiBaseService.Post(
      uriPath,
      payload,
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static getEvaluationCasesCount(
    payload: InsightsEvaluationFilterDto,
    handleSuccess: (_data: number) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = InsightsService.basePath() + 'cases/count';
    ApiBaseService.Post(
      uriPath,
      payload,
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static getEvaluationSystemCount(
    payload: InsightsEvaluationFilterDto,
    handleSuccess: (_data: number) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = InsightsService.basePath() + 'system/count';
    ApiBaseService.Post(
      uriPath,
      payload,
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static getEvaluationTraineeDetails(
    payload: InsightsEvaluationTraineeFilterDto,
    handleSuccess: (_data: InsightsEvaluationTraineeDetailsResponseDto) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = InsightsService.basePath() + 'trainee/details';
    ApiBaseService.Post(
      uriPath,
      payload,
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static getEvaluationCoachDetails(
    payload: InsightsEvaluationCoachFilterDto,
    handleSuccess: (_data: InsightsEvaluationCoachDetailsResponseDto) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = InsightsService.basePath() + 'coach/details';
    ApiBaseService.Post(
      uriPath,
      payload,
      (_code: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static getReporting(
      payload: InsightsEvaluationFilterDto,
      handleSuccess: (_data:InsightsReportingResponseDto[]) => void,
      handleError: (err:Error) => void,
  ) {
    const uriPath = InsightsService.basePath() + 'reporting';
    ApiBaseService.Post(
        uriPath,
        payload,
        (_code: number, _data: any) => {
          handleSuccess(_data);
        },
        (err:Error) => {
          handleError(err);
        },
    );
  }
}
