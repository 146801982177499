import React, { ReactElement, RefObject, useEffect, useRef, useState } from 'react';
import {
  AudioTrack,
  LocalAudioTrack,
  LocalTrack,
  LocalVideoTrack,
  Participant,
  RemoteTrack,
  RemoteTrackPublication,
  VideoTrack,
} from 'twilio-video';

import { MediaDeviceDto } from '../../../service/dto/MediaDevice.dto';

// REDUX
import { DeviceState } from '../../../redux/types';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../redux/store';

const ParticipantComponent = ({
  participant,
  placeholder,
  isRemote,
  onRemoteAudioMute,
  onRemoteVideoMute,
  remoteAudioPaused,
  audioPlayPause,
  speaker,
  className,
  classNameMuted,
  classNameNoVideo,
}: {
  participant: Participant;
  placeholder: ReactElement;
  isRemote: boolean;
  onRemoteAudioMute: (isMuted: boolean, identity: Participant.Identity) => void;
  onRemoteVideoMute: (isMuted: boolean, identity: Participant.Identity) => void;
  remoteAudioPaused: boolean;
  audioPlayPause: (audioRef: RefObject<HTMLAudioElement>) => void;
  speaker: MediaDeviceDto | undefined;
  className?: string;
  classNameMuted?: string;
  classNameNoVideo?: string;
}) => {
  const deviceState: DeviceState = useAppSelector((state: any) => state.devicesConfig);
  const [videoTrack, setVideoTrack] = useState<VideoTrack | null>(null);
  const [audioTrack, setAudioTrack] = useState<AudioTrack | null>(null);

  const [remoteVideoMute, setRemoteVideoMute] = useState<boolean>(false);

  const { t } = useTranslation();

  const videoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    // audioPlayPause(audioRef);
  }, [remoteAudioPaused]);

  useEffect(() => {
    const vT = participant.videoTracks.size ? participant.videoTracks.values().next().value.track : null;
    const aT = participant.audioTracks.size ? participant.audioTracks.values().next().value.track : null;
    if (!isRemote) {
      const videoDevice = deviceState.devices.find(device => device.kind === 'videoinput' && device.active);
      if (vT && videoDevice && !videoDevice.enabled) {
        (vT as LocalVideoTrack).disable();
      }
      const audioDevice = deviceState.devices.find(device => device.kind === 'audioinput' && device.active);
      if (aT && audioDevice && !audioDevice.enabled) {
        (aT as LocalAudioTrack).disable();
      }
    }
    setVideoTrack(vT);
    setAudioTrack(aT);

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackPublished', trackPublished);
    participant.on('trackUnsubscribed', trackUnsubscribed);

    return () => {
      videoTrack?.detach();
      if (videoTrack instanceof LocalVideoTrack) {
        (videoTrack as LocalVideoTrack)?.stop();
      }
      audioTrack?.detach();
      if (audioTrack instanceof LocalAudioTrack) {
        (audioTrack as LocalAudioTrack)?.stop();
      }
      setVideoTrack(null);
      setAudioTrack(null);
      participant.removeAllListeners();
    };
  }, [participant]);

  const trackSubscribed = (track: RemoteTrack) => {
    handleTrack(track);
  };

  const trackPublished = (publication: RemoteTrackPublication) => {
    const track = publication.track;
    if (!track) {
      return;
    }
    handleTrack(track);
  };

  const handleTrack = (track: LocalTrack | RemoteTrack) => {
    if (track.kind === 'video') {
      if (!track.isEnabled) {
        setRemoteVideoMute(true);
        onRemoteVideoMute(true, participant.identity);
      }
      videoTrack?.detach();
      if (track instanceof LocalVideoTrack) {
        (videoTrack as LocalVideoTrack)?.stop();
      }
      setVideoTrack(track as VideoTrack);
      track.on('enabled', () => {
        setRemoteVideoMute(false);
        onRemoteVideoMute(false, participant.identity);
      });
      track.on('disabled', () => {
        setRemoteVideoMute(true);
        onRemoteVideoMute(true, participant.identity);
      });
    } else if (track.kind === 'audio') {
      if (!track.isEnabled) {
        onRemoteAudioMute(true, participant.identity);
      }
      audioTrack?.detach();
      if (track instanceof LocalAudioTrack) {
        (audioTrack as LocalAudioTrack)?.stop();
      }
      setAudioTrack(track as AudioTrack);
      track.on('enabled', () => {
        onRemoteAudioMute(false, participant.identity);
      });
      track.on('disabled', () => {
        onRemoteAudioMute(true, participant.identity);
      });
    }
  };

  const trackUnsubscribed = (track: RemoteTrack | LocalTrack) => {
    track.removeAllListeners();
  };

  useEffect(() => {
    const el = videoRef.current;
    if (videoTrack && el) {
      videoTrack.attach(el);
      return () => {
        videoTrack.detach(el);
        // This addresses a Chrome issue where the number of WebMediaPlayers is limited.
        el.srcObject = null;
      };
    }
  }, [videoTrack, remoteVideoMute]);

  useEffect(() => {
    const el = audioRef.current;
    if (audioTrack && el) {
      audioTrack.attach(el);
      // audioPlayPause(audioRef);
      return () => {
        audioTrack.detach(el);
        el.srcObject = null;
      };
    }
  }, [audioTrack]);

  const changeSpeakers = async (device: MediaDeviceDto) => {
    if (isRemote) {
      await (document.getElementsByTagName('audio')[0] as any).setSinkId(device.deviceId);
    }
  };

  useEffect(() => {
    if (speaker) {
      changeSpeakers(speaker);
    }
  }, [speaker]);

  const renderVideo = () => {
    if (isRemote && remoteVideoMute) {
      return (
        <div
          className={`flex flex-col items-center justify-center bg-primary rounded-lg ${
            classNameMuted ? classNameMuted : ''
          } `}
        >
          <div className='relative bg-primary-light text-white rounded-full w-34 h-34'>
            <div className='uppercase absolute text-5.3xl top-1/2 left-1/2 font-bold -translate-x-1/2 -translate-y-1/2'>
              {placeholder}
            </div>
          </div>
        </div>
      );
    } else if (isRemote && !videoTrack) {
      return (
        <div
          className={`flex flex-col items-center justify-center bg-primary rounded-lg ${
            classNameNoVideo ? classNameNoVideo : ''
          }`}
        >
          <div className='text-center text-white w-1/2 waiting-user_content'>
            <div className='mb-6 font-bold text-xl'>{t('session.participant.noVideoTitle')}</div>
            <div className='text-base'>{t('session.participant.noVideoInfo')}</div>
          </div>
        </div>
      );
    } else {
      return (
        <video
          ref={videoRef}
          autoPlay={true}
          muted
          playsInline={true}
          className={`rounded-lg ${className ? className : ''}`}
        />
      );
    }
  };

  return (
    <div className='participant'>
      {renderVideo()}
      {isRemote && <audio ref={audioRef} autoPlay={true} />}
    </div>
  );
};

export default ParticipantComponent;
