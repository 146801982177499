import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import InputLabel from '../../../components/InputLabel';
import { IRouteComponent } from '../../../routes';
import { CertificationStatus } from '../../../service/dto/certification.dto';
import { ScoringResponseDto } from '../../../service/dto/scoring.dto';
import PrimaryButton from '../../../components/PrimaryButton';
import SecondaryButton from '../../../components/SecondaryButton';
import { UserSummaryResponseDto } from '../../../service/dto/user-summary.response.dto';
import AbstractModal from '../../../components/AbstractModal';
import Tag, { TagType } from '../../../components/Tag';
import {
  thunkCertificationAcceptRequest,
  thunkCertificationApproveRequest,
  thunkCertificationDeleteRequest,
  thunkCertificationInfoLoad,
} from './thunks';
import { selectorCertificationDetails } from '../selectors';
import { actionCertificationReset, actionCertificationRoomUpdate } from '../actions';
import RecordsTabs, { RecordTabType, TabContent } from '../../schedule/record/RecordsTabs';
import { RecordStatus } from '../../../common/enums';
import { useAppDispatch, useAppSelector } from '../../../redux/store';

const CertificationDetailView = ({ currentUser }: IRouteComponent) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { certification, certifiedCoaches } = useAppSelector(selectorCertificationDetails);
  const [acceptCertificationPopupVisible, setAcceptCertificationPopupVisible] = useState(false);
  const [deleteCertificationPopupVisible, setDeleteCertificationPopupVisible] = useState(false);

  const canCertify = () => {
    if (certification.type != 'SUCCESS') {
      return false;
    }

    const { result } = certification;
    return (
      result.status == CertificationStatus.Accepted && result.isCertified && result.certifiedCoach.id == currentUser.id
    );
  };
  const canAccept = () => {
    if (certification.type != 'SUCCESS') {
      return false;
    }

    const { result } = certification;
    return result.status == CertificationStatus.Open && result.isCertified;
  };
  const canStartTraining = () => {
    if (certification.type != 'SUCCESS') {
      return false;
    }

    const { result } = certification;
    if (result.status != CertificationStatus.Accepted) {
      return false;
    }

    return [result.certifiedCoach.id, result.noviceCoach.id].indexOf(currentUser.id) != -1;
  };

  useEffect(() => {
    dispatch(thunkCertificationInfoLoad(id!));
  }, []);

  const renderSessionRecords = (): React.ReactElement | null => {
    if (
      !certification /*|| (certification.type === 'SUCCESS' && certification.result.status !== CertificationStatus.Certified)*/
    ) {
      return null;
    }
    const filteredRecords: TabContent[] = [
      {
        title: t('certificationDetail.coachingRecordingLabel'),
        type: RecordTabType.RecordType,
        data: {
          isReadonly: false,
          record:
            certification.type === 'SUCCESS' &&
            certification.result.sessionProgress.coachingSessionRecord &&
            (certification.result.sessionProgress.coachingSessionRecord.status === RecordStatus.Progress ||
              certification.result.sessionProgress.coachingSessionRecord.status === RecordStatus.Available)
              ? certification.result.sessionProgress.coachingSessionRecord
              : undefined,
        },
      },
      {
        title: t('certificationDetail.feedbackRecordingLabel'),
        type: RecordTabType.RecordType,
        data: {
          isReadonly: false,
          record:
            certification.type === 'SUCCESS' &&
            certification.result.sessionProgress.feedbackSessionRecord &&
            (certification.result.sessionProgress.feedbackSessionRecord.status === RecordStatus.Progress ||
              certification.result.sessionProgress.feedbackSessionRecord.status === RecordStatus.Available)
              ? certification.result.sessionProgress.feedbackSessionRecord
              : undefined,
        },
      },
    ].filter(x => x.type !== RecordTabType.RecordType || x.data.record);
    if (!filteredRecords.length) {
      return null;
    }
    return <RecordsTabs tabs={filteredRecords} />;
  };

  const startTraining = () => {
    if (certification.type != 'SUCCESS') {
      return;
    }

    if (certification.result.status != 'status:accepted') {
      return;
    }

    dispatch(actionCertificationRoomUpdate);
    navigate(`/certification/room/${id}`, { replace: true });
  };

  switch (certification.type) {
    case 'EMPTY':
    case 'LOADING':
      return null;
    case 'DELETED':
      dispatch(actionCertificationReset());
      navigate('/certification', { replace: true });
      return null;
    case 'ERROR':
      return <div>Error: {certification.error.toString()}</div>;
    case 'SUCCESS':
      return (
        <div className='my-6 mx-4 2xl:mx-6'>
          <div className='w-full flex items-center justify-between py-2'>
            <div className='font-bold text-lg text-black flex'>
              <div>{t('certificationDetail.title')}</div>
              <div className='ml-6'>
                {certification.result.status == CertificationStatus.Open && <Tag label='Open' type={TagType.Red} />}
                {certification.result.status == CertificationStatus.Accepted && (
                  <Tag label='Accepted' type={TagType.Yellow} />
                )}
                {certification.result.status == CertificationStatus.Certified && (
                  <Tag label='Certified' type={TagType.Green} />
                )}
              </div>
            </div>
            {certification.result.noviceCoach.id === currentUser.id &&
              certification.result.status != CertificationStatus.Certified && (
                <SecondaryButton
                  className='text-negative border-negative hover:text-negative hover:bg-background-black'
                  title={t('certificationDetail.deleteAction')}
                  icon='bi bi-trash'
                  onClick={() => setDeleteCertificationPopupVisible(true)}
                />
              )}
            {canAccept() && (
              <PrimaryButton
                title={t('certificationDetail.acceptAction')}
                onClick={() => dispatch(thunkCertificationAcceptRequest())}
              />
            )}
            {canCertify() && (
              <PrimaryButton
                title={t('certificationDetail.certifyAction')}
                onClick={() => setAcceptCertificationPopupVisible(true)}
              />
            )}
          </div>
          <hr className='w-full h-px bg-black-divider border-0 mt-4 mb-6' />
          <div className='flex justify-between'>
            <div className='w-4/6 lg:w-1/2'>
              <div className='grid grid-cols-1'>
                <div>
                  <InputLabel label={t('certificationDetail.caseTitle')} />
                  <p className='font-bold text-lg text-black'>{certification.result.caseTemplate.name}</p>
                  <hr className='w-full h-px bg-black-divider border-0 my-6' />
                </div>
                <div>
                  <InputLabel label={t('certificationDetail.noviceCoachLabel')} />
                  <p className='text-black text-lg mb-6'>
                    {certification.result.noviceCoach.firstName + ' ' + certification.result.noviceCoach.lastName}
                  </p>
                  <>
                    <InputLabel label={t('certificationDetail.certifiedCoachLabel')} />

                    {certification.result.certifiedCoach ? (
                      <p className='text-black text-lg mb-6'>
                        {certification.result.certifiedCoach.firstName +
                          ' ' +
                          certification.result.certifiedCoach.lastName}
                      </p>
                    ) : (
                      <p className='text-black text-lg mb-6 italic'>{t('certificationDetail.noCertifiedTrainer')}</p>
                    )}
                  </>

                  <InputLabel label={t('certificationDetail.caseDescriptionLabel')} />
                  <p className='text-black text-lg mb-6'>{certification.result.caseTemplate.description}</p>

                  <InputLabel label={t('certificationDetail.scoringItemsLabel')} />
                  <ul className='mx-0 mt-0 mb-6 list-disc pl-6'>
                    {certification.result.caseTemplate.scoring_items.map((scoringItem: ScoringResponseDto) => (
                      <li className='text-black text-lg' key={'k_' + scoringItem.id}>
                        {scoringItem.name}
                      </li>
                    ))}
                  </ul>

                  <InputLabel label={t('certificationDetail.certifiedCoachesLabel')} />
                  {certifiedCoaches.type == 'SUCCESS' && (
                    <ul className='mx-0 mt-0 mb-6 list-disc pl-6'>
                      {certifiedCoaches.result.map((coach: UserSummaryResponseDto) => (
                        <li className='text-black text-lg' key={'k_' + coach.id}>
                          {coach.firstName} {coach.lastName}
                        </li>
                      ))}
                    </ul>
                  )}

                  <InputLabel label={t('certificationDetail.caseInstructionsLabel')} />
                  <p className='text-black text-lg mb-6'>{certification.result.caseTemplate.instructions}</p>

                  {renderSessionRecords()}
                </div>
              </div>
            </div>

            {canStartTraining() && (
              <div className='w-2/6 lg:w-3/12'>
                <div className='text-center bg-white py-6 px-2 rounded-lg'>
                  <div className='mx-4 mb-6'>{t('certificationDetail.trainingBoxTitle')}</div>
                  <hr />
                  <div className='mt-6'>
                    <p>{t('certificationDetail.trainingBoxText')}</p>
                  </div>
                  <div className='flex justify-center mt-12'>
                    <PrimaryButton title={t('certificationDetail.startTrainingAction')} onClick={startTraining} />
                  </div>
                </div>
              </div>
            )}
          </div>
          {deleteCertificationPopupVisible && certification.type == 'SUCCESS' && (
            <AbstractModal
              position={{ width: 'w-1/3', left: 'left-1/3' }}
              label={t('deleteCertificationModal.title')}
              leftBtn={{
                label: t('deleteCertificationModal.cancel'),
                onClick: () => setDeleteCertificationPopupVisible(false),
              }}
              rightBtn={{
                label: t('deleteCertificationModal.delete'),
                onClick: () => {
                  dispatch(thunkCertificationDeleteRequest());
                  setDeleteCertificationPopupVisible(false);
                },
              }}
              toDelete
            >
              <div>{t('deleteCertificationModal.text')}</div>
            </AbstractModal>
          )}

          {acceptCertificationPopupVisible && certification.type == 'SUCCESS' && (
            <AbstractModal
              position={{ width: 'w-1/3', left: 'left-1/3' }}
              label={t('certifyRequestModal.title')}
              leftBtn={{
                label: t('certifyRequestModal.cancel'),
                onClick: () => {
                  setAcceptCertificationPopupVisible(false);
                },
              }}
              rightBtn={{
                label: t('certifyRequestModal.approve'),
                onClick: () => {
                  setAcceptCertificationPopupVisible(false);
                  dispatch(thunkCertificationApproveRequest());
                },
              }}
              toDelete
            >
              <div>
                {t('certifyRequestModal.text', {
                  user: `${certification.result.noviceCoach.firstName} ${certification.result.noviceCoach.lastName}`,
                  case: certification.result.caseTemplate.name,
                })}
              </div>
            </AbstractModal>
          )}
        </div>
      );
    default:
      throw 'Can not read type';
  }
};

export default CertificationDetailView;
