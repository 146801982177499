import { ApiBaseService } from './api.service';

import {
  SignupRequestDto,
  SigninRequestDto,
  UserPaswordResetDto,
  UserPaswordForgotDto,
  SigninResponseDto,
  UserSummary,
} from './dto/auth.dto';

export class AuthService {
  public static basePath() {
    if (!process.env.API_URL_USER) {
      throw new Error('API_URL_USER does not exist');
    }
    return process.env.API_URL_USER;
  }

  public static signin(
    signinRequest: SigninRequestDto,
    handleSuccess: (_data: SigninResponseDto) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = AuthService.basePath() + 'signin';

    ApiBaseService.Post(
      uriPath,
      signinRequest,
      (_status: number, _data: any) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static CurrentUser(handleSuccess: (data: UserSummary) => void, handleError: (err: Error) => void) {
    const uriPath = AuthService.basePath() + 'current';

    ApiBaseService.Get(
      uriPath,
      {},
      (_status: number, data: UserSummary) => {
        handleSuccess(data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static signup(signupRequest: SignupRequestDto, handleSuccess: () => void, handleError: (err: Error) => void) {
    const uriPath = AuthService.basePath() + 'signup';

    ApiBaseService.Post(
      uriPath,
      signupRequest,
      (_status: number, _data: any) => {
        handleSuccess();
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static signout(handleSuccess: () => void, handleError: (err: Error) => void) {
    const uriPath = AuthService.basePath() + 'signout';

    ApiBaseService.Post(
      uriPath,
      {},
      (_status: number, _data: any) => {
        handleSuccess();
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static passwordForgot(
    userPaswordForgotDto: UserPaswordForgotDto,
    handleSuccess: () => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = AuthService.basePath() + 'requestpasswordreset';

    ApiBaseService.Post(
      uriPath,
      userPaswordForgotDto,
      (_status: number, _data: any) => {
        handleSuccess();
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static passwordReset(
    userPasswordReset: UserPaswordResetDto,
    handleSuccess: () => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = AuthService.basePath() + 'resetpassword';

    ApiBaseService.Post(
      uriPath,
      userPasswordReset,
      (_status: number, _data: any) => {
        handleSuccess();
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static userConfirmation(confirmationId: string, handleSuccess: () => void, handleError: (err: Error) => void) {
    const uriPath = AuthService.basePath() + 'confirm/' + confirmationId;

    ApiBaseService.Get(
      uriPath,
      {},
      (_status: number, _data: any) => {
        handleSuccess();
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }
}
