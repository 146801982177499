import React from 'react';

import PrimaryButton from '../../components/PrimaryButton';

interface ITimerComponentProps {
  infoText: string;
  buttonTitle: string;
  timerView?: string;
  timerContent?: string;
  disableButton: boolean;
  handleWidget: () => any;
  showSecondButton?: boolean;
  secondButtonTitle?: string;
  secondButtonAction?: Function;
}

const TimerComponent = ({
  infoText,
  buttonTitle,
  timerView,
  timerContent,
  disableButton,
  handleWidget,
  showSecondButton,
  secondButtonTitle,
  secondButtonAction,
}: ITimerComponentProps) => {
  return (
    <div className='text-center bg-white py-6 px-2 rounded-lg min-h-64.5'>
      <div className='mx-4 mb-6'>{infoText}</div>
      <div className='w-7/12 mt-0 mx-auto mb-6'>
        <div className='circle py-1/2 px-0 inline-block bg-primary-lightest w-full h-0 rounded-full'>
          <div className='-mt-1/5 font-bold text-gray text-1.1vw'>{timerView}</div>
          <div className='font-bold text-black text-1.6vw'>{timerContent}</div>
        </div>
      </div>
      <div className='w-full'>
        <PrimaryButton
          title={buttonTitle}
          onClick={() => {
            handleWidget();
          }}
          disabled={disableButton}
        />
      </div>
      <div className='w-full mt-4'>
        {showSecondButton && (
          <PrimaryButton
            title={secondButtonTitle || ''}
            onClick={() => {
              secondButtonAction && secondButtonAction();
            }}
          />
        )}
      </div>
    </div>
  );
};

export default TimerComponent;
