import {
  CertificationListQuery,
  CertificationResponseDto,
  CertificationResponseWithProgressDto,
  CreateCertificationRequestDto,
} from './dto/certification.dto';
import { ApiBaseService } from './api.service';
import {
  SessionAddParticipantRequestDto,
  SessionRecordRecordingResponseDto,
  SessionRecordResponseDto,
  SessionScoreRequestDto,
  SessionTeachableMomentRequestDto,
} from './dto/session.dto';

export class CertificationService {
  public static basePath() {
    if (!process.env.API_URL_CERTIFICATION_BASE) {
      throw new Error('API_URL_CERTIFICATION_BASE does not exist');
    }
    return process.env.API_URL_CERTIFICATION_BASE;
  }

  public static create(
    payload: CreateCertificationRequestDto,
    handleSuccess: (_data: CertificationResponseDto) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = CertificationService.basePath();

    ApiBaseService.Post(
      uriPath,
      payload,
      (_code: number, _data: CertificationResponseDto) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static accept(
    id: string,
    handleSuccess: (_data: CertificationResponseDto) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = CertificationService.basePath() + 'accept/' + id;

    ApiBaseService.Post(
      uriPath,
      {},
      (_code: number, _data: CertificationResponseDto) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static certify(
    id: string,
    handleSuccess: (_data: CertificationResponseDto) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = CertificationService.basePath() + 'certify/' + id;

    ApiBaseService.Post(
      uriPath,
      {},
      (_code: number, _data: CertificationResponseDto) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static delete(id: string, handleSuccess: () => void, handleError: (err: Error) => void) {
    const uriPath = CertificationService.basePath() + id;

    ApiBaseService.Delete(uriPath, handleSuccess, (err: Error) => {
      handleError(err);
    });
  }

  public static list(
    payload: CertificationListQuery,
    handleSuccess: (_data: CertificationResponseDto[]) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = CertificationService.basePath() + 'list';

    ApiBaseService.Post(
      uriPath,
      payload,
      (_code: number, _data: CertificationResponseDto[]) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static getById(
    id: string,
    handleSuccess: (_data: CertificationResponseDto) => void,
    handleError: (err: Error) => void,
  ) {
    const uriPath = CertificationService.basePath() + id;

    ApiBaseService.Get(
      uriPath,
      {},
      (_code: number, _data: CertificationResponseDto) => {
        handleSuccess(_data);
      },
      (err: Error) => {
        handleError(err);
      },
    );
  }

  public static join(id: string): Promise<CertificationResponseWithProgressDto> {
    const uriPath = CertificationService.basePath() + `room/join/${id}`;
    return new Promise<CertificationResponseWithProgressDto>((resolve, reject) => {
      ApiBaseService.Get(uriPath, {}, (_, data) => resolve(data), reject);
    });
  }

  public static swapRoles(id: string): Promise<Record<string, never>> {
    const uriPath = CertificationService.basePath() + `room/swap/${id}`;
    return new Promise<Record<string, never>>((resolve, reject) => {
      ApiBaseService.Get(uriPath, {}, (_, data) => resolve(data), reject);
    });
  }

  public static toggleFeedback(id: string): Promise<Record<string, never>> {
    const uriPath = CertificationService.basePath() + `room/feedback/${id}`;
    return new Promise<Record<string, never>>((resolve, reject) => {
      ApiBaseService.Get(uriPath, {}, (_, data) => resolve(data), reject);
    });
  }

  public static score(id: string, payload: SessionScoreRequestDto): Promise<CertificationResponseDto> {
    const uriPath = CertificationService.basePath() + 'room/score/' + id;
    return new Promise<CertificationResponseDto>((resolve, reject) => {
      ApiBaseService.Patch(
        uriPath,
        payload,
        (_, data: CertificationResponseDto) => {
          resolve(data);
        },
        reject,
      );
    });
  }

  public static note(id: string, payload: SessionTeachableMomentRequestDto): Promise<CertificationResponseDto> {
    const uriPath = CertificationService.basePath() + 'note/' + id;
    return new Promise<CertificationResponseDto>((resolve, reject) => {
      ApiBaseService.Patch(
        uriPath,
        payload,
        (_code: number, _data: CertificationResponseDto) => {
          resolve(_data);
        },
        reject,
      );
    });
  }

  public static restart(id: string): Promise<Record<string, never>> {
    const uriPath = CertificationService.basePath() + `room/restart/${id}`;
    return new Promise<Record<string, never>>((resolve, reject) => {
      ApiBaseService.Get(uriPath, {}, (_, data) => resolve(data), reject);
    });
  }

  public static end(id: string): Promise<Record<string, never>> {
    const uriPath = CertificationService.basePath() + `room/end/${id}`;
    return new Promise<Record<string, never>>((resolve, reject) => {
      ApiBaseService.Get(uriPath, {}, (_, data) => resolve(data), reject);
    });
  }

  public static addParticipantIdToSession(id: string, payload: SessionAddParticipantRequestDto) {
    const uriPath = CertificationService.basePath() + 'participant/' + id;

    return new Promise<SessionRecordResponseDto>((resolve, reject) =>
      ApiBaseService.Patch(
        uriPath,
        payload,
        (_code: number, _data: SessionRecordResponseDto) => resolve(_data),
        (err: Error) => reject(err),
      ),
    );
  }

  public static toggleSessionRecording(sessionId: string): Promise<SessionRecordRecordingResponseDto> {
    const uriPath = `${CertificationService.basePath()}recording/${sessionId}`;
    return new Promise<SessionRecordRecordingResponseDto>((resolve, reject) =>
      ApiBaseService.Patch(
        uriPath,
        {},
        (code: number, data: SessionRecordRecordingResponseDto) => resolve(data),
        (err: Error) => reject(err),
      ),
    );
  }
}
