import React from 'react';

interface IMessageBlockProps {
  type: string;
  message: string;
}

const stylesBlock = new Map([
  ['errorBlock', 'bg-negative-lightest'],
  ['warningBlock', 'bg-warning-lightest border border-solid border-warning-light'],
]);
const stylesIcon = new Map([
  ['errorBlock', 'text-negative'],
  ['warningBlock', 'text-warning'],
]);

const MessageBlock = ({ type, message }: IMessageBlockProps) => {
  return (
    <div className={`rounded-lg p-1 ${stylesBlock.get(type)}`}>
      <i className={`bi bi-exclamation-circle mx-1.5 my-0 ${stylesIcon.get(type)}`}></i>
      {message}
    </div>
  );
};

export default MessageBlock;
