import React from 'react';
import { io, Socket } from 'socket.io-client';
import { SocketChannels } from '../service/socket-channels.enum';
import { COMPONENT_STATE, SessionStatus } from '../common/enums';
import {
  actionSetEndSession,
  actionSetRecordStatus,
  actionSetRoomStatus,
  actionSetSessionManipulationListener,
  actionSetStartFeedback,
  setEndSession,
  setRecordStatus,
  setRoomStatus,
  setSessionManipulationListener,
  setSessionScoreResult, setSessionScoreValue,
  setStartFeedback,
} from '../redux/actions';
import { StartFeedbackPayload } from '../module/schedule/socket-start-feedback-payload.interface';
import {ScoreResultDto, SessionRecordResponseDto} from '../service/dto/session.dto';
import { SocketSessionRecordManipulated } from '../module/schedule/socket-session-record-manipulated.interface';
import { WsType } from '../common/interfaces';
import { actionCertificationRoomUpdate } from '../module/certification/actions';
import Alert from '../utils/alert';
import {useAppDispatch, useAppSelector} from '../redux/store';

const WebSocketContext = React.createContext<WsType | null>(null);

export default ({ children }: any) => {
  let socket: Socket | null = null;
  let ws: WsType | null = null;

  const dispatch = useAppDispatch();

  const joinRoom = (sessionId: string, userId: string) => {
    socket!.emit(SocketChannels.RoomJoined, { sessionId, userId });
  };

  const getRoomStatus = (sessionId: string, userId: string) => {
    socket!.emit(SocketChannels.RoomGetStatus, { sessionId, userId });
  };

  const sessionRecordManipulated = (record: SocketSessionRecordManipulated) => {
    socket!.emit(SocketChannels.SessionRecordManipulated, record);
  };

  const coachSubscribeToAdHoc = (userId: string) => {
    socket!.emit(SocketChannels.SubscribeCoachToAdHoc, userId);
  };

  const coachUpdateRating = (sessionId: string) => {
    socket!.emit(SocketChannels.RatingUpdate, sessionId);
  };

  const emitDisconnect = () => {
    socket!.emit(SocketChannels.Disconnect);
  };

  if (!socket) {
    socket = io(process.env.API_URL_PREFIX as string);
    socket.on(SocketChannels.RoomStatus, (data: any) => {
      // if 2 participants present -> start stream
      if (data.clients && data.clients.length == 2) {
        dispatch(actionSetRoomStatus({ componentState: COMPONENT_STATE.STREAM }));
      }

      // if 1 participant present -> wait status
      if (data.clients && data.clients.length == 1) {
        dispatch(actionSetRoomStatus({ componentState: COMPONENT_STATE.WAITING }));
      }
    });

    socket.on(SocketChannels.TraineeJoinAdHocSession, (data: any) => {
      Alert.success(
        `Trainee has joined your live session, click button <a href='#/session/details/${data.sessionId}' style='
    text-decoration: underline;
    font-weight: 700;
    font-size: 15px;'>here</a> to join as well.`,
        { html: true, pauseOnHover: true },
      );
    });

    socket.on(SocketChannels.StartFeedback, (data: StartFeedbackPayload) => {
      dispatch(
        actionSetStartFeedback({
          sessionStatus: SessionStatus.Feedback,
          startFeedbackPayload: data.startFeedbackPayload,
        }),
      );
    });

    socket.on(SocketChannels.RatingUpdate, (sessionId: string, scoreId: string, scoreValue: number) => {
      dispatch(setSessionScoreValue({
        [scoreId]: scoreValue,
      }));
    })

    socket.on(SocketChannels.EndSession, () => {
      dispatch(actionSetEndSession({ sessionStatus: SessionStatus.Finished }));
    });

    socket.on(SocketChannels.RecordStatusUpdate, (updatedRecord: SessionRecordResponseDto) => {
      dispatch(actionSetRecordStatus({ sessionRecord: updatedRecord }));
    });

    socket.on(SocketChannels.SessionRecordManipulationListener, (manipulation: SocketSessionRecordManipulated) => {
      dispatch(actionSetSessionManipulationListener({ sessionManipulation: manipulation }));
    });

    socket.on(SocketChannels.CertificationProcessUpdate, payload => {
      dispatch(
        actionCertificationRoomUpdate({
          progress: {
            type: 'SUCCESS',
            result: payload,
          },
        }),
      );
    });

    const currentUser = localStorage.getItem('user')
      ? JSON.parse((localStorage as Storage).getItem('user') || '')
      : null;

    if (currentUser) {
      coachSubscribeToAdHoc(currentUser.id);
    }

    ws = {
      socket: socket,
      joinRoom,
      getRoomStatus,
      sessionRecordManipulated,
      coachSubscribeToAdHoc,
      coachUpdateRating,
      emitDisconnect,
    };
  }
  return <WebSocketContext.Provider value={ws}>{children}</WebSocketContext.Provider>;
};

export { WebSocketContext };
