import axios from 'axios';

export class ApiBaseService {
  public static baseUrl() {
    if (!process.env.API_URL_PREFIX) {
      throw new Error('API_URL_PREFIX not defined');
    }
    return process.env.API_URL_PREFIX;
  }

  public static fullUri(uriPath: string) {
    return this.baseUrl() + uriPath;
  }

  static _Post(
    uriPath: string,
    payload: any,
    responseType: 'json' | 'arraybuffer',
    handleSuccess: (status: number, data: any) => void,
    handleError: (error: Error) => void,
  ) {
    const uri = this.fullUri(uriPath);
    axios
      .post(uri, payload, {
        withCredentials: true,
        responseType,
      })
      .then(res => {
        const { data, status } = res;
        handleSuccess(status, data);
      })
      .catch(err => {
        handleError(err);
      });
  }

  static Post(
    uriPath: string,
    payload: any,
    handleSuccess: (status: number, data: any) => void,
    handleError: (error: Error) => void,
  ) {
    this._Post(uriPath, payload, 'json', handleSuccess, handleError);
  }

  static PostArrayBufferAccept(
    uriPath: string,
    payload: any,
    handleSuccess: (status: number, data: any) => void,
    handleError: (error: Error) => void,
  ) {
    this._Post(uriPath, payload, 'arraybuffer', handleSuccess, handleError);
  }

  static Patch(
    uriPath: string,
    payload: any,
    handleSuccess: (status: number, data: any) => void,
    handleError: (error: Error) => void,
  ) {
    const uri = this.fullUri(uriPath);
    axios
      .patch(uri, payload, { withCredentials: true })
      .then(res => {
        const { data, status } = res;
        handleSuccess(status, data);
      })
      .catch(err => {
        handleError(err);
      });
  }

  static Get(
    uriPath: string,
    payload: Object,
    handleSuccess: (status: number, data: any) => void,
    handleError: (error: Error) => void,
  ) {
    const uri = this.fullUri(uriPath);
    axios
      .get(uri, { params: payload, withCredentials: true })
      .then(res => {
        const { data, status } = res;
        handleSuccess(status, data);
      })
      .catch(err => {
        handleError(err);
      });
  }

  static Delete(
    uriPath: string,
    handleSuccess: (status: number, data: any) => void,
    handleError: (error: Error) => void,
  ) {
    const uri = this.fullUri(uriPath);
    axios
      .delete(uri, { withCredentials: true })
      .then(res => {
        const { data, status } = res;
        handleSuccess(status, data);
      })
      .catch(err => {
        handleError(err);
      });
  }
}

export const queryToUrl = (obj: any) => {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      if (obj[p]) str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  return '?' + str.join('&');
};
