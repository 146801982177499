import { CertificationResponseDto } from '../../../service/dto/certification.dto';
import { UserSummaryResponseDto } from '../../../service/dto/user-summary.response.dto';
import { StateDeleted, StateEmpty, StateError, StateLoading, StateSuccess } from '../../../redux/common';

type CertificationDetailsInfoEmpty = StateEmpty;
type CertificationDetailsInfoLoading = StateLoading;
type CertificationDetailsInfoError = StateError;
type CertificationDetailsInfoSuccess = StateSuccess<CertificationResponseDto>;
type CertificationDetailsInfoDeleted = StateDeleted;
type CertificationDetailsInfo =
  | CertificationDetailsInfoEmpty
  | CertificationDetailsInfoSuccess
  | CertificationDetailsInfoError
  | CertificationDetailsInfoLoading
  | CertificationDetailsInfoDeleted;

type CertificationCoachesEmpty = StateEmpty;
type CertificationCoachesLoading = StateLoading;
type CertificationCoachesError = StateError;
type CertificationCoachesSuccess = StateSuccess<UserSummaryResponseDto[]>;
type CertificationCoaches =
  | CertificationCoachesEmpty
  | CertificationCoachesLoading
  | CertificationCoachesSuccess
  | CertificationCoachesError;

export type CertificationDetailsState = {
  certification: CertificationDetailsInfo;
  certifiedCoaches: CertificationCoaches;
};

export const defaultCertificationDetailsState: CertificationDetailsState = {
  certification: {
    type: 'EMPTY',
  },
  certifiedCoaches: {
    type: 'EMPTY',
  },
};
