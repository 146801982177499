import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';

// EXTERNAL LIBRARIES
import Alert from '../../utils/alert';

// SERVICE
import { UserService } from '../../service/user.service';
import { UserEditListDto, UserResponseDto } from '../../service/dto/user.dto';

// MODAL
import AbstractModal from '../../components/AbstractModal';
import ModalContent from '../../components/ModalContent';
import InviteUserForm from './InviteUserForm';
import EditUserForm from './EditUserForm';

// UI COMPONENTS
import UserTable from './UserTable';
import SearchField from '../../components/SearchField';

// ROUTES
import { IRouteComponent } from '../../routes';
import { USER_ROLES } from '../../common/enums';

// CONSTANTS
import { userPageSize } from '../../common/constants';

// COMPONENTS
import DefaultHeader from '../../components/DefaultHeader';
import CustomSelect, { ICustomSelectItem } from '../../components/CustomSelect';
import CustomMultiSelect, { ICustomSelectedItem } from '../../components/CustomMultiSelect';
import PrimaryButton from '../../components/PrimaryButton';
import InsightsFilterFromUntil from '../insights/InsightsFilterFromUntil';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import {
  setUserDateRegistrationFilterFromUntil,
  setUsersRoleFilter,
  setUsersSearchFilter,
  setUsersSort
} from '../../redux/actions';

enum COMPONENT_STATE {
  LIST,
  INVITE,
  EDIT,
  DELETE,
  REMOVE,
}

const initialValue: UserEditListDto = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  roles: [],
  isSuperAdmin: false,
};

const UserList = ({ currentUser, currentInstitution }: IRouteComponent) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [state, setState] = useState(COMPONENT_STATE.LIST);

  const { from, until }: { from: Date; until: Date } = useAppSelector((state: any) => state.userDateRegistrationFilterReducer);
  const { search }: { search: string } = useAppSelector((state: any) => state.usersSearchFilterReducer);
  const { roleFilter }: { roleFilter: USER_ROLES[] } = useAppSelector((state: any) => state.usersRoleFilterReducer);
  const { sortUser }: { sortUser: string} = useAppSelector((state: any) => state.usersSortReducer);
  const [list, setList] = useState<UserEditListDto[]>([]);
  const [data, setData] = useState<UserEditListDto>(initialValue);
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [page, setPage] = useState(0);
  const [totalUsers, setTotalUsers] = useState<number>(0);
  const [resetFilter, setResetFilter] = useState<boolean>(false);

  const sortList: ICustomSelectItem[] = [
      {label: t('inputLabels.email'), value: 'email'},
      {label: t('inputLabels.firstName'), value: 'firstName'},
      {label: t('inputLabels.lastName'), value: 'lastName'},
      {label: t('inputLabels.registrationDate'), value: 'institutions.createdAt'}
  ];

  const filterRole: ICustomSelectedItem[] = [
    {label: t('user.admin'), value: USER_ROLES.Admin},
    {label: t('user.coach'), value: USER_ROLES.SP},
    {label: t('user.trainee'), value: USER_ROLES.Trainee},
  ]

  useEffect(() => {
    setPage(0);
    serviceCountFetch();
    serviceDataFetch();
  }, [search, sortUser, roleFilter, from, until]);

  useEffect(() => {
    serviceDataFetch();
  }, [page]);

  const onSearch = (value: string) => {
    dispatch(setUsersSearchFilter({ search: value }));
  };

  // SERVICE FETCH
  const serviceDataFetch = () => {
    UserService.list(
      { search: search, page: page, pageSize: userPageSize, sort: sortUser, rolesFilter: roleFilter, from, until},
      (data: Array<UserResponseDto>) => {
        const newData: UserEditListDto[] = data.map(d => {
          return {
            id: d.id,
            firstName: d.firstName,
            lastName: d.lastName,
            email: d.email,
            roles: d.institutions.filter(i => i.institutionId === currentInstitution.id)[0].roles,
            isSuperAdmin: d.isSuperAdmin,
            registrationDate: new Date(d.institutions.filter(i => i.institutionId === currentInstitution.id)[0].createdAt),
          };
        });
        setList(newData);
      },
      error => {
        setList([]); //TODO: catch error and handle appropriately
      },
    );
  };

  const serviceCountFetch = () => {
    UserService.count(
        { search: search, page: page, pageSize: userPageSize, sort: sortUser, rolesFilter: roleFilter, from, until},
      (data: number) => {
        setTotalUsers(data);
      },
      error => {
        setTotalUsers(0);
        setPage(0);
      },
    );
  };

  // SERVICE UPDATE
  const serviceUpdate = () => {
    switch (data.roles[0]) {
      case USER_ROLES.Admin:
        data.roles = [USER_ROLES.Admin, USER_ROLES.SP, USER_ROLES.Trainee];
        break;
      case USER_ROLES.SP:
        data.roles = [USER_ROLES.SP, USER_ROLES.Trainee];
        break;
      case USER_ROLES.Trainee:
        data.roles = [USER_ROLES.Trainee];
        break;
      default:
        data.roles = [];
    }
    UserService.updateByAdmin(
      list[selectedIndex].id,
      data,
      (data: UserResponseDto) => {
        serviceDataFetch();
        setState(COMPONENT_STATE.LIST);
        Alert.success('User was successfully updated.');
      },
      error => {
        console.log(error);
        Alert.warning('Error while updating user.');
      },
    );
  };
  const serviceRemove = () => {
    UserService.removeUserFromCurrentInstitution(
      list[selectedIndex].id,
      {},
      () => {
        serviceDataFetch();
        setState(COMPONENT_STATE.LIST);
        Alert.success('User was successfully removed from institution.');
      },
      error => {
        console.log(error);
        Alert.warning('Error while removing user.');
      },
    );
  };

  const onPageChange = (event: { selected: number }) => {
    setPage(event.selected);
  };

  // SERVICE DELETE
  const handleDelete = () => {
    return;
    //TODO: refactor to 'remove from institution'
    UserService.delete(
      list[selectedIndex].id,
      () => {
        serviceDataFetch();
        setState(COMPONENT_STATE.LIST);
        Alert.success(t('successMessages.userDeleted'));
      },
      error => {
        Alert.warning(t('errorMessages.userNotDeleted'));
      },
    );
  };

  const handleChange = (fieldName: string, value: any) => {
    const newData: any = Object.assign({}, data);
    newData[fieldName] = value;
    setData(newData);
  };

  const handleSubmit = () => {
    if (state == COMPONENT_STATE.EDIT) {
      serviceUpdate();
    }
  };


  const handleRoleFilterChange = (roles: USER_ROLES[]) => {
    dispatch(setUsersRoleFilter( { roleFilter: roles }));
  };

  const cleanFilters = () => {
    dispatch(setUsersSearchFilter({ search: '' }));
    dispatch(setUsersRoleFilter( { roleFilter: [] }));
    dispatch(setUsersSort({ sortUser: undefined }));
    dispatch(setUserDateRegistrationFilterFromUntil( { from: undefined, until: undefined }));
    setResetFilter(true);
  }

  const title = t('user.title') + ' ' + currentInstitution.name + ' (' + totalUsers + ')';

  return (
    <div className='rounded-lg bg-white my-6 shadow pb-2 mx-4 2xl:mx-6'>
      <DefaultHeader
        title={title}
        primaryAction={{
          title: t('buttons.inviteNewUser'),
          onClick: () => setState(COMPONENT_STATE.INVITE),
        }}
      />
      <div className='xl:w-1/3 2xl:pr-40 5xl:pr-48 lg:pr-6 xl:pr-0 w-1/2'>
        <InsightsFilterFromUntil
            key={resetFilter ? 0 : 1}
            from={from}
            labelFrom={t('inputLabels.registrationFrom')}
            until={until}
            maxUntil={new Date()}
            styleUntil={'w-44'}
            styleFrom={'w-44'}
            onChange={({ from, until})=>{
              dispatch(setUserDateRegistrationFilterFromUntil( { from, until }));
            }}
        />
      </div>
      <div className='p-6 flex flex-row justify-between -mt-7'>
        <div className='mr-6 ml-1'>
          <SearchField onSearch={onSearch} value={search} width={'lg:w-95 5xl:w-112.5 sm:w-91'}/>
        </div>
        <div className='mr-6 mt-0.5'>
          <CustomMultiSelect
              list={filterRole}
              handleChange={handleRoleFilterChange}
              placeholderAll={t('placeholders.allRoles')}
              placeholderSelected={t('placeholders.rolesSelected')}
              values={roleFilter}
          />
        </div>
        <div className='mr-6 w-48'>
          <CustomSelect
              placeholder={t('placeholders.sortBy')}
              list={sortList}
              reset={resetFilter}
              value={sortUser}
              handleChange={(item: ICustomSelectItem)=>{
                setResetFilter(false);
                dispatch(setUsersSort({ sortUser: item.value }));
              }}
          />
        </div>
        <div className='mr-6'>
          <PrimaryButton
              title={t('buttons.reset')}
              className='h-full'
              onClick={cleanFilters}
          />
        </div>
      </div>

      <UserTable
        list={list}
        actions={[
          {
            action: (index: number) => {
              setSelectedIndex(index);
              setData(list[index]);
              setState(COMPONENT_STATE.EDIT);
            },
            buttonTitle: t('buttons.edit'),
          },
          {
            action: (index: number) => {
              setSelectedIndex(index);
              setState(COMPONENT_STATE.REMOVE);
            },
            buttonTitle: t('buttons.remove'),
          },
        ]}
        currentInstitution={currentInstitution}
      />

      {!!totalUsers && (
        <ReactPaginate
          previousLabel={<i className='bi bi-chevron-left' />}
          breakLabel='...'
          nextLabel={<i className='bi bi-chevron-right' />}
          onPageChange={onPageChange}
          pageRangeDisplayed={5}
          pageCount={Math.floor((totalUsers - 1) / userPageSize) + 1}
          containerClassName='pagination flex justify-center text-gray-dark'
          pageClassName='page-item'
          pageLinkClassName='page-link'
          previousClassName='page-item'
          previousLinkClassName='page-link'
          nextClassName='page-item'
          nextLinkClassName='page-link'
          activeClassName='active'
          breakClassName='page-item'
          breakLinkClassName='page-link'
          forcePage={page}
        />
      )}

      {state == COMPONENT_STATE.INVITE && (
        <AbstractModal
          position={{ width: 'w-1/2', left: 'left-1/3' }}
          label={t('buttons.inviteNewUser')}
          leftBtn={{
            label: t('buttons.cancel'),
            onClick: () => {
              setState(COMPONENT_STATE.LIST);
            },
          }}
        >
          <InviteUserForm currentInstitution={currentInstitution} />
        </AbstractModal>
      )}

      {state == COMPONENT_STATE.DELETE && (
        <AbstractModal
          position={{ width: 'w-1/3', left: 'left-1/3' }}
          label={t('modals.deleteUser')}
          leftBtn={{
            label: t('buttons.cancel'),
            onClick: () => {
              setState(COMPONENT_STATE.LIST);
            },
          }}
          rightBtn={{
            label: t('buttons.yesDelete'),
            onClick: () => {
              handleDelete();
            },
          }}
          toDelete
        >
          <ModalContent
            entity='user'
            title={t('auth.user.deleteModal.title')}
            subtitle={t('auth.user.deleteModal.subtitle')}
          />
        </AbstractModal>
      )}

      {state == COMPONENT_STATE.EDIT && (
        <AbstractModal
          position={{ width: 'w-1/2', left: 'left-1/3' }}
          label='Edit User'
          leftBtn={{
            label: 'cancel',
            onClick: () => {
              setState(COMPONENT_STATE.LIST);
            },
          }}
          rightBtn={{
            label: 'save changes',
            onClick: () => {
              handleSubmit();
            },
          }}
        >
          <EditUserForm
            errors={errors}
            payload={data}
            handleChange={handleChange}
            currentInstitution={currentInstitution}
          />
        </AbstractModal>
      )}

      {state == COMPONENT_STATE.REMOVE && (
        <AbstractModal
          position={{ width: 'w-1/2', left: 'left-1/3' }}
          label='Remove User'
          leftBtn={{
            label: 'Cancel',
            onClick: () => {
              setState(COMPONENT_STATE.LIST);
            },
          }}
          rightBtn={{
            label: 'Yes, Remove',
            onClick: () => {
              serviceRemove();
            },
          }}
        >
          <h3>{t('modals.removerUser')}</h3>
        </AbstractModal>
      )}
    </div>
  );
};

export default UserList;
