import { CaseResponseDto } from './case.dto';
import { UserSummaryResponseDto } from './user-summary.response.dto';
import { ScoreItemDto, ScoreResultDto, SessionRecordResponseDto, TeachableMomentDto } from './session.dto';
export interface CertificationListQuery {
  onlyOwn?: boolean;
  showCertified: boolean;
  cases?: string[];
}

export enum CertificationStatus {
  Open = 'status:open',
  Accepted = 'status:accepted',
  Certified = 'status:certified',
}

export type CertificationResponseDto = {
  id: string;
  noviceCoach: UserSummaryResponseDto;
  certifiedCoach: UserSummaryResponseDto;
  status: CertificationStatus;
  isCertified: boolean;
  caseTemplate: CaseResponseDto;
  sessionProgress: CertificationSessionProgress;
};

export type CertificationSessionProgress = {
  coachId: string;
  traineeId: string;
  roomSid: string;
  roomName: string;
  isSimulateFeedback: boolean;
  endSessionRequestedBy: string | null;
  scoreResult: ScoreResultDto;
  teachableMoments?: TeachableMomentDto[];
  coachingSessionRecord?: SessionRecordResponseDto;
  feedbackSessionRecord?: SessionRecordResponseDto;
  isFinished: boolean;
  coachingSessionStart: string;
};

export type CertificationResponseWithProgressDto = CertificationResponseDto & {
  sessionProgress: CertificationSessionProgress;
};

export interface CreateCertificationRequestDto {
  caseTemplate: string;
}
