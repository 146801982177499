import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

// SERVICE
import { InstitutionService } from '../../service/institution.service';
import { CreateInstitutionRequestDto, InstitutionResponseDto } from '../../service/dto/institution.dto';

// MODAL
import AbstractModal from './../../components/AbstractModal';
import ModalContent from '../../components/ModalContent';

// FORM
import CreateInstitutionForm from './CreateInstitutionForm';

// UI COMPONENTS
import InstitutionTable from './InstitutionTable';
import SearchField from '../../components/SearchField';

//
import Alert from '../../utils/alert';
import DefaultHeader from '../../components/DefaultHeader';
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {setInstitutionSearchSelector} from "../../redux/actions";

enum COMPONENT_STATE {
  LIST,
  CREATE,
  EDIT,
  DELETE,
}

const initialValue = {
  name: '',
  code: '',
  timezone: 'Europe/Zurich',
};

const initialValidation = {
  name: '',
  code: '',
  timezone: '',
};

const InstitutionList = ({}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {search}: {search: string} = useAppSelector((state: any) => state.institutionSearchReducer);

  const [state, setState] = useState(COMPONENT_STATE.LIST);

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<InstitutionResponseDto[]>([]);

  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  const [data, setData] = useState<CreateInstitutionRequestDto>(initialValue);
  const [errors, setErrors] = useState(initialValidation);

  useEffect(() => {
    serviceDataFetch();
  }, [loading, search]);

  const onSearch = (value: string) => {
    dispatch(setInstitutionSearchSelector({ search: value}));
  };

  // SERVICE FETCH
  const serviceDataFetch = () => {
    InstitutionService.list(
      { search: search },
      (data: Array<InstitutionResponseDto>) => {
        setList(data);
      },
      error => {
        setList([]);
      },
    );
  };

  // SERVICE CHECK BY CODE
  const institutionServiceByCode = (code: string, onExist: Function, onNotExist: Function) => {
    InstitutionService.byCode(
      code,
      (data: InstitutionResponseDto) => {
        onExist(data);
      },
      error => {
        onNotExist();
      },
    );
  };

  // SERVICE CREATE
  const serviceCreate = () => {
    InstitutionService.create(
      data,
      (data: InstitutionResponseDto) => {
        serviceDataFetch();
        setState(COMPONENT_STATE.LIST);
        Alert.success(t('successMessages.institutionCreated'));
        window.location.reload();
      },
      error => {
        console.log(error);
        Alert.warning(t('errorMessages.institutionNotCreated'));
      },
    );
  };

  // SERVICE UPDATE
  const serviceUpdate = () => {
    InstitutionService.update(
      list[selectedIndex].id,
      data,
      (data: InstitutionResponseDto) => {
        serviceDataFetch();
        setState(COMPONENT_STATE.LIST);
        Alert.success(t('successMessages.institutionUpdated'));
        window.location.reload();
      },
      error => {
        console.log(error);
        Alert.warning(t('errorMessages.institutionNotUpdated'));
      },
    );
  };

  // SERVICE DELETE
  const serviceDelete = () => {
    InstitutionService.delete(
      list[selectedIndex].id,
      () => {
        serviceDataFetch();
        setState(COMPONENT_STATE.LIST);
        Alert.success(t('successMessages.institutionDeleted'));
      },
      error => {
        Alert.warning(t('errorMessages.institutionNotDeleted'));
      },
    );
  };

  const handleChange = (fieldName: string, value: any) => {
    const newData = Object.assign({}, data);
    newData[fieldName] = value;
    setData(newData);
  };

  const handleSubmit = () => {
    if (state == COMPONENT_STATE.CREATE) {
      institutionServiceByCode(
        data.code,
        (payload: any) => {
          // if exists
          setErrors({
            ...errors,
            code: 'Code is taken by “' + payload.name + '”',
          });
        },
        () => {
          // if NOT exists
          setErrors(initialValidation);
          serviceCreate();
        },
      );
    }

    if (state == COMPONENT_STATE.EDIT) {
      serviceUpdate();
    }
  };

  const title = t('institutions.title') + ' (' + list.length + ')';
  return (
    <div className='rounded-lg bg-white my-6 shadow pb-2 mx-4 2xl:mx-6'>
      <DefaultHeader
        title={title}
        primaryAction={{
          title: t('buttons.createANewInstitution'),
          onClick: () => {
            setData(initialValue);
            setState(COMPONENT_STATE.CREATE);
          },
        }}
      />

      <div className='p-6'>
        <div className='flex'>
          <div className='mr-6'>
            <SearchField onSearch={onSearch} value={search}/>
          </div>
        </div>
      </div>

      <InstitutionTable
        list={list}
        onDelete={(index: number) => {
          setSelectedIndex(index);
          setState(COMPONENT_STATE.DELETE);
        }}
        onEdit={(index: number) => {
          setSelectedIndex(index);
          setData(list[index]);
          setState(COMPONENT_STATE.EDIT);
        }}
      />

      {(state == COMPONENT_STATE.CREATE || state == COMPONENT_STATE.EDIT) && (
        <AbstractModal
          label={state == COMPONENT_STATE.CREATE ? t('modals.createANewInstitution') : t('modals.updateInstitution')}
          leftBtn={{
            label: t('buttons.cancel'),
            onClick: () => {
              setState(COMPONENT_STATE.LIST);
            },
          }}
          rightBtn={{
            label: state == COMPONENT_STATE.CREATE ? t('modals.createInstitution') : t('modals.updateInstitution'),
            icon: 'bi-check2-circle',
            onClick: () => {
              handleSubmit();
            },
          }}
        >
          <CreateInstitutionForm errors={Object.assign(errors, {})} payload={data} handleChange={handleChange} />
        </AbstractModal>
      )}

      {state == COMPONENT_STATE.DELETE && (
        <AbstractModal
          label={t('modals.deleteInstitution')}
          leftBtn={{
            label: t('buttons.cancel'),
            onClick: () => {
              setState(COMPONENT_STATE.LIST);
            },
          }}
          rightBtn={{
            label: t('buttons.yesDelete'),
            onClick: () => {
              serviceDelete();
            },
          }}
          toDelete
        >
          <ModalContent
            entity='institution'
            title={t('institutions.deleteModal.title')}
            subtitle={t('institutions.deleteModal.subtitle')}
          />
        </AbstractModal>
      )}
    </div>
  );
};

export default InstitutionList;
