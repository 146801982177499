export const PercentageMultiplier = 100;

export const DurationMS = {
  SEC: 1000,
  MIN: 1000 * 60,
  HOUR: 1000 * 60 * 60,
  DAY: 1000 * 60 * 60 * 24,
};

export const scoreI18NKey = new Map([
  [0, 'score_marks.none'],
  [1, 'score_marks.unsatisfactory'],
  [2, 'score_marks.satisfactory'],
  [3, 'score_marks.good'],
]);

export const scoreColors = new Map([
  [0, 'transparent'],
  [0.5, '#000000'],
  [1, '#ff5858'],
  [2, '#eda800'],
  [3, '#0fc285'],
]);
