import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// FORM
import { useForm } from '../../hooks/useForm';
import { userRequestValidation } from '../../validation/user.validation';

// SERVICE
import { UserResponseDto, UpdateUserRequestDto } from '../../service/dto/user.dto';
import { UserService } from '../../service/user.service';

// REDUX
import { updateMe } from '../../redux/actions';
import { useAppDispatch } from '../../redux/store';

// UI COMPONENTS
import TextInput from '../../components/TextInput';
import InputLabel from '../../components/InputLabel';

import DefaultHeader from '../../components/DefaultHeader';

interface IUserEdit {
  currentUser: UserResponseDto;
}

enum COMPONENT_STATE {
  INIT,
  SUCCESS,
  FAIL,
}

const UserEdit = ({ currentUser }: IUserEdit) => {
  const { t } = useTranslation();
  const [state, setState] = useState(COMPONENT_STATE.INIT);
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    handleChange,
    data: payload,
    errors,
  } = useForm<UpdateUserRequestDto>({
    validations: userRequestValidation,

    onSubmit: () => {
      UserService.update(
        currentUser.id,
        payload,
        data => {
          dispatch(updateMe(data));
          setState(COMPONENT_STATE.SUCCESS);
        },
        () => {
          setState(COMPONENT_STATE.FAIL);
        },
      );
    },
  });
  return (
    <div className='rounded-lg bg-white my-6 shadow pb-2 mx-4 2xl:mx-6'>
      <div className='container p-6'>
        {(state == COMPONENT_STATE.INIT || state == COMPONENT_STATE.FAIL) && (
          <form className='my-6 mx-4 2xl:mx-6' onSubmit={handleSubmit}>
            <div className='w-full'>
              <DefaultHeader
                style={'pb-2'}
                title={t('user.editProfile')}
                primaryAction={{
                  title: t('buttons.save'),
                  onClick: () => {
                    return true;
                  },
                  icon: 'bi bi-check2-circle',
                  type: 'submit',
                }}
              />
              <hr className='w-full h-px bg-black-divider border-0 mt-4 mb-6' />
            </div>
            <div className='mb-6 w-1/4'>
              <InputLabel label={t('inputLabels.firstName')} />
              <TextInput
                placeholder={t('placeholders.firstName')}
                value={payload.firstName || currentUser.firstName}
                handleChange={handleChange('firstName')}
                error={errors.firstName}
                required
              />
            </div>
            <div className='mb-6 w-1/4'>
              <InputLabel label={t('inputLabels.lastName')} />
              <TextInput
                placeholder={t('placeholders.lastName')}
                value={payload.lastName || currentUser.lastName}
                handleChange={handleChange('lastName')}
                error={errors.lastName}
                required
              />
            </div>
            {state == COMPONENT_STATE.FAIL && (
              <div className='rounded-lg text-center text-negative w-full border border-solid border-negative'>
                {t('errorMessages.failState')}
              </div>
            )}
          </form>
        )}
        {state === COMPONENT_STATE.SUCCESS && (
          <>
            <div className='text-center'>
              <div className='text-2xl font-bold my-10'>{t('user.editProfile')}</div>
              <div className='text-base mb-10'>{t('successMessages.profileUpdated')}</div>
            </div>
            <hr className='w-full h-px bg-black-divider border-0 mb-10' />
            <div className='text-base mb-10 text-center'>
              <Link className='text-primary underline' to='/user/profile' replace>
                {t('auth.linkTo.backProfile')}
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default UserEdit;
