import { StateEmpty, StateError, StateLoading, StateSuccess } from '../../../redux/common';
import { CaseTemplateSummaryDto } from '../../../service/dto/case.dto';
import { CertificationResponseDto } from '../../../service/dto/certification.dto';
import { ICustomSelectItem } from '../../../components/CustomSelect';

type CertificationCasesListEmpty = StateEmpty;
type CertificationCasesListLoading = StateLoading;
type CertificationCasesListError = StateError;
type CertificationCasesListSuccess = StateSuccess<CaseTemplateSummaryDto[]>;
type CertificationCasesList =
  | CertificationCasesListEmpty
  | CertificationCasesListLoading
  | CertificationCasesListError
  | CertificationCasesListSuccess;

type CertificationDtoListEmpty = StateEmpty;
type CertificationDtoListLoading = StateLoading;
type CertificationDtoListError = StateError;
type CertificationDtoListSuccess = StateSuccess<CertificationResponseDto[]>;
type CertificationDtoList =
  | CertificationDtoListEmpty
  | CertificationDtoListLoading
  | CertificationDtoListError
  | CertificationDtoListSuccess;

type CertificationCreatedEmpty = StateEmpty;
type CertificationCreatedLoading = StateLoading;
type CertificationCreatedError = StateError;
type CertificationCreatedSuccess = StateSuccess<CertificationResponseDto>;
type CertificationCreated =
  | CertificationCreatedEmpty
  | CertificationCreatedLoading
  | CertificationCreatedError
  | CertificationCreatedSuccess;

export type CertificationListState = {
  created: CertificationCreated;
  certifications: CertificationDtoList;
  availableCases: CertificationCasesList;
  notCertifiedCases: CaseTemplateSummaryDto[];
  selectedCaseForCertification: ICustomSelectItem | null;
  filter: {
    casesToFilter: string[];
    showCertified: boolean;
  };
};

export const defaultCertificationListState: CertificationListState = {
  created: {
    type: 'EMPTY',
  },
  certifications: {
    type: 'EMPTY',
  },
  availableCases: {
    type: 'EMPTY',
  },
  notCertifiedCases: [],
  selectedCaseForCertification: null,
  filter: {
    casesToFilter: [],
    showCertified: false,
  },
};
