import { CertificationListState, defaultCertificationListState } from './list/state';
import { CertificationDetailsState, defaultCertificationDetailsState } from './detail/state';
import { CertificationRoomState, defaultCertificationRoomState } from './room/state';

export type CertificationState = {
  list: CertificationListState;
  details: CertificationDetailsState;
  room: CertificationRoomState;
};

export const defaultCertificationState: CertificationState = {
  list: defaultCertificationListState,
  details: defaultCertificationDetailsState,
  room: defaultCertificationRoomState,
};
