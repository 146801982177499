import React from 'react';
import { useTranslation } from 'react-i18next';
import Tag, { TagType } from '../../components/Tag';
import { DurationMS } from '../../utils/constants';
import { SessionResponseDto } from '../../service/dto/session.dto';
import { getDateAndTimeDuration } from '../../utils';
import { SessionStatus, SessionType, USER_ROLES } from '../../common/enums';
import { ICurrentUser } from '../../common/interfaces';

interface ITableProps {
  sessionList: Array<SessionResponseDto>;
  currentInstitution: any;
  roles: USER_ROLES[];
  cancelSession: (id: string, number: number) => void;
  bookSession: (id: string, number: number) => void;
  detailsSession: (id: string, number: number) => void;
  resultSession: (id: string, number: number) => void;
  currentUser: ICurrentUser;
  sessionType?: SessionType;
}

const ScheduleTable = ({
  sessionList,
  roles,
  cancelSession,
  bookSession,
  detailsSession,
  resultSession,
  currentUser,
  sessionType = SessionType.RegularSession,
}: ITableProps) => {
  const { t } = useTranslation();

  const renderTime = (index: number) => {
    // Get item from list by array index
    const sessionItem: SessionResponseDto = sessionList[index];

    const { startTime, endTime, diff, monthLabel, month, day } = getDateAndTimeDuration(sessionItem);
    const today: Date = new Date();

    // Check if show date label withing the same day sessions
    let showDateLabel = false;
    if (index != 0 && sessionList[index - 1]) {
      const prev = sessionList[index - 1];
      const prevDate = new Date(prev.startDate);
      if (prevDate.getMonth() != month || prevDate.getDate() != day) {
        showDateLabel = true;
      }
    } else {
      showDateLabel = true;
    }

    const dateLabel = today.getMonth() == month && today.getDate() == day ? 'Today' : day + ' ' + monthLabel;

    return (
      <div className='flex items-center'>
        <div className={`mr-2${showDateLabel ? '' : ' text-transparent'}`}>{dateLabel}</div>
        <div>
          <div>
            {startTime} - {endTime}
          </div>
          <span className='font-thin text-xs text-gray'>{diff} MIN</span>
        </div>
      </div>
    );
  };

  const moreThan2HourBeforeStart = (startDate: string) => {
    return Date.now() < new Date(startDate).getTime() - 2 * DurationMS.HOUR;
  };

  return (
    <table className='dataTable table-fixed w-full bg-white px-4'>
      <thead>
        <tr className='font-bold bg-ghostwhite border border-solid border-gray-lighter'>
          {sessionType === SessionType.RegularSession && <td className='py-4 w-1/5'>{t('table.time')}</td>}
          <td className='text-gray-dark font-bold py-4 w-1/4'>{t('table.case')}</td>
          <td className='text-gray-dark font-bold py-4 w-1/6'>{t('table.category')}</td>

          {roles.includes(USER_ROLES.Trainee) && (
            <td className='text-gray-dark font-bold py-4 w-1/6'>{t('table.coach')}</td>
          )}

          {roles.includes(USER_ROLES.SP) && (
            <td className='text-gray-dark font-bold py-4 w-1/6'>{t('table.trainee')}</td>
          )}

          <td className='text-gray-dark font-bold py-4 w-1/6'>{t('table.status')}</td>
          <td className='text-gray-light py-4 w-1/5'>{t('table.actions')}</td>
        </tr>
      </thead>

      <tbody>
        {sessionList.map((sessionItem: SessionResponseDto, index: number) => {
          return (
            <tr className='border border-solid border-black-divider' key={sessionItem.id}>
              {sessionType === SessionType.RegularSession && (
                <td className='text-gray-dark py-4'>{renderTime(index)}</td>
              )}
              <td className='text-gray-dark py-4'>{sessionItem.caseTemplateSummary.name}</td>
              <td className='text-gray-dark py-4'>{sessionItem.categoryName}</td>

              {roles.includes(USER_ROLES.Trainee) && (
                <td className='text-gray-dark py-4'>
                  {sessionItem.coach && sessionItem.coach.firstName + ' ' + sessionItem.coach.lastName}
                </td>
              )}
              {roles.includes(USER_ROLES.SP) && (
                <td className='text-gray-dark py-4'>
                  {sessionItem.trainee ? sessionItem.trainee.firstName + ' ' + sessionItem.trainee.lastName : '--'}
                </td>
              )}

              <td className='py-4'>
                {sessionItem.status == SessionStatus.Open && <Tag label='Open' type={TagType.Yellow} />}
                {sessionItem.status == SessionStatus.Scheduled && <Tag label='Scheduled' type={TagType.Green} />}
                {sessionItem.status == SessionStatus.Coaching && <Tag label='Coaching' type={TagType.Green} />}
                {sessionItem.status == SessionStatus.Feedback && <Tag label='Feedback' type={TagType.Green} />}
                {sessionItem.status == SessionStatus.Finished && <Tag label='Finished' type={TagType.Blue} />}
              </td>

              <td className='py-4'>
                <div className='flex flex-wrap'>
                  <span
                    className='text-primary cursor-pointer pr-2'
                    onClick={() => {
                      if (sessionItem.status === SessionStatus.Finished) {
                        resultSession(sessionItem.id, index);
                      } else {
                        detailsSession(sessionItem.id, index);
                      }
                    }}
                  >
                    {t('buttons.session_details')}
                  </span>

                  {sessionItem.coach.id === currentUser.id && sessionItem.status !== SessionStatus.Finished ? (
                    <span
                      className='text-negative-darker cursor-pointer pr-2'
                      onClick={() => {
                        cancelSession(sessionItem.id, index);
                      }}
                    >
                      {t('buttons.cancel_session')}
                    </span>
                  ) : (
                    <>
                      {sessionItem.status == SessionStatus.Scheduled &&
                        (sessionItem.coach.id === currentUser.id || sessionItem.trainee.id === currentUser.id) && (
                          <>
                            <span
                              className='text-negative-darker cursor-pointer pr-2'
                              onClick={() => {
                                cancelSession(sessionItem.id, index);
                              }}
                            >
                              {t('buttons.cancel_session')}
                            </span>
                          </>
                        )}
                    </>
                  )}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ScheduleTable;
