export const SaveCaseRequestValidation = {
  name: { required: true, message: 'cases.validationErrors.name' },
  description: { required: false },
  author: { required: false },
  durationInterview: { required: false },
  durationFeedback: { required: false },
  instructions: { required: false },
  scoring_items: { required: false },
  certified_coaches: { required: false },
};

export const PublishCaseRequestValidation = {
  name: { required: true, message: 'cases.validationErrors.name' },
  description: { required: true, message: 'cases.validationErrors.description' },
  author: { required: true, message: 'cases.validationErrors.author' },
  interview_duration: { required: true, message: 'cases.validationErrors.interview_duration' },
  feedback_duration: { required: true, message: 'cases.validationErrors.feedback_duration' },
  instructions: { required: true, message: 'cases.validationErrors.instructions' },
  scoring_items: { required: false },
  certified_coaches: { required: true, minLength: 1, message: 'cases.validationErrors.certified_coaches' },
};

export interface ErrorDictionary {
  [field: string]: string;
}
